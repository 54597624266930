import { Box, Card, Flex, Text, UnstyledButton } from '@mantine/core'
import { IconCirclePlus } from '@tabler/icons-react'
import { FormattedMessage } from 'react-intl'
import { AnimatedCard } from '@/components/ui-shared/AnimatedCard/AnimatedCard'

const PlusIcon = () => (
  <Box h={110}>
    <Flex h={95} justify="center" align="end">
      <IconCirclePlus size={32} />
    </Flex>
  </Box>
)

type CreateApplicationButtonProps = {
  onClick: () => void
}

export const CreateApplicationButton = ({
  onClick
}: CreateApplicationButtonProps) => {
  return (
    <UnstyledButton onClick={onClick}>
      <AnimatedCard h={175}>
        <Card.Section>
          <PlusIcon />
        </Card.Section>

        <Text size="sm" fw="bold" mt="lg" ta="center" truncate>
          <FormattedMessage id="apps.createApp" />
        </Text>
      </AnimatedCard>
    </UnstyledButton>
  )
}
