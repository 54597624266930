import { Box, Button, Card, Flex, Text, Title } from '@mantine/core'
import { ReactNode } from 'react'
import { FormattedMessage } from 'react-intl'
import { CARD_HEIGHT } from '../SubscriptionPlanCard/SubscriptionPlanCard'

type CustomPlanCardProps = {
  title: string | ReactNode
  onContactClick: () => void
}
export const CustomPlanCard = ({
  title,
  onContactClick
}: CustomPlanCardProps) => {
  return (
    <Card h={CARD_HEIGHT} padding="lg" radius="md" withBorder>
      <Flex h="100%" direction="column" justify="space-between">
        <Box>
          <Title order={3} mb={80}>
            {title}
          </Title>

          <Text size="sm" mb="lg">
            <FormattedMessage id="subscriptions.customPlan.needCustomSolution" />
          </Text>

          <Text size="sm">
            <FormattedMessage id="subscriptions.customPlan.letsDiscuss" />
          </Text>
        </Box>

        <Button size="md" radius="md" fullWidth onClick={onContactClick}>
          <FormattedMessage id="contact" />
        </Button>
      </Flex>
    </Card>
  )
}
