import {
  Avatar,
  Box,
  Group,
  LoadingOverlay,
  Stack,
  Text,
  Title
} from '@mantine/core'
import { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import KoenAvatar from '@/assets/avatars/koen.png'
import { APP_NAME } from '@/config/app'

const VIDEO_URL =
  'https://www.youtube.com/embed/DbdsyrrrCnM?si=KBRM9GIBf_F87duV'

export const VpDemoVideo = () => {
  const [isLoading, setIsLoading] = useState(true)

  return (
    <Group gap={60} align="start" justify="center">
      <Box pos="relative">
        <LoadingOverlay visible={isLoading} />

        <iframe
          width="500"
          height="280"
          src={VIDEO_URL}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerPolicy="strict-origin-when-cross-origin"
          style={{ display: 'block' }}
          allowFullScreen
          onLoad={() => setIsLoading(false)}
        />
      </Box>

      <Stack w={400}>
        <Title order={3}>
          <FormattedMessage
            id="welcomeTo"
            values={{
              appName: APP_NAME
            }}
          />
        </Title>

        <Text>
          <FormattedMessage
            id="demoVideo.description1"
            values={{
              appName: APP_NAME
            }}
          />
        </Text>

        <Text>
          <FormattedMessage id="demoVideo.description2" />
        </Text>

        <Group w={400} wrap="nowrap" align="center" mt="md">
          <Avatar src={KoenAvatar} size="lg" />

          <Text fw="bold" size="xs">
            Koen de Jong, CEO
          </Text>
        </Group>
      </Stack>
    </Group>
  )
}
