import { Stack, Text } from '@mantine/core'
import { IconBolt } from '@tabler/icons-react'
import { ModelStatus } from '@/components/models/ModelStatus/ModelStatus'
import { UserMLModelStatus } from '@/types/model'
import { SelectableCard } from '../../../../ui-shared/SelectableCard/SelectableCard'

type ModelCardProps = {
  name: string
  status: UserMLModelStatus
  isSelected?: boolean
  onClick?: () => void
}

export const ModelCard = ({
  name,
  status,
  isSelected,
  onClick
}: ModelCardProps) => {
  return (
    <SelectableCard isSelected={isSelected} onClick={onClick}>
      <Stack h="100%" align="center" justify="space-between">
        <IconBolt size={24} />

        <Text w="100%" ta="center" size="sm" fw={600} lineClamp={2}>
          {name}
        </Text>

        <ModelStatus status={status} />
      </Stack>
    </SelectableCard>
  )
}
