import { Group, Text, ThemeIcon } from '@mantine/core'
import { IconLine, IconVector } from '@tabler/icons-react'

type LineTitleProps = {
  type: string
  name: string
  color: string
}

export const LineTitle = ({ type, name, color }: LineTitleProps) => (
  <Group align="center" gap="xs">
    <ThemeIcon color={color} variant="light" size="sm">
      {type === 'line_crossing' ? (
        <IconLine style={{ width: '90%', height: '90%' }} />
      ) : (
        <IconVector style={{ width: '90%', height: '90%' }} />
      )}
    </ThemeIcon>

    <Text size="xs" fw={600} c={color}>
      {name}
    </Text>
  </Group>
)
