import { Drawer, Image, Modal, Tooltip, createTheme } from '@mantine/core'

export const theme = createTheme({
  /** Put your mantine theme override here */
  colors: {
    'brand-primary': [
      '#c3c5d8',
      '#989cb8',
      '#6d7299',
      '#424879',
      '#272c5a',
      '#171b40', // Base color
      '#131735',
      '#0f132a',
      '#0b0f1f',
      '#070b14'
    ],
    'brand-secondary': [
      '#f8d3cf',
      '#f3b6ad',
      '#ef998b',
      '#eb7c6a',
      '#e85f49',
      '#ee6f61', // Base color
      '#cb594e',
      '#a8463d',
      '#86332c',
      '#64201b'
    ]
  },
  primaryColor: 'brand-primary',
  primaryShade: 5,
  components: {
    Modal: Modal.extend({
      defaultProps: {
        padding: 'lg'
      },
      styles: {
        title: {
          fontWeight: 'bold'
        }
      }
    }),
    Drawer: Drawer.extend({
      defaultProps: {
        padding: 'lg'
      },
      styles: {
        title: {
          fontWeight: 'bold'
        }
      }
    }),
    Tooltip: Tooltip.extend({
      defaultProps: {
        withArrow: true,
        openDelay: 50,
        transitionProps: { duration: 200, transition: 'pop' }
      },
      styles: (theme) => ({
        tooltip: {
          fontSize: theme.fontSizes.xs
        }
      })
    }),
    Image: Image.extend({
      styles: {
        root: {
          flex: '0 1 auto' // TODO remove after https://github.com/mantinedev/mantine/pull/5641#issuecomment-2152151729 is resolved
        }
      }
    })
  }
})
