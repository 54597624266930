type Params = Record<string, unknown>

export const objectToQueryString = (params: Params): string => {
  return Object.entries(params)
    .filter(([, value]) => value !== undefined && value !== null)
    .map(([key, value]) => {
      let stringValue: string

      if (value instanceof Date) {
        stringValue = value.toISOString()
      } else {
        stringValue = typeof value === 'string' ? value : String(value)
      }

      return `${encodeURIComponent(key)}=${encodeURIComponent(stringValue)}`
    })
    .join('&')
}
