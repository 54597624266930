import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient
} from '@tanstack/react-query'
import { useCallback } from 'react'
import { modelAPI } from '@/api/modelApi'
import { UserMLModelStatus } from '@/types/model'
import { objectToQueryString } from '@/utils/url'

type ModelListQueryParams = {
  limit?: number
  ordering?: string
  status?: UserMLModelStatus | UserMLModelStatus[]
  id?: string
}

type LibraryModelListQueryParams = {
  limit?: number
  ordering?: string
  is_master_model?: boolean
}

const defaultModelListQueryParams = {
  limit: 100,
  ordering: 'created_at'
}

export const modelQueryKeys = {
  all: ['models'] as const,
  listUserMlModels: (appId: string, params: ModelListQueryParams) =>
    [...modelQueryKeys.all, 'userMlModelsList', appId, params] as const,
  listLibraryMlModels: (params: LibraryModelListQueryParams) =>
    [...modelQueryKeys.all, 'libraryMlModelsList', params] as const,
  details: (appId: string, modelId: string) =>
    [...modelQueryKeys.all, 'details', appId, modelId] as const
}

export const useGetUserMlModels = (
  appId: string,
  params?: ModelListQueryParams
) => {
  const queryParams = {
    ...defaultModelListQueryParams,
    ...params
  }

  const queryString = objectToQueryString(queryParams)
  const initialPageParam = `/v1/applications/${appId}/models/?${queryString}`

  return useInfiniteQuery({
    queryKey: modelQueryKeys.listUserMlModels(appId, queryParams),
    queryFn: ({ pageParam }) =>
      modelAPI.getUserMLModels({ pageUrl: pageParam }),
    getNextPageParam: (lastPage) => {
      return lastPage.next ?? undefined
    },
    initialPageParam
  })
}

export const useGetLibraryMlModels = (params?: LibraryModelListQueryParams) => {
  const queryParams = {
    ...defaultModelListQueryParams,
    ...params
  }

  const queryString = objectToQueryString(queryParams)
  const initialPageParam = `/v1/applications/models/library/?${queryString}`

  return useInfiniteQuery({
    queryKey: modelQueryKeys.listLibraryMlModels(queryParams),
    queryFn: ({ pageParam }) =>
      modelAPI.getLibraryMLModels({ pageUrl: pageParam }),
    getNextPageParam: (lastPage) => {
      return lastPage.next ?? undefined
    },
    initialPageParam
  })
}

type UseGetModelDetailsParams = {
  appId: string
  modelId: string
  enabled?: boolean
}

export const useGetModelDetails = ({
  appId,
  modelId,
  enabled
}: UseGetModelDetailsParams) => {
  return useQuery({
    queryKey: modelQueryKeys.details(appId, modelId),
    queryFn: () => modelAPI.getModelDetails({ appId, modelId }),
    enabled
  })
}

export const useCreateModel = () => {
  return useMutation({
    mutationFn: modelAPI.createModel
  })
}

type UseUpdateModelParams = {
  appId: string
  modelId: string
}

export const useUpdateModel = ({ appId, modelId }: UseUpdateModelParams) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: modelAPI.updateModel,
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: modelQueryKeys.details(appId, modelId)
      })
  })
}

export const useInvalidateModelDetails = (appId: string, modelId: string) => {
  const queryClient = useQueryClient()

  const invalidateModelDetails = useCallback(() => {
    void queryClient.invalidateQueries({
      queryKey: modelQueryKeys.details(appId, modelId)
    })
  }, [queryClient, appId, modelId])

  return {
    invalidateModelDetails
  }
}

export const useStartInference = () => {
  return useMutation({
    mutationFn: modelAPI.inferModel
  })
}

export const useDeleteModel = () => {
  return useMutation({
    mutationFn: modelAPI.deleteModel
  })
}
