import { Box } from '@mantine/core'
import { useHotkeys } from '@mantine/hooks'
import Konva from 'konva'
import { KonvaEventObject } from 'konva/lib/Node'
import { useState } from 'react'
import { CalibrationToolbar } from '../ObjectMeasureHandler/CalibrationToolbar/CalibrationToolbar'
import { LineCompleted } from './Line/LineCompleted'
import { LineInProgress } from './Line/LineInProgress'
import { Line, useDrawLine } from './Line/useDrawLine'
import { Rectangle } from './Rectangle/Rectangle'
import { getCenteredRectangleCoordinates } from './Rectangle/helpers'
import { StageMask } from './StageMask/StageMask'
import { StageWithZoom } from './StageWithZoom/StageWithZoom'

const ACTIVE_AREA_PERCENT = 0.7

export type Polygon = {
  name: string
  color: string
  points: number[][]
}

const ROI_COLOR = '#FCC419' // yellow

type CalibrationSide = 'side1' | 'side2'

type CalibrationAreaProps = {
  imageUrl?: string
  isImageLoading?: boolean
  width: number
  height: number
  lines: Line[]
  rois: Polygon[]
  showHelpIcon: boolean
  onLineAdd: (line: Line) => void
  onLineChange: (lineIndex: number, line: Line) => void
  onRoiAdd: (roi: Polygon) => void
  onRoiChange: (roiIndex: number, roi: Polygon) => void
  onImageRefresh: () => void
}

export const CalibrationArea = ({
  imageUrl,
  isImageLoading,
  width,
  height,
  lines,
  rois,
  showHelpIcon,
  onLineAdd,
  onLineChange,
  onRoiAdd,
  onRoiChange,
  onImageRefresh
}: CalibrationAreaProps) => {
  const [activeShape, setActiveShape] = useState<
    Konva.Shape | Konva.Stage | null
  >(null)

  const {
    color: lineColor,
    points: linePoints,
    flattenedPoints: lineFlattenedPoints,
    isCompleted: isLineCompleted,
    start: startLine,
    exit: exitLine,
    handleMouseMove: handleLineMouseMove,
    handleMouseDown: handleLineMouseDown
  } = useDrawLine({ onLineAdd })

  useHotkeys([['Escape', exitLine]])

  const isAnnotating = !isLineCompleted

  const side1Completed = lines.some((line) => line.name === 'side1')
  const side2Completed = lines.some((line) => line.name === 'side2')
  const roiCompleted = rois.length > 0

  const handleStartCalibration = (side: CalibrationSide) => () => {
    exitLine()
    startLine(side)
  }

  const handleLineCoordinateChange = (
    lineIndex: number,
    points: number[][]
  ) => {
    onLineChange(lineIndex, {
      ...lines[lineIndex],
      points
    })
  }

  const handleAddRoi = () => {
    onRoiAdd({
      name: 'area1',
      color: ROI_COLOR,
      points: getCenteredRectangleCoordinates(width, height, 0.4)
    })
  }

  const handleRoiCoordinateChange = (roiIndex: number, points: number[][]) => {
    onRoiChange(roiIndex, {
      ...rois[roiIndex],
      points
    })
  }

  const handleStageMouseDown = (e: KonvaEventObject<MouseEvent>) => {
    if (isLineCompleted) {
      setActiveShape(e.target)
    } else {
      setActiveShape(null)
    }

    handleLineMouseDown(e)
  }

  return (
    <Box>
      <CalibrationToolbar
        side1Disabled={side1Completed}
        side2Disabled={side2Completed}
        roiDisabled={roiCompleted}
        hasCalibrations={side1Completed || side2Completed}
        showHelpIcon={showHelpIcon}
        onSide1Click={handleStartCalibration('side1')}
        onSide2Click={handleStartCalibration('side2')}
        onRoiClick={handleAddRoi}
      >
        <StageWithZoom
          width={width}
          height={height}
          imageUrl={imageUrl}
          isLoading={isImageLoading}
          isAnnotating={isAnnotating}
          onMouseMove={handleLineMouseMove}
          onMouseDown={handleStageMouseDown}
          onRefreshClick={onImageRefresh}
        >
          {rois.map((roi, roiIndex) => (
            <Rectangle
              key={roi.name}
              name={roi.name}
              color={roi.color}
              points={roi.points}
              showLabel={false}
              draggable={isLineCompleted}
              isActivated={
                activeShape?.getParent()?.name() === roi.name && isLineCompleted
              }
              dashed
              onCoordinateChange={(points) =>
                handleRoiCoordinateChange(roiIndex, points)
              }
            />
          ))}

          {lines.map((line, lineIndex) => (
            <LineCompleted
              key={line.name}
              name={line.name}
              color={line.color}
              points={line.points}
              onCoordinateChange={(points) =>
                handleLineCoordinateChange(lineIndex, points)
              }
            />
          ))}

          {!isLineCompleted && (
            <LineInProgress
              color={lineColor}
              points={linePoints}
              flattenedPoints={lineFlattenedPoints}
            />
          )}

          <StageMask
            width={width}
            height={height}
            activeAreaPercent={ACTIVE_AREA_PERCENT}
          />
        </StageWithZoom>
      </CalibrationToolbar>
    </Box>
  )
}
