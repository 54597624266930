import {
  ActionIcon,
  Button,
  Card,
  Center,
  Group,
  Modal,
  Stack,
  Text,
  Title,
  Tooltip
} from '@mantine/core'
import { useClipboard, useDisclosure } from '@mantine/hooks'
import { IconCopy, IconTool } from '@tabler/icons-react'
import { FormattedMessage } from 'react-intl'
import { ContactInfo } from '../../ui-shared/ContactInfo/ContactInfo'
import { VpLogo } from '../../ui-shared/VpLogo/VpLogo'

type ErrorPageProps = {
  errorId?: string
}

export const ErrorPage = ({ errorId }: ErrorPageProps) => {
  const clipboard = useClipboard()

  const [isContactUsModalOpened, { open, close }] = useDisclosure(false)

  const handleReload = () => {
    window.location.reload()
  }

  return (
    <>
      <Center mt="xl">
        <VpLogo variant="dark" />
      </Center>

      <Center mih={480}>
        <Stack justify="center" align="center" gap="xl">
          <Group wrap="nowrap">
            <IconTool size={32} />

            <Title order={2}>
              <FormattedMessage id="errorPage.title" />
            </Title>
          </Group>

          <Text>
            <FormattedMessage id="errorPage.description" />
          </Text>

          {errorId && (
            <Card p="sm" withBorder>
              <Group wrap="nowrap">
                <Text size="xs">
                  <FormattedMessage id="errorPage.id" />
                </Text>

                <Text size="xs" fw="bold">
                  {errorId}
                </Text>

                <Tooltip label={<FormattedMessage id="copy" />}>
                  <ActionIcon
                    variant="light"
                    size="sm"
                    onClick={() => clipboard.copy(errorId)}
                  >
                    <IconCopy
                      style={{ width: '70%', height: '70%' }}
                      stroke={1.5}
                    />
                  </ActionIcon>
                </Tooltip>
              </Group>
            </Card>
          )}
        </Stack>
      </Center>

      <Center>
        <Group>
          <Button miw={160} variant="outline" onClick={open}>
            <FormattedMessage id="contactUs" />
          </Button>

          <Button miw={160} onClick={handleReload}>
            <FormattedMessage id="reload" />
          </Button>
        </Group>
      </Center>

      <Modal
        title={<FormattedMessage id="contactUs" />}
        opened={isContactUsModalOpened}
        onClose={close}
      >
        <ContactInfo />
      </Modal>
    </>
  )
}
