import { Card, Image, Text } from '@mantine/core'
import ImagePlaceholder from '@/assets/image-placeholder.png'
import { SelectableCard } from '@/components/ui-shared/SelectableCard/SelectableCard'

type CameraSelectionCardProps = {
  isSelected?: boolean
  disabled?: boolean
  name: string
  thumbnail?: string
  onClick?: () => void
}

export const CameraSelectionCard = ({
  isSelected,
  disabled,
  name,
  thumbnail,
  onClick
}: CameraSelectionCardProps) => {
  return (
    <SelectableCard
      isSelected={isSelected}
      disabled={disabled}
      height={100}
      onClick={onClick}
    >
      <Card.Section>
        <Image
          h={60}
          fit="cover"
          src={thumbnail}
          fallbackSrc={ImagePlaceholder}
        />
      </Card.Section>

      <Card.Section px="xs" mt="xs">
        <Text w="100%" ta="center" size="xs" truncate>
          {name}
        </Text>
      </Card.Section>
    </SelectableCard>
  )
}
