import { Button } from '@mantine/core'
import { IconEye } from '@tabler/icons-react'
import { MouseEventHandler } from 'react'
import { FormattedMessage } from 'react-intl'

type PreviewButtonProps = {
  onClick: MouseEventHandler<HTMLButtonElement>
}

export const PreviewButton = ({ onClick }: PreviewButtonProps) => {
  return (
    <Button
      variant="subtle"
      color="white"
      size="xs"
      radius="xl"
      leftSection={<IconEye size={14} />}
      onClick={onClick}
    >
      <FormattedMessage id="datasets.clickToPreview" />
    </Button>
  )
}
