import { Card, Text } from '@mantine/core'
import { ReactNode } from 'react'

type EventInfoCardProps = {
  title: string | ReactNode
  children: ReactNode
}

export const EventInfoCard = ({ title, children }: EventInfoCardProps) => {
  return (
    <Card shadow="sm" radius="md" mb="md" withBorder>
      <Card.Section py="xs" bg="gray.2" withBorder inheritPadding>
        <Text fw={700}>{title}</Text>
      </Card.Section>

      {children}
    </Card>
  )
}
