import { Box, Card, LoadingOverlay } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { ErrorWithReload } from '@/components/ui-shared/ErrorWithReload/ErrorWithReload'
import { useGetMultipleDeviceDetails } from '@/queries/deviceQueries'
import { Deployment } from '@/types/deployment'
import { useDeploymentNavigation } from '../../deployment-editor/helpers/navigation'
import { DeploymentSteps } from '../../deployment-editor/types'
import { DeploymentsTable } from '../DeploymentsTable/DeploymentsTable'
import { StartDeploymentConfirmation } from '../StartDeploymentConfirmation/StartDeploymentConfirmation'
import { StopDeploymentConfirmation } from '../StopDeploymentConfirmation/StopDeploymentConfirmation'
import { buildDeploymentLines } from './buildDeploymentLines'

type DeploymentDetailsProps = {
  appId: string
  deployments: Deployment[]
  onRefresh: () => void
}

export const DeploymentDetails = ({
  appId,
  deployments,
  onRefresh
}: DeploymentDetailsProps) => {
  const [selectedDeploymentId, setSelectedDeploymentId] = useState<string>('')

  const { navigateToDeploymentStep } = useDeploymentNavigation()

  const [
    isStopDeploymentModalOpen,
    { open: openStopDeploymentModal, close: closeStopDeploymentModal }
  ] = useDisclosure(false)

  const [
    isStartDeploymentModalOpen,
    { open: openStartDeploymentModal, close: closeStartDeploymentModal }
  ] = useDisclosure(false)

  const uniqueDeviceIds = [
    ...new Set(deployments.map((d) => d.settings_payload.device_id))
  ]

  const { data, isError, isLoading, refetch } =
    useGetMultipleDeviceDetails(uniqueDeviceIds)

  const devices = data || []

  const deploymentLines = buildDeploymentLines(deployments, devices)

  const handleOnReload = () => {
    void refetch()
  }

  const handleOpenStopDeploymentModal = (deploymentId: string) => {
    setSelectedDeploymentId(deploymentId)
    openStopDeploymentModal()
  }

  const handleOpenStartDeploymentModal = (deploymentId: string) => {
    setSelectedDeploymentId(deploymentId)
    openStartDeploymentModal()
  }

  const handleCloseStopDeploymentModal = () => {
    setSelectedDeploymentId('')
    closeStopDeploymentModal()
  }

  const handleCloseStartDeploymentModal = () => {
    setSelectedDeploymentId('')
    closeStartDeploymentModal()
  }

  const handleEditBusinessLogic = () => {
    navigateToDeploymentStep(appId, DeploymentSteps.LogicSelection)
  }

  const handleStopTriggered = () => {
    closeStopDeploymentModal()
    void onRefresh()
  }

  const handleStartTriggered = () => {
    closeStartDeploymentModal()
    void onRefresh()
  }

  if (isError) {
    return (
      <ErrorWithReload onReload={handleOnReload}>
        <FormattedMessage id="deployments.details.fetchError" />
      </ErrorWithReload>
    )
  }

  if (isLoading) {
    return (
      <Box pos="relative" mih={120}>
        <LoadingOverlay visible />
      </Box>
    )
  }

  return (
    <>
      <Card p={0} withBorder>
        <DeploymentsTable
          lines={deploymentLines}
          onStopDeployment={handleOpenStopDeploymentModal}
          onStartDeployment={handleOpenStartDeploymentModal}
          onEditBusinessLogic={handleEditBusinessLogic}
        />
      </Card>

      <StopDeploymentConfirmation
        opened={isStopDeploymentModalOpen}
        deploymentId={selectedDeploymentId}
        onClose={handleCloseStopDeploymentModal}
        onStopTriggered={handleStopTriggered}
      />

      <StartDeploymentConfirmation
        opened={isStartDeploymentModalOpen}
        deploymentId={selectedDeploymentId}
        onClose={handleCloseStartDeploymentModal}
        onStartTriggered={handleStartTriggered}
      />
    </>
  )
}
