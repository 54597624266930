import { KonvaEventObject } from 'konva/lib/Node'
import { Stage as StageType } from 'konva/lib/Stage'
import { useMemo, useState } from 'react'
import { getRandomColor } from '@/theme/randomColor'
import { LogicLineValues } from '../../types'
import { LineAddParams } from '../AnnotationArea'

type UseLineCrossingParams = {
  lines: LogicLineValues[]
  onLineAdd: (params: LineAddParams) => void
  onLineChange: (lineIndex: number, line: LogicLineValues) => void
}

export const useLineCrossing = ({
  lines,
  onLineAdd,
  onLineChange
}: UseLineCrossingParams) => {
  const [linePoints, setLinePoints] = useState<number[][]>([])
  const [directionPoints, setDirectionPoints] = useState<number[][]>([])
  const [position, setPosition] = useState([0, 0])
  const [color, setColor] = useState(getRandomColor())
  const [step, setStep] = useState<'line' | 'direction'>('line')
  const [isCompleted, setIsCompleted] = useState(true)

  const lineFlattenedPoints = useMemo(() => {
    return linePoints
      .concat(linePoints.length === 2 ? [] : position)
      .reduce((a, b) => a.concat(b), [])
  }, [linePoints, position])

  const directionFlattenedPoints = useMemo(() => {
    return directionPoints
      .concat(directionPoints.length === 2 ? [] : position)
      .reduce((a, b) => a.concat(b), [])
  }, [directionPoints, position])

  const reset = () => {
    setLinePoints([])
    setDirectionPoints([])
    setStep('line')
    setColor(getRandomColor())
  }

  const exit = () => {
    if (isCompleted) {
      return
    }

    reset()
    setIsCompleted(true)
  }

  const start = () => {
    reset()
    setIsCompleted(false)
  }

  const getMousePos = (stage: StageType | null) => {
    const pointerPosition = stage?.getPointerPosition()

    if (pointerPosition) {
      return [pointerPosition.x, pointerPosition.y]
    }

    return [0, 0]
  }

  const handleMouseDown = (e: KonvaEventObject<MouseEvent>) => {
    if (isCompleted) {
      return
    }

    const stage = e.target.getStage()
    const mousePos = getMousePos(stage)

    if (step === 'line') {
      setLinePoints([...linePoints, mousePos])

      if (linePoints.length === 1) {
        setStep('direction')
      }
    }

    if (step === 'direction') {
      if (directionPoints.length === 1) {
        const lineCrossingCount = lines.filter(
          (line) => line.type === 'line_crossing'
        ).length

        onLineAdd({
          lineType: 'line_crossing',
          name: `line${lineCrossingCount + 1}`,
          color,
          points: [...directionPoints, [...mousePos], ...linePoints]
        })

        exit()
      } else {
        setDirectionPoints([...directionPoints, mousePos])
      }
    }
  }

  const handleMouseMove = (e: KonvaEventObject<MouseEvent>) => {
    const stage = e.target.getStage()
    const mousePos = getMousePos(stage)
    setPosition(mousePos)
  }

  const handlePointDragMove = (
    e: KonvaEventObject<DragEvent>,
    lineIndex: number,
    pointIndex: number
  ) => {
    const stage = e.target.getStage()
    const lastPos = e.target._lastPos

    if (!stage || !lastPos) {
      return
    }

    const pos = [lastPos.x, lastPos.y]

    if (pos[0] < 0) {
      pos[0] = 0
    }

    if (pos[1] < 0) {
      pos[1] = 0
    }

    if (pos[0] > stage.width()) {
      pos[0] = stage.width()
    }

    if (pos[1] > stage.height()) {
      pos[1] = stage.height()
    }

    const activeLine = lines[lineIndex]

    onLineChange(lineIndex, {
      ...activeLine,
      points: [
        ...activeLine.points.slice(0, pointIndex),
        pos,
        ...activeLine.points.slice(pointIndex + 1)
      ]
    })
  }

  const handleGroupDragEnd = (
    e: KonvaEventObject<DragEvent>,
    lineIndex: number,
    pointsSlice: [number, number]
  ) => {
    if (
      e.target.name() === 'draggable_line_crossing' ||
      e.target.name() === 'draggable_line_crossing_direction'
    ) {
      const result = [] as number[][]
      const activeLine = lines[lineIndex]
      const copyPoints = activeLine.points.slice(...pointsSlice)

      copyPoints.map((point) =>
        result.push([point[0] + e.target.x(), point[1] + e.target.y()])
      )

      e.target.position({ x: 0, y: 0 })

      onLineChange(lineIndex, {
        ...activeLine,
        points: [
          ...activeLine.points.slice(0, pointsSlice[0]),
          ...result,
          ...activeLine.points.slice(pointsSlice[1])
        ]
      })
    }
  }

  return {
    color,
    step,
    linePoints,
    directionPoints,
    lineFlattenedPoints,
    directionFlattenedPoints,
    isCompleted,
    start,
    exit,
    handleMouseDown,
    handleMouseMove,
    handlePointDragMove,
    handleGroupDragEnd
  }
}
