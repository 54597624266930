import { Group, Image, Text } from '@mantine/core'
import MilestoneLogo from '@/assets/integrations/milestone.png'
import { INTEGRATION_NAMES } from '@/config/integrations'

export const MilestoneHeader = () => {
  return (
    <Group align="center" wrap="nowrap" gap="sm">
      <Image src={MilestoneLogo} w={36} h={36} fit="contain" />

      <Text fw="bold" size="sm">
        {INTEGRATION_NAMES.MILESTONE}
      </Text>
    </Group>
  )
}
