import { Card, CardProps } from '@mantine/core'
import { useHover } from '@mantine/hooks'
import { BORDERED_CARD_STYLES } from '@/theme/customStyles'

export const AnimatedCard = (props: CardProps) => {
  const { hovered, ref } = useHover()
  const cardShadow = hovered ? 'sm' : 'xs'

  const cardStyles = {
    root: {
      ...BORDERED_CARD_STYLES.root,
      transition: 'transform 0.3s ease',
      transform: hovered ? 'scale(1.03)' : 'scale(1)'
    }
  }

  return (
    <Card
      ref={ref}
      shadow={cardShadow}
      radius="md"
      styles={cardStyles}
      withBorder
      {...props}
    />
  )
}
