import { FileRejection, FileWithPath } from '@mantine/dropzone'

type CheckResults = {
  files: FileWithPath[]
  fileRejections: FileRejection[]
}

export const checkFileCount = (
  files: FileWithPath[],
  fileLimit?: number
): CheckResults => {
  const validFiles = files.slice(0, fileLimit)
  const excessFiles = files.slice(fileLimit || files.length)

  return {
    files: validFiles,
    fileRejections: excessFiles.map((file) => ({
      file,
      errors: [{ code: 'too-many-files', message: 'File limit exceeded' }]
    }))
  }
}

export const checkBatchSize = (
  files: FileWithPath[],
  maxSize: number
): CheckResults => {
  let totalSize = 0

  const validFiles: FileWithPath[] = []
  const fileRejections: FileRejection[] = []

  for (const file of files) {
    if (totalSize + file.size <= maxSize) {
      validFiles.push(file)
      totalSize += file.size
    } else {
      fileRejections.push({
        file,
        errors: [
          { code: 'batch-size-exceeded', message: 'Batch size exceeded' }
        ]
      })
    }
  }

  return {
    files: validFiles,
    fileRejections: fileRejections
  }
}
