import { Box, LoadingOverlay } from '@mantine/core'
import { TrainingDetails } from '@/components/trainings/TrainingDetails/TrainingDetails'
import { useApplicationContext } from '@/providers/ApplicationContext'
import { useModelContext } from '@/providers/ModelContext'
import { UserMLModel } from '@/types/model'

export const StepTraining = () => {
  const { application } = useApplicationContext()
  const { model, isFetching } = useModelContext()
  const { training: trainingId } = model || {}

  if (isFetching) {
    return (
      <Box pos="relative" mih={120}>
        <LoadingOverlay visible />
      </Box>
    )
  }

  if (trainingId) {
    return (
      <TrainingDetails
        appId={application?.id || ''}
        trainingId={trainingId}
        model={model as UserMLModel}
      />
    )
  }
}
