import { Button, Group, Loader } from '@mantine/core'
import { IconBolt, IconTag, IconUpload } from '@tabler/icons-react'
import { FormattedMessage } from 'react-intl'
import { AnnotationProgressBar } from '@/components/annotations/AnnotationProgressBar/AnnotationProgressBar'
import { StepActionToolbar } from '@/components/models/ModelDetails/StepActionToolbar/StepActionToolbar'

type UploadToolbarProps = {
  isUploading: boolean
  isLabelButtonDisabled: boolean
  isTrainingButtonDisabled: boolean
  isAnnotationProgressBarShown: boolean
  annotatedImagesCount: number
  annotatedVideosCount: number
  onUploadClick: () => void
  onLabelClick: () => void
  onTrainClick: () => void
}

export const UploadToolbar = ({
  isUploading,
  isLabelButtonDisabled,
  isTrainingButtonDisabled,
  isAnnotationProgressBarShown,
  annotatedImagesCount,
  annotatedVideosCount,
  onUploadClick,
  onLabelClick,
  onTrainClick
}: UploadToolbarProps) => {
  return (
    <StepActionToolbar
      leftSection={
        <Group>
          <Button
            size="xs"
            radius="xl"
            miw={140}
            leftSection={<IconTag size={16} />}
            disabled={isLabelButtonDisabled}
            onClick={onLabelClick}
          >
            <FormattedMessage id="labels" />
          </Button>

          <Button
            size="xs"
            radius="xl"
            miw={140}
            leftSection={
              isUploading ? (
                <Loader color="white" size={16} />
              ) : (
                <IconUpload size={16} />
              )
            }
            onClick={onUploadClick}
          >
            <FormattedMessage id="datasets.upload.uploadFiles" />
          </Button>
        </Group>
      }
      rightSection={
        <Group gap="lg">
          {isAnnotationProgressBarShown && (
            <AnnotationProgressBar
              annotatedImagesCount={annotatedImagesCount}
              annotatedVideosCount={annotatedVideosCount}
            />
          )}

          <Button
            size="xs"
            radius="xl"
            color="green.8"
            miw={160}
            disabled={isTrainingButtonDisabled}
            leftSection={<IconBolt size={16} />}
            onClick={onTrainClick}
          >
            <FormattedMessage id="models.trainModel" />
          </Button>
        </Group>
      }
    />
  )
}
