import { Box, LoadingOverlay } from '@mantine/core'
import { ReactNode, createContext, useContext } from 'react'
import { FormattedMessage } from 'react-intl'
import { useParams } from 'react-router-dom'
import { ErrorWithReload } from '@/components/ui-shared/ErrorWithReload/ErrorWithReload'
import { useGetApplicationDetails } from '@/queries/applicationQueries'
import { Application } from '@/types/app'

type ContextProps = {
  application: Application | null
}

const initialValues = {
  application: null
}

export const ApplicationContext = createContext<ContextProps>(initialValues)

type ApplicationProviderProps = {
  children: ReactNode
}

export const ApplicationProvider = ({ children }: ApplicationProviderProps) => {
  const { appId } = useParams()

  const {
    data: application,
    isLoading,
    isError,
    refetch
  } = useGetApplicationDetails(appId as string)

  const handleOnReload = () => {
    void refetch()
  }

  if (isLoading) {
    return (
      <Box pos="relative" mih={200}>
        <LoadingOverlay visible />
      </Box>
    )
  }

  if (isError) {
    return (
      <ErrorWithReload onReload={handleOnReload}>
        <FormattedMessage id="apps.appList.apiError" />
      </ErrorWithReload>
    )
  }

  return (
    <ApplicationContext.Provider value={{ application: application || null }}>
      {application && children}
    </ApplicationContext.Provider>
  )
}

// eslint-disable-next-line react-refresh/only-export-components
export const useApplicationContext = () => {
  return useContext(ApplicationContext)
}
