import { FormErrors } from '@mantine/form'

export const getStreamErrors = (
  formErrors: FormErrors,
  streamIndex: number
) => {
  return Object.entries(formErrors).reduce((errors, [key, value]) => {
    if (key.startsWith(`cameraStreams.${streamIndex}.`)) {
      const newKey = key.replace(`cameraStreams.${streamIndex}.`, '')
      errors[newKey] = value
    }
    return errors
  }, {} as FormErrors)
}
