import { Flex, Text } from '@mantine/core'
import { IconCheck } from '@tabler/icons-react'
import { ReactNode } from 'react'
import { FormattedMessage } from 'react-intl'

import {
  hasNumber,
  hasSpecialChar,
  hasUpperCase,
  isMinLength
} from '@/utils/validation/helpers'

import { PASSWORD_MIN_LENGTH } from '@/utils/validation/password'

import classes from './PasswordRules.module.css'

type RuleItemProps = {
  children: ReactNode
  isCompleted: boolean
}

const RuleItem = ({ children, isCompleted }: RuleItemProps) => {
  return (
    <li>
      <Flex align="center">
        <Text
          size="xs"
          c={isCompleted ? 'green.8' : 'gray'}
          style={{ marginRight: '4px' }}
        >
          • {children}
        </Text>

        {isCompleted && (
          <IconCheck size={14} color="var(--mantine-color-green-8)" />
        )}
      </Flex>
    </li>
  )
}

type PasswordRulesProps = {
  password: string
}

export const PasswordRules = ({ password }: PasswordRulesProps) => {
  const status = {
    isMinLength: isMinLength(password, PASSWORD_MIN_LENGTH),
    hasNumber: hasNumber(password),
    hasUpperCase: hasUpperCase(password),
    hasSpecialChar: hasSpecialChar(password)
  }

  return (
    <ul className={classes['password-rules-list']}>
      <RuleItem isCompleted={status.isMinLength}>
        <FormattedMessage
          id="validation.strongPassword.minLength"
          values={{
            length: PASSWORD_MIN_LENGTH
          }}
        />
      </RuleItem>

      <RuleItem isCompleted={status.hasNumber}>
        <FormattedMessage id="validation.strongPassword.hasNumber" />
      </RuleItem>

      <RuleItem isCompleted={status.hasUpperCase}>
        <FormattedMessage id="validation.strongPassword.hasUpperCase" />
      </RuleItem>

      <RuleItem isCompleted={status.hasSpecialChar}>
        <FormattedMessage id="validation.strongPassword.hasSpecialChar" />
      </RuleItem>
    </ul>
  )
}
