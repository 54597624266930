import { Box, LoadingOverlay, Modal } from '@mantine/core'
import { FormattedMessage, useIntl } from 'react-intl'
import { getApiError } from '@/api/helpers/apiError'
import { useUpdateModel } from '@/queries/modelQueries'
import { showToast } from '@/theme/notifications'
import { UserMLModel } from '@/types/model'
import { FormValues, UpdateModelForm } from '../UpdateModelForm/UpdateModelForm'

type UpdateModelModalProps = {
  opened: boolean
  appId: string
  model: UserMLModel
  onClose: () => void
  onUpdated: () => void
}

export const UpdateModelModal = ({
  opened,
  appId,
  model,
  onClose,
  onUpdated
}: UpdateModelModalProps) => {
  const intl = useIntl()

  const {
    mutateAsync: updateModel,
    isPending,
    reset
  } = useUpdateModel({ appId, modelId: model.id })

  const handleUpdateModel = async ({ name }: FormValues) => {
    try {
      await updateModel({
        appId,
        modelId: model.id,
        data: { name }
      })

      onUpdated()
      showToast(intl.formatMessage({ id: 'models.update.success' }), 'success')
    } catch (err) {
      const { errorMessage } = getApiError(err)

      const message =
        errorMessage || intl.formatMessage({ id: 'models.update.error' })

      showToast(message, 'error')
    }
  }

  const handleClose = () => {
    reset()
    onClose()
  }

  return (
    <Modal
      size="lg"
      opened={opened}
      title={<FormattedMessage id="models.update" />}
      onClose={handleClose}
    >
      <Box pos="relative">
        <LoadingOverlay visible={isPending} />

        <UpdateModelForm
          initialValues={{ name: model.name }}
          onCancel={handleClose}
          onSubmit={(values) => void handleUpdateModel(values)}
        />
      </Box>
    </Modal>
  )
}
