import { Application } from '../types/app'
import { PaginatedResponse } from '../types/pagination'
import { vpApi } from './vpApi'

type ApplicationsResponse = PaginatedResponse<Application[]>

type GetApplicationsParams = {
  pageUrl: string
}

const getApplications = async ({ pageUrl }: GetApplicationsParams) => {
  const res = await vpApi.get<ApplicationsResponse>(pageUrl)
  return res.data
}

const getApplicationDetails = async (id: string) => {
  const res = await vpApi.get<Application>(`/v1/applications/${id}/`)
  return res.data
}

type ApplicationCreateUpdatePayload = Pick<Application, 'name'>

const createApplication = (payload: ApplicationCreateUpdatePayload) => {
  return vpApi.post<Application>('/v1/applications/', payload)
}

type UpdateApplicationParams = {
  id: string
  data: ApplicationCreateUpdatePayload
}

const updateApplication = ({ id, data }: UpdateApplicationParams) => {
  return vpApi.patch(`/v1/applications/${id}/`, data)
}

const deleteApplication = (id: string) => {
  return vpApi.delete(`/v1/applications/${id}/`)
}

export const applicationApi = {
  getApplications,
  getApplicationDetails,
  createApplication,
  updateApplication,
  deleteApplication
}
