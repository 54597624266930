import { Container, rem } from '@mantine/core'
import { ReactNode } from 'react'
import { PAGE_MAX_WIDTH } from '../PageContainer/PageContainer'

type HeaderContainerProps = {
  children: ReactNode
}

export const HeaderContainer = ({ children }: HeaderContainerProps) => {
  return (
    <Container size={rem(PAGE_MAX_WIDTH)} h="100%">
      {children}
    </Container>
  )
}
