import { Modal } from '@mantine/core'
import { ReactNode } from 'react'
import { FormattedMessage } from 'react-intl'

type CreateDatasetModalProps = {
  opened: boolean
  children: ReactNode
  onClose: () => void
}

export const CreateDatasetModal = ({
  opened,
  children,
  onClose
}: CreateDatasetModalProps) => {
  return (
    <Modal
      size="lg"
      opened={opened}
      title={<FormattedMessage id="datasets.create" />}
      onClose={onClose}
    >
      {children}
    </Modal>
  )
}
