import { useMutation, useQuery } from '@tanstack/react-query'
import { accountApi } from '@/api/accountApi'

export const accountQueryKeys = {
  all: ['account'] as const,
  products: () => [...accountQueryKeys.all, 'products'] as const
}

export const useGetProducts = () => {
  return useQuery({
    queryKey: accountQueryKeys.products(),
    queryFn: accountApi.getProducts
  })
}

export const useCreateCheckoutSession = () => {
  return useMutation({
    mutationFn: accountApi.createCheckoutSession
  })
}

export const useCreateCustomerPortalSession = () => {
  return useMutation({
    mutationFn: accountApi.createCustomerPortalSession
  })
}
