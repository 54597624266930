import { Box, LoadingOverlay, Modal } from '@mantine/core'
import { FormattedMessage, useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { ApiError, getApiError } from '@/api/helpers/apiError'
import { useCreateApplication } from '@/queries/applicationQueries'
import { RouterPath, replacePathParams } from '@/router/paths'
import { showToast } from '@/theme/notifications'
import { ErrorBanner } from '../ui-shared/ErrorBanner/ErrorBanner'
import {
  CreateApplicationFormLegacy,
  FormValues
} from './CreateApplicationFormLegacy/CreateApplicationFormLegacy'

type CreateAppErrorProps = {
  error: ApiError
}

const CreateAppError = ({ error }: CreateAppErrorProps) => {
  const { errorMessage } = error

  if (errorMessage) {
    return <ErrorBanner>{errorMessage}</ErrorBanner>
  }

  return (
    <ErrorBanner>
      <FormattedMessage id="apps.createApp.error" />
    </ErrorBanner>
  )
}

type CreateApplicationModalProps = {
  opened: boolean
  onClose: () => void
}

export const CreateApplicationModal = ({
  opened,
  onClose
}: CreateApplicationModalProps) => {
  const intl = useIntl()
  const navigate = useNavigate()

  const {
    mutateAsync: createApp,
    isPending,
    isError,
    error
  } = useCreateApplication()

  const handleCreateApp = async ({ appName }: FormValues) => {
    const { data: application } = await createApp({ name: appName })

    showToast(intl.formatMessage({ id: 'apps.createApp.success' }), 'success')

    onClose()

    navigate(
      replacePathParams(RouterPath.appDetails, {
        appId: application.id
      })
    )
  }

  return (
    <Modal
      size="lg"
      opened={opened}
      title={<FormattedMessage id="apps.createApp" />}
      onClose={onClose}
    >
      <Box pos="relative">
        {isError && (
          <Box mb="lg">
            <CreateAppError error={getApiError(error)} />
          </Box>
        )}

        <LoadingOverlay visible={isPending} />

        <CreateApplicationFormLegacy
          onCancel={onClose}
          onSubmit={(values) => void handleCreateApp(values)}
        />
      </Box>
    </Modal>
  )
}
