import { Box, Group, Stack, Text, ThemeIcon, Title } from '@mantine/core'
import { IconAlertTriangle, IconRuler2, IconVector } from '@tabler/icons-react'
import { FormattedMessage, useIntl } from 'react-intl'

export const CalibrationHelp = () => {
  const intl = useIntl()

  const steps = [
    {
      title: intl.formatMessage({
        id: 'logic.objectMeasurement.help.step1.title'
      }),
      description: intl.formatMessage({
        id: 'logic.objectMeasurement.help.step1.description'
      }),
      icon: (
        <ThemeIcon variant="light" size="lg" radius="xl">
          <IconVector style={{ width: '70%', height: '70%' }} />
        </ThemeIcon>
      )
    },
    {
      title: intl.formatMessage({
        id: 'logic.objectMeasurement.help.step2.title'
      }),
      description: intl.formatMessage({
        id: 'logic.objectMeasurement.help.step2.description'
      }),
      icon: (
        <ThemeIcon variant="light" size="lg" radius="xl">
          <IconRuler2 style={{ width: '70%', height: '70%' }} />
        </ThemeIcon>
      )
    },
    {
      title: intl.formatMessage({
        id: 'logic.objectMeasurement.help.step3.title'
      }),
      description: intl.formatMessage({
        id: 'logic.objectMeasurement.help.step3.description'
      }),
      icon: (
        <ThemeIcon variant="light" size="lg" radius="xl">
          <IconAlertTriangle style={{ width: '70%', height: '70%' }} />
        </ThemeIcon>
      )
    }
  ]
  return (
    <Stack gap="xl">
      <Title order={4}>
        <FormattedMessage id="logic.objectMeasurement.helpTitle" />
      </Title>

      {steps.map((step, index) => (
        <Box key={index}>
          <Group wrap="nowrap" gap="xs" mb="sm">
            {step.icon}

            <Text fw={600} size="sm">
              {step.title}
            </Text>
          </Group>

          <Text size="sm">{step.description}</Text>
        </Box>
      ))}
    </Stack>
  )
}
