import { Button, Group, LoadingOverlay, Modal, Text } from '@mantine/core'
import { IconTrash } from '@tabler/icons-react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useDeleteModel } from '@/queries/modelQueries'
import { showToast } from '@/theme/notifications'

type DeleteModelModalProps = {
  opened: boolean
  appId: string
  modelId: string
  onClose: () => void
  onDeleted: () => void
}

const DeleteModelModalTitle = () => (
  <Group>
    <IconTrash size={20} />
    <FormattedMessage id="models.deleteModel" />
  </Group>
)

export const DeleteModelModal = ({
  opened,
  appId,
  modelId,
  onClose,
  onDeleted
}: DeleteModelModalProps) => {
  const intl = useIntl()
  const { mutateAsync: deleteModel, isPending } = useDeleteModel()

  const handleConfirmDelete = async () => {
    try {
      await deleteModel({ appId: appId, modelId: modelId })
      onDeleted()
    } catch (err) {
      showToast(
        intl.formatMessage({ id: 'models.deleteModelApiError' }),
        'error'
      )
    }
  }
  return (
    <Modal
      size="lg"
      opened={opened}
      title={<DeleteModelModalTitle />}
      onClose={onClose}
    >
      <LoadingOverlay visible={isPending} />

      <Text size="sm">
        <FormattedMessage id="models.willPermanentlyRemove" />
      </Text>

      <Group justify="end" mt="xl">
        <Button miw={120} variant="default" onClick={onClose}>
          <FormattedMessage id="cancel" />
        </Button>

        <Button color="red" onClick={() => void handleConfirmDelete()}>
          <FormattedMessage id="models.deleteModel" />
        </Button>
      </Group>
    </Modal>
  )
}
