import { Spoiler, Stack, Text, rem } from '@mantine/core'
import { FormattedMessage } from 'react-intl'
import { DatasetType } from '@/types/dataset'
import { DatasetTypeChip } from '../../DatasetTypeChip/DatasetTypeChip'

type DatasetInfoSectionProps = {
  datasetName: string
  description?: string
  datasetType: DatasetType
}

export const DatasetInfoSection = ({
  datasetName,
  description,
  datasetType
}: DatasetInfoSectionProps) => {
  return (
    <Stack>
      <Text fw="bold">{datasetName}</Text>

      <DatasetTypeChip datasetType={datasetType} />

      {description && (
        <Spoiler
          maxHeight={80}
          showLabel={<FormattedMessage id="showMore" />}
          hideLabel={<FormattedMessage id="showLess" />}
          styles={{
            control: {
              fontSize: rem(13)
            },
            content: {
              fontSize: rem(13)
            }
          }}
        >
          {description}
        </Spoiler>
      )}
    </Stack>
  )
}
