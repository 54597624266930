import {
  Anchor,
  Box,
  Flex,
  Grid,
  List,
  Paper,
  ScrollArea,
  Text,
  Timeline,
  Title,
  Transition,
  UnstyledButton,
  rem
} from '@mantine/core'
import { useInViewport } from '@mantine/hooks'
import {
  IconLocation,
  IconPencilPlus,
  IconPlayerPlay,
  IconTrash,
  IconUpload
} from '@tabler/icons-react'
import { useState } from 'react'
import { ListThemeIcon } from './ListThemeIcon'

const SCROLL_VIEWPORT_HEIGHT = 440

type FeatureCardProps = {
  opened: boolean
}

const LabelManagementCard = ({ opened }: FeatureCardProps) => {
  const { ref, inViewport } = useInViewport()
  return (
    <Flex ref={ref} p="lg" px="xl" h={rem(SCROLL_VIEWPORT_HEIGHT)}>
      <Transition
        mounted={opened && inViewport}
        transition="fade"
        duration={400}
        timingFunction="ease"
      >
        {(styles) => (
          <Box m="auto" style={styles}>
            <Timeline
              active={3}
              bulletSize={24}
              lineWidth={2}
              color="brand-primary.6"
            >
              <Timeline.Item
                bullet={<IconPencilPlus size={16} />}
                title={<strong>Add New Labels</strong>}
              >
                <Text size="sm" mt={4}>
                  Click on the{' '}
                  <Text c="brand-primary.6" fw="bold" span>
                    Labels
                  </Text>{' '}
                  button to create custom labels for your data labeling. Provide
                  a descriptive name for the label and hit{' '}
                  <Text c="brand-primary.6" fw="bold" span>
                    Save
                  </Text>{' '}
                  to add it to your label list.
                </Text>
              </Timeline.Item>

              <Timeline.Item
                bullet={<IconLocation size={16} />}
                title={<strong>Select Labels for Labeling</strong>}
              >
                <Text size="sm" mt={4}>
                  Choose which labels to use for labeling by selecting them from
                  the label list. Selected labels will be available for labeling
                  the uploaded media.
                </Text>
              </Timeline.Item>

              <Timeline.Item
                bullet={<IconTrash size={16} />}
                title={<strong>Remove Label</strong>}
              >
                <Text size="sm" mt={4}>
                  To remove labels and exclude them from labeling, simply click
                  on the{' '}
                  <Text c="brand-primary.6" fw="bold" span>
                    X
                  </Text>{' '}
                  button next to the label you wish to delete. Confirm the
                  action, and the label will be removed from the list.
                </Text>
              </Timeline.Item>
            </Timeline>
          </Box>
        )}
      </Transition>
    </Flex>
  )
}

const MediaManagementCard = ({ opened }: FeatureCardProps) => {
  const { ref, inViewport } = useInViewport()

  return (
    <Flex ref={ref} p="lg" px="xl" h={rem(SCROLL_VIEWPORT_HEIGHT)}>
      <Transition
        mounted={opened && inViewport}
        transition="fade"
        duration={400}
        timingFunction="ease"
      >
        {(styles) => (
          <Box m="auto" style={styles}>
            <Timeline
              active={3}
              bulletSize={24}
              lineWidth={2}
              color="brand-primary.6"
            >
              <Timeline.Item
                bullet={<IconPlayerPlay size={16} />}
                title={<strong>Supported Media Types</strong>}
              >
                <Text size="sm" mt={4}>
                  You can upload both images (JPEG format) and videos (MP4
                  format) for labeling. These media files will serve as training
                  data for your application.
                </Text>
              </Timeline.Item>

              <Timeline.Item
                bullet={<IconUpload size={16} />}
                title={<strong>Uploading Process</strong>}
              >
                <Text size="sm" mt={4}>
                  Select the desired media file from your device and upload it
                  using the{' '}
                  <Text c="brand-primary.6" fw="bold" span>
                    Upload Files{' '}
                  </Text>
                  button. Once uploaded, the media will appear in the labeling
                  workspace for labeling.
                </Text>
              </Timeline.Item>

              <Timeline.Item
                bullet={<IconTrash size={16} />}
                title={<strong>Remove Media</strong>}
              >
                <Text size="sm" mt={4}>
                  If you need to remove a media file, select a media and click
                  on the{' '}
                  <Text c="brand-primary.6" fw="bold" span>
                    Delete File
                  </Text>{' '}
                  button. Confirm the action, and the media will be permanently
                  removed from the labeling workspace.
                </Text>
              </Timeline.Item>
            </Timeline>
          </Box>
        )}
      </Transition>
    </Flex>
  )
}

const MediaAnnotationCard = ({ opened }: FeatureCardProps) => {
  const { ref, inViewport } = useInViewport()

  return (
    <Flex ref={ref} p="lg" px="xl" h={rem(SCROLL_VIEWPORT_HEIGHT)}>
      <Transition
        mounted={opened && inViewport}
        transition="fade"
        duration={400}
        timingFunction="ease"
      >
        {(styles) => (
          <Box m="auto" style={styles}>
            <Timeline
              active={3}
              bulletSize={24}
              lineWidth={2}
              color="brand-primary.6"
            >
              <Timeline.Item
                bullet={<IconPencilPlus size={16} />}
                title={<strong>Add Labels and Upload Media</strong>}
              >
                <Text size="sm" mt={4}>
                  Before you start labeling your media, make sure you have added
                  all the necessary labels. Once labels are added and your media
                  files are uploaded, you are ready to label!
                </Text>
              </Timeline.Item>

              <Timeline.Item
                bullet={<IconLocation size={16} />}
                title={<strong>Begin Labeling</strong>}
              >
                <Text size="sm" mt={4}>
                  To start labeling, simply choose the media file you want to
                  work with. Click on the{' '}
                  <Text c="brand-primary.6" fw="bold" span>
                    Start Labeling
                  </Text>{' '}
                  button to begin. This will open CVAT (Computer Vision
                  Annotation Tool).
                </Text>
              </Timeline.Item>

              <Timeline.Item
                bullet={<IconTrash size={16} />}
                title={<strong>What is CVAT?</strong>}
              >
                <Text size="sm" mt={4}>
                  CVAT, short for Computer Vision Annotation Tool, is a
                  user-friendly tool designed to help you label your media files
                  effortlessly. With CVAT, you can easily mark and identify
                  objects, scenes, or actions within your videos and images,
                  making it simple to organize and analyze your visual data.
                  Click{' '}
                  <Anchor
                    href="https://opencv.github.io/cvat/docs/manual/"
                    target="_blank"
                    underline="always"
                  >
                    here
                  </Anchor>{' '}
                  to learn more about CVAT.
                </Text>
              </Timeline.Item>
            </Timeline>
          </Box>
        )}
      </Transition>
    </Flex>
  )
}

type FeaturePageProps = {
  page: number
}
const FeaturePage = ({ page }: FeaturePageProps) => {
  return page === 0 ? (
    <LabelManagementCard opened={page === 0} />
  ) : page === 1 ? (
    <MediaManagementCard opened={page === 1} />
  ) : (
    <MediaAnnotationCard opened={page === 2} />
  )
}

export const UploadAndAnnotateContent = () => {
  const [currentPage, setCurrentPage] = useState(0)
  return (
    <Grid gutter="lg">
      <Grid.Col span={{ base: 12, lg: 5 }}>
        <Title order={2}>Provide Training Data to Your Model</Title>
        <Text c="dimmed" mt="md">
          On this page, you will be guided how to manage labels and upload media
          for labeling to enhance your training data.
        </Text>
        <List mt="lg" spacing="md" size="lg" center>
          <List.Item
            icon={<ListThemeIcon currentPage={currentPage} page={0} />}
          >
            <UnstyledButton onClick={() => setCurrentPage(0)}>
              Label Management
            </UnstyledButton>
          </List.Item>
          <List.Item
            icon={<ListThemeIcon currentPage={currentPage} page={1} />}
          >
            <UnstyledButton onClick={() => setCurrentPage(1)}>
              Media Management
            </UnstyledButton>
          </List.Item>
          <List.Item
            icon={<ListThemeIcon currentPage={currentPage} page={2} />}
          >
            <UnstyledButton onClick={() => setCurrentPage(2)}>
              Media Labeling
            </UnstyledButton>
          </List.Item>
        </List>
      </Grid.Col>
      <Grid.Col span={{ base: 12, lg: 7 }}>
        <Paper radius="md" h={rem(SCROLL_VIEWPORT_HEIGHT + 10)} withBorder>
          <ScrollArea w="100%" h={rem(SCROLL_VIEWPORT_HEIGHT)} type="auto">
            <FeaturePage page={currentPage} />
          </ScrollArea>
        </Paper>
      </Grid.Col>
    </Grid>
  )
}
