import { useSearchParams } from 'react-router-dom'

const DATASET_VERSION_ID_PARAM = 'datasetVersionId'

export const useDatasetVersionParam = () => {
  const [searchParams, setSearchParams] = useSearchParams()

  const datasetVersionId = searchParams.get(DATASET_VERSION_ID_PARAM)

  const setDatasetVersion = (value: string | null) => {
    const newParams = new URLSearchParams(searchParams)

    if (value === null) {
      newParams.delete(DATASET_VERSION_ID_PARAM)
    } else {
      newParams.set(DATASET_VERSION_ID_PARAM, value)
    }

    setSearchParams(newParams)
  }

  return {
    datasetVersionId,
    setDatasetVersion
  }
}
