import { useDisclosure } from '@mantine/hooks'
import { useQueryClient } from '@tanstack/react-query'
import { useState } from 'react'
import { AnnotationHandler } from '@/components/annotations/AnnotationHandler'
import { DeleteAnnotationModal } from '@/components/annotations/DeleteAnnotationModal/DeleteAnnotationModal'
import { ViewAnnotationToolbarHandler } from '@/components/annotations/ViewAnnotationToolbarHandler'
import { useModelContext } from '@/providers/ModelContext'
import {
  annotationQueryKeys,
  useGetAnnotation
} from '@/queries/annotationQueries'
import { datasetQueryKeys } from '@/queries/datasetLegacyQueries'
import { FileMetadata, FileStatus } from '@/types/dataset'
import { LibraryMLModel } from '@/types/model'
import { MediaGallery } from '../../datasets/MediaGallery/MediaGallery'
import { MediaOverlay } from './MediaOverlay'

const useInvalidateFileList = (datasetId: string) => {
  const queryClient = useQueryClient()

  const invalidateFileList = () => {
    void queryClient.invalidateQueries({
      queryKey: datasetQueryKeys.files(datasetId)
    })
  }

  return { invalidateFileList }
}

const useInvalidateAnnotation = (fileId: string) => {
  const queryClient = useQueryClient()

  const invalidateAnnotation = () => {
    void queryClient.resetQueries({
      queryKey: annotationQueryKeys.details(fileId)
    })
  }

  return { invalidateAnnotation }
}

type MediaViewProps = {
  opened: boolean
  activeSlide: number
  mediaFiles: FileMetadata[]
  datasetId: string
  onSlideChange: (index: number) => void
  onClose: () => void
}

export const MediaView = ({
  opened,
  activeSlide,
  mediaFiles,
  datasetId,
  onSlideChange,
  onClose
}: MediaViewProps) => {
  const [isAnnotationMode, setIsAnnotationMode] = useState(false)
  const { model } = useModelContext()

  const [
    isDeleteAnnotationModalOpened,
    { close: closeDeleteAnnotationModal, open: openDeleteAnnotationModal }
  ] = useDisclosure(false)

  const activeFile = mediaFiles[activeSlide]

  const { data: annotation, isFetching } = useGetAnnotation({
    mediaId: activeFile?.id,
    enabled:
      opened &&
      activeFile !== undefined &&
      (activeFile.status === FileStatus.Labeled ||
        activeFile.annotation_id !== null) // We check for annotation_id because
    // in certain scenarios unlabeled file may have unfinished annotation with cvat task_id and url.
    // We can then resume unfinished cvat session without the need to re-upload the file.
  })

  const { invalidateFileList } = useInvalidateFileList(datasetId)
  const { invalidateAnnotation } = useInvalidateAnnotation(activeFile?.id || '')

  const images = mediaFiles.map((file) => ({
    original: file.media_file,
    file
  }))

  const handleSaveLabeling = () => {
    onClose()
    invalidateFileList()
    setIsAnnotationMode(false)
  }

  const handleDeleteAnnotation = () => {
    invalidateFileList()
    invalidateAnnotation()
    closeDeleteAnnotationModal()
  }

  const handleCancelLabeling = () => {
    invalidateFileList()
    setIsAnnotationMode(false)
  }

  return isAnnotationMode ? (
    <AnnotationHandler
      file={activeFile}
      annotation={annotation || null}
      libraryModel={model?.root_model as LibraryMLModel}
      onCancelLabeling={handleCancelLabeling}
      onSaveLabeling={handleSaveLabeling}
    />
  ) : (
    <>
      <MediaOverlay
        opened={opened}
        toolbar={
          <ViewAnnotationToolbarHandler
            file={activeFile}
            annotation={annotation || null}
            isLoading={isFetching}
            onStartLabeling={() => setIsAnnotationMode(true)}
            onViewLabeling={() => setIsAnnotationMode(true)}
            onRemoveLabeling={openDeleteAnnotationModal}
          />
        }
        isClosable
        onClose={onClose}
      >
        <MediaGallery
          items={images}
          startIndex={activeSlide}
          onSlideChange={onSlideChange}
        />
      </MediaOverlay>

      {annotation?.task_id !== undefined && (
        <DeleteAnnotationModal
          taskId={annotation.task_id}
          opened={isDeleteAnnotationModalOpened}
          onClose={closeDeleteAnnotationModal}
          onDelete={handleDeleteAnnotation}
        />
      )}
    </>
  )
}
