import { Box, Card, Drawer } from '@mantine/core'
import { FormattedMessage } from 'react-intl'
import { ContactInfo } from '@/components/ui-shared/ContactInfo/ContactInfo'

type ContactUsDrawerProps = {
  opened: boolean
  onClose: () => void
}

export const ContactUsDrawer = ({ opened, onClose }: ContactUsDrawerProps) => {
  return (
    <Drawer
      position="right"
      size="lg"
      title={<FormattedMessage id="contactUs" />}
      opened={opened}
      onClose={onClose}
    >
      <Box pos="relative">
        <Card p="lg" radius="sm" withBorder>
          <ContactInfo />
        </Card>
      </Box>
    </Drawer>
  )
}
