import { Box, Button, Center, Drawer, Paper, Select, Text } from '@mantine/core'
import { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { BillingPeriod, Product } from '@/types/subscription'
import { formatPrice } from '@/utils/price'
import { getBillingPeriod, getProductPrice } from '@/utils/subscription'
import { JetsonItem } from './JetsonItem'
import { SubscriptionPlanItem } from './SubscriptionPlanItem'

const DrawerTitle = () => (
  <Text fw="bold">
    <FormattedMessage id="subscriptions.yourOrder" />
  </Text>
)

type DeviceSelectionDrawerProps = {
  isOpened: boolean
  subscriptionPlan: Product | null
  jetsonProducts: Product[]
  canAddJetson: boolean
  onConfirm: (priceIds: string[]) => void
  onClose: () => void
}

export const DeviceSelectionDrawer = ({
  isOpened,
  subscriptionPlan,
  jetsonProducts,
  canAddJetson,
  onConfirm,
  onClose
}: DeviceSelectionDrawerProps) => {
  const intl = useIntl()
  const [selectedJetson, setSelectedJetson] = useState<Product | null>(null)

  const jetsonOptions = jetsonProducts.map((product) => {
    const price = getProductPrice(product.price)

    return {
      value: product.price_id,
      label: `${product.name} (${formatPrice(price, product.currency)})`
    }
  })

  const handleConfirm = () => {
    if (subscriptionPlan) {
      const priceIds = [subscriptionPlan.price_id]

      if (selectedJetson) {
        priceIds.push(selectedJetson.price_id)
      }

      onConfirm(priceIds)
    }
  }

  const handleClose = () => {
    setSelectedJetson(null)
    onClose()
  }

  const handleJetsonSelect = (value: string | null) => {
    setSelectedJetson(
      jetsonProducts.find((product) => product.price_id === value) || null
    )
  }

  return (
    <Drawer
      opened={isOpened}
      position="right"
      size="lg"
      title={<DrawerTitle />}
      onClose={handleClose}
    >
      {subscriptionPlan && (
        <>
          <Box>
            <Paper py="lg" px="sm" radius="md" withBorder>
              <SubscriptionPlanItem
                billingPeriod={
                  getBillingPeriod(subscriptionPlan.freq) as BillingPeriod
                }
                name={subscriptionPlan.name}
                description={subscriptionPlan.description || ''}
                price={formatPrice(
                  getProductPrice(subscriptionPlan.price),
                  subscriptionPlan.currency
                )}
              />
            </Paper>

            {canAddJetson && (
              <Box mt="xl">
                <Text fw="bold" mb="xs">
                  <FormattedMessage id="subscriptions.extras" />
                </Text>

                {!selectedJetson && (
                  <>
                    <Text size="sm" mb="sm">
                      <FormattedMessage id="subscriptions.wantToAddJetson" />
                    </Text>

                    <Select
                      value={null}
                      placeholder={intl.formatMessage({
                        id: 'subscriptions.chooseYourDevice'
                      })}
                      data={jetsonOptions}
                      onChange={handleJetsonSelect}
                    />
                  </>
                )}

                {selectedJetson && (
                  <Paper py="lg" px="sm" radius="md" withBorder>
                    <JetsonItem
                      name={selectedJetson.name}
                      description={selectedJetson.description || ''}
                      price={formatPrice(
                        getProductPrice(selectedJetson.price),
                        selectedJetson.currency
                      )}
                      onRemove={() => setSelectedJetson(null)}
                    />
                  </Paper>
                )}
              </Box>
            )}
          </Box>

          <Center mt="xl">
            <Button fullWidth onClick={handleConfirm}>
              <FormattedMessage id="subscriptions.continueToCheckout" />
            </Button>
          </Center>
        </>
      )}
    </Drawer>
  )
}
