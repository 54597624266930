import { FormattedMessage } from 'react-intl'
import { LibraryLogicType } from '@/types/businessLogic'

const nameMap = {
  [LibraryLogicType.ObjectDetection]: 'logic.objectDetection',
  [LibraryLogicType.ObjectCount]: 'logic.objectCount',
  [LibraryLogicType.ObjectMeasurement3d]: 'logic.objectMeasurement.3d'
}

type BusinessLogicNameProps = {
  businessLogicType: LibraryLogicType
}

export const BusinessLogicName = ({
  businessLogicType
}: BusinessLogicNameProps) => {
  return <FormattedMessage id={nameMap[businessLogicType]} />
}
