import { Table } from '@mantine/core'
import { FormattedMessage } from 'react-intl'

type MeasurementInfoProps = {
  id: string
  width: number
  height: number
  depth: number
  sizeUnit: string
  weight: number
  weightUnit: string
}

export const MeasurementInfo = ({
  id,
  width,
  height,
  depth,
  sizeUnit,
  weight,
  weightUnit
}: MeasurementInfoProps) => {
  return (
    <Table horizontalSpacing="md" verticalSpacing="sm">
      <Table.Tbody>
        <Table.Tr>
          <Table.Td>
            <strong>
              <FormattedMessage id="events.measurement.id" />
            </strong>
          </Table.Td>

          <Table.Td>{id}</Table.Td>
        </Table.Tr>

        <Table.Tr>
          <Table.Td>
            <strong>
              <FormattedMessage id="events.measurement.size" />
            </strong>
          </Table.Td>

          <Table.Td>
            {width} x {height} x {depth} {sizeUnit}
          </Table.Td>
        </Table.Tr>

        <Table.Tr>
          <Table.Td>
            <strong>
              <FormattedMessage id="events.measurement.weight" />
            </strong>
          </Table.Td>

          <Table.Td>
            {weight} {weightUnit}
          </Table.Td>
        </Table.Tr>
      </Table.Tbody>
    </Table>
  )
}
