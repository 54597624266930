import { Box, Group, List, ScrollArea, Text, ThemeIcon } from '@mantine/core'
import { FileRejection } from '@mantine/dropzone'
import { IconCheck, IconX } from '@tabler/icons-react'
import { FormattedMessage } from 'react-intl'
import { MEDIA_UPLOAD_LIMITS } from '@/config/media'
import { bytesToGigabytes, getHumanFileSize } from '@/utils/file'

type RejectionCode =
  | 'file-invalid-type'
  | 'file-too-large'
  | 'file-too-small'
  | 'name-too-long'
  | 'batch-size-exceeded'

type RejectionReasonProps = {
  code: RejectionCode
}

const RejectionReason = ({ code }: RejectionReasonProps) => {
  if (code === 'file-invalid-type') {
    return <FormattedMessage id="datasets.upload.rejection.invalidFileType" />
  }

  if (code === 'file-too-large') {
    return (
      <FormattedMessage
        id="datasets.upload.rejection.fileTooLarge"
        values={{
          maxSize:
            bytesToGigabytes(MEDIA_UPLOAD_LIMITS.MAX_FILE_SIZE_BYTES) + ' GB'
        }}
      />
    )
  }

  if (code === 'name-too-long') {
    return (
      <FormattedMessage
        id="datasets.upload.rejection.nameTooLong"
        values={{
          limit: MEDIA_UPLOAD_LIMITS.MAX_FILE_NAME_LENGTH
        }}
      />
    )
  }

  if (code === 'batch-size-exceeded') {
    return (
      <FormattedMessage
        id="datasets.upload.rejection.batchSizeExceeded"
        values={{
          maxSize:
            bytesToGigabytes(MEDIA_UPLOAD_LIMITS.MAX_BATCH_SIZE_BYTES) + ' GB'
        }}
      />
    )
  }

  return ''
}

type ConfirmFileListProps = {
  acceptedFiles: File[]
  fileRejections: FileRejection[]
}

export const ConfirmFileList = ({
  acceptedFiles,
  fileRejections
}: ConfirmFileListProps) => {
  const acceptedFileCount = acceptedFiles.length
  const rejectedFileCount = fileRejections.length

  const rejectionsByCode = fileRejections.reduce(
    (acc, rejection) => {
      const code = rejection.errors[0].code as RejectionCode
      const existing = acc[code] || []

      return {
        ...acc,
        [code]: [...existing, rejection.file]
      }
    },
    {} as Record<RejectionCode, File[]>
  )

  const rejectionsByCodeEntries = Object.entries(rejectionsByCode)
  const showCodeCount = rejectionsByCodeEntries.length > 1

  const acceptedTotalSize = acceptedFiles.reduce(
    (acc, file) => acc + file.size,
    0
  )

  return (
    <>
      {acceptedFileCount > 0 && (
        <Box mb="lg">
          <Group wrap="nowrap" gap="xs" mb="sm">
            <ThemeIcon color="green" size={16}>
              <IconCheck />
            </ThemeIcon>

            <Text size="sm">
              <FormattedMessage
                id="datasets.upload.readyForUpload"
                values={{ count: acceptedFileCount }}
              />
            </Text>

            <Text size="sm" c="dimmed">
              ({getHumanFileSize(acceptedTotalSize)})
            </Text>
          </Group>

          <ScrollArea h={300}>
            <List size="xs">
              {acceptedFiles.map((acceptedFile) => (
                <List.Item key={acceptedFile.name} icon={null}>
                  <Group wrap="nowrap" gap="xs">
                    <Text size="xs">{acceptedFile.name}</Text>

                    <Text size="xs" c="dimmed">
                      ({getHumanFileSize(acceptedFile.size)})
                    </Text>
                  </Group>
                </List.Item>
              ))}
            </List>
          </ScrollArea>
        </Box>
      )}

      {rejectedFileCount > 0 && (
        <Box>
          <Group wrap="nowrap" gap="xs" mb="sm">
            <ThemeIcon color="red" size={16}>
              <IconX />
            </ThemeIcon>

            <Text size="sm">
              <FormattedMessage
                id="datasets.upload.rejectedFiles"
                values={{ count: rejectedFileCount }}
              />
            </Text>
          </Group>

          <List size="xs">
            {rejectionsByCodeEntries.map(([code, files]) => (
              <List.Item key={code} icon={null}>
                <Group wrap="nowrap" gap="xs">
                  <Text size="xs">
                    <RejectionReason code={code as RejectionCode} />
                  </Text>

                  {showCodeCount && (
                    <Text size="xs" c="dimmed">
                      ({files.length})
                    </Text>
                  )}
                </Group>
              </List.Item>
            ))}
          </List>
        </Box>
      )}
    </>
  )
}
