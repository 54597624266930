import { Button, Loader } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { IconChecklist } from '@tabler/icons-react'
import { useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { getApiError } from '@/api/helpers/apiError'
import { useGetInferenceDetails } from '@/queries/inferenceQueries'
import { useStartInference } from '@/queries/modelQueries'
import { showToast } from '@/theme/notifications'
import { InferenceStatus } from '@/types/inference'
import { ModelType } from '@/types/model'
import { TestModelModal } from '../TestModelModal/TestModelModal'

type InferenceHandlerProps = {
  disable?: boolean
  appId: string
  modelId: string
  modelType?: ModelType | undefined
  buttonVariant?: string
  resolution?: string
}

export const InferenceHandler = ({
  appId,
  disable = false,
  modelId,
  modelType = ModelType.UserMLModel,
  buttonVariant = 'filled',
  resolution
}: InferenceHandlerProps) => {
  const intl = useIntl()
  const [modalPage, setModalPage] = useState<number>(1)
  const [isTrainModalOpen, { open: openTrainModal, close: closeTrainModal }] =
    useDisclosure(false)
  const [inferenceId, setInferenceId] = useState<string>('')
  const [inferredMedia, setInferredMedia] = useState<string[]>([])

  const {
    mutateAsync: startInference,
    isError: isStartingInferenceError,
    reset: restartInference,
    isPending: isStartingInferencePending
  } = useStartInference()

  const { data: inferenceData, isError } = useGetInferenceDetails({
    inferenceId,
    enabled: Boolean(inferenceId)
  })
  const inferenceDetails = inferenceData?.result

  const isInferenceError =
    isStartingInferenceError ||
    inferenceDetails?.status === InferenceStatus.Failed
  const isPending = inferenceDetails?.status === InferenceStatus.InProgress

  const handleModelInference = async (acceptedFiles: File[]) => {
    try {
      const response = await startInference({
        applicationId: appId,
        modelId: modelId,
        modelType: modelType,
        mediaFiles: acceptedFiles,
        resolution
      })
      setInferenceId(response.data.results.inference_id || '')
      showToast(intl.formatMessage({ id: 'models.test.loading' }), 'info')
    } catch (err) {
      const { errorMessage } = getApiError(err)
      const message =
        errorMessage || intl.formatMessage({ id: 'models.test.error' })
      showToast(message, 'error')
      setInferenceId('')
    }
  }

  useEffect(() => {
    if (inferenceDetails) {
      if (inferenceDetails.status === InferenceStatus.Success) {
        showToast(intl.formatMessage({ id: 'models.test.success' }), 'success')
        setInferredMedia(inferenceDetails.media_files || [])
        setModalPage(2)
        setInferenceId('')
      }

      if (inferenceDetails?.status === InferenceStatus.Failed || isError) {
        showToast(intl.formatMessage({ id: 'models.test.error' }), 'error')
        setInferenceId('')
      }
    }
  }, [inferenceDetails, intl, isError, setInferenceId, setInferredMedia])

  return (
    <>
      <Button
        miw={160}
        size="xs"
        radius="xl"
        disabled={disable}
        variant={buttonVariant}
        leftSection={
          isPending ? (
            <Loader size={16} color="white" />
          ) : (
            <IconChecklist size={16} />
          )
        }
        onClick={openTrainModal}
      >
        {inferredMedia.length > 0 ? (
          <FormattedMessage id="models.test.viewResults" />
        ) : isPending ? (
          <FormattedMessage id="models.test.inProgress" />
        ) : (
          <FormattedMessage id="models.test" />
        )}
      </Button>

      <TestModelModal
        applicationId={appId}
        selectedModelId={modelId}
        selectedModelType={modelType}
        inferredMedia={inferredMedia}
        modalPage={modalPage}
        isOpen={isTrainModalOpen}
        isInferenceError={isInferenceError}
        isInferringPending={isPending || isStartingInferencePending}
        setInferenceId={setInferenceId}
        setInferredMedia={setInferredMedia}
        setModalPage={setModalPage}
        restartInference={restartInference}
        onClose={closeTrainModal}
        onInference={handleModelInference}
      />
    </>
  )
}
