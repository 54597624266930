import { SimpleGrid, Title } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useApplicationContext } from '@/providers/ApplicationContext'
import { useGetUserMlModels } from '@/queries/modelQueries'
import { buildModelLink } from '@/router/paths'
import { UserMLModel } from '@/types/model'
import { ErrorWithReload } from '../ui-shared/ErrorWithReload/ErrorWithReload'
import { InfiniteScroll } from '../ui-shared/InfiniteScroll/InfiniteScroll'
import { BaseModelInfo } from './BaseModelInfo/BaseModelInfo'
import { CreateModelModal } from './CreateModelModal/CreateModelModal'
import { DeleteModelModal } from './DeleteModelModal'
import { ModelCard } from './ModelCard/ModelCard'
import { TrainModelButton } from './TrainModelButton/TrainModelButton'
import { UpdateModelModal } from './UpdateModelModal/UpdateModelModal'

export const ModelsPage = () => {
  const { application } = useApplicationContext()

  const [
    isCreateModelModalOpen,
    { open: openCreateModelModal, close: closeCreateModelModal }
  ] = useDisclosure(false)

  const [modelToDelete, setModelToDelete] = useState<UserMLModel | null>(null)
  const [modelToRename, setModelToRename] = useState<UserMLModel | null>(null)

  const { data, isFetching, isError, refetch, fetchNextPage, hasNextPage } =
    useGetUserMlModels(application?.id || '')

  const models = data?.pages?.flatMap((page) => page.results) || []

  const handleLoadMore = () => {
    void fetchNextPage()
  }

  const handleOnReload = () => {
    void refetch()
  }

  const handleModelUpdated = () => {
    setModelToRename(null)
    void refetch()
  }

  const handleModelDeleted = () => {
    setModelToDelete(null)
    void refetch()
  }

  if (isError) {
    return (
      <ErrorWithReload onReload={handleOnReload}>
        <FormattedMessage id="models.modelList.apiError" />
      </ErrorWithReload>
    )
  }

  return (
    <>
      <Title order={2} mb="lg">
        <FormattedMessage id="models.myModels" />
      </Title>

      <InfiniteScroll
        hasMore={hasNextPage}
        isFetching={isFetching}
        onLoadMore={handleLoadMore}
      >
        <SimpleGrid
          cols={{ base: 1, sm: 2, lg: 4, xl: 6 }}
          spacing="lg"
          verticalSpacing="lg"
        >
          <TrainModelButton onClick={() => void openCreateModelModal()} />

          {models.map((model) => (
            <ModelCard
              key={model.id}
              modelName={model.name}
              modelStatus={model.status}
              modelDetailsLink={buildModelLink(application?.id || '', model.id)}
              logoUrl={model.root_model?.brand_logo || undefined}
              infoComponent={
                <BaseModelInfo
                  name={model.name}
                  baseModelName={model.root_model?.name}
                  labels={model.labels}
                  brandName={model?.root_model?.brand_name_display || undefined}
                  brandLogo={model?.root_model?.brand_logo || undefined}
                  networkType={model?.root_model?.network_type}
                />
              }
              showInfoOnHover
              onRename={() => setModelToRename(model)}
              onDelete={() => setModelToDelete(model)}
            />
          ))}
        </SimpleGrid>
      </InfiniteScroll>

      <CreateModelModal
        appId={application?.id || ''}
        opened={isCreateModelModalOpen}
        onClose={closeCreateModelModal}
      />

      {modelToRename && (
        <UpdateModelModal
          opened={modelToRename !== null}
          appId={application?.id || ''}
          model={modelToRename}
          onClose={() => setModelToRename(null)}
          onUpdated={handleModelUpdated}
        />
      )}

      <DeleteModelModal
        opened={modelToDelete !== null}
        modelId={modelToDelete?.id || ''}
        appId={application?.id || ''}
        onClose={() => setModelToDelete(null)}
        onDeleted={handleModelDeleted}
      />
    </>
  )
}
