import { Grid, Paper, ScrollArea } from '@mantine/core'
import { useViewportSize } from '@mantine/hooks'
import { ReactNode } from 'react'
import { PREVIEW_HEADER_HEIGHT } from './DatasetPreviewDrawer'

const SIDEBAR_WIDTH = 280

type DatasetPreviewLayoutProps = {
  sidebar: ReactNode
  children: ReactNode
}

export const DatasetPreviewLayout = ({
  sidebar,
  children
}: DatasetPreviewLayoutProps) => {
  const { height } = useViewportSize()

  return (
    <Grid>
      <Grid.Col span="content">
        <Paper
          w={SIDEBAR_WIDTH}
          radius={0}
          styles={{
            root: {
              borderWidth: '0 1px 0 0'
            }
          }}
          withBorder
        >
          <ScrollArea h={height - PREVIEW_HEADER_HEIGHT}>{sidebar}</ScrollArea>
        </Paper>
      </Grid.Col>

      <Grid.Col span="auto">
        <ScrollArea h={height - PREVIEW_HEADER_HEIGHT}>{children}</ScrollArea>
      </Grid.Col>
    </Grid>
  )
}
