import { ActionIcon, Box, Button, Group, Title } from '@mantine/core'
import { IconArrowLeft, IconQuestionMark } from '@tabler/icons-react'
import { ReactNode } from 'react'

type StepHeadlineProps = {
  title: string | ReactNode
  showBackButton?: boolean
  backButtonText?: string | ReactNode
  showInfoIcon?: boolean
  onBackClick?: () => void
  onInfoClick?: () => void
}

export const StepHeadline = ({
  title,
  showBackButton,
  backButtonText,
  showInfoIcon,
  onBackClick,
  onInfoClick
}: StepHeadlineProps) => {
  return (
    <Group justify="space-between">
      <Box miw={160}>
        {showBackButton && (
          <Button
            size="xs"
            variant="transparent"
            leftSection={<IconArrowLeft size={20} />}
            onClick={onBackClick}
          >
            {backButtonText}
          </Button>
        )}
      </Box>

      <Group gap="xs" wrap="nowrap">
        <Title order={3}>{title}</Title>

        {showInfoIcon && (
          <ActionIcon
            variant="light"
            size="sm"
            radius="xl"
            onClick={onInfoClick}
          >
            <IconQuestionMark style={{ width: '90%', height: '90%' }} />
          </ActionIcon>
        )}
      </Group>

      <Box miw={160} />
    </Group>
  )
}
