import { Button, Group, Text, TextInput } from '@mantine/core'
import { useForm } from '@mantine/form'
import { ChangeEvent } from 'react'
import { FormattedMessage } from 'react-intl'

type DeleteApplicationFormProps = {
  confirmAppName: string
  onCancel: () => void
  onConfirmDelete: () => void
}

export const DeleteApplicationForm = ({
  confirmAppName,
  onCancel,
  onConfirmDelete
}: DeleteApplicationFormProps) => {
  const form = useForm({
    initialValues: {
      appName: ''
    },

    validate: {
      appName: (value) => value !== confirmAppName.toUpperCase()
    }
  })

  const transformToUppercase = (e: ChangeEvent<HTMLInputElement>) => {
    form.setFieldValue('appName', e.target.value.toUpperCase())
  }

  return (
    <form onSubmit={form.onSubmit(onConfirmDelete)}>
      <Text size="sm" mb="lg">
        <FormattedMessage
          id="apps.deleteApp.areYouSure"
          values={{
            appName: <strong>{confirmAppName}</strong>
          }}
        />
      </Text>

      <Text size="sm" mb="lg" fw="bold">
        <FormattedMessage id="apps.deleteApp.willPermanentlyRemove" />
      </Text>

      <Text size="sm" mb="lg">
        <FormattedMessage
          id="apps.deleteApp.typeAppName"
          values={{
            appName: <strong>{confirmAppName.toUpperCase()}</strong>
          }}
        />
      </Text>

      <TextInput
        mb="xl"
        autoFocus
        data-autofocus // required for auto focus inside the modal
        {...form.getInputProps('appName')}
        onChange={transformToUppercase}
      />

      <Group justify="end">
        <Button miw={120} variant="default" onClick={onCancel}>
          <FormattedMessage id="cancel" />
        </Button>

        <Button type="submit" color="red" disabled={!form.isValid()}>
          <FormattedMessage id="apps.deleteApp" />
        </Button>
      </Group>
    </form>
  )
}
