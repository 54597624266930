import { useInfiniteQuery, useMutation, useQuery } from '@tanstack/react-query'
import { deviceApi } from '@/api/deviceApi'
import { objectToQueryString } from '@/utils/url'

export const deviceQueryKeys = {
  all: ['devices'] as const,
  list: () => [...deviceQueryKeys.all, 'list'] as const,
  details: (deviceId: string) =>
    [...deviceQueryKeys.all, 'details', deviceId] as const,
  detailsList: (deviceIds: string[]) =>
    [...deviceQueryKeys.all, 'details-list', deviceIds] as const,
  cameraStream: (deviceId: string, cameraStreamId: string) =>
    [...deviceQueryKeys.all, 'cameraStream', deviceId, cameraStreamId] as const,
  pollCameraCreationStatus: (deviceId: string, pollTaskId: string) =>
    [
      ...deviceQueryKeys.all,
      'pollCameraCreationStatus',
      deviceId,
      pollTaskId
    ] as const
}

export const useGetDevices = () => {
  return useInfiniteQuery({
    queryKey: deviceQueryKeys.list(),
    queryFn: ({ pageParam }) => deviceApi.getDevices({ pageUrl: pageParam }),
    getNextPageParam: (lastPage) => {
      return lastPage.next ?? undefined
    },
    initialPageParam: `/v1/devices/?${objectToQueryString({
      limit: 20
    })}`
  })
}

export const useGetDeviceDetails = (deviceId: string) => {
  return useQuery({
    queryKey: deviceQueryKeys.details(deviceId),
    queryFn: () => deviceApi.getDeviceDetails(deviceId)
  })
}

export const useGetMultipleDeviceDetails = (deviceIds: string[]) => {
  return useQuery({
    queryKey: deviceQueryKeys.detailsList(deviceIds),
    queryFn: () => {
      return Promise.all(
        deviceIds.map((deviceId) => deviceApi.getDeviceDetails(deviceId))
      )
    }
  })
}

export const useClaimDevice = () => {
  return useMutation({
    mutationFn: deviceApi.claimDevice
  })
}

export const useCreateCameraStreams = () => {
  return useMutation({
    mutationFn: deviceApi.createCameraStreams
  })
}

type UsePollCameraCreationStatusParams = {
  deviceId: string
  pollTaskId: string
  enabled: boolean
}

export const usePollCameraCreationStatus = ({
  deviceId,
  pollTaskId,
  enabled
}: UsePollCameraCreationStatusParams) => {
  return useQuery({
    queryKey: deviceQueryKeys.pollCameraCreationStatus(deviceId, pollTaskId),
    queryFn: () => deviceApi.getCameraCreationStatus(deviceId),
    refetchInterval: (query) => {
      const { data, error } = query.state

      return data?.status === 'Complete' || data?.status === 'Failed' || error
        ? false
        : 3000
    },
    enabled
  })
}

type UseGetCameraStreamDetailsParams = {
  deviceId: string
  cameraStreamId: string
  enabled?: boolean
}

export const useGetCameraStreamDetails = ({
  deviceId,
  cameraStreamId,
  enabled
}: UseGetCameraStreamDetailsParams) => {
  return useQuery({
    queryKey: deviceQueryKeys.cameraStream(deviceId, cameraStreamId),
    queryFn: () =>
      deviceApi.getCameraStreamDetails({ deviceId, cameraStreamId }),
    enabled
  })
}

export const useRefreshCameraStreamImage = () => {
  return useMutation({
    mutationFn: deviceApi.refreshCameraStreamImage
  })
}

export const useRemoveCameraStream = () => {
  return useMutation({
    mutationFn: deviceApi.removeCameraStream
  })
}

export const useGetImportCameraStreams = () => {
  return useMutation({
    mutationFn: deviceApi.getImportCameraStreams
  })
}

export const useValidateIntegrationUrl = () => {
  return useMutation({
    mutationFn: deviceApi.validateIntegrationUrl
  })
}
