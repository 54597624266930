import { Group, Text } from '@mantine/core'
import { IconCheck } from '@tabler/icons-react'

import classes from './FeatureList.module.css'

type FeatureListProps = {
  features: string[]
}

export const FeatureList = ({ features }: FeatureListProps) => {
  return (
    <ul className={classes['feature-list']}>
      {features.map((feature) => (
        <li key={feature}>
          <Group gap="xs" wrap="nowrap" align="start">
            <IconCheck
              size={16}
              color="var(--mantine-color-green-7)"
              stroke={4}
              style={{ marginTop: '2px' }}
            />

            <Text size="sm">{feature}</Text>
          </Group>
        </li>
      ))}
    </ul>
  )
}
