import { Box, Button, PasswordInput, Text, Title } from '@mantine/core'
import { useForm } from '@mantine/form'
import { FormattedMessage, useIntl } from 'react-intl'
import { PasswordRules } from '@/components/ui-shared/PasswordRules/PasswordRules'
import { isStrongPassword } from '@/utils/validation/password'

export type FormValues = {
  password: string
  confirmPassword: string
}

type SetNewPasswordFormProps = {
  onSubmit: (formValues: FormValues) => void
}

const messages = {
  password: {
    id: 'password'
  },
  confirmPassword: {
    id: 'confirmPassword'
  },
  invalidPassword: {
    id: 'validation.strongPassword.invalid'
  },
  passwordsDoNotMatch: {
    id: 'validation.passwordsDoNotMatch'
  }
}

export const SetNewPasswordForm = ({ onSubmit }: SetNewPasswordFormProps) => {
  const intl = useIntl()

  const form = useForm({
    initialValues: {
      password: '',
      confirmPassword: ''
    },

    validate: {
      password: isStrongPassword(intl.formatMessage(messages.invalidPassword)),
      confirmPassword: (value, values) =>
        value !== values.password
          ? intl.formatMessage(messages.passwordsDoNotMatch)
          : null
    }
  })

  return (
    <form noValidate onSubmit={form.onSubmit((values) => onSubmit(values))}>
      <Title order={2} mb="lg">
        <FormattedMessage id="passwordReset.setNewPassword" />
      </Title>

      <Text size="sm" c="dimmed" mb="lg">
        <FormattedMessage id="passwordReset.chooseYourNewPassword" />
      </Text>

      <Box mb="lg">
        <PasswordInput
          label={intl.formatMessage(messages.password)}
          mb="sm"
          {...form.getInputProps('password')}
        />

        <PasswordRules
          password={form.getInputProps('password').value as string}
        />
      </Box>

      <PasswordInput
        label={intl.formatMessage(messages.confirmPassword)}
        mb="xl"
        {...form.getInputProps('confirmPassword')}
      />

      <Button type="submit" mb="md" fullWidth>
        <FormattedMessage id="continue" />
      </Button>
    </form>
  )
}
