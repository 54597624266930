import { ReactNode, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'

const TRIGGER_ERROR_PARAM = 'trigger-error'

type ErrorTesterProps = {
  children: ReactNode
}

export const ErrorTester = ({ children }: ErrorTesterProps) => {
  const [searchParams] = useSearchParams()
  const errorParam = searchParams.get(TRIGGER_ERROR_PARAM)

  useEffect(() => {
    if (errorParam === '1') {
      throw new Error('test error')
    }
  }, [errorParam])

  return <>{children}</>
}
