import { Box, Group, Loader, Text } from '@mantine/core'
import { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { getApiError } from '@/api/helpers/apiError'
import { INTEGRATION_NAMES } from '@/config/integrations'
import { useGetImportCameraStreams } from '@/queries/deviceQueries'
import { CameraSource, ImportCameraData } from '@/types/device'
import { useImportContext } from '../../ImportContext'
import {
  FormValues,
  MilestoneUrlForm
} from '../MilestoneUrlForm/MilestoneUrlForm'

type MilestoneUrlHandlerProps = {
  onSubmit: (deviceId: string, cameraStreams: ImportCameraData[]) => void
}

export const MilestoneUrlHandler = ({ onSubmit }: MilestoneUrlHandlerProps) => {
  const intl = useIntl()
  const [errorDetails, setErrorDetails] = useState<string | null>(null)
  const { mutateAsync: getCameras, isPending } = useGetImportCameraStreams()

  const { devices } = useImportContext()

  const deviceOptions = devices.map((device) => ({
    value: device.id,
    label: device.label
  }))

  const handleSubmit = async (values: FormValues) => {
    setErrorDetails(null)

    try {
      const { cameras } = await getCameras({
        deviceId: values.deviceId,
        integrationUrl: values.milestoneUrl,
        source: CameraSource.Milestone
      })

      onSubmit(values.deviceId, cameras)
    } catch (err) {
      const { errorMessage } = getApiError(err)

      const message =
        errorMessage ||
        intl.formatMessage(
          { id: 'integrations.validateUrl.error' },
          {
            platform: INTEGRATION_NAMES.MILESTONE
          }
        )

      setErrorDetails(message)
    }
  }

  return (
    <Box>
      <MilestoneUrlForm
        deviceId={deviceOptions[0].value}
        deviceOptions={deviceOptions}
        isLoading={isPending}
        onSubmit={(values) => void handleSubmit(values)}
      />

      {isPending && (
        <Group align="center" wrap="nowrap" mt="md">
          <Loader size="xs" />

          <Text size="xs">
            <FormattedMessage
              id="integrations.validateUrl.pending"
              values={{
                platform: INTEGRATION_NAMES.MILESTONE
              }}
            />
          </Text>
        </Group>
      )}

      {errorDetails && (
        <Text c="red" size="xs" mt="md">
          {errorDetails}
        </Text>
      )}
    </Box>
  )
}
