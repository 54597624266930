import * as Sentry from '@sentry/react'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { accountApi } from '@/api/accountApi'
import { RouterPath } from '@/router/paths'
import { useSession } from '@/session/SessionContext'

export const useLogout = () => {
  const { clearSession } = useSession()
  const navigate = useNavigate()

  const [isPending, setIsPending] = useState(false)
  const [isError, setIsError] = useState(false)
  const [error, setError] = useState<unknown>(null)

  const logout = async () => {
    setError(null)
    setIsError(false)
    setIsPending(true)

    try {
      await accountApi.logout()
      clearSession()
      Sentry.setUser(null)
      navigate(RouterPath.login)
    } catch (err) {
      setError(err)
      setIsError(true)
    }

    setIsPending(false)
  }

  return {
    logout,
    isPending,
    isError,
    error
  }
}
