import axios from 'axios'
import { VP_API_BASE_URL } from '../config'

export const CSRF_HEADER_NAME = 'x-csrftoken'

export const vpApi = axios.create({
  baseURL: VP_API_BASE_URL
})

export const setCsrfToken = (token: string) => {
  vpApi.defaults.headers[CSRF_HEADER_NAME] = token
}
