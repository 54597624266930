import { Box, Card, Stack, Text, UnstyledButton } from '@mantine/core'
import { useHover } from '@mantine/hooks'
import { IconCirclePlus } from '@tabler/icons-react'
import { ReactNode } from 'react'
import { BORDERED_CARD_STYLES } from '@/theme/customStyles'

const CARD_HEIGHT = 160

type AddButtonCardProps = {
  label: string | ReactNode
  height?: number
  disabled?: boolean
  onClick: () => void
}

export const AddButtonCard = ({
  label,
  height,
  disabled,
  onClick
}: AddButtonCardProps) => {
  const { hovered, ref } = useHover()
  const cardShadow = hovered ? 'sm' : 'xs'

  const cardStyle = {
    ...(disabled ? { opacity: 0.5, cursor: 'not-allowed' } : {})
  }

  return (
    <UnstyledButton w="100%" disabled={disabled} onClick={onClick}>
      <Card
        ref={ref}
        shadow={cardShadow}
        h={height || CARD_HEIGHT}
        padding="md"
        radius="md"
        styles={BORDERED_CARD_STYLES}
        style={cardStyle}
        withBorder
      >
        <Stack h="100%" gap="xs" align="center" justify="center">
          <Box mt="md">
            <IconCirclePlus size={24} />
          </Box>

          <Text ta="center" fw="bold" size="sm">
            {label}
          </Text>
        </Stack>
      </Card>
    </UnstyledButton>
  )
}
