import { Vector2d } from 'konva/lib/types'

export const minMax = (points: number[]): [number, number] => {
  const min = Math.min(...points)
  const max = Math.max(...points)
  return [min, max]
}

export const dragBoundFunc = (
  stageWidth: number,
  stageHeight: number,
  vertexRadius: number,
  pos: Vector2d
) => {
  let x = pos.x
  let y = pos.y

  if (pos.x + vertexRadius > stageWidth) {
    x = stageWidth
  }

  if (pos.x - vertexRadius < 0) {
    x = 0
  }

  if (pos.y + vertexRadius > stageHeight) {
    y = stageHeight
  }

  if (pos.y - vertexRadius < 0) {
    y = 0
  }

  return { x, y }
}
