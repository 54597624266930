import { KonvaEventObject } from 'konva/lib/Node'
import { Stage } from 'konva/lib/Stage'
import { Vector2d } from 'konva/lib/types'
import { useState } from 'react'
import { dragBoundFunc } from './utils'

export const useDraggablePoint = () => {
  const [stage, setStage] = useState<Stage>()

  const handlePointMouseOver = (e: KonvaEventObject<MouseEvent>) => {
    const eventStage = e.target.getStage() as Stage
    eventStage.container().style.cursor = 'move'
    setStage(eventStage)

    e.target.scale({ x: 1.8, y: 1.8 })
  }

  const handlePointMouseOut = (e: KonvaEventObject<MouseEvent>) => {
    const eventStage = e.target.getStage() as Stage
    eventStage.container().style.cursor = 'default'
    setStage(eventStage)

    e.target.scale({ x: 1, y: 1 })
  }

  const pointDragBoundFunc = (pos: Vector2d, circleSize: number) =>
    dragBoundFunc(stage?.width() ?? 0, stage?.height() ?? 0, circleSize, pos)

  return {
    handlePointMouseOver,
    handlePointMouseOut,
    pointDragBoundFunc
  }
}
