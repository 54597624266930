import { Box, Center, Image } from '@mantine/core'
import { useViewportSize } from '@mantine/hooks'
import ImageGallery, { ReactImageGalleryItem } from 'react-image-gallery'
import ImagePlaceholder from '@/assets/image-placeholder.png'
import { useDeviceDetect } from '@/hooks/useDeviceDetect'
import { FileMetadata } from '@/types/dataset'

const THUMBNAIL_SIZE = 90

type MediaItemProps = {
  file: FileMetadata
  withThumbnails: boolean
}

const MediaItem = ({ file, withThumbnails }: MediaItemProps) => {
  const { isMobile } = useDeviceDetect()

  const { height: vHeight } = useViewportSize()

  const verticalOffset = withThumbnails ? 220 : 100

  return (
    <Box px={isMobile ? 60 : 120} h={vHeight - verticalOffset}>
      <Center h="100%">
        {file.media_type === 'video' ? (
          <video
            style={{ maxHeight: vHeight - 2 * verticalOffset, width: '100%' }}
            controls
          >
            <source src={file.media_file} type="video/mp4" />
          </video>
        ) : (
          <Image
            src={file.media_file}
            fit="contain"
            h={vHeight - 2 * verticalOffset}
          />
        )}
      </Center>
    </Box>
  )
}

type GalleryItem = ReactImageGalleryItem & {
  file: FileMetadata
}

type MediaGalleryProps = {
  items: GalleryItem[]
  startIndex: number
  showThumbnails?: boolean
  onSlideChange: (index: number) => void
}

export const MediaGallery = ({
  items,
  startIndex,
  showThumbnails = false,
  onSlideChange
}: MediaGalleryProps) => {
  const augmentedItems = items.map((item) => ({
    ...item,
    ...(showThumbnails
      ? { thumbnailWidth: THUMBNAIL_SIZE, thumbnailHeight: THUMBNAIL_SIZE }
      : {})
  }))

  return (
    <Box px={24}>
      <ImageGallery
        items={augmentedItems}
        startIndex={startIndex}
        infinite={false}
        showThumbnails={showThumbnails}
        showPlayButton={false}
        showFullscreenButton={false}
        renderItem={(item) => {
          // it's important to use item attached to gallery data, otherwise
          // media is not rendered properly
          const { file } = item as GalleryItem
          return <MediaItem file={file} withThumbnails={showThumbnails} />
        }}
        useBrowserFullscreen={false}
        lazyLoad
        onErrorImageURL={ImagePlaceholder}
        onSlide={onSlideChange}
      />
    </Box>
  )
}
