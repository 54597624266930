import { Group, Text } from '@mantine/core'
import { IconPhoto, IconVideo } from '@tabler/icons-react'
import { FileMetadata } from '@/types/dataset'

type MediaFileNameProps = {
  fileName: string
  fileType: FileMetadata['media_type']
}

export const MediaFileName = ({ fileName, fileType }: MediaFileNameProps) => {
  return (
    <Group wrap="nowrap" gap="xs">
      {fileType === 'video' ? <IconVideo size={20} /> : <IconPhoto size={20} />}

      <Text size="sm" fw={500}>
        {fileName}
      </Text>
    </Group>
  )
}
