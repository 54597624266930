import { Box, Center, Loader } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { FormattedMessage, useIntl } from 'react-intl'
import { HelpDialog } from '@/components/help-dialog/HelpDialog'
import { UploadAndAnnotateContent } from '@/components/help-dialog/contents/UploadAndAnnotateContent'
import { AddLabelMessage } from '@/components/labels/AddLabelMessage/AddLabelMessage'
import { LabelSelectionModal } from '@/components/labels/LabelSelectionModal/LabelSelectionModal'
import { UploadHandler } from '@/components/media/UploadHandler/UploadHandler'
import { useApplicationContext } from '@/providers/ApplicationContext'
import { useModelContext } from '@/providers/ModelContext'
import { useGetLabels } from '@/queries/labelQueries'
import { StepHeadline } from '../StepHeadline/StepHeadline'
import { useModelStepNavigation } from '../useModelStepNavigation'

export const StepAnnotate = () => {
  const intl = useIntl()
  const { application } = useApplicationContext()
  const { model } = useModelContext()

  const [isHelpDialogOpen, { open: openHelpDialog, close: closeHelpDialog }] =
    useDisclosure(false)

  const [
    isLabelSelectionModalOpened,
    { open: openLabelSelectionModal, close: closeLabelSelectionModal }
  ] = useDisclosure(false)

  const { goToBaseModelScreen } = useModelStepNavigation({
    appId: application?.id || '',
    modelId: model?.id || ''
  })

  const { data, isFetching } = useGetLabels(application?.id || '')
  const labels = data?.pages.flatMap((page) => page.results) ?? []
  const appLabels = labels.filter((label) => label.source === 'applications')

  const handleBackClick = () => {
    goToBaseModelScreen()
  }

  return (
    <>
      <StepHeadline
        title={<FormattedMessage id="models.stepper.step2" />}
        backButtonText={<FormattedMessage id="models.stepper.step1" />}
        showBackButton
        showInfoIcon
        onBackClick={handleBackClick}
        onInfoClick={openHelpDialog}
      />

      {appLabels.length === 0 && isFetching && (
        <Center mih={160}>
          <Loader />
        </Center>
      )}

      {appLabels.length === 0 && !isFetching && (
        <Box mt="lg">
          <AddLabelMessage onAddLabelClick={openLabelSelectionModal} />
        </Box>
      )}

      {application && model && appLabels.length > 0 && (
        <Box mt="lg">
          <UploadHandler applicationId={application.id} model={model} />
        </Box>
      )}

      <LabelSelectionModal
        applicationId={application?.id || ''}
        opened={isLabelSelectionModalOpened}
        onClose={closeLabelSelectionModal}
      />

      <HelpDialog
        isOpen={isHelpDialogOpen}
        title={intl.formatMessage({ id: 'models.stepper.step2' })}
        onClose={closeHelpDialog}
      >
        <UploadAndAnnotateContent />
      </HelpDialog>
    </>
  )
}
