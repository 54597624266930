import { Box, Button, Group, LoadingOverlay, Modal, Text } from '@mantine/core'
import { IconTrash } from '@tabler/icons-react'
import { FormattedMessage, useIntl } from 'react-intl'
import { getApiError } from '@/api/helpers/apiError'
import { useRemoveAppDeployment } from '@/queries/deploymentQueries'
import { showToast } from '@/theme/notifications'

const ModalTitle = () => (
  <Group>
    <IconTrash size={20} />
    <FormattedMessage id="deployments.remove.confirm.title" />
  </Group>
)

type RemoveDeploymentConfirmationProps = {
  opened: boolean
  applicationId: string
  applicationName: string
  onRemoved: () => void
  onClose: () => void
}

export const RemoveDeploymentConfirmation = ({
  opened,
  applicationId,
  applicationName,
  onRemoved,
  onClose
}: RemoveDeploymentConfirmationProps) => {
  const intl = useIntl()
  const { mutateAsync: removeDeployment, isPending } = useRemoveAppDeployment()

  const handleRemoveDeployment = async () => {
    try {
      await removeDeployment(applicationId)
      onRemoved()
    } catch (err) {
      const { errorMessage } = getApiError(err)
      const message =
        errorMessage || intl.formatMessage({ id: 'deployments.remove.error' })
      showToast(message, 'error')
    }
  }

  return (
    <Modal size="lg" title={<ModalTitle />} opened={opened} onClose={onClose}>
      <Box pos="relative">
        <LoadingOverlay visible={isPending} />

        <Text size="sm" mb="xl">
          <FormattedMessage
            id="deployments.remove.confirm.message"
            values={{
              b: (chunks) => <b>{chunks}</b>,
              name: applicationName
            }}
          />
        </Text>

        <Group justify="end">
          <Button miw={140} variant="default" onClick={onClose}>
            <FormattedMessage id="cancel" />
          </Button>

          <Button color="red" onClick={() => void handleRemoveDeployment()}>
            <FormattedMessage id="deployments.remove" />
          </Button>
        </Group>
      </Box>
    </Modal>
  )
}
