import { AppShell, Container, rem } from '@mantine/core'
import { ReactNode } from 'react'
import classes from './SubscriptionsLayout.module.css'

const CONTAINER_MAX_WIDTH = 1600

type SubscriptionsLayoutProps = {
  children: ReactNode
}

export const SubscriptionsLayout = ({ children }: SubscriptionsLayoutProps) => {
  return (
    <AppShell padding="md">
      <AppShell.Main className={classes['subscriptions-container']}>
        <Container p={0} size={rem(CONTAINER_MAX_WIDTH)}>
          {children}
        </Container>
      </AppShell.Main>
    </AppShell>
  )
}
