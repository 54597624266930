import { Badge, Group } from '@mantine/core'
import { Label } from '@/types/label'

type LabelGroupProps = {
  labels: Label[]
}

export const LabelGroup = ({ labels }: LabelGroupProps) => {
  return (
    <Group gap="xs">
      {labels.map((item) => (
        <Badge key={item.name} size="sm" color={item.color}>
          {item.name}
        </Badge>
      ))}
    </Group>
  )
}
