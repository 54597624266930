import { Box, Button, Card, Group, Image, Stack, Text } from '@mantine/core'
import { IconDownload } from '@tabler/icons-react'
import { FormattedMessage } from 'react-intl'
import MilestoneLogo from '@/assets/integrations/milestone.png'
import { INTEGRATION_NAMES } from '@/config/integrations'
import { CameraSource } from '@/types/device'

type IntegrationsListProps = {
  onImportClick: (source: CameraSource) => void
}

export const IntegrationsList = ({ onImportClick }: IntegrationsListProps) => {
  return (
    <Card p="lg" withBorder>
      <Card.Section py="lg" inheritPadding>
        <Group align="start" wrap="nowrap" gap="xl">
          <Image src={MilestoneLogo} w={36} h={36} fit="contain" />

          <Stack>
            <Box>
              <Text fw="bold" mb={4}>
                {INTEGRATION_NAMES.MILESTONE}
              </Text>

              <Text size="xs" c="dimmed">
                <FormattedMessage
                  id="integrations.importStream.description"
                  values={{
                    platform: INTEGRATION_NAMES.MILESTONE
                  }}
                />
              </Text>
            </Box>

            <Box>
              <Button
                leftSection={<IconDownload size={16} />}
                onClick={() => onImportClick(CameraSource.Milestone)}
              >
                <FormattedMessage
                  id="importFrom"
                  values={{
                    source: 'Milestone'
                  }}
                />
              </Button>
            </Box>
          </Stack>
        </Group>
      </Card.Section>
    </Card>
  )
}
