import { Label } from '@/types/label'
import { PaginatedResponse } from '../types/pagination'
import { vpApi } from './vpApi'

type LabelsResponse = PaginatedResponse<Label[]>

type GetLabelsParams = {
  pageUrl: string
}

const getLabels = async ({ pageUrl }: GetLabelsParams) => {
  const res = await vpApi.get<LabelsResponse>(pageUrl)
  return res.data
}

type LabelData = Pick<Label, 'name' | 'color'>

type CreateLabelParams = {
  appId: string
  data: LabelData[]
}

const createLabels = ({ appId, data }: CreateLabelParams) => {
  return vpApi.post<Label>(`/v1/applications/${appId}/labels/`, data)
}

type DeleteLabelParams = {
  appId: string
  labelId: string
}

const deleteLabel = ({ appId, labelId }: DeleteLabelParams) => {
  return vpApi.delete(`/v1/applications/${appId}/labels/${labelId}/`)
}

export const labelApi = {
  getLabels,
  createLabels,
  deleteLabel
}
