import { Box, Button, Card, Drawer, Group, Title } from '@mantine/core'
import { IconArrowLeft } from '@tabler/icons-react'
import { ReactNode } from 'react'
import { FormattedMessage } from 'react-intl'

export const PREVIEW_HEADER_HEIGHT = 64

type DatasetPreviewDrawerProps = {
  opened: boolean
  children: ReactNode
  closeOnEscape?: boolean
  onClose: () => void
}

export const DatasetPreviewDrawer = ({
  opened,
  children,
  closeOnEscape,
  onClose
}: DatasetPreviewDrawerProps) => {
  return (
    <Drawer.Root
      opened={opened}
      position="right"
      size="80%"
      closeOnEscape={closeOnEscape}
      onClose={onClose}
    >
      <Drawer.Overlay />

      <Drawer.Content>
        <Card padding="lg">
          <Card.Section
            h={PREVIEW_HEADER_HEIGHT}
            py="md"
            withBorder
            inheritPadding
          >
            <Group
              align="center"
              gap="xl"
              wrap="nowrap"
              justify="space-between"
            >
              <Button
                miw={160}
                variant="outline"
                size="xs"
                radius="xl"
                leftSection={<IconArrowLeft size={18} />}
                onClick={onClose}
              >
                <FormattedMessage id="datasets.preview.backToList" />
              </Button>

              <Title order={4}>
                <FormattedMessage id="datasets.preview" />
              </Title>

              <Box miw={160}>&nbsp;</Box>
            </Group>
          </Card.Section>

          <Card.Section>{children}</Card.Section>
        </Card>
      </Drawer.Content>
    </Drawer.Root>
  )
}
