import { NotificationData, notifications } from '@mantine/notifications'

type NotificationType = 'success' | 'error' | 'info'

export const showToast = (
  message: string,
  type: NotificationType,
  options?: Partial<NotificationData>
) => {
  switch (type) {
    case 'success':
      notifications.show({
        message,
        color: 'teal',
        autoClose: 5000,
        style: {
          backgroundColor: `var(--mantine-color-green-0)`
        },
        ...options
      })
      break

    case 'error':
      notifications.show({
        message,
        color: 'red',
        autoClose: 10000,
        style: {
          backgroundColor: `var(--mantine-color-red-0)`
        },

        ...options
      })
      break

    case 'info':
      notifications.show({
        message,
        color: 'blue',
        autoClose: 10000,
        style: {
          backgroundColor: `var(--mantine-color-blue-0)`
        },
        ...options
      })
      break

    default:
      return null
  }
}
