import { Button, Group, Image, Stack, Text } from '@mantine/core'
import { IconArrowLeft } from '@tabler/icons-react'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import VpLogoSrc from '@/assets/vp-stripes.png'
import { ModelStepperHandler } from '@/components/models/ModelDetails/ModelStepperHandler'
import { ModelStatus } from '@/components/models/ModelStatus/ModelStatus'
import { Chatbot } from '@/components/support/Chatbot'
import { ApplicationNestedPath, buildAppLink } from '@/router/paths'
import { Application } from '@/types/app'
import { UserMLModel } from '@/types/model'

type ModelDetailsSidebarProps = {
  application: Application | null
  model: UserMLModel | null
}

export const ModelDetailsSidebar = ({
  application,
  model
}: ModelDetailsSidebarProps) => {
  if (!application || !model) {
    return null
  }

  return (
    <Stack gap="xl">
      <Button
        variant="light"
        size="xs"
        leftSection={<IconArrowLeft size={16} />}
        component={Link}
        to={buildAppLink(application.id, ApplicationNestedPath.models)}
      >
        <FormattedMessage id="models.backToMyModels" />
      </Button>

      <Group wrap="nowrap" align="center" gap="sm" ml="xs">
        <Image src={model.root_model?.brand_logo || VpLogoSrc} w={32} h={32} />

        <Stack gap={4}>
          <Text size="sm" fw={600}>
            {model.name}
          </Text>

          <ModelStatus status={model.status} />
        </Stack>
      </Group>

      <ModelStepperHandler appId={application.id} model={model} />

      <Chatbot />
    </Stack>
  )
}
