import { Button, Grid, Select, TextInput } from '@mantine/core'
import { useForm } from '@mantine/form'
import { IconChevronRight } from '@tabler/icons-react'
import { FormattedMessage, useIntl } from 'react-intl'
import { INTEGRATION_NAMES } from '@/config/integrations'
import { isValidUrl } from '@/utils/validation/url'

type DeviceOption = {
  value: string
  label: string
}

export type FormValues = {
  deviceId: string
  milestoneUrl: string
}

type MilestoneUrlFormProps = {
  deviceId: string
  deviceOptions: DeviceOption[]
  isLoading?: boolean
  onSubmit: (formValues: FormValues) => void
}

export const MilestoneUrlForm = ({
  deviceId,
  deviceOptions,
  isLoading,
  onSubmit
}: MilestoneUrlFormProps) => {
  const intl = useIntl()

  const form = useForm({
    initialValues: {
      deviceId,
      milestoneUrl: ''
    },

    validate: {
      milestoneUrl: (value) => {
        return isValidUrl(value)
          ? null
          : intl.formatMessage({ id: 'validation.url.invalid' })
      }
    }
  })

  return (
    <form noValidate onSubmit={form.onSubmit((values) => onSubmit(values))}>
      <Select
        label={<FormattedMessage id="streams.selectDevice" />}
        data={deviceOptions}
        mb="xl"
        allowDeselect={false}
        {...form.getInputProps('deviceId')}
      />

      <TextInput
        type="url"
        label={
          <FormattedMessage
            id="integrations.enterPlatformUrl"
            values={{
              platform: INTEGRATION_NAMES.MILESTONE
            }}
          />
        }
        inputContainer={(children) => (
          <Grid gutter="xs">
            <Grid.Col span="auto">{children}</Grid.Col>

            <Grid.Col span="content">
              <Button
                miw={160}
                rightSection={<IconChevronRight size={18} />}
                loading={isLoading}
                type="submit"
              >
                <FormattedMessage id="next" />
              </Button>
            </Grid.Col>
          </Grid>
        )}
        autoFocus
        {...form.getInputProps('milestoneUrl')}
      />
    </form>
  )
}
