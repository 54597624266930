import { Button, TextInput } from '@mantine/core'
import { isEmail, useForm } from '@mantine/form'
import { FormattedMessage, useIntl } from 'react-intl'

export type FormValues = {
  email: string
}

type SignUpTypeSelectionProps = {
  onEmailContinue: (formValues: FormValues) => void
}

const messages = {
  email: {
    id: 'email'
  },
  invalidEmail: {
    id: 'validation.invalidEmail'
  }
}

export const SignUpTypeSelection = ({
  onEmailContinue
}: SignUpTypeSelectionProps) => {
  const intl = useIntl()

  const form = useForm({
    initialValues: {
      email: ''
    },

    validate: {
      email: isEmail(intl.formatMessage(messages.invalidEmail))
    }
  })

  const onSubmit = (formValues: FormValues) => {
    // we will do server side validation here before triggering onEmailContinue callback
    onEmailContinue(formValues)
  }

  return (
    <form noValidate onSubmit={form.onSubmit((values) => onSubmit(values))}>
      <TextInput
        type="email"
        label={intl.formatMessage(messages.email)}
        mb="lg"
        autoFocus
        {...form.getInputProps('email')}
      />

      <Button type="submit" fullWidth>
        <FormattedMessage id="continue" />
      </Button>
    </form>
  )
}
