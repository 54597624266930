import {
  Box,
  Button,
  Group,
  Loader,
  Modal,
  Text,
  ThemeIcon
} from '@mantine/core'
import { IconCheck } from '@tabler/icons-react'
import { FormattedMessage } from 'react-intl'
import { FailedFiles } from './FailedFiles/FailedFiles'
import { UploadProgressIndicator } from './UploadProgressIndicator/UploadProgressIndicator'

type UploadProgressModalProps = {
  opened: boolean
  isCompleted: boolean
  totalFiles: number
  uploadedFies: number
  failedFileNames: string[]
  onClose: () => void
}

export const UploadProgressModal = ({
  opened,
  isCompleted,
  totalFiles,
  uploadedFies,
  failedFileNames,
  onClose
}: UploadProgressModalProps) => {
  return (
    <Modal
      size="lg"
      title={<FormattedMessage id="datasets.upload.uploadFiles" />}
      opened={opened}
      onClose={onClose}
    >
      <>
        {isCompleted && (
          <Box pt="md">
            <Group wrap="nowrap" gap="xs" mb="xl">
              <ThemeIcon size="xs" color="green" radius="xl">
                <IconCheck style={{ width: '80%', height: '80%' }} />
              </ThemeIcon>

              <Text size="sm" fw={500}>
                <FormattedMessage id="datasets.upload.completed" />
              </Text>
            </Group>

            <UploadProgressIndicator
              filesUploaded={uploadedFies}
              filesTotal={totalFiles}
            />

            {failedFileNames.length > 0 && (
              <Box mt="xl">
                <FailedFiles fileNames={failedFileNames} />
              </Box>
            )}

            <Button mt="xl" fullWidth onClick={onClose}>
              <FormattedMessage id="done" />
            </Button>
          </Box>
        )}

        {!isCompleted && (
          <Box pt="md">
            <Group wrap="nowrap" gap="xs" mb="xl">
              <Loader size={16} />

              <Text size="sm" fw={500}>
                <FormattedMessage id="datasets.upload.pending" />
              </Text>
            </Group>

            <UploadProgressIndicator
              filesUploaded={uploadedFies}
              filesTotal={totalFiles}
            />

            <Text size="xs" mt="xl">
              <FormattedMessage id="datasets.upload.canCloseWindow" />
            </Text>

            <Button variant="default" mt="lg" fullWidth onClick={onClose}>
              <FormattedMessage id="datasets.upload.closeWindow" />
            </Button>
          </Box>
        )}
      </>
    </Modal>
  )
}
