import {
  IMAGE_ANNOTATION_VALUE,
  VIDEO_ANNOTATION_VALUE
} from '@/config/annotation'

export const getAnnotationPoints = (imageCount: number, videoCount: number) => {
  return (
    imageCount * IMAGE_ANNOTATION_VALUE + videoCount * VIDEO_ANNOTATION_VALUE
  )
}
