import { Modal, Portal, Text } from '@mantine/core'
import { ReactNode } from 'react'

type HelpDialogProps = {
  title: string | ReactNode
  isOpen: boolean
  children: ReactNode
  onClose: () => void
}

export const HelpDialog = ({
  title,
  isOpen,
  children,
  onClose
}: HelpDialogProps) => {
  return (
    <>
      {isOpen && (
        <Portal>
          <Modal.Root
            opened={isOpen}
            size="55%"
            h={200}
            centered
            onClose={onClose}
          >
            <Modal.Overlay />
            <Modal.Content>
              <Modal.Header bg="brand-primary.0">
                <Modal.Title>
                  <Text size="xl" fw={800}>
                    {title}
                  </Text>
                </Modal.Title>
                <Modal.CloseButton />
              </Modal.Header>
              <Modal.Body h={530} p="xl">
                {children}
              </Modal.Body>
            </Modal.Content>
          </Modal.Root>
        </Portal>
      )}
    </>
  )
}
