import { KonvaEventObject } from 'konva/lib/Node'
import { Stage as StageType } from 'konva/lib/Stage'
import { useMemo, useState } from 'react'

const DEFAULT_COLOR = '#F03E3E' // red

export type Line = {
  name: string
  color: string
  points: number[][]
}

type UseDrawLineParams = {
  onLineAdd: (params: Line) => void
}

export const useDrawLine = ({ onLineAdd }: UseDrawLineParams) => {
  const [points, setPoints] = useState<number[][]>([])
  const [position, setPosition] = useState([0, 0])
  const [color, setColor] = useState(DEFAULT_COLOR)
  const [name, setName] = useState('')
  const [isCompleted, setIsCompleted] = useState(true)

  const flattenedPoints = useMemo(() => {
    return points
      .concat(points.length === 2 ? [] : position)
      .reduce((a, b) => a.concat(b), [])
  }, [points, position])

  const reset = () => {
    setPoints([])
    setName('')
    setColor(DEFAULT_COLOR)
  }

  const exit = () => {
    if (isCompleted) {
      return
    }

    reset()
    setIsCompleted(true)
  }

  const start = (name: string, color?: string) => {
    reset()
    setName(name)
    setColor(color || DEFAULT_COLOR)
    setIsCompleted(false)
  }

  const getMousePos = (stage: StageType | null) => {
    const pointerPosition = stage?.getRelativePointerPosition()

    if (pointerPosition) {
      return [pointerPosition.x, pointerPosition.y]
    }

    return [0, 0]
  }

  const handleMouseDown = (e: KonvaEventObject<MouseEvent>) => {
    if (isCompleted) {
      return
    }

    const stage = e.target.getStage()
    const mousePos = getMousePos(stage)

    if (points.length === 0) {
      setPoints([mousePos])
    } else {
      onLineAdd({
        name,
        color,
        points: [...points, mousePos]
      })

      exit()
    }
  }

  const handleMouseMove = (e: KonvaEventObject<MouseEvent>) => {
    const stage = e.target.getStage()
    const mousePos = getMousePos(stage)
    setPosition(mousePos)
  }

  return {
    color,
    name,
    points,
    flattenedPoints,
    isCompleted,
    start,
    exit,
    handleMouseDown,
    handleMouseMove
  }
}
