import { Alert, Anchor, Box, LoadingOverlay, Text, Title } from '@mantine/core'
import { useMutation } from '@tanstack/react-query'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import { accountApi } from '@/api/accountApi'
import { RouterPath } from '@/router/paths'
import { AuthLayout } from '../layout/AuthLayout/AuthLayout'
import {
  FormValues,
  SignUpTypeSelection
} from './SignUpTypeSelection/SignUpTypeSelection'

const LoginLink = () => {
  return (
    <Text size="xs" ta="center">
      <FormattedMessage id="alreadyHaveAccount" />{' '}
      <Anchor component={Link} to={RouterPath.login}>
        <FormattedMessage id="login" />
      </Anchor>
    </Text>
  )
}

const ApiError = () => {
  const title = <FormattedMessage id="genericApiError.pleaseTryAgain" />

  return (
    <Box mb="md">
      <Alert variant="light" color="red" title={title} />
    </Box>
  )
}

type SignUpTypeSelectionHandlerProps = {
  onEmailSent: (email: string) => void
}

export const SignUpTypeSelectionHandler = ({
  onEmailSent
}: SignUpTypeSelectionHandlerProps) => {
  const { mutate, isPending, isError } = useMutation({
    mutationFn: accountApi.sendVerifyEmail,
    onSuccess: (_, variables) => {
      onEmailSent(variables)
    }
  })

  const handleEmailContinue = (formValues: FormValues) => {
    if (!isPending) {
      mutate(formValues.email)
    }
  }

  return (
    <AuthLayout>
      <Box pos="relative">
        <LoadingOverlay visible={isPending} />

        <Title order={2} mb="xl">
          <FormattedMessage id="signup" />
        </Title>

        <Box mb="lg">
          {isError && <ApiError />}
          <SignUpTypeSelection onEmailContinue={handleEmailContinue} />
        </Box>

        <LoginLink />
      </Box>
    </AuthLayout>
  )
}
