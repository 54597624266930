import { SimpleGrid } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { ErrorWithReload } from '@/components/ui-shared/ErrorWithReload/ErrorWithReload'
import { InfiniteScroll } from '@/components/ui-shared/InfiniteScroll/InfiniteScroll'
import { useGetDatasetFiles } from '@/queries/datasetQueries'
import { MediaCard } from '../MediaCard/MediaCard'
import { MediaFileName } from '../MediaGallery/MediaFileName'
import { MediaGallery } from '../MediaGallery/MediaGallery'
import { MediaGalleryOverlay } from '../MediaGallery/MediaGalleryOverlay'

type DatasetVersionDetailsProps = {
  datasetId: string
  versionId: string
  onOverlayChange: (opened: boolean) => void
}

export const DatasetVersionDetails = ({
  datasetId,
  versionId,
  onOverlayChange
}: DatasetVersionDetailsProps) => {
  const [galleryItemIndex, setGalleryItemIndex] = useState(0)

  const [
    isMediaGalleryOpened,
    { open: openMediaGallery, close: closeMediaGallery }
  ] = useDisclosure(false)

  const { data, isFetching, isError, refetch, fetchNextPage, hasNextPage } =
    useGetDatasetFiles(datasetId, versionId)

  const files = data?.pages?.flatMap((page) => page.results) || []

  const galleryItems = files.map((file) => ({
    original: file.media_file,
    thumbnail: file.thumbnail,
    file
  }))

  const activeGalleryItem = galleryItems[galleryItemIndex]

  const handleMediaClick = (index: number) => {
    setGalleryItemIndex(index)
    openMediaGallery()
    onOverlayChange(true)
  }

  const handleGalleryClose = () => {
    closeMediaGallery()
    onOverlayChange(false)
  }

  if (isError) {
    return (
      <ErrorWithReload onReload={() => void refetch()}>
        <FormattedMessage id="datasets.data.error" />
      </ErrorWithReload>
    )
  }

  return (
    <>
      <InfiniteScroll
        hasMore={hasNextPage}
        isFetching={isFetching}
        onLoadMore={() => void fetchNextPage()}
      >
        <SimpleGrid cols={{ base: 1, sm: 2, lg: 4, xl: 6 }} spacing="xl">
          {files.map((file, index) => (
            <MediaCard
              key={file.id}
              fileName={file.name}
              thumbnailUrl={file.thumbnail}
              onClick={() => handleMediaClick(index)}
            />
          ))}
        </SimpleGrid>
      </InfiniteScroll>

      <MediaGalleryOverlay
        opened={isMediaGalleryOpened}
        toolbar={
          activeGalleryItem && (
            <MediaFileName
              fileName={activeGalleryItem.file.name}
              fileType={activeGalleryItem.file.media_type}
            />
          )
        }
        onClose={handleGalleryClose}
      >
        <MediaGallery
          items={galleryItems}
          startIndex={galleryItemIndex}
          showThumbnails
          onSlideChange={setGalleryItemIndex}
        />
      </MediaGalleryOverlay>
    </>
  )
}
