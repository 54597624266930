import { Box, Button, Group, Image, Text } from '@mantine/core'
import { FormattedMessage } from 'react-intl'
import NvidiaLogo from '@/assets/nvidia-logo.png'

type JetsonItemProps = {
  name: string
  description: string
  price: string
  onRemove: () => void
}

export const JetsonItem = ({
  name,
  description,
  price,
  onRemove
}: JetsonItemProps) => {
  return (
    <Group wrap="nowrap" justify="space-between" align="start">
      <Group wrap="nowrap" align="start" gap="xs">
        <Image src={NvidiaLogo} w={40} />

        <Box>
          <Text size="md" fw={500}>
            {name}
          </Text>

          <Text size="xs">{description}</Text>

          <Button variant="transparent" size="xs" px={0} onClick={onRemove}>
            <FormattedMessage id="remove" />
          </Button>
        </Box>
      </Group>

      <Text fw={600}>{price}</Text>
    </Group>
  )
}
