import { vpApi } from './vpApi'

type ContactFormPayload = {
  name: string
  email: string
  subject: string
  message: string
}

const submitContactForm = (payload: ContactFormPayload) => {
  return vpApi.post('/v1/contact/', { payload })
}

export type GetCeleryTaskStatusResponse = {
  task_id: string
  task_status: 'PENDING' | 'SUCCESS' | 'FAILURE'
  task_result: unknown
}

// Celery task status will be moved away from cvat namespace
const getCeleryTaskStatus = async (celeryTaskId: string) => {
  const res = await vpApi.get<GetCeleryTaskStatusResponse>(
    `/v1/cvat/task/${celeryTaskId}/status/`
  )
  return res.data
}

export const generalApi = {
  submitContactForm,
  getCeleryTaskStatus
}
