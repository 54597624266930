import { DatasetLegacy, FileMetadata, UploadStatus } from '@/types/dataset'
import { PaginatedResponse } from '../types/pagination'
import { vpApi } from './vpApi'

type GetDatasetsParams = {
  pageUrl: string
}

type DatasetsResponse = PaginatedResponse<DatasetLegacy>

const getDatasets = async ({ pageUrl }: GetDatasetsParams) => {
  const res = await vpApi.get<DatasetsResponse>(pageUrl)
  return res.data
}

type GetFilesParams = {
  pageUrl: string
}

type FilesResponse = PaginatedResponse<FileMetadata>

const getFiles = async ({ pageUrl }: GetFilesParams) => {
  const res = await vpApi.get<FilesResponse>(pageUrl)
  return res.data
}

type DatasetData = {
  application_id: string
  name: string
  media_files: File[]
}

type DatasetResponse = DatasetLegacy & {
  batch_id: string
}

const createDataset = (data: DatasetData) => {
  const formData = new FormData()
  formData.append('name', data.name)
  formData.append('application_id', data.application_id)
  data.media_files.forEach((file) => formData.append('media_files', file))

  return vpApi.post<DatasetResponse>('/v1/datasets/', formData)
}

type UpdateDatasetParams = {
  datasetId: string
  data: Partial<DatasetData>
}

const updateDataset = ({ datasetId, data }: UpdateDatasetParams) => {
  const formData = new FormData()

  if (data.name) {
    formData.append('name', data.name)
  }

  if (data.media_files) {
    data.media_files.forEach((file) => formData.append('media_files', file))
  }

  return vpApi.patch<DatasetResponse>(`/v1/datasets/${datasetId}/`, formData)
}

const getUploadStatus = async (batchId: string) => {
  const res = await vpApi.get<UploadStatus>(
    `/v1/datasets/${batchId}/upload-status/`
  )
  return res.data
}

type DeleteFilesParams = {
  datasetId: string
  fileIds: string[]
}

const deleteFiles = ({ datasetId, fileIds }: DeleteFilesParams) => {
  return vpApi.delete(`/v1/datasets/${datasetId}/delete-media/`, {
    data: {
      media_ids: fileIds
    }
  })
}

export const datasetLegacyApi = {
  getDatasets,
  getFiles,
  createDataset,
  updateDataset,
  getUploadStatus,
  deleteFiles
}
