import { Deployment } from '@/types/deployment'
import { SavedEventEndpoint } from '../IntegrationSelection/IntegrationSelection'

export const buildSavedEventEndpoints = (
  deployments: Deployment[]
): SavedEventEndpoint[] => {
  if (deployments.length === 0) {
    return []
  }

  return deployments[0].settings_payload.event_endpoints.map(
    (eventEndpoint) => ({
      service: eventEndpoint.service,
      endpoint: eventEndpoint.endpoint
    })
  )
}
