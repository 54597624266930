import { Box, Button, Group, LoadingOverlay, Stack, Text } from '@mantine/core'
import { useDisclosure, useInterval } from '@mantine/hooks'
import { useEffect } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { DeploymentDetails } from '@/components/deployments/deployment-list/DeploymentDetails/DeploymentDetails'
import { ErrorWithReload } from '@/components/ui-shared/ErrorWithReload/ErrorWithReload'
import { useApplicationContext } from '@/providers/ApplicationContext'
import { useGetDeployments } from '@/queries/deploymentQueries'
import { ApplicationNestedPath, buildAppLink } from '@/router/paths'
import { DeploymentStatus } from '@/types/deployment'
import { HelpDialog } from '../../help-dialog/HelpDialog'
import { DeploymentsContent } from '../../help-dialog/contents/DeploymentsContent'
import { PageTitle } from '../../ui-shared/PageTitle/PageTitle'
import { DeploymentProcessBar } from './DeploymentProcessBar/DeploymentProcessBar'
import { DeploymentToolbar } from './DeploymentToolbar/DeploymentToolbar'
import { RemoveDeploymentConfirmation } from './RemoveDeploymentConfirmation/RemoveDeploymentConfirmation'

const POLL_INTERVAL = 10000

export const DeploymentsPage = () => {
  const intl = useIntl()
  const navigate = useNavigate()
  const { application } = useApplicationContext()

  const { data, isError, isLoading, isFetching, refetch } = useGetDeployments(
    application?.id || ''
  )

  const [
    isRemoveDeploymentModalOpen,
    { open: openRemoveDeploymentModal, close: closeRemoveDeploymentModal }
  ] = useDisclosure(false)

  const [isHelpDialogOpen, { open: openHelpDialog, close: closeHelpDialog }] =
    useDisclosure(false)

  const { start, stop, active } = useInterval(
    () => void refetch(),
    POLL_INTERVAL
  )

  useEffect(() => {
    if (data && !active) {
      start()
    }
  }, [data, active, start])

  // Cleanup function to stop the interval when the component is unmounted
  useEffect(() => {
    return stop
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const deployments = data?.pages.flatMap((page) => page.results) ?? []

  const deploymentInProgress = deployments.some(
    (deployment) => deployment.status === DeploymentStatus.InProgress
  )

  const deploymentRemovalInProgress = deployments.some(
    (deployment) => deployment.status === DeploymentStatus.Removing
  )

  const deploymentOperationInProgress =
    deploymentInProgress || deploymentRemovalInProgress

  const handleOnReload = () => {
    void refetch()
  }

  const goToCreateDeploymentsPage = () => {
    navigate(
      buildAppLink(
        application?.id || '',
        ApplicationNestedPath.createDeployment
      )
    )
  }

  const goToEventsPage = () => {
    navigate(buildAppLink(application?.id || '', ApplicationNestedPath.events))
  }

  const handleDeploymentRemoved = () => {
    closeRemoveDeploymentModal()
    void refetch()
  }

  if (isError) {
    return (
      <ErrorWithReload onReload={handleOnReload}>
        <FormattedMessage id="deployments.details.fetchError" />
      </ErrorWithReload>
    )
  }

  if (isLoading || (isFetching && !deployments.length)) {
    return (
      <Box pos="relative" mih={120}>
        <LoadingOverlay visible />
      </Box>
    )
  }

  if (!deployments.length) {
    return (
      <Stack align="center" justify="center" mih={200} gap="lg">
        <Text>
          <FormattedMessage id="deployments.noDeployments" />
        </Text>

        <Button onClick={goToCreateDeploymentsPage}>
          <FormattedMessage id="deployments.deployModel" />
        </Button>
      </Stack>
    )
  }

  return (
    <>
      <Group mb="lg" justify="space-between" align="center">
        <PageTitle
          title={<FormattedMessage id="deployments.title" />}
          showHelper
          onHelpClick={openHelpDialog}
        />
      </Group>

      <DeploymentToolbar
        applicationName={application?.name || ''}
        isFetching={isFetching}
        isEditDisabled={deploymentOperationInProgress}
        isRemoveDisabled={deploymentOperationInProgress}
        onRefreshClick={() => void refetch()}
        onViewEventsClick={goToEventsPage}
        onEditClick={goToCreateDeploymentsPage}
        onRemoveClick={openRemoveDeploymentModal}
      />

      <Box
        style={{
          opacity: isFetching ? 0.7 : 1
        }}
      >
        <DeploymentDetails
          appId={application?.id || ''}
          deployments={deployments}
          onRefresh={handleOnReload}
        />
      </Box>

      <DeploymentProcessBar
        isShown={deploymentInProgress || deploymentRemovalInProgress}
        processType={deploymentRemovalInProgress ? 'removal' : 'deployment'}
      />

      <RemoveDeploymentConfirmation
        opened={isRemoveDeploymentModalOpen}
        applicationId={application?.id || ''}
        applicationName={application?.name || ''}
        onClose={closeRemoveDeploymentModal}
        onRemoved={handleDeploymentRemoved}
      />

      <HelpDialog
        isOpen={isHelpDialogOpen}
        title={intl.formatMessage({ id: 'deployments.title' })}
        onClose={closeHelpDialog}
      >
        <DeploymentsContent />
      </HelpDialog>
    </>
  )
}
