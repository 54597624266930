import { Product, UserSubscription } from '@/types/subscription'
import { User } from '../types/user'
import { CSRF_HEADER_NAME, vpApi } from './vpApi'

const getCsrfToken = async () => {
  const response = await vpApi.get('/v1/accounts/csrf/')
  return response.headers[CSRF_HEADER_NAME] as string
}

const sendVerifyEmail = (email: string) => {
  return vpApi.post('/v1/accounts/email-verification/', { email })
}

type ValidateTokenPayload = {
  uid: string
  token: string
}

type ValidateTokenResponse = {
  email: string
  verified: boolean
}

// validate password reset token or email verification token
const validateToken = (payload: ValidateTokenPayload) => {
  return vpApi.post<ValidateTokenResponse>(
    '/v1/accounts/token-validation/',
    payload
  )
}

type RegistrationPayload = {
  email: string
  first_name: string
  last_name: string
  password: string
  token: string
}

const completeRegistration = (payload: RegistrationPayload) => {
  return vpApi.post('/v1/accounts/registration/', payload)
}

export type LoginPayload = {
  email: string
  password: string
}

const login = (payload: LoginPayload) => {
  return vpApi.post('/v1/accounts/login/', payload)
}

type WhoAmIResponse = {
  user: User
}

const whoAmI = async () => {
  const res = await vpApi.get<WhoAmIResponse>('/v1/accounts/whoami/')
  return res.data
}

const logout = () => {
  return vpApi.delete('/v1/accounts/logout/')
}

const sendPasswordResetEmail = (email: string) => {
  return vpApi.post('/v1/accounts/reset-password/', { email })
}

type ResetPasswordConfirmParams = {
  uid: string
  password: string
  token: string
}

const resetPasswordConfirm = ({
  uid,
  password,
  token
}: ResetPasswordConfirmParams) => {
  return vpApi.post(`/v1/accounts/${uid}/reset-password/confirm/`, {
    password,
    token
  })
}

const getMySubscriptions = async () => {
  const res = await vpApi.get<UserSubscription[]>(
    '/v1/accounts/stripe/my-subscription/'
  )
  return res.data
}

const getProducts = async () => {
  const res = await vpApi.get<Product[]>(
    '/v1/accounts/stripe/subscribable-product/'
  )
  return res.data
}

type CreateCheckoutSessionResponse = {
  url: string
}

const createCheckoutSession = async (priceIds: string[]) => {
  const res = await vpApi.post<CreateCheckoutSessionResponse>(
    '/v1/accounts/stripe/checkout/',
    {
      price_ids: priceIds
    }
  )
  return res.data
}

type CreateCustomerPortalSessionResponse = {
  url: string
}

const createCustomerPortalSession = async () => {
  const res = await vpApi.post<CreateCustomerPortalSessionResponse>(
    '/v1/accounts/stripe/customer-portal/'
  )
  return res.data
}

export const accountApi = {
  getCsrfToken,
  sendVerifyEmail,
  validateToken,
  completeRegistration,
  login,
  whoAmI,
  logout,
  sendPasswordResetEmail,
  resetPasswordConfirm,
  getMySubscriptions,
  getProducts,
  createCheckoutSession,
  createCustomerPortalSession
}
