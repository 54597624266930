import { Box, LoadingOverlay } from '@mantine/core'
import { useMutation } from '@tanstack/react-query'
import { useIntl } from 'react-intl'
import { accountApi } from '@/api/accountApi'
import { showToast } from '@/theme/notifications'
import { AuthLayout } from '../layout/AuthLayout/AuthLayout'
import { VerifyEmail } from './VerifyEmail/VerifyEmail'

const messages = {
  emailSentTo: {
    id: 'emailSentTo'
  },
  emailFailedToSend: {
    id: 'emailFailedToSend'
  }
}

type VerifyEmailHandlerProps = {
  email: string
}

export const VerifyEmailHandler = ({ email }: VerifyEmailHandlerProps) => {
  const intl = useIntl()

  const { mutate, isPending } = useMutation({
    mutationFn: accountApi.sendVerifyEmail,
    onSuccess: () => {
      showToast(intl.formatMessage(messages.emailSentTo, { email }), 'success')
    },
    onError: () => {
      showToast(
        intl.formatMessage(messages.emailFailedToSend, { email }),
        'error'
      )
    }
  })

  const handleResendEmail = () => {
    mutate(email)
  }

  return (
    <AuthLayout variant="large">
      <Box pos="relative">
        <LoadingOverlay visible={isPending} />

        <VerifyEmail onResendEmail={handleResendEmail} />
      </Box>
    </AuthLayout>
  )
}
