import { hasNumber, hasSpecialChar, hasUpperCase, isMinLength } from './helpers'

export const PASSWORD_MIN_LENGTH = 8

/*
Custom password validator to be used with Mantine form validation.

Checks the password according to the following rules:
- min. 8 characters
- at least one number
- at least one capital letter
- at least one special character

Returns null if password is valid, otherwise returns an error message.
*/
export const isStrongPassword = (message: string) => {
  return function (value: string) {
    if (
      isMinLength(value, PASSWORD_MIN_LENGTH) &&
      hasNumber(value) &&
      hasUpperCase(value) &&
      hasSpecialChar(value)
    ) {
      return null
    }

    return message
  }
}
