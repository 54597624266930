import {
  Box,
  Button,
  Group,
  Loader,
  LoadingOverlay,
  Stepper,
  Text,
  rem
} from '@mantine/core'
import { useDisclosure, useSetState } from '@mantine/hooks'
import {
  IconApi,
  IconBolt,
  IconCheck,
  IconCpu,
  IconRocket,
  IconSettings,
  IconVideo
} from '@tabler/icons-react'
import { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { getApiError } from '@/api/helpers/apiError'
import { useApplicationContext } from '@/providers/ApplicationContext'
import {
  useCreateDeployment,
  useUpdateDeployment
} from '@/queries/deploymentQueries'
import { ApplicationNestedPath, buildAppLink } from '@/router/paths'
import { showToast } from '@/theme/notifications'
import { EventDestination } from '@/types/deployment'
import { CameraStreamWithDeviceId } from '@/types/device'
import { UserMLModel } from '@/types/model'
import { HelpDialog } from '../../help-dialog/HelpDialog'
import { NewDeploymentContent } from '../../help-dialog/contents/NewDeploymentContent'
import { PageTitle } from '../../ui-shared/PageTitle/PageTitle'
import { CameraStreamSelection } from './CameraStreamSelection/CameraStreamSelection'
import { DeployPreview } from './DeployPreview/DeployPreview'
import { DeviceSelection } from './DeviceSelection/DeviceSelection'
import {
  IntegrationSelection,
  SavedEventEndpoint
} from './IntegrationSelection/IntegrationSelection'
import {
  FRAME_HEIGHT,
  FRAME_WIDTH
} from './LogicSelection/LogicHandler/constants'
import { LogicSelection } from './LogicSelection/LogicSelection'
import { SavedStreamLogic } from './LogicSelection/types'
import { ModelSelection } from './ModelSelection/ModelSelection'
import { buildDeploymentData } from './helpers/buildDeploymentData'
import { MODEL_ID_QUERY_PARAM } from './helpers/navigation'
import { DeploymentSteps } from './types'

type DeploymentHandlerState = {
  selectedModel: UserMLModel | null
  selectedDeviceIds: string[]
  selectedCameraStreams: CameraStreamWithDeviceId[]
  savedStreamLogics: SavedStreamLogic[]
  savedEventEndpoints: SavedEventEndpoint[]
  liveInferenceCameraStreamIds: string[]
}

type DeploymentHandlerProps = {
  mode: 'new' | 'edit'
  initialStep?: number
  initialModel?: UserMLModel
  initialDeviceIds?: string[]
  initialCameraStreams?: CameraStreamWithDeviceId[]
  initialStreamLogics?: SavedStreamLogic[]
  initialEventEndpoints?: SavedEventEndpoint[]
  initialInferenceStreamIds?: string[]
}

export const DeploymentHandler = ({
  mode,
  initialStep,
  initialModel,
  initialDeviceIds,
  initialCameraStreams,
  initialStreamLogics,
  initialEventEndpoints,
  initialInferenceStreamIds
}: DeploymentHandlerProps) => {
  const intl = useIntl()
  const navigate = useNavigate()

  const { application } = useApplicationContext()

  const [step, setStep] = useState(
    initialStep ?? DeploymentSteps.ModelSelection
  )

  const [state, setState] = useSetState<DeploymentHandlerState>({
    selectedModel: initialModel || null,
    selectedDeviceIds: initialDeviceIds || [],
    selectedCameraStreams: initialCameraStreams || [],
    savedStreamLogics: initialStreamLogics || [],
    savedEventEndpoints: initialEventEndpoints || [
      {
        service: EventDestination.Visionplatform,
        endpoint: ''
      }
    ],
    liveInferenceCameraStreamIds: initialInferenceStreamIds || []
  })

  const { mutateAsync: createDeployment, isPending: isCreatePending } =
    useCreateDeployment()
  const { mutateAsync: updateDeployment, isPending: isUpdatePending } =
    useUpdateDeployment()

  const isPending = isCreatePending || isUpdatePending

  const [searchParams] = useSearchParams()
  const modelIdFromParams = searchParams.get(MODEL_ID_QUERY_PARAM)

  const [isHelpDialogOpen, { open: openHelpDialog, close: closeHelpDialog }] =
    useDisclosure(false)

  const handleModelSelection = (model: UserMLModel | null) => {
    setState({ selectedModel: model })
  }

  const handleDeviceSelectionChange = (deviceIds: string[]) => {
    setState({ selectedDeviceIds: deviceIds })
  }

  const handleCameraStreamSelectionChange = (
    cameraStreams: CameraStreamWithDeviceId[]
  ) => {
    const filteredSavedStreamLogics = state.savedStreamLogics.filter(
      (savedLogic) =>
        cameraStreams.some(
          (stream) => stream.camera_id === savedLogic.camera_id
        )
    )

    setState({
      selectedCameraStreams: cameraStreams,
      savedStreamLogics: filteredSavedStreamLogics
    })
  }

  const handleStreamLogicsSave = (logics: SavedStreamLogic[]) => {
    setState({ savedStreamLogics: logics })
  }

  const handleEventEndpointsSave = (endpoints: SavedEventEndpoint[]) => {
    setState({ savedEventEndpoints: endpoints })
    setStep(DeploymentSteps.DeployPreview)
  }

  const handleLiveInferenceChange = (cameraStreamIds: string[]) => {
    setState({
      liveInferenceCameraStreamIds: cameraStreamIds
    })
  }

  const handleCancelEditClick = () => {
    navigate(
      buildAppLink(application?.id || '', ApplicationNestedPath.deployments)
    )
  }

  const handleDeployModel = async () => {
    try {
      const deploymentData = buildDeploymentData({
        appId: application?.id || '',
        modelId: state.selectedModel?.id || '',
        width: FRAME_WIDTH,
        height: FRAME_HEIGHT,
        cameraStreams: state.selectedCameraStreams,
        savedStreamLogics: state.savedStreamLogics.filter(
          (logic) => logic.lines.length > 0
        ),
        savedEventEndpoints: state.savedEventEndpoints,
        liveInferenceCameraStreamIds: state.liveInferenceCameraStreamIds
      })

      await (mode === 'new'
        ? createDeployment(deploymentData)
        : updateDeployment(deploymentData))

      navigate(
        buildAppLink(application?.id || '', ApplicationNestedPath.deployments)
      )
    } catch (err) {
      const { errorMessage } = getApiError(err)
      const message =
        errorMessage || intl.formatMessage({ id: 'deployments.error' })
      showToast(message, 'error')
    }
  }

  return (
    <Box pos="relative">
      <Group mb="xl" justify="space-between">
        <PageTitle
          title={
            mode === 'edit' ? (
              <FormattedMessage id="deployments.edit" />
            ) : (
              <FormattedMessage id="deployments.new" />
            )
          }
          showHelper={mode === 'new'}
          onHelpClick={openHelpDialog}
        />

        {mode === 'edit' && (
          <Button
            miw={120}
            size="xs"
            variant="outline"
            color="red"
            onClick={handleCancelEditClick}
          >
            <FormattedMessage id="cancelEdit" />
          </Button>
        )}
      </Group>

      <LoadingOverlay
        visible={isPending}
        loaderProps={{
          children: (
            <Group>
              <Loader size={32} />

              <Text fw="bold">
                <FormattedMessage id="deployments.inProgress" />
              </Text>
            </Group>
          )
        }}
      />

      <Stepper
        active={step}
        size="sm"
        iconSize={48}
        completedIcon={
          <IconCheck style={{ width: rem(24), height: rem(24) }} stroke={3} />
        }
        color="green"
        styles={{
          steps: {
            marginBottom: rem(40)
          },
          stepLabel: {
            fontSize: rem(15),
            fontWeight: 400
          }
        }}
      >
        <Stepper.Step
          label={<FormattedMessage id="deployments.step1" />}
          icon={<IconBolt style={{ width: rem(24), height: rem(24) }} />}
        >
          <ModelSelection
            applicationId={application?.id || ''}
            defaultModelId={modelIdFromParams || undefined}
            selectedModel={state.selectedModel}
            singleModelSelection={Boolean(modelIdFromParams)}
            onSelectionChange={handleModelSelection}
            onContinue={() => setStep(DeploymentSteps.DeviceSelection)}
          />
        </Stepper.Step>

        <Stepper.Step
          label={<FormattedMessage id="deployments.step2" />}
          icon={<IconCpu style={{ width: rem(24), height: rem(24) }} />}
        >
          <DeviceSelection
            selectedDeviceIds={state.selectedDeviceIds}
            onSelectionChange={handleDeviceSelectionChange}
            onContinue={() => setStep(DeploymentSteps.CameraStreamSelection)}
            onGoBack={() => setStep(DeploymentSteps.ModelSelection)}
          />
        </Stepper.Step>

        <Stepper.Step
          label={<FormattedMessage id="deployments.step3" />}
          icon={<IconVideo style={{ width: rem(24), height: rem(24) }} />}
        >
          <CameraStreamSelection
            deviceIds={state.selectedDeviceIds}
            selectedCameraStreams={state.selectedCameraStreams}
            onSelectionChange={handleCameraStreamSelectionChange}
            onContinue={() => setStep(DeploymentSteps.LogicSelection)}
            onGoBack={() => setStep(DeploymentSteps.DeviceSelection)}
          />
        </Stepper.Step>

        <Stepper.Step
          label={<FormattedMessage id="deployments.step4" />}
          icon={<IconSettings style={{ width: rem(24), height: rem(24) }} />}
        >
          <LogicSelection
            model={state.selectedModel}
            cameraStreams={state.selectedCameraStreams}
            savedStreamLogics={state.savedStreamLogics}
            liveInferenceCameraStreamIds={state.liveInferenceCameraStreamIds}
            onLiveInferenceChange={handleLiveInferenceChange}
            onContinue={() => setStep(DeploymentSteps.IntegrationSelection)}
            onGoBack={() => setStep(DeploymentSteps.CameraStreamSelection)}
            onStreamLogicsSave={handleStreamLogicsSave}
          />
        </Stepper.Step>

        <Stepper.Step
          label={<FormattedMessage id="deployments.step5" />}
          icon={<IconApi style={{ width: rem(24), height: rem(24) }} />}
        >
          <IntegrationSelection
            selectedDeviceIds={state.selectedDeviceIds}
            savedEventEndpoints={state.savedEventEndpoints}
            onGoBack={() => setStep(DeploymentSteps.LogicSelection)}
            onContinue={handleEventEndpointsSave}
          />
        </Stepper.Step>

        <Stepper.Step
          label={<FormattedMessage id="deployments.step6" />}
          icon={<IconRocket style={{ width: rem(24), height: rem(24) }} />}
        >
          <DeployPreview
            model={state.selectedModel}
            cameraStreams={state.selectedCameraStreams}
            savedStreamLogics={state.savedStreamLogics}
            savedEventEndpoints={state.savedEventEndpoints}
            onGoBack={() => setStep(DeploymentSteps.IntegrationSelection)}
            onDeployModel={() => void handleDeployModel()}
          />
        </Stepper.Step>
      </Stepper>

      <HelpDialog
        isOpen={isHelpDialogOpen}
        title={intl.formatMessage({ id: 'deployments.new' })}
        onClose={closeHelpDialog}
      >
        <NewDeploymentContent />
      </HelpDialog>
    </Box>
  )
}
