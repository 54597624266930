export const resizeRectangle = (
  points: number[][],
  movedPointIndex: number,
  newX: number,
  newY: number
) => {
  // Copy existing points to avoid mutation
  const newPoints: number[][] = points.map((point) => [...point])

  // Get the index of the opposite point
  const oppositePointIndex = (movedPointIndex + 2) % 4

  // Calculate new positions for the adjacent points
  const adjacent1Index = (movedPointIndex + 1) % 4
  const adjacent2Index = (movedPointIndex + 3) % 4

  // Update the moved point
  newPoints[movedPointIndex] = [newX, newY]

  // Update adjacent points to reflect the movement in the appropriate axis
  if (movedPointIndex % 2 == 0) {
    newPoints[adjacent1Index][1] = newY
    newPoints[adjacent2Index][0] = newX
  } else {
    newPoints[adjacent1Index][0] = newX
    newPoints[adjacent2Index][1] = newY
  }

  newPoints[oppositePointIndex] = [...points[oppositePointIndex]]

  return newPoints
}

export const getCenteredRectangleCoordinates = (
  containerWidth: number,
  containerHeight: number,
  rectSize: number
) => {
  const rectWidth = containerWidth * rectSize
  const rectHeight = containerHeight * rectSize

  // Calculate the top-left corner to center the rectangle
  const x = (containerWidth - rectWidth) / 2
  const y = (containerHeight - rectHeight) / 2

  // Return the four corners of the rectangle
  return [
    [x, y], // Top-left
    [x + rectWidth, y], // Top-right
    [x + rectWidth, y + rectHeight], // Bottom-right
    [x, y + rectHeight] // Bottom-left
  ]
}
