import { KonvaEventObject } from 'konva/lib/Node'
import { Circle, Group, Line } from 'react-konva'
import { AnnotationLabel } from '../AnnotationLabel'
import { useDraggablePoint } from '../useDraggablePoint'
import { resizeRectangle } from './helpers'

const CIRCLE_SIZE = 3

type RectangleProps = {
  name: string
  color: string
  points: number[][]
  draggable?: boolean
  isActivated?: boolean
  dashed?: boolean
  showLabel?: boolean
  onCoordinateChange: (points: number[][]) => void
}

export const Rectangle = ({
  name,
  color,
  points,
  draggable = false,
  isActivated = false,
  dashed = false,
  showLabel = true,
  onCoordinateChange
}: RectangleProps) => {
  const { handlePointMouseOver, handlePointMouseOut } = useDraggablePoint()

  const handlePointDragMove = (
    e: KonvaEventObject<DragEvent>,
    pointIndex: number
  ) => {
    onCoordinateChange(
      resizeRectangle(points, pointIndex, e.target.x(), e.target.y())
    )
  }

  const handleGroupDragEnd = (e: KonvaEventObject<DragEvent>) => {
    if (e.target.name() === name) {
      const result = [] as number[][]

      points.forEach((point) =>
        result.push([point[0] + e.target.x(), point[1] + e.target.y()])
      )

      e.target.position({ x: 0, y: 0 })
      onCoordinateChange(result)
    }
  }

  return (
    <Group name={name} draggable={draggable} onDragEnd={handleGroupDragEnd}>
      <Line
        points={points.flat()}
        stroke={color}
        strokeWidth={isActivated ? 1.5 : 1}
        fill={isActivated ? 'rgba(140,30,255,0.15)' : 'rgba(140,30,255,0.1)'}
        dash={dashed ? [4, 3] : []}
        closed
      />

      {points.map((point, index) => {
        const x = point[0] - 1
        const y = point[1] - 1

        return (
          <Circle
            key={index}
            x={x}
            y={y}
            radius={CIRCLE_SIZE}
            fill={isActivated ? 'white' : 'transparent'}
            stroke={isActivated ? color : 'transparent'}
            strokeWidth={2}
            draggable={draggable}
            onDragMove={(e) => handlePointDragMove(e, index)}
            onMouseOver={isActivated ? handlePointMouseOver : undefined}
            onMouseOut={isActivated ? handlePointMouseOut : undefined}
          />
        )
      })}

      {showLabel && (
        <AnnotationLabel
          x={points[0][0]}
          y={points[0][1] - 24}
          color="rgba(0,0,0,0.8)"
          textColor="white"
          text={name}
        />
      )}
    </Group>
  )
}
