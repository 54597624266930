import { useDisclosure } from '@mantine/hooks'
import { useIntl } from 'react-intl'
import { useSearchParams } from 'react-router-dom'
import { useGetDatasetDetails } from '@/queries/datasetQueries'
import { PageWithFixedToolbar } from '../layout/PageWithFixedToolbar/PageWithFixedToolbar'
import { DATASET_ID_QUERY_PARAM } from '../models/ModelDetails/useModelStepNavigation'
import { DatasetToolbar } from './DatasetToolbar/DatasetToolbar'
import { DatasetToolbarSkeleton } from './DatasetToolbar/DatasetToolbarSkeleton'
import { DatasetVersionSelect } from './DatasetVersionSelect/DatasetVersionSelect'
import { useDatasetVersionParam } from './DatasetVersionSelect/useDatasetVersionParam'
import { NewVersionConfirmModal } from './NewVersionConfirmModal/NewVersionConfirmModal'
import { UpdateDatasetModal } from './UpdateDatasetModal/UpdateDatasetModal'
import { UploadableMediaListHandler } from './UploadableMediaList/UploadableMediaListHandler'

export const DatasetDetailsPage = () => {
  const intl = useIntl()
  const [searchParams] = useSearchParams()

  const [
    isNewVersionModalOpened,
    { open: openNewVersionModal, close: closeNewVersionModal }
  ] = useDisclosure(false)

  const [
    isUpdateDatasetModalOpened,
    { open: openUpdateDatasetModal, close: closeUpdateDatasetModal }
  ] = useDisclosure(false)

  const datasetId = searchParams.get(DATASET_ID_QUERY_PARAM) || ''
  const { datasetVersionId, setDatasetVersion } = useDatasetVersionParam()

  const { data: dataset, isLoading } = useGetDatasetDetails(
    datasetId,
    datasetId !== ''
  )

  const versionSelectOptions =
    dataset?.versions.map((version) => ({
      value: version.id,
      label: intl
        .formatMessage(
          { id: 'datasets.versionNumber' },
          { number: version.version }
        )
        .toLowerCase(),
      version
    })) || []

  const handleVersionChange = (value: string) => {
    setDatasetVersion(value || null)
  }

  return (
    <PageWithFixedToolbar
      toolbar={
        <>
          {isLoading && <DatasetToolbarSkeleton />}

          {dataset && (
            <DatasetToolbar
              datasetName={dataset.name}
              datasetType={dataset.type}
              annotatedImagesCount={2}
              annotatedVideosCount={2}
              isAnnotationProgressBarShown={true}
              isTrainingButtonDisabled={true}
              versionSelector={
                <DatasetVersionSelect
                  value={datasetVersionId || ''}
                  versions={versionSelectOptions}
                  onChange={handleVersionChange}
                />
              }
              onSettingsClick={openUpdateDatasetModal}
              onTrainModel={openNewVersionModal}
            />
          )}
        </>
      }
    >
      {datasetId && datasetVersionId && (
        <UploadableMediaListHandler
          datasetId={datasetId}
          versionId={datasetVersionId}
        />
      )}

      <NewVersionConfirmModal
        opened={isNewVersionModalOpened}
        onClose={closeNewVersionModal}
      />

      {dataset && (
        <UpdateDatasetModal
          dataset={dataset}
          opened={isUpdateDatasetModalOpened}
          onClose={closeUpdateDatasetModal}
        />
      )}
    </PageWithFixedToolbar>
  )
}
