import { Card, ThemeIcon, UnstyledButton } from '@mantine/core'
import { useHover } from '@mantine/hooks'
import { IconCheck } from '@tabler/icons-react'
import { ReactNode } from 'react'

const CARD_HEIGHT = 160

type SelectableCardProps = {
  children: ReactNode
  isSelected?: boolean
  disabled?: boolean
  height?: number
  renderAs?: 'div' | 'button'
  onClick?: () => void
}

export const SelectableCard = ({
  children,
  isSelected = false,
  disabled = false,
  height,
  renderAs = 'button',
  onClick
}: SelectableCardProps) => {
  const { hovered, ref } = useHover()
  const cardShadow = hovered ? 'md' : 'xs'

  const border = isSelected
    ? '1px solid var(--mantine-color-green-6)'
    : '1px solid var(--mantine-color-gray-5)'

  const outline = isSelected ? '1px solid var(--mantine-color-green-6)' : 'none'

  const disabledStyles = {
    opacity: 0.5,
    cursor: 'not-allowed'
  }

  const cardStyles = {
    border,
    outline,
    cursor: onClick === undefined ? 'default' : 'pointer',
    ...(disabled ? disabledStyles : {})
  }

  const handleClick = () => {
    if (!disabled) {
      onClick?.()
    }
  }

  return (
    <UnstyledButton component={renderAs} w="100%" onClick={handleClick}>
      <Card
        ref={ref}
        shadow={cardShadow}
        h={height ?? CARD_HEIGHT}
        padding="md"
        radius="md"
        pos="relative"
        style={cardStyles}
        withBorder
      >
        {children}

        {isSelected && (
          <ThemeIcon
            color="green"
            size="xs"
            radius="xl"
            pos="absolute"
            top={4}
            left={4}
            styles={{
              root: {
                boxShadow: '0 0 1px 1px var(--mantine-color-white)'
              }
            }}
          >
            <IconCheck style={{ width: '80%', height: '80%' }} stroke={3} />
          </ThemeIcon>
        )}
      </Card>
    </UnstyledButton>
  )
}
