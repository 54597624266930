import { Box, Card, Grid, Text } from '@mantine/core'
import { FormErrors } from '@mantine/form'
import { FormattedMessage } from 'react-intl'
import { useModelContext } from '@/providers/ModelContext'
import { BusinessLogicSettingType, Trigger } from '@/types/businessLogic'
import { CalibrationArea, Polygon } from '../AnnotationArea/CalibrationArea'
import { Line } from '../AnnotationArea/Line/useDrawLine'
import { EventSettingType } from '../EventSettings/EventSettings'
import { LogicLineDetection } from '../LogicHandler/LogicLine/LogicLineDetection'
import {
  DEFAULT_EVENT_SETTINGS,
  FRAME_HEIGHT,
  FRAME_WIDTH
} from '../LogicHandler/constants'
import { useCameraImage } from '../hooks/useCameraImage'
import { LogicLineValues, SavedCalibrationValues } from '../types'
import { CalibrationHelp } from './CalibrationHelp'
import { CalibrationLine } from './CalibrationLine'

type StreamConfigProps = {
  deviceId: string
  cameraStreamId: string
  active: boolean
  settings: SavedCalibrationValues[]
  triggers: LogicLineValues[]
  streamErrors: FormErrors
  onSettingAdd: (setting: SavedCalibrationValues) => void
  onSettingChange: (
    settingIndex: number,
    setting: SavedCalibrationValues
  ) => void
  onSettingRemove: (settingIndex: number) => void
  onTriggerAdd: (trigger: LogicLineValues) => void
  onTriggerChange: (triggerIndex: number, trigger: LogicLineValues) => void
  onTriggerRemove: (triggerIndex: number) => void
}

export const StreamConfig = ({
  deviceId,
  cameraStreamId,
  active,
  settings,
  triggers,
  streamErrors,
  onSettingAdd,
  onSettingChange,
  onSettingRemove,
  onTriggerAdd,
  onTriggerChange,
  onTriggerRemove
}: StreamConfigProps) => {
  const { model } = useModelContext()

  const { isImageLoading, cameraImage, refreshCameraImage } = useCameraImage({
    deviceId,
    cameraStreamId,
    enabled: active
  })

  const labels = model?.labels || []

  const labelOptions = labels.map((label) => ({
    labelId: label.id,
    name: label.name,
    color: label.color
  }))

  const lines = settings.map(({ side, color, points }) => ({
    name: side,
    color,
    points
  }))

  const rois = triggers.map(({ name, color, points }) => ({
    name,
    color,
    points
  }))

  const handleLineAdd = ({ name, color, points }: Line) => {
    onSettingAdd({
      type: BusinessLogicSettingType.Calibration,
      side: name,
      points,
      size: 0,
      unit: 'cm',
      color
    })
  }

  const handleLineChange = (lineIndex: number, line: Line) => {
    onSettingChange(lineIndex, {
      ...settings[lineIndex],
      points: line.points
    })
  }

  const handleSizeChange = (settingIndex: number, size: number) => {
    onSettingChange(settingIndex, {
      ...settings[settingIndex],
      size
    })
  }

  const handleRoiAdd = ({ name, color, points }: Polygon) => {
    onTriggerAdd({
      ...DEFAULT_EVENT_SETTINGS,
      type: 'roi',
      name,
      color,
      points,
      triggers: []
    })
  }

  const handleRoiChange = (triggerIndex: number, roi: Polygon) => {
    onTriggerChange(triggerIndex, {
      ...triggers[triggerIndex],
      points: roi.points
    })
  }

  const handleEventSettingChange = (
    triggerIndex: number,
    settingType: EventSettingType,
    value: boolean
  ) => {
    onTriggerChange(triggerIndex, {
      ...triggers[triggerIndex],
      [settingType]: value
    })
  }

  const handleLabelsChange = (triggerIndex: number, objTriggers: Trigger[]) => {
    onTriggerChange(triggerIndex, {
      ...triggers[triggerIndex],
      triggers: objTriggers
    })
  }

  const handleImageRefresh = () => {
    if (!isImageLoading) {
      void refreshCameraImage()
    }
  }

  return (
    <Grid>
      <Grid.Col span="content">
        <CalibrationArea
          imageUrl={cameraImage}
          isImageLoading={isImageLoading}
          width={FRAME_WIDTH}
          height={FRAME_HEIGHT}
          lines={lines}
          rois={rois}
          showHelpIcon={triggers.length !== 0 || settings.length !== 0}
          onLineAdd={handleLineAdd}
          onLineChange={handleLineChange}
          onRoiAdd={handleRoiAdd}
          onRoiChange={handleRoiChange}
          onImageRefresh={handleImageRefresh}
        />
      </Grid.Col>

      <Grid.Col span="auto">
        <Box h="100%" miw={300} maw={FRAME_WIDTH}>
          {triggers.length === 0 && settings.length === 0 && (
            <Card h="100%" p="lg" radius={0} withBorder>
              <CalibrationHelp />
            </Card>
          )}

          {triggers.map((trigger, i) => (
            <Box key={trigger.name} mb="md">
              <LogicLineDetection
                type="roi"
                name={trigger.name}
                color={trigger.color}
                label={
                  <FormattedMessage id="logic.objectMeasurement.labelName" />
                }
                triggers={trigger.triggers}
                triggerError={streamErrors[`lines.${i}.triggers`] as string}
                notification={trigger.notification}
                videoUpload={trigger.video_upload}
                labelOptions={labelOptions}
                onLineRemove={() => onTriggerRemove(i)}
                onTriggerChange={(objTriggers) =>
                  handleLabelsChange(i, objTriggers)
                }
                onSettingChange={(settingType, value) =>
                  handleEventSettingChange(i, settingType, value)
                }
              />
            </Box>
          ))}

          {settings.length > 0 && (
            <Card withBorder>
              <Text size="sm" fw={600} mb="md">
                <FormattedMessage id="logic.calibration.settings" />
              </Text>

              {settings.map((setting, i) => (
                <Box
                  key={setting.side}
                  mb={i === settings.length - 1 ? 0 : 'md'}
                >
                  <CalibrationLine
                    name={setting.side}
                    color={setting.color}
                    size={settings[i].size}
                    unit={settings[i].unit}
                    onRemove={() => onSettingRemove(i)}
                    onSizeChange={(size) => handleSizeChange(i, size)}
                  />
                </Box>
              ))}
            </Card>
          )}
        </Box>
      </Grid.Col>
    </Grid>
  )
}
