import { useMutation, useQuery } from '@tanstack/react-query'
import { GetCvatTaskStatusParams, annotationApi } from '@/api/annotationApi'

export const annotationQueryKeys = {
  all: ['annotations'] as const,
  details: (mediaId: string) =>
    [...annotationQueryKeys.all, 'details', mediaId] as const,
  cvatUrl: (taskId: number) =>
    [...annotationQueryKeys.all, 'cvatUrl', taskId] as const,
  taskStatus: (
    taskId: number,
    statusType: string,
    processId: string | number
  ) =>
    [
      ...annotationQueryKeys.all,
      'taskStatus',
      processId,
      taskId,
      statusType
    ] as const,
  celeryTaskStatus: (celeryTaskId: string) =>
    [...annotationQueryKeys.all, 'celeryTaskStatus', celeryTaskId] as const
}

type UseGetAnnotationParams = {
  mediaId: string
  enabled: boolean
}

export const useGetAnnotation = ({
  mediaId,
  enabled
}: UseGetAnnotationParams) => {
  return useQuery({
    queryKey: annotationQueryKeys.details(mediaId),
    queryFn: () => annotationApi.getAnnotation(mediaId),
    enabled,
    retry: false
  })
}

export const useCvatLogin = () => {
  return useMutation({
    mutationFn: annotationApi.cvatLogin
  })
}

export const useCreateCvatTask = () => {
  return useMutation({
    mutationFn: annotationApi.createCvatTask
  })
}

export const useUpdateCvatTask = () => {
  return useMutation({
    mutationFn: annotationApi.updateCvatTask
  })
}

export const useCreateCvatTaskAnnotation = () => {
  return useMutation({
    mutationFn: annotationApi.createCvatTaskAnnotation
  })
}

type UseGetCvatUrlParams = {
  taskId: number
  enabled: boolean
}

export const useGetCvatUrl = ({ taskId, enabled }: UseGetCvatUrlParams) => {
  return useQuery({
    queryKey: annotationQueryKeys.cvatUrl(taskId),
    queryFn: () => annotationApi.getCvatUrl(taskId),
    enabled
  })
}

type UseGetTaskStatusParams = {
  taskId: number
  statusType: GetCvatTaskStatusParams['statusType']
  processId: string | number
  enabled: boolean
}

export const useGetTaskStatus = ({
  taskId,
  statusType,
  processId,
  enabled
}: UseGetTaskStatusParams) => {
  return useQuery({
    queryKey: annotationQueryKeys.taskStatus(taskId, statusType, processId),
    queryFn: () => annotationApi.getTaskStatus({ taskId, statusType }),
    refetchInterval: (query) => {
      const { data } = query.state
      const isDone =
        data?.is_video_uploaded === true || data?.completed === true
      return isDone ? false : 3000
    },
    enabled
  })
}

type UseGetCeleryTaskStatusParams = {
  celeryTaskId: string
  enabled: boolean
}

export const useGetCeleryTaskStatus = ({
  celeryTaskId,
  enabled
}: UseGetCeleryTaskStatusParams) => {
  return useQuery({
    queryKey: annotationQueryKeys.celeryTaskStatus(celeryTaskId),
    queryFn: () => annotationApi.getCeleryTaskStatus(celeryTaskId),
    refetchInterval: (query) => {
      const { data } = query.state

      return data?.task_status === 'SUCCESS' ? false : 3000
    },
    enabled
  })
}

export const useDeleteAnnotationTask = () => {
  return useMutation({
    mutationFn: annotationApi.deleteAnnotationTask
  })
}
