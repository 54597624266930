import { Text, ThemeIcon } from '@mantine/core'
import { IconLine, IconVector } from '@tabler/icons-react'
import { FormattedMessage } from 'react-intl'

export const EmptyLogicText = () => {
  return (
    <Text component="div" size="md" ta="center">
      <FormattedMessage
        id="logic.annotate.start"
        values={{
          lineIcon: (
            <ThemeIcon variant="light" size="sm" pos="relative" top={4}>
              <IconLine style={{ width: '90%', height: '90%' }} />
            </ThemeIcon>
          ),
          roiIcon: (
            <ThemeIcon variant="light" size="sm" pos="relative" top={4}>
              <IconVector style={{ width: '90%', height: '90%' }} />
            </ThemeIcon>
          )
        }}
      />
    </Text>
  )
}
