import {
  Accordion,
  Card,
  Code,
  Grid,
  ScrollArea,
  Table,
  Text
} from '@mantine/core'
import { FormattedMessage, useIntl } from 'react-intl'
import { Event } from '@/types/event'
import { EventInfoCard } from './EventInfoCard/EventInfoCard'
import { MeasurementInfo } from './MeasurementInfo/MeasurementInfo'

type EventDetailRightPaneProps = {
  event: Event
}

export const EventDetailRightPane = ({ event }: EventDetailRightPaneProps) => {
  const intl = useIntl()

  const outputStream = event.output.stream

  const detections =
    outputStream.business_logics[0]?.parameters[0]?.detections || []

  const rows = detections.map((detection, idx) => (
    <Table.Tr key={idx}>
      <Table.Td>{detection.label_name}</Table.Td>
      <Table.Td>{detection.count}</Table.Td>
    </Table.Tr>
  ))

  return (
    <Grid.Col span={{ base: 12, md: 5, lg: 5 }}>
      <ScrollArea h="80vh">
        <EventInfoCard title={intl.formatMessage({ id: 'events.detections' })}>
          <Card.Section>
            <Table verticalSpacing="sm" horizontalSpacing="md">
              <Table.Thead bg="gray.1">
                <Table.Tr>
                  <Table.Th>Label</Table.Th>
                  <Table.Th>Count</Table.Th>
                </Table.Tr>
              </Table.Thead>

              <Table.Tbody>{rows}</Table.Tbody>
            </Table>
          </Card.Section>
        </EventInfoCard>

        {event.measurement && (
          <EventInfoCard
            title={intl.formatMessage({ id: 'events.measurement' })}
          >
            <Card.Section>
              <MeasurementInfo
                id={event.measurement.id}
                width={event.measurement.dimensions.width}
                height={event.measurement.dimensions.height}
                depth={event.measurement.dimensions.depth}
                sizeUnit={event.measurement.dimensions.unit}
                weight={event.measurement.weight.value}
                weightUnit={event.measurement.weight.unit}
              />
            </Card.Section>
          </EventInfoCard>
        )}

        <EventInfoCard
          title={intl.formatMessage({ id: 'events.configuration' })}
        >
          <Card.Section p="0" inheritPadding>
            <Accordion
              defaultValue="parameters"
              styles={{
                content: {
                  padding: 0
                }
              }}
            >
              <Accordion.Item value="parameters" style={{ border: 'none' }}>
                <Accordion.Control style={{ borderRadius: '8px' }} bg="gray.1">
                  <Text size="sm" fw={600}>
                    <FormattedMessage id="events.configuration.parameters" />
                  </Text>
                </Accordion.Control>

                <Accordion.Panel bg="gray.0">
                  <ScrollArea w="100%" h={420}>
                    <Code bg="gray.0" block>
                      {JSON.stringify(outputStream.business_logics, null, 4)}
                    </Code>
                  </ScrollArea>
                </Accordion.Panel>
              </Accordion.Item>
            </Accordion>
          </Card.Section>
        </EventInfoCard>
      </ScrollArea>
    </Grid.Col>
  )
}
