import { BillingPeriod } from '../types/subscription'

// Stripe returns prices in cents, so we need to divide it by 100. For example, 1000 cents = $10
export const getProductPrice = (price: number) => price / 100

export const getProductMonthlyPrice = (
  price: number,
  billingPeriod: BillingPeriod
) => {
  let displayPrice = getProductPrice(price)

  if (billingPeriod === 'yearly') {
    displayPrice = displayPrice / 12
  }

  return displayPrice
}

export const getBillingPeriod = (freq: string): BillingPeriod | null => {
  if (freq === 'month_1') {
    return 'monthly'
  }

  if (freq === 'year_1') {
    return 'yearly'
  }

  return null
}
