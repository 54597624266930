import { Card, Group, Text, ThemeIcon, UnstyledButton } from '@mantine/core'
import { IconCheck, IconCircle, IconCircleFilled } from '@tabler/icons-react'
import { ReactNode } from 'react'
import { FormattedMessage } from 'react-intl'
import { ModelNestedPath } from '@/router/paths'
import classes from './ModelStepper.module.css'

type StepIconProps = {
  isCompleted: boolean
  isActive: boolean
}

const StepIcon = ({ isCompleted, isActive }: StepIconProps) => {
  if (isCompleted) {
    return (
      <ThemeIcon color="green" size={16} radius="xl">
        <IconCheck style={{ width: '80%', height: '80%' }} />
      </ThemeIcon>
    )
  }

  if (isActive) {
    return (
      <ThemeIcon color="blue" size={16} radius="xl">
        <IconCircleFilled style={{ width: '50%', height: '50%' }} />
      </ThemeIcon>
    )
  }

  return (
    <ThemeIcon color="gray" size={16} radius="xl">
      <IconCircle style={{ width: '60%', height: '60%' }} stroke={3} />
    </ThemeIcon>
  )
}

type StepProps = {
  title: ReactNode
  isCompleted: boolean
  isActive: boolean
  isClickable: boolean
  onClick: () => void
}

const Step = ({
  title,
  isCompleted,
  isActive,
  isClickable,
  onClick
}: StepProps) => {
  const handleClick = () => {
    if (isClickable) {
      onClick()
    }
  }

  return (
    <li>
      <UnstyledButton
        w="100%"
        style={{
          cursor: isClickable ? 'pointer' : 'not-allowed',
          opacity: isClickable ? 1 : 0.5
        }}
        onClick={handleClick}
      >
        <Card bg={isActive ? 'brand-primary.0' : undefined} p={10}>
          <Group wrap="nowrap" gap="sm">
            <StepIcon isCompleted={isCompleted} isActive={isActive} />

            <Text
              c={isClickable || isActive ? undefined : 'brand-primary.9'}
              fw={500}
              size="sm"
            >
              {title}
            </Text>
          </Group>
        </Card>
      </UnstyledButton>
    </li>
  )
}

export type ModelStep = {
  id: number
  messageId: string
  urlPath: ModelNestedPath
}

type ModelStepperProps = {
  steps: ModelStep[]
  activeStep: number
  completedSteps: number[]
  disabledSteps: number[]
  onStepClick: (step: ModelStep) => void
}

export const ModelStepper = ({
  steps,
  activeStep,
  completedSteps,
  disabledSteps,
  onStepClick
}: ModelStepperProps) => {
  return (
    <ul className={classes['model-stepper']}>
      {steps.map((step, i) => (
        <Step
          key={step.id}
          title={<FormattedMessage id={step.messageId} />}
          isCompleted={completedSteps.includes(i)}
          isActive={activeStep === i}
          isClickable={!disabledSteps.includes(i)}
          onClick={() => onStepClick(step)}
        />
      ))}
    </ul>
  )
}
