import { em } from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'

const MOBILE_WIDTH = 768

export const useDeviceDetect = () => {
  const isMobile = useMediaQuery(`(max-width: ${em(MOBILE_WIDTH)})`) as boolean

  return {
    isMobile,
    isDesktop: !isMobile
  }
}
