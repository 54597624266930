import { Indicator, Stack, Text, ThemeIcon } from '@mantine/core'
import { IconBorderCorners, IconRuler } from '@tabler/icons-react'
import { SelectableCard } from '@/components/ui-shared/SelectableCard/SelectableCard'
import { LibraryLogicType } from '@/types/businessLogic'
import { BusinessLogicName } from '../BusinessLogicName'

type BusinessLogicIconProps = {
  businessLogicType: LibraryLogicType
}

const BusinessLogicIcon = ({ businessLogicType }: BusinessLogicIconProps) => {
  if (businessLogicType === LibraryLogicType.ObjectDetection) {
    return (
      <ThemeIcon variant="light" radius="xl" size="lg">
        <IconBorderCorners style={{ width: '80%', height: '80%' }} stroke={2} />
      </ThemeIcon>
    )
  }

  if (businessLogicType === LibraryLogicType.ObjectCount) {
    return (
      <Indicator label="5" size={18} position="bottom-end" inline>
        <ThemeIcon variant="light" radius="xl" size="lg">
          <IconBorderCorners
            style={{ width: '80%', height: '80%' }}
            stroke={2}
          />
        </ThemeIcon>
      </Indicator>
    )
  }

  if (businessLogicType === LibraryLogicType.ObjectMeasurement3d) {
    return (
      <ThemeIcon variant="light" radius="xl" size="lg">
        <IconRuler style={{ width: '80%', height: '80%' }} stroke={2} />
      </ThemeIcon>
    )
  }

  return null
}

type BusinessLogicCardPros = {
  businessLogicType: LibraryLogicType
  isSelected?: boolean
  disabled?: boolean
  onClick?: () => void
}

export const BusinessLogicCard = ({
  businessLogicType,
  isSelected,
  disabled,
  onClick
}: BusinessLogicCardPros) => {
  return (
    <SelectableCard
      isSelected={isSelected}
      disabled={disabled}
      onClick={onClick}
    >
      <Stack h="100%" align="center" justify="center" gap="lg">
        <BusinessLogicIcon businessLogicType={businessLogicType} />

        <Text w="100%" ta="center" size="sm" fw={600} lineClamp={2}>
          <BusinessLogicName businessLogicType={businessLogicType} />
        </Text>
      </Stack>
    </SelectableCard>
  )
}
