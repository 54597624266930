import { Alert, Group } from '@mantine/core'
import { IconCheck } from '@tabler/icons-react'
import { ReactNode } from 'react'
import { FormattedMessage } from 'react-intl'

type TrainingCompletedBannerProps = {
  rightSection?: ReactNode
}

export const TrainingCompletedBanner = ({
  rightSection
}: TrainingCompletedBannerProps) => {
  const icon = <IconCheck size={16} />

  return (
    <Alert
      variant="light"
      color="green"
      title={<FormattedMessage id="training.status.completed" />}
      icon={icon}
    >
      <Group justify="space-between" align="center">
        <FormattedMessage id="training.completedDescription" />

        {rightSection}
      </Group>
    </Alert>
  )
}
