import { AxiosError, isAxiosError } from 'axios'

const DETAIL_KEY = 'detail'
const NON_FIELD_ERROR_KEY = 'non_field_errors'

type ErrorData = Record<string, string | string[]>

export type ApiError = {
  status?: number
  errorMessage?: string
  data?: ErrorData
  axiosError?: AxiosError
}

const getErrorMessage = (data: ErrorData | undefined) => {
  if (data && Object.keys(data).length) {
    if (data[DETAIL_KEY]) {
      return data[DETAIL_KEY] as string
    }

    if (data[NON_FIELD_ERROR_KEY]) {
      return data[NON_FIELD_ERROR_KEY][0]
    }

    const messageKey = Object.keys(data)[0]

    if (Array.isArray(data[messageKey])) {
      return `${data[messageKey][0]} [${messageKey}]`
    }
  }

  return ''
}

// This utility function extracts the HTTP status and error payload from an Axios error response.
// It also retrieves any server-generated error messages for display to the user, if available.
export const getApiError = (error: unknown) => {
  if (isAxiosError(error)) {
    const data = error.response?.data as ErrorData | undefined

    return {
      status: error.response?.status,
      errorMessage: getErrorMessage(data),
      data,
      axiosError: error // attach raw axios arror
    }
  }

  return {}
}
