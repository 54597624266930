import { Box, Center, Modal } from '@mantine/core'
import { useDisclosure, useViewportSize } from '@mantine/hooks'
import { useEffect, useRef, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { Annotation } from '@/types/annotation'
import { FileMetadata } from '@/types/dataset'
import { LibraryMLModel } from '@/types/model'
import { AnnotationOverlay, TOOLBAR_HEIGHT } from './AnnotationOverlay'
import { EditAnnotationToolbarHandler } from './EditAnnotationToolbarHandler'
import { LoadingMessage } from './LoadingMessage/LoadingMessage'
import { LoadingStatus } from './LoadingMessage/status'
import { FormValues, VideoFpsForm } from './VideoFpsForm/VideoFpsForm'
import { useFinishCvatSession } from './useFinishCvatSession'
import { useStartCvatSession } from './useStartCvatSession'

type AnnotationHandlerProps = {
  file: FileMetadata
  annotation: Annotation | null
  libraryModel: LibraryMLModel
  onCancelLabeling: () => void
  onSaveLabeling: () => void
}

export const AnnotationHandler = ({
  file,
  annotation,
  libraryModel,
  onCancelLabeling,
  onSaveLabeling
}: AnnotationHandlerProps) => {
  const timestamp = useRef(Date.now())
  const cvatInitialized = useRef(false)
  const iframe = useRef<HTMLIFrameElement | null>(null)

  const [isFpsModalOpened, { open: openFpsModal, close: closeFpsModal }] =
    useDisclosure(false)

  const [loadingStatus, setLoadingStatus] = useState<LoadingStatus | null>(
    LoadingStatus.prepareFile
  )
  const isLoading = loadingStatus !== null

  const { height: vHeight } = useViewportSize()

  const { iframeUrl, token, cvatTaskId, startCvatSession } =
    useStartCvatSession({
      fileMetadataId: file.id,
      taskName: `cvat_task_${file.id}_${timestamp.current}`,
      taskId: annotation?.task_id
    })

  const { finishCvatSession, completed } = useFinishCvatSession({
    taskId: cvatTaskId,
    model_type: libraryModel.model_type
  })

  const isVideoFile = file.media_type === 'video'

  useEffect(() => {
    if (!cvatInitialized.current) {
      if (isVideoFile && annotation?.task_id === undefined) {
        openFpsModal()
      } else {
        cvatInitialized.current = true
        void startCvatSession(1)
      }
    }
  }, [isVideoFile, openFpsModal, startCvatSession, annotation?.task_id])

  useEffect(() => {
    if (completed) {
      onSaveLabeling()
    }
  }, [completed, onSaveLabeling])

  const handleFpsSubmit = ({ labelFps }: FormValues) => {
    closeFpsModal()
    void startCvatSession(labelFps)
  }

  const handleIframeLoad = () => {
    setLoadingStatus(null)

    if (token && iframeUrl && iframe.current?.contentWindow) {
      iframe.current.contentWindow.postMessage(
        { action: 'ADD_TOKEN', value: '"' + token + '"' },
        new URL(iframeUrl).origin
      )
    }
  }

  const handleSaveLabeling = () => {
    if (iframeUrl && iframe.current?.contentWindow) {
      iframe.current.contentWindow.postMessage(
        'FINISH_JOB',
        new URL(iframeUrl).origin
      )
    }

    setLoadingStatus(LoadingStatus.saveChanges)
    finishCvatSession()
  }

  return (
    <>
      <AnnotationOverlay
        toolbar={
          isLoading ? null : (
            <EditAnnotationToolbarHandler
              file={file}
              onCancelLabeling={onCancelLabeling}
              onSaveLabeling={handleSaveLabeling}
            />
          )
        }
        opened
      >
        <Box w="100%" h={vHeight - TOOLBAR_HEIGHT} bg="white">
          <Center h="100%">
            {isLoading && !isFpsModalOpened && (
              <LoadingMessage
                imageUrl={file.thumbnail}
                loadingStatus={loadingStatus}
              />
            )}

            {iframeUrl && (
              <iframe
                ref={iframe}
                src={iframeUrl}
                style={{
                  width: '100%',
                  height: vHeight - TOOLBAR_HEIGHT,
                  border: 'none',
                  backgroundColor: 'transparent',
                  display: isLoading ? 'none' : 'block'
                }}
                onLoad={handleIframeLoad}
              />
            )}
          </Center>
        </Box>
      </AnnotationOverlay>

      <Modal
        title={<FormattedMessage id="annotation.labelFps" />}
        opened={isFpsModalOpened}
        onClose={onCancelLabeling}
      >
        <VideoFpsForm
          originalFps={file.original_video_fps || 1}
          onCancel={onCancelLabeling}
          onSubmit={(values) => void handleFpsSubmit(values)}
        />
      </Modal>
    </>
  )
}
