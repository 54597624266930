import { useQuery } from '@tanstack/react-query'
import { inferenceApi } from '@/api/inferenceApi'
import { InferenceStatus } from '@/types/inference'

export const inferenceQueryKeys = {
  all: ['inference'] as const,
  inferenceStatus: (inferenceId: string) =>
    [...inferenceQueryKeys.all, 'inference-status', inferenceId] as const
}

type UseGetInferenceDetailsParams = {
  inferenceId: string
  enabled: boolean
}
export const useGetInferenceDetails = ({
  inferenceId,
  enabled
}: UseGetInferenceDetailsParams) => {
  return useQuery({
    queryKey: inferenceQueryKeys.inferenceStatus(inferenceId),
    queryFn: () => inferenceApi.getInferenceDetails(inferenceId),
    refetchInterval: (query) => {
      const { data } = query.state
      const isFinished =
        data?.result?.status === InferenceStatus.Success ||
        data?.result?.status === InferenceStatus.Failed
      return isFinished ? false : 2000
    },
    enabled
  })
}
