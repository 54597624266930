import { Group, Skeleton } from '@mantine/core'

export const DatasetToolbarSkeleton = () => {
  return (
    <Group align="center" justify="space-between" py="sm">
      <Group>
        <Skeleton height={20} circle />
        <Skeleton width={160} height={24} />
        <Skeleton width={160} height={24} />
      </Group>

      <Skeleton width={200} height={36} />
    </Group>
  )
}
