import { Box, Center, SimpleGrid, Skeleton } from '@mantine/core'
import { SubscriptionPlanCardSkeleton } from './SubscriptionPlanCard/SubscriptionPlanCardSkeleton'

type LoadingSkeletonsProps = {
  isMobile: boolean
}

export const LoadingSkeletons = ({ isMobile }: LoadingSkeletonsProps) => (
  <Box>
    <Center mih={220}>
      <Skeleton height={48} width="50%" />
    </Center>

    <SimpleGrid cols={isMobile ? 1 : 5} spacing="lg">
      <SubscriptionPlanCardSkeleton />
      <SubscriptionPlanCardSkeleton />
      <SubscriptionPlanCardSkeleton />
      <SubscriptionPlanCardSkeleton />
      <SubscriptionPlanCardSkeleton />
    </SimpleGrid>
  </Box>
)
