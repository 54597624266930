import {
  Flex,
  Grid,
  List,
  Paper,
  ScrollArea,
  Stack,
  Text,
  Title,
  Transition,
  UnstyledButton,
  rem
} from '@mantine/core'
import { useInViewport } from '@mantine/hooks'
import { useState } from 'react'
import { ListThemeIcon } from './ListThemeIcon'

const SCROLL_VIEWPORT_HEIGHT = 440

type FeatureCardProps = {
  opened: boolean
}

const ViewDeployments = ({ opened }: FeatureCardProps) => {
  const { ref, inViewport } = useInViewport()
  return (
    <Flex ref={ref} p="lg" px="xl" h={rem(SCROLL_VIEWPORT_HEIGHT)}>
      <Transition
        mounted={opened && inViewport}
        transition="fade"
        duration={400}
        timingFunction="ease"
      >
        {(styles) => (
          <Stack style={styles}>
            <Text>
              Upon accessing the Deployments page, you will be presented with an
              intuitive table displaying all your device deployments. Each row
              represents the following information:
            </Text>
            <List>
              <List.Item>
                <strong>Camera Stream:</strong> the source of the camera stream
                for each deployment, allowing you to quickly identify which
                cameras are being utilized.
              </List.Item>
              <List.Item>
                <strong>Model:</strong> computer vision model deployed to each
                device, providing insight into the AI capabilities powering your
                deployments.
              </List.Item>
              <List.Item>
                <strong>Business Logic:</strong> logic or custom workflows
                associated with each deployment, enabling you to understand the
                operational context of your devices.
              </List.Item>
              <List.Item>
                <strong>Deployment Status:</strong> provides real-time feedback
                on the status of each deployment, including indicators for
                active, paused, or offline deployments, ensuring you stay
                informed about the operational status of your devices.
              </List.Item>
            </List>
          </Stack>
        )}
      </Transition>
    </Flex>
  )
}

const ManagingDeployments = ({ opened }: FeatureCardProps) => {
  const { ref, inViewport } = useInViewport()

  return (
    <Flex ref={ref} p="lg" px="xl" h={rem(SCROLL_VIEWPORT_HEIGHT)}>
      <Transition
        mounted={opened && inViewport}
        transition="fade"
        duration={400}
        timingFunction="ease"
      >
        {(styles) => (
          <Stack style={styles} m="auto">
            <Text>
              From the Deployments page, you have the option to take further
              actions, such as viewing recent events or deploying a new model to
              a device. By clicking on the{' '}
              <Text c="brand-primary.6" fw="bold" span>
                View Events
              </Text>{' '}
              button, you can access a log of recent events and activities
              related to the selected deployment, aiding in troubleshooting and
              performance analysis.
            </Text>
            <Text>
              Likewise, if you wish to deploy a different model to a device,
              simply click on the{' '}
              <Text c="brand-primary.6" fw="bold" span>
                Deploy Model
              </Text>{' '}
              button associated with the respective deployment. This streamlined
              process empowers you to adapt and optimize your deployments with
              ease.
            </Text>
          </Stack>
        )}
      </Transition>
    </Flex>
  )
}

type FeaturePageProps = {
  page: number
}
const FeaturePage = ({ page }: FeaturePageProps) => {
  return page === 0 ? (
    <ViewDeployments opened={page === 0} />
  ) : (
    <ManagingDeployments opened={page === 1} />
  )
}

export const DeploymentsContent = () => {
  const [currentPage, setCurrentPage] = useState(0)

  return (
    <Grid gutter="lg">
      <Grid.Col span={{ base: 12, lg: 5 }}>
        <Title order={2}>Manage Your Deployments</Title>
        <Text c="dimmed" mt="md">
          Welcome to the Deployments page, your central hub for managing device
          deployments and monitoring their status and configurations. Here, you
          can effortlessly oversee your camera streams, associated models,
          business logic, and deployment statuses in a clear and concise table
          format.
        </Text>
        <List mt="lg" spacing="md" size="lg" center>
          <List.Item
            icon={<ListThemeIcon currentPage={currentPage} page={0} />}
          >
            <UnstyledButton onClick={() => setCurrentPage(0)}>
              Viewing Device Deployments
            </UnstyledButton>
          </List.Item>
          <List.Item
            icon={<ListThemeIcon currentPage={currentPage} page={1} />}
          >
            <UnstyledButton onClick={() => setCurrentPage(1)}>
              Managing Deployments
            </UnstyledButton>
          </List.Item>
        </List>
      </Grid.Col>
      <Grid.Col span={{ base: 12, lg: 7 }}>
        <Paper radius="md" h={rem(SCROLL_VIEWPORT_HEIGHT + 10)} withBorder>
          <ScrollArea w="100%" h={rem(SCROLL_VIEWPORT_HEIGHT)} type="auto">
            <FeaturePage page={currentPage} />
          </ScrollArea>
        </Paper>
      </Grid.Col>
    </Grid>
  )
}
