import {
  ActionIcon,
  Box,
  Group,
  Indicator,
  LoadingOverlay,
  Popover,
  Text,
  Tooltip
} from '@mantine/core'
import { useClickOutside } from '@mantine/hooks'
import { IconMessageCircle, IconTrash } from '@tabler/icons-react'
import { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { getApiError } from '@/api/helpers/apiError'
import { useAddEventNote, useDeleteEventNote } from '@/queries/eventQueries'
import { showToast } from '@/theme/notifications'
import { EventNoteForm } from '../EventNoteForm/EventNoteForm'

type EventNoteHandlerProps = {
  applicationId: string
  eventId: string
  hasNote: boolean
  noteText: string
  onNoteSave: () => void
}

export const EventNoteHandler = ({
  applicationId,
  eventId,
  hasNote,
  noteText,
  onNoteSave
}: EventNoteHandlerProps) => {
  const intl = useIntl()
  const [isPopoverOpen, setIsPopoverOpen] = useState(false)

  const popoverRef = useClickOutside(() => {
    setIsPopoverOpen(false)
  })

  const { mutateAsync: addEventNote, isPending: isAddPending } =
    useAddEventNote()
  const { mutateAsync: deleteEventNote, isPending: isDeletePending } =
    useDeleteEventNote()

  const handleNoteSave = async (note: string) => {
    try {
      await addEventNote({
        appId: applicationId,
        eventId,
        note
      })

      setIsPopoverOpen(false)
      onNoteSave()

      showToast(
        intl.formatMessage({ id: 'events.notes.add.success' }),
        'success'
      )
    } catch (err) {
      const { errorMessage } = getApiError(err)

      const message =
        errorMessage || intl.formatMessage({ id: 'events.notes.add.error' })

      showToast(message, 'error')
    }
  }

  const handleDeleteNote = async () => {
    try {
      await deleteEventNote({
        appId: applicationId,
        eventId
      })

      setIsPopoverOpen(false)
      onNoteSave()

      showToast(
        intl.formatMessage({ id: 'events.notes.delete.success' }),
        'success'
      )
    } catch (err) {
      const { errorMessage } = getApiError(err)

      const message =
        errorMessage || intl.formatMessage({ id: 'events.notes.delete.error' })

      showToast(message, 'error')
    }
  }

  const handleCancel = () => {
    setIsPopoverOpen(false)
  }

  return (
    <div
      onClick={(e) => {
        e.stopPropagation()
      }}
    >
      <Popover width={480} opened={isPopoverOpen} shadow="lg" withArrow>
        <Popover.Target>
          <Indicator label="1" size={16} offset={1} disabled={!hasNote}>
            <ActionIcon
              variant="subtle"
              size="md"
              onClick={() => {
                setIsPopoverOpen((opened) => !opened)
              }}
            >
              <IconMessageCircle style={{ width: '70%', height: '70%' }} />
            </ActionIcon>
          </Indicator>
        </Popover.Target>

        <Popover.Dropdown ref={popoverRef}>
          <Box pos="relative">
            <LoadingOverlay visible={isAddPending || isDeletePending} />

            <Group align="center" justify="space-between" mb="lg">
              <Text size="sm" fw="bold">
                {hasNote ? (
                  <FormattedMessage id="events.notes.edit" />
                ) : (
                  <FormattedMessage id="events.notes.add" />
                )}
              </Text>

              {hasNote && (
                <Tooltip label={<FormattedMessage id="delete" />}>
                  <ActionIcon
                    variant="subtle"
                    size="md"
                    radius="xl"
                    color="dark.8"
                    onClick={() => {
                      void handleDeleteNote()
                    }}
                  >
                    <IconTrash size={14} />
                  </ActionIcon>
                </Tooltip>
              )}
            </Group>

            <EventNoteForm
              noteText={noteText}
              onSubmit={(value) => void handleNoteSave(value)}
              onCancel={handleCancel}
            />
          </Box>
        </Popover.Dropdown>
      </Popover>
    </div>
  )
}
