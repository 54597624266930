import { useState } from 'react'
import { SignUpTypeSelectionHandler } from './SignUpTypeSelectionHandler'
import { VerifyEmailHandler } from './VerifyEmailHandler'

export const SignUpPage = () => {
  const [email, setEmail] = useState('')

  const handleEmailSent = (email: string) => {
    setEmail(email)
  }

  if (email) {
    return <VerifyEmailHandler email={email} />
  }

  return <SignUpTypeSelectionHandler onEmailSent={handleEmailSent} />
}
