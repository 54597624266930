import {
  Badge,
  Box,
  Card,
  Image,
  Text,
  Tooltip,
  UnstyledButton
} from '@mantine/core'
import { useHover } from '@mantine/hooks'
import { MouseEvent } from 'react'
import { FormattedMessage } from 'react-intl'
import ImagePlaceholder from '@/assets/image-placeholder.png'
import { HoverAction, SelectionModeIcon } from './ActionIcons'

const IMAGE_SIZE = 150
const SELECTION_PADDING = 12

type ImageCardProps = {
  imageUrl: string
  fileName: string
  isSelectionModeEnabled: boolean
  isSelected: boolean
  isLabeled: boolean
  onSelectionChange: (isSelected: boolean) => void
  onClick: () => void
}

export const ImageCard = ({
  imageUrl,
  fileName,
  isSelectionModeEnabled,
  isSelected,
  isLabeled,
  onSelectionChange,
  onClick
}: ImageCardProps) => {
  const { hovered, ref } = useHover()

  const imageHeight = isSelected
    ? IMAGE_SIZE - SELECTION_PADDING * 2
    : IMAGE_SIZE

  const handleCardClick = () => {
    if (isSelectionModeEnabled) {
      onSelectionChange(!isSelected)
    } else {
      onClick()
    }
  }

  const startSelection = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    onSelectionChange(true)
  }

  return (
    <UnstyledButton component="a" onClick={handleCardClick}>
      <Card
        ref={ref}
        pos="relative"
        shadow={hovered ? 'sm' : undefined}
        styles={{
          root: {
            transition: 'transform 0.3s ease',
            transform: hovered ? 'scale(1.03)' : 'scale(1)',
            borderColor: isSelected
              ? 'var(--mantine-color-brand-primary-6)'
              : undefined
          }
        }}
        withBorder
      >
        <Card.Section p={isSelected ? SELECTION_PADDING : undefined}>
          <Image
            h={imageHeight}
            fit="cover"
            src={imageUrl}
            fallbackSrc={ImagePlaceholder}
          />

          {isSelectionModeEnabled && (
            <Box pos="absolute" top={4} right={4}>
              <SelectionModeIcon isSelected={isSelected} />
            </Box>
          )}

          {hovered && !isSelectionModeEnabled && (
            <Box pos="absolute" top={4} right={4}>
              <HoverAction onClick={startSelection} />
            </Box>
          )}

          {isLabeled && (
            <Box pos="absolute" top={-2} left={4}>
              <Badge size="xs" color="green">
                <FormattedMessage id="annotation.labeled" />
              </Badge>
            </Box>
          )}
        </Card.Section>

        <Card.Section p={6} bg={isSelected ? 'brand-primary' : 'gray.9'}>
          <Tooltip label={fileName}>
            <Text c="white" size="xs" truncate>
              {fileName}
            </Text>
          </Tooltip>
        </Card.Section>
      </Card>
    </UnstyledButton>
  )
}
