export enum RouterPath {
  root = '/',
  login = '/login',
  signup = '/signup',
  signupComplete = '/signup/complete',
  forgotPassword = '/forgot-password',
  setNewPassword = '/forgot-password/set-new-password',
  dashboard = '/dashboard',
  devices = '/devices',
  deviceClaim = '/devices/claim',
  apps = '/apps',
  appDetails = '/apps/:appId',
  mySubscription = '/my-subscription'
}

export enum ApplicationNestedPath {
  models = 'models',
  modelDetails = 'models/:modelId',
  datasets = 'datasets',
  trainings = 'trainings',
  deployments = 'deployments',
  createDeployment = 'deployments/create',
  events = 'events'
}

export enum ModelNestedPath {
  selectBaseModel = 'select-base-model',
  annotateData = 'annotate-data',
  training = 'training',

  // hidden, wip
  selectDataset = 'select-dataset',
  labelData = 'label-data'
}

export const buildAppLink = (appId: string, path: ApplicationNestedPath) => {
  const appLink = replacePathParams(RouterPath.appDetails, { appId })
  return `${appLink}/${path}`
}

export const buildModelLink = (appId: string, modelId: string) => {
  const modelLink = buildAppLink(appId, ApplicationNestedPath.modelDetails)
  return replacePathParams(modelLink, { modelId })
}

export const buildModelDetailsLink = (
  appId: string,
  modelId: string,
  path: ModelNestedPath
) => {
  const modelLink = buildModelLink(appId, modelId)
  return `${modelLink}/${path}`
}

export const replacePathParams = (
  path: string,
  params: Record<string, string>
) => {
  let newPath = path

  Object.keys(params).forEach((key) => {
    newPath = newPath.replace(`:${key}`, params[key])
  })

  return newPath
}
