import { Group, ThemeIcon, Tooltip } from '@mantine/core'
import { IconInfoCircle } from '@tabler/icons-react'
import { ReactNode } from 'react'

type LabelWithHintProps = {
  label: string | ReactNode
  hint: string | ReactNode
}

export const LabelWithHint = ({ label, hint }: LabelWithHintProps) => (
  <Group gap={4}>
    {label}

    <Tooltip label={hint} w={300} multiline>
      <ThemeIcon variant="transparent" radius="xl" size="xs">
        <IconInfoCircle style={{ width: '100%', height: '100%' }} />
      </ThemeIcon>
    </Tooltip>
  </Group>
)
