import { Group, Text, ThemeIcon } from '@mantine/core'
import {
  IconCheck,
  IconClock,
  IconPlayerStopFilled,
  IconX
} from '@tabler/icons-react'
import { FormattedMessage } from 'react-intl'
import { DeploymentStatus } from '@/types/deployment'

type StatusIconProps = {
  status: DeploymentStatus
}

const StatusIcon = ({ status }: StatusIconProps) => {
  if (status === DeploymentStatus.Running) {
    return (
      <ThemeIcon color="green" radius="xl" size="xs">
        <IconCheck style={{ width: '80%', height: '80%' }} stroke={2} />
      </ThemeIcon>
    )
  }

  if (status === DeploymentStatus.Failed) {
    return (
      <ThemeIcon color="red" radius="xl" size="xs">
        <IconX style={{ width: '80%', height: '80%' }} stroke={2} />
      </ThemeIcon>
    )
  }

  if (status === DeploymentStatus.Inactive) {
    return (
      <ThemeIcon color="grey" radius="xl" size="xs">
        <IconX style={{ width: '80%', height: '80%' }} stroke={2} />
      </ThemeIcon>
    )
  }

  if (status === DeploymentStatus.Stopped) {
    return (
      <ThemeIcon color="grey" radius="xl" size="xs">
        <IconPlayerStopFilled
          style={{ width: '60%', height: '60%' }}
          stroke={2}
        />
      </ThemeIcon>
    )
  }

  if (
    status === DeploymentStatus.InProgress ||
    status === DeploymentStatus.Removing ||
    status === DeploymentStatus.Stopping
  ) {
    return (
      <ThemeIcon variant="light" radius="xl" size="xs">
        <IconClock style={{ width: '90%', height: '90%' }} stroke={2} />
      </ThemeIcon>
    )
  }

  return null
}

const statusTextMap = {
  [DeploymentStatus.Running]: 'deployments.status.running',
  [DeploymentStatus.Inactive]: 'deployments.status.inactive',
  [DeploymentStatus.Failed]: 'deployments.status.failed',
  [DeploymentStatus.InProgress]: 'deployments.status.inProgress',
  [DeploymentStatus.Removing]: 'deployments.status.removing',
  [DeploymentStatus.Stopping]: 'deployments.status.stopping',
  [DeploymentStatus.Stopped]: 'deployments.status.stopped'
}

type StatusTextProps = {
  status: DeploymentStatus
}

const StatusText = ({ status }: StatusTextProps) => {
  return <FormattedMessage id={statusTextMap[status]} />
}

type DeploymentStatusIndicatorProps = {
  status: DeploymentStatus
}

export const DeploymentStatusIndicator = ({
  status
}: DeploymentStatusIndicatorProps) => {
  return (
    <Group gap="xs">
      <StatusIcon status={status} />

      <Text size="xs" fw="bold">
        <StatusText status={status} />
      </Text>
    </Group>
  )
}
