import {
  ActionIcon,
  Box,
  LoadingOverlay,
  ScrollArea,
  Stack,
  Tooltip
} from '@mantine/core'
import { useHotkeys } from '@mantine/hooks'
import { IconMinus, IconPlus, IconRefresh } from '@tabler/icons-react'
import Konva from 'konva'
import { KonvaEventObject } from 'konva/lib/Node'
import { Image as ImageType } from 'konva/lib/shapes/Image'
import { Vector2d } from 'konva/lib/types'
import { ReactNode, useEffect, useMemo, useRef, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Image, Layer, Stage } from 'react-konva'
import { showToast } from '@/theme/notifications'

const MIN_SCALE = 1
const MAX_SCALE = 3
const ZOOM_STEP = 0.25

type StageWithZoomProps = {
  children: ReactNode
  width: number
  height: number
  imageUrl?: string
  isLoading?: boolean
  isAnnotating: boolean
  onMouseMove: (e: KonvaEventObject<MouseEvent>) => void
  onMouseDown: (e: KonvaEventObject<MouseEvent>) => void
  onRefreshClick: () => void
}

export const StageWithZoom = ({
  children,
  width,
  height,
  imageUrl,
  isLoading,
  isAnnotating,
  onMouseMove,
  onMouseDown,
  onRefreshClick
}: StageWithZoomProps) => {
  const intl = useIntl()
  const imageRef = useRef<ImageType | null>(null)
  const stageRef = useRef<Konva.Stage>(null)
  const [image, setImage] = useState<HTMLImageElement | null>(null)
  const [scale, setScale] = useState(MIN_SCALE)

  const imageElement = useMemo(() => {
    if (imageUrl) {
      const element = new window.Image()
      element.width = width
      element.height = height
      element.src = imageUrl
      return element
    }

    return null
  }, [imageUrl, width, height])

  useEffect(() => {
    if (!imageElement) {
      return
    }

    const onload = () => {
      setImage(imageElement)
    }

    const onerror = () => {
      showToast(intl.formatMessage({ id: 'streams.image.error' }), 'error')
    }

    imageElement.addEventListener('load', onload)
    imageElement.addEventListener('error', onerror)

    return () => {
      imageElement.removeEventListener('load', onload)
      imageElement.removeEventListener('error', onerror)
    }
  }, [imageElement, intl])

  useHotkeys([
    [
      'mod+=',
      () => {
        handleZoomIn()
      }
    ],
    [
      'mod+-',
      () => {
        handleZoomOut()
      }
    ]
  ])

  const handleZoomIn = () => {
    const newScale =
      scale + ZOOM_STEP > MAX_SCALE ? MAX_SCALE : scale + ZOOM_STEP

    setScale(newScale)
  }

  const handleZoomOut = () => {
    const newScale =
      scale - ZOOM_STEP > MIN_SCALE ? scale - ZOOM_STEP : MIN_SCALE

    setScale(newScale)
  }

  const handleScrollChange = (pos: Vector2d) => {
    if (!stageRef.current) {
      return
    }

    const dx = pos.x
    const dy = pos.y

    stageRef.current.container().style.transform =
      'translate(' + dx + 'px, ' + dy + 'px)'

    stageRef.current.x(-dx)
    stageRef.current.y(-dy)
  }

  return (
    <Box pos="relative">
      <LoadingOverlay visible={isLoading} />

      <ScrollArea
        w={width}
        h={height}
        onScrollPositionChange={handleScrollChange}
      >
        <div
          style={{
            overflow: 'hidden',
            width: width * scale,
            height: height * scale
          }}
        >
          <Stage
            ref={stageRef}
            name="stage"
            width={width}
            height={height}
            style={{ cursor: isAnnotating ? 'crosshair' : 'default' }}
            x={0}
            y={0}
            scaleX={scale}
            scaleY={scale}
            onMouseMove={onMouseMove}
            onMouseDown={onMouseDown}
          >
            <Layer>
              <Image
                ref={imageRef}
                image={image as CanvasImageSource}
                x={0}
                y={0}
                width={width}
                height={height}
              />

              {children}
            </Layer>
          </Stage>

          <Box pos="absolute" top={12} right={16}>
            <Stack gap="xs">
              <ActionIcon
                variant="white"
                size="sm"
                radius="xl"
                onClick={handleZoomIn}
              >
                <IconPlus style={{ width: '70%', height: '70%' }} />
              </ActionIcon>

              <ActionIcon
                variant="white"
                size="sm"
                radius="xl"
                onClick={handleZoomOut}
              >
                <IconMinus style={{ width: '70%', height: '70%' }} />
              </ActionIcon>

              <Tooltip label={<FormattedMessage id="streams.refreshImage" />}>
                <ActionIcon
                  variant="white"
                  size="sm"
                  radius="xl"
                  onClick={onRefreshClick}
                >
                  <IconRefresh style={{ width: '70%', height: '70%' }} />
                </ActionIcon>
              </Tooltip>
            </Stack>
          </Box>
        </div>
      </ScrollArea>
    </Box>
  )
}
