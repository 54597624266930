import { Button, Card, Group, Stack, Text, ThemeIcon } from '@mantine/core'
import { IconPlus, IconTag } from '@tabler/icons-react'
import { FormattedMessage } from 'react-intl'

type AddLabelMessageProps = {
  onAddLabelClick: () => void
}

export const AddLabelMessage = ({ onAddLabelClick }: AddLabelMessageProps) => {
  return (
    <Card withBorder>
      <Stack mih={300} align="center" justify="center" gap="xl">
        <Group wrap="nowrap">
          <ThemeIcon variant="transparent" color="orange" size="lg">
            <IconTag style={{ width: '100%', height: '100%' }} />
          </ThemeIcon>

          <Text size="sm">
            <FormattedMessage id="annotation.getStarted" />
          </Text>
        </Group>

        <Button
          miw={160}
          leftSection={<IconPlus size={16} />}
          onClick={onAddLabelClick}
        >
          <FormattedMessage id="labels.add" />
        </Button>
      </Stack>
    </Card>
  )
}
