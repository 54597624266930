import {
  Box,
  Button,
  Card,
  Group,
  LoadingOverlay,
  Stack,
  Text,
  Tooltip
} from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import {
  IconCaretRightFilled,
  IconInfoCircle,
  IconRocket
} from '@tabler/icons-react'
import { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useDeploymentNavigation } from '@/components/deployments/deployment-editor/helpers/navigation'
import { HelpDialog } from '@/components/help-dialog/HelpDialog'
import { BaseModelContent } from '@/components/help-dialog/contents/BaseModelContent'
import { useApplicationContext } from '@/providers/ApplicationContext'
import { useModelContext } from '@/providers/ModelContext'
import { useUpdateModel } from '@/queries/modelQueries'
import { showToast } from '@/theme/notifications'
import { ModelType } from '@/types/model'
import { InferenceHandler } from './InferenceHandler/InferenceHandler'
import { MasterModelList } from './MasterModelList/MasterModelList'
import { PreTrainedModelList } from './PreTrainedModelList/PreTrainedModelList'
import { StepActionToolbar } from './StepActionToolbar/StepActionToolbar'
import { StepHeadline } from './StepHeadline/StepHeadline'
import { UserTrainedModelList } from './UserTrainedModelList/UserTrainedModelList'
import { useModelStepNavigation } from './useModelStepNavigation'

export const StepChooseBaseModel = () => {
  const { application } = useApplicationContext()
  const { model } = useModelContext()
  const intl = useIntl()

  const { goToModelsScreen, goToAnnotateDataScreen } = useModelStepNavigation({
    appId: application?.id || '',
    modelId: model?.id || ''
  })

  const { mutateAsync: updateModel, isPending } = useUpdateModel({
    appId: application?.id || '',
    modelId: model?.id || ''
  })

  const baseModel = model?.base_model || model?.root_model || null

  const [selectedModelId, setSelectedModelId] = useState<string>(
    baseModel?.id || ''
  )

  const [selectedModelType, setSelectedModelType] = useState<
    ModelType | undefined
  >(
    !baseModel
      ? undefined
      : 'is_master_model' in baseModel
        ? ModelType.LibraryMLModel
        : ModelType.UserMLModel
  )

  const [isHelpDialogOpen, { open: openHelpDialog, close: closeHelpDialog }] =
    useDisclosure(false)

  const { navigateToModelDeployment } = useDeploymentNavigation()

  const goBack = () => {
    goToModelsScreen()
  }

  const handleNext = async () => {
    try {
      await updateModel({
        appId: application?.id,
        modelId: model?.id,
        data: {
          base_model_id: selectedModelId,
          base_model_type: selectedModelType
        }
      })

      void goToAnnotateDataScreen()
    } catch (err) {
      showToast(
        intl.formatMessage({ id: 'models.updateModelApiError' }),
        'error'
      )
    }
  }

  const handleDeployClick = async () => {
    try {
      await updateModel({
        appId: application?.id,
        modelId: model?.id,
        data: {
          base_model_id: selectedModelId,
          base_model_type: selectedModelType
        }
      })

      navigateToModelDeployment(application?.id || '', model?.id || '')
    } catch (err) {
      showToast(
        intl.formatMessage({ id: 'models.updateModelApiError' }),
        'error'
      )
    }
  }

  const handleSelection = (modelId: string, modelType: ModelType) => {
    setSelectedModelId(modelId)
    setSelectedModelType(modelType)
  }

  const disableButtons = !selectedModelId && !selectedModelType
  return (
    <>
      <LoadingOverlay visible={isPending} />

      <StepHeadline
        title={<FormattedMessage id="models.stepper.step1" />}
        showInfoIcon
        onBackClick={goBack}
        onInfoClick={openHelpDialog}
      />

      <Box mt="xl">
        <StepActionToolbar
          leftSection={
            !disableButtons && (
              <Group maw={620} gap="xs" align="start" wrap="nowrap">
                <IconInfoCircle size={20} color="var(--mantine-color-blue-8)" />

                <Text size="xs">
                  <FormattedMessage id="models.test.hint" />
                </Text>
              </Group>
            )
          }
          rightSection={
            <Group>
              <InferenceHandler
                appId={application?.id || ''}
                disable={disableButtons}
                modelId={selectedModelId}
                modelType={selectedModelType}
              />

              <Button
                miw={160}
                size="xs"
                radius="xl"
                leftSection={<IconRocket size={16} />}
                disabled={disableButtons}
                onClick={() => void handleDeployClick()}
              >
                <FormattedMessage id="deployments.deployModel" />
              </Button>

              <Tooltip
                disabled={disableButtons}
                label={<FormattedMessage id="models.startLabeling" />}
              >
                <Button
                  miw={160}
                  size="xs"
                  radius="xl"
                  color="green.8"
                  rightSection={<IconCaretRightFilled size={16} />}
                  disabled={disableButtons}
                  onClick={() => void handleNext()}
                >
                  <FormattedMessage id="continue" />
                </Button>
              </Tooltip>
            </Group>
          }
        />
      </Box>

      <Stack mt="xl">
        <Text size="md" fw="bold">
          <FormattedMessage id="models.test.startFromScratch" />
        </Text>

        <Card bg="gray.0">
          <MasterModelList
            selectedModelId={selectedModelId}
            selectedModelType={selectedModelType}
            onSelectionChange={handleSelection}
          />
        </Card>
      </Stack>

      <Stack mt="xl">
        <Text size="md" fw="bold">
          <FormattedMessage id="models.test.preTrained" />
        </Text>

        <Card bg="gray.0">
          <PreTrainedModelList
            selectedModelId={selectedModelId}
            selectedModelType={selectedModelType}
            onSelectionChange={handleSelection}
          />
        </Card>
      </Stack>

      <Stack mt="xl">
        <Text size="md" fw="bold">
          <FormattedMessage id="models.ownModels" />
        </Text>

        <Card bg="gray.0">
          <UserTrainedModelList
            applicationId={application?.id || ''}
            selectedModelId={selectedModelId}
            selectedModelType={selectedModelType}
            onSelectionChange={handleSelection}
          />
        </Card>
      </Stack>

      <HelpDialog
        isOpen={isHelpDialogOpen}
        title={intl.formatMessage({ id: 'models.stepper.step1' })}
        onClose={closeHelpDialog}
      >
        <BaseModelContent />
      </HelpDialog>
    </>
  )
}
