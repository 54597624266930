import { Box, LoadingOverlay } from '@mantine/core'
import { FormattedMessage } from 'react-intl'
import { useSearchParams } from 'react-router-dom'
import { useApplicationContext } from '@/providers/ApplicationContext'
import { useGetDeployments } from '@/queries/deploymentQueries'
import { useGetMultipleDeviceDetails } from '@/queries/deviceQueries'
import { useGetModelDetails } from '@/queries/modelQueries'
import { ErrorWithReload } from '../../ui-shared/ErrorWithReload/ErrorWithReload'
import { DeploymentHandler } from './DeploymentHandler'
import { buildSavedEventEndpoints } from './helpers/buildSavedEventEndpoints'
import { buildSavedStreamLogic } from './helpers/buildSavedStreamLogic'
import { getCameraStreamsFromDevice } from './helpers/getCameraStreamsFromDevice'
import { STEP_QUERY_PARAM } from './helpers/navigation'
import { DeploymentSteps } from './types'

const useGetDeploymentsAndDevices = (applicationId: string) => {
  const {
    data: deploymentsData,
    isError: isDeploymentsError,
    isLoading: isDeploymentsLoading,
    refetch: refetchDeployments
  } = useGetDeployments(applicationId)

  const deployments =
    deploymentsData?.pages.flatMap((page) => page.results) ?? []

  const uniqueDeviceIds = [
    ...new Set(deployments.map((d) => d.settings_payload.device_id))
  ]

  const {
    data: devicesData,
    isError: isDevicesError,
    isLoading: isDevicesLoading
  } = useGetMultipleDeviceDetails(uniqueDeviceIds)
  const devices = devicesData || []

  return {
    deployments,
    devices,
    isError: isDeploymentsError || isDevicesError,
    isLoading: isDeploymentsLoading || isDevicesLoading,
    refetch: refetchDeployments
  }
}

export const EditDeploymentPage = () => {
  const { application } = useApplicationContext()

  const [searchParams] = useSearchParams()

  const {
    deployments,
    devices,
    isError: isDeploymentsAndDevicesError,
    isLoading: isDeploymentsAndDevicesLoading,
    refetch
  } = useGetDeploymentsAndDevices(application?.id || '')

  const stepParamValue = searchParams.get(STEP_QUERY_PARAM)

  const initialStep = stepParamValue
    ? parseInt(stepParamValue)
    : DeploymentSteps.ModelSelection

  const initialModelId = deployments[0]?.user_ml_model.id
  const initialDeviceIds = devices.map((d) => d.id)
  const cameraStreamIds = deployments.map((d) => d.settings_payload.stream_id)

  const {
    data: initialModel,
    isError: isModelError,
    isLoading: isModelLoading
  } = useGetModelDetails({
    appId: application?.id || '',
    modelId: initialModelId || '',
    enabled: !!initialModelId
  })

  const initialCameraStreams = devices
    .map(getCameraStreamsFromDevice)
    .flat()
    .filter((stream) => cameraStreamIds.includes(stream.camera_id))

  const initialStreamLogics = buildSavedStreamLogic(
    deployments,
    initialCameraStreams
  )

  const initialEventEndpoints = buildSavedEventEndpoints(deployments)

  const initialInferenceStreamIds = deployments
    .filter((d) => d.settings_payload.eglsink)
    .map((d) => d.settings_payload.stream_id)

  const isEditMode = deployments.length > 0

  const isLoading = isDeploymentsAndDevicesLoading || isModelLoading
  const isError = isDeploymentsAndDevicesError || isModelError

  if (isError) {
    return (
      <ErrorWithReload onReload={() => void refetch()}>
        <FormattedMessage id="deployments.details.fetchError" />
      </ErrorWithReload>
    )
  }

  if (isLoading) {
    return (
      <Box pos="relative" mih={120}>
        <LoadingOverlay visible />
      </Box>
    )
  }

  return (
    <>
      <DeploymentHandler
        mode={isEditMode ? 'edit' : 'new'}
        {...(isEditMode && {
          initialStep,
          initialModel,
          initialDeviceIds,
          initialCameraStreams,
          initialStreamLogics,
          initialEventEndpoints,
          initialInferenceStreamIds
        })}
      />
    </>
  )
}
