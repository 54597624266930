import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { BrowserRouter as Router } from 'react-router-dom'
import { CsrfHandler } from './components/csrf/CsrfHandler'
import { ErrorBoundary } from './components/errors/ErrorBoundary'
import { REACT_QUERY_DEVTOOLS_ENABLED } from './config'
import { I18nProvider } from './i18n/I18nProvider'
import { Routes } from './router/Routes'
import { SessionProvider } from './session/SessionContext'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false // disable refetch on window focus globally, can be overridden in individual queries
    }
  }
})

export const App = () => {
  const showReactQueryDevtools = REACT_QUERY_DEVTOOLS_ENABLED === 'true'

  return (
    <Router>
      <I18nProvider>
        <ErrorBoundary>
          <QueryClientProvider client={queryClient}>
            {showReactQueryDevtools && <ReactQueryDevtools />}
            <CsrfHandler>
              <SessionProvider>
                <Routes />
              </SessionProvider>
            </CsrfHandler>
          </QueryClientProvider>
        </ErrorBoundary>
      </I18nProvider>
    </Router>
  )
}
