import { EventDestination, EventEndpoint } from '@/types/deployment'
import { CameraSource, CameraStreamWithDeviceId } from '@/types/device'
import { SavedEventEndpoint } from '../IntegrationSelection/IntegrationSelection'

export const buildEventEndpoints = (
  eventEndponts: SavedEventEndpoint[],
  cameraStream: CameraStreamWithDeviceId
): EventEndpoint[] => {
  return eventEndponts
    .filter((eventEndpoint) => {
      return eventEndpoint.service === EventDestination.Milestone &&
        cameraStream.source !== CameraSource.Milestone
        ? false
        : true
    })
    .map((eventEndpoint) => {
      if (eventEndpoint.service === EventDestination.Milestone) {
        return {
          service: EventDestination.Milestone,
          endpoint: eventEndpoint.endpoint,
          attributes: {
            milestone_camera_id:
              cameraStream.attributes?.milestone_camera_id || '',
            milestone_stream_ids:
              cameraStream.attributes?.milestone_stream_ids || []
          }
        }
      }

      return {
        service: eventEndpoint.service
      }
    })
}
