import { Box, Card, Flex, Skeleton } from '@mantine/core'
import { CARD_HEIGHT } from './SubscriptionPlanCard'

export const SubscriptionPlanCardSkeleton = () => {
  return (
    <Card h={CARD_HEIGHT} padding="lg" radius="md" withBorder>
      <Flex h="100%" direction="column" justify="space-between">
        <Box>
          <Skeleton height={30} width={200} radius="xs" mb="xl" />
          <Skeleton height={24} width={140} radius="xs" mb="xl" />
          <Skeleton height={12} width={220} radius="xs" mb="lg" />
          <Skeleton height={12} width={220} radius="xs" mb="lg" />
          <Skeleton height={12} width={220} radius="xs" mb="lg" />
        </Box>

        <Skeleton height={40} radius="sm" />
      </Flex>
    </Card>
  )
}
