import { Group, Text, ThemeIcon } from '@mantine/core'
import { IconCheck, IconX } from '@tabler/icons-react'
import { FormattedMessage } from 'react-intl'
import { UrlStatus } from './ApiEndpointInput'

type UrlStatusIndicatorProps = {
  urlStatus: UrlStatus
}

export const UrlStatusIndicator = ({ urlStatus }: UrlStatusIndicatorProps) => {
  if (urlStatus === 'none') {
    return null
  }

  if (urlStatus === 'ok') {
    return (
      <Group wrap="nowrap" gap="xs">
        <ThemeIcon color="green" radius="xl" size={16}>
          <IconCheck style={{ width: '80%', height: '80%' }} />
        </ThemeIcon>

        <Text c="green" size="xs">
          <FormattedMessage id="integrations.testUrl.ok" />
        </Text>
      </Group>
    )
  }

  return (
    <Group wrap="nowrap" gap="xs">
      <ThemeIcon color="red" radius="xl" size={16}>
        <IconX style={{ width: '80%', height: '80%' }} />
      </ThemeIcon>

      <Text c="red" size="xs">
        <FormattedMessage id="integrations.testUrl.error" />
      </Text>
    </Group>
  )
}
