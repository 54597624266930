import {
  Badge,
  Box,
  Flex,
  Grid,
  Group,
  HoverCard,
  Image,
  ScrollArea,
  Stack,
  Text
} from '@mantine/core'
import { useIntersection } from '@mantine/hooks'
import { IconBolt, IconInfoCircle } from '@tabler/icons-react'
import { useRef } from 'react'
import { FormattedMessage } from 'react-intl'
import ImagePlaceholder from '@/assets/image-placeholder.png'
import { LabelGroup } from '@/components/labels/LabelGroup/LabelGroup'
import { SelectableCard } from '@/components/ui-shared/SelectableCard/SelectableCard'
import { Label } from '@/types/label'
import { LibraryMLModel, UserMLModel } from '@/types/model'

type BaseModelHoveredInfoProps = {
  name: string
  labels: Label[]
  baseModelName?: string
  brandName?: string
  brandLogo?: string
  networkType?: string
}

export const BaseModelHoverInfo = ({
  name,
  labels,
  baseModelName,
  brandName,
  brandLogo,
  networkType
}: BaseModelHoveredInfoProps) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const { ref, entry } = useIntersection({
    root: containerRef.current,
    threshold: 1
  })

  const showLabelInfo = brandName || networkType
  return (
    <HoverCard width={350} shadow="lg">
      <HoverCard.Target>
        <IconInfoCircle size={20} />
      </HoverCard.Target>

      <HoverCard.Dropdown
        bg="gray.0"
        styles={{
          dropdown: {
            border: '1px solid var(--mantine-color-gray-5)'
          }
        }}
      >
        <Stack gap="sm">
          <Grid>
            <Grid.Col span={10}>
              <Flex align="center" h="100%">
                <Stack gap={0}>
                  <Text size="sm" fw="bold">
                    {name}
                  </Text>
                  {baseModelName && (
                    <Group>
                      <Text c="dimmed" size="xs" fw={600}>
                        <FormattedMessage id="models.basedOn" />:
                      </Text>
                      <Text size="xs" fw="light">
                        {baseModelName}
                      </Text>
                    </Group>
                  )}
                </Stack>
              </Flex>
            </Grid.Col>
            <Grid.Col span={2}>
              <Flex justify="flex-end">
                {brandLogo && (
                  <Image
                    h={40}
                    w="auto"
                    src={brandLogo}
                    fallbackSrc={ImagePlaceholder}
                    alt={brandName}
                  />
                )}
              </Flex>
            </Grid.Col>
          </Grid>

          {showLabelInfo && (
            <Stack gap={2}>
              {networkType && (
                <Flex>
                  <Group>
                    <Text c="dimmed" size="xs" fw={600}>
                      <FormattedMessage id="models.networkType" />:
                    </Text>
                    <Text size="xs">{networkType}</Text>
                  </Group>
                </Flex>
              )}
              {brandName && (
                <Flex>
                  <Group>
                    <Text c="dimmed" size="xs" fw={600}>
                      <FormattedMessage id="models.brand" />:
                    </Text>
                    <Text size="xs">{brandName}</Text>
                  </Group>
                </Flex>
              )}
            </Stack>
          )}

          {labels.length > 0 && (
            <ScrollArea
              ref={containerRef}
              h={entry?.isIntersecting ? 'auto' : 160}
              mb={5}
            >
              <Box ref={ref}>
                <LabelGroup labels={labels} />
              </Box>
            </ScrollArea>
          )}
        </Stack>
      </HoverCard.Dropdown>
    </HoverCard>
  )
}

type BaseModelCardProps = {
  model: UserMLModel | LibraryMLModel
  baseModelName?: string
  brandLogo?: string
  brandName?: string
  networkType?: string
  trainable?: boolean
  testable?: boolean
  deployable?: boolean
  isSelected: boolean
  isMoreInfoShown?: boolean
  disabled?: boolean
  isComingSoon?: boolean
  showBadges?: boolean
  showBrandLogo?: boolean
  onClick: () => void
}

export const BaseModelCard = ({
  model,
  baseModelName,
  brandLogo,
  brandName,
  networkType,
  trainable,
  testable,
  deployable,
  isSelected,
  isMoreInfoShown,
  disabled,
  isComingSoon,
  showBadges = false,
  showBrandLogo = false,
  onClick
}: BaseModelCardProps) => {
  return (
    <SelectableCard
      isSelected={isSelected}
      disabled={disabled}
      onClick={onClick}
    >
      <Stack h="100%" align="center" justify="center">
        {showBrandLogo && brandLogo ? (
          <Image
            h={32}
            w="auto"
            src={brandLogo}
            fallbackSrc={ImagePlaceholder}
            alt={brandName || undefined}
          />
        ) : (
          <IconBolt size={28} />
        )}

        <Text w="100%" ta="center" size="sm" fw="bold" truncate>
          {model?.name}
        </Text>

        {showBadges && (
          <Group gap={2}>
            {trainable && (
              <Badge color="brand-primary.3" size="xs" variant="light">
                <FormattedMessage id="models.trainable" />
              </Badge>
            )}
            {testable && (
              <Badge color="brand-primary.3" size="xs" variant="light">
                <FormattedMessage id="models.testable" />
              </Badge>
            )}
            {deployable && (
              <Badge color="brand-primary.3" size="xs" variant="light">
                <FormattedMessage id="models.deployable" />
              </Badge>
            )}
          </Group>
        )}
      </Stack>

      {isMoreInfoShown && (
        <Box pos="absolute" top={2} right={2}>
          <BaseModelHoverInfo
            name={model?.name}
            labels={model?.labels || []}
            baseModelName={baseModelName}
            brandName={brandName || undefined}
            brandLogo={brandLogo || undefined}
            networkType={networkType || undefined}
          />
        </Box>
      )}

      {isComingSoon && (
        <Badge
          color="brand-secondary.4"
          size="xs"
          pos="absolute"
          top={4}
          left={4}
        >
          <FormattedMessage id="comingSoon" />
        </Badge>
      )}
    </SelectableCard>
  )
}
