import { Box } from '@mantine/core'
import { useState } from 'react'
import { ImportCameraData } from '@/types/device'
import { CameraSelectionHandler } from './CameraSelectionHandler/CameraSelectionHandler'
import { ImportProgressHandler } from './ImportProgressHandler/ImportProgressHandler'
import { MilestoneHeader } from './MilestoneHeader/MilestoneHeader'
import { MilestoneUrlHandler } from './MilestoneUrlHandler/MilestoneUrlHandler'

type Step = 'url_validation' | 'stream_selection' | 'import_progress'

export const MilestoneImportHandler = () => {
  const [step, setStep] = useState<Step>('url_validation')
  const [deviceId, setDeviceId] = useState<string | null>(null)
  const [cameraStreams, setCameraStreams] = useState<ImportCameraData[]>([])

  const handleUrlSubmit = (deviceId: string, cameras: ImportCameraData[]) => {
    setDeviceId(deviceId)
    setCameraStreams(cameras)
    setStep('stream_selection')
  }

  const handleImportStart = () => {
    setStep('import_progress')
  }

  return (
    <>
      <Box my="xl">
        <MilestoneHeader />
      </Box>

      {step === 'url_validation' && (
        <MilestoneUrlHandler onSubmit={handleUrlSubmit} />
      )}

      {deviceId && step === 'stream_selection' && (
        <CameraSelectionHandler
          deviceId={deviceId}
          cameraStreams={cameraStreams}
          onImportStart={handleImportStart}
          onBack={() => setStep('url_validation')}
        />
      )}

      {deviceId && step === 'import_progress' && (
        <ImportProgressHandler
          deviceId={deviceId}
          onBack={() => setStep('url_validation')}
        />
      )}
    </>
  )
}
