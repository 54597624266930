import { ActionIcon, Title } from '@mantine/core'
import { IconQuestionMark } from '@tabler/icons-react'
import { ReactNode } from 'react'

type PageTitleProps = {
  title: ReactNode | string
  showHelper?: boolean
  onHelpClick?: () => void
}

export const PageTitle = ({
  title,
  showHelper = false,
  onHelpClick
}: PageTitleProps) => {
  return (
    <Title order={2}>
      {title}
      {showHelper && (
        <ActionIcon
          variant="light"
          size="sm"
          radius="xl"
          ml="sm"
          onClick={onHelpClick}
        >
          <IconQuestionMark style={{ width: '90%', height: '90%' }} />
        </ActionIcon>
      )}
    </Title>
  )
}
