import { Alert, Box, Button, Group, Text } from '@mantine/core'
import { useForm } from '@mantine/form'
import { IconLock } from '@tabler/icons-react'
import { FormattedMessage, useIntl } from 'react-intl'
import { LabelOption, LabelsInput } from '../LabelsInput/LabelsInput'

export type FormValues = {
  labels: LabelOption[]
}

type LabelSelectFormProps = {
  initialValues: LabelOption[]
  options: LabelOption[]
  onCancel: () => void
  onSubmit: (values: FormValues) => void
}

export const LabelSelectForm = ({
  initialValues,
  options,
  onCancel,
  onSubmit
}: LabelSelectFormProps) => {
  const intl = useIntl()

  const form = useForm<FormValues>({
    initialValues: {
      labels: initialValues
    },

    validate: {
      labels: (value) =>
        value.length === 0
          ? intl.formatMessage({
              id: 'labels.validation.selectAtLeastOneLabel'
            })
          : null
    }
  })

  const handleLabelChange = (values: LabelOption[]) => {
    form.setFieldValue('labels', values)
  }

  return (
    <form noValidate onSubmit={form.onSubmit((values) => onSubmit(values))}>
      <Text size="sm" my="lg">
        <FormattedMessage id="labels.addLabelInfo" />
      </Text>

      <Alert variant="light" color="yellow.7" mb="xl" icon={<IconLock />}>
        <Text size="xs">
          <FormattedMessage id="labels.labelRemovalWarning" />
        </Text>
      </Alert>

      <Box mb={260}>
        <LabelsInput
          label={intl.formatMessage({ id: 'labels' })}
          values={form.values.labels}
          options={options}
          error={form.errors.labels}
          onChange={handleLabelChange}
        />
      </Box>

      <Group justify="end">
        <Button miw={120} variant="default" onClick={onCancel}>
          <FormattedMessage id="cancel" />
        </Button>

        <Button type="submit" miw={120}>
          <FormattedMessage id="save" />
        </Button>
      </Group>
    </form>
  )
}
