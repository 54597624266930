import { Badge } from '@mantine/core'
import { CameraSource } from '@/types/device'

const messageMap = {
  [CameraSource.Milestone]: {
    name: 'Milestone',
    color: 'blue'
  }
}

type CameraSourceChipProps = {
  source: CameraSource
}

export const CameraSourceChip = ({ source }: CameraSourceChipProps) => {
  const settings = messageMap[source as keyof typeof messageMap]

  if (settings) {
    return (
      <Badge size="xs" color={settings.color}>
        {settings.name}
      </Badge>
    )
  }

  return null
}
