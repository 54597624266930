import { Box, Button, Card, Flex, Text, Title } from '@mantine/core'
import { ReactNode } from 'react'
import { FormattedMessage } from 'react-intl'
import { FormattedPrice } from '@/components/subscriptions/FormattedPrice/FormattedPrice'
import { BillingPeriod } from '@/types/subscription'
import { FeatureList } from './FeatureList'

export const CARD_HEIGHT = 420

type SubscriptionPlanCardProps = {
  title: string | ReactNode
  description: string
  price: number
  currency: string
  billingPeriod: BillingPeriod
  features: string[]
  onSelect: () => void
}

export const SubscriptionPlanCard = ({
  title,
  description,
  price,
  currency,
  billingPeriod,
  features,
  onSelect
}: SubscriptionPlanCardProps) => {
  return (
    <Card h={CARD_HEIGHT} padding="lg" radius="md" withBorder>
      <Flex h="100%" direction="column" justify="space-between">
        <Box>
          <Title order={3} mb="lg">
            {title}
          </Title>

          <Box mb="xl">
            <FormattedPrice
              price={price}
              currency={currency}
              billingPeriod={billingPeriod}
            />
          </Box>

          <Text size="sm" mb="xl">
            {description}
          </Text>

          <FeatureList features={features} />
        </Box>

        <Button size="md" radius="md" fullWidth onClick={onSelect}>
          <FormattedMessage id="select" />
        </Button>
      </Flex>
    </Card>
  )
}
