import { Stack, Text } from '@mantine/core'
import { IconCpu2 } from '@tabler/icons-react'
import { SelectableCard } from '../../../../ui-shared/SelectableCard/SelectableCard'
import { OnlineStatus } from '../../../OnlineStatus/OnlineStatus'

type DeviceCardProps = {
  name: string
  isActive: boolean
  isSelected?: boolean
  disabled?: boolean
  onClick?: () => void
}

export const DeviceCard = ({
  name,
  isActive,
  isSelected,
  disabled,
  onClick
}: DeviceCardProps) => {
  return (
    <SelectableCard
      isSelected={isSelected}
      disabled={disabled}
      onClick={onClick}
    >
      <Stack h="100%" align="center" justify="space-between">
        <IconCpu2 size={24} />

        <Text w="100%" ta="center" size="sm" fw={600} lineClamp={2}>
          {name}
        </Text>

        <OnlineStatus status={isActive ? 'online' : 'offline'} />
      </Stack>
    </SelectableCard>
  )
}
