const rtspRegex =
  /^rtsp:\/\/(?:[^/:@]+(?::[^/:@]*)?@)?[^/:@]+(?::[0-9]+)?(?:\/.*)?$/

const usbRegex = /^\/dev\/video[0-9]+$/

export function isValidStreamInput(input: string) {
  // Regular expression to validate RTSP URLs

  return rtspRegex.test(input) || usbRegex.test(input)
}
