import { Badge, rem } from '@mantine/core'
import { FormattedMessage } from 'react-intl'
import { DatasetVersionStatus } from '@/types/dataset'

const DATASET_STATUS_CONFIG = {
  [DatasetVersionStatus.Draft]: {
    color: 'gray',
    text: 'datasets.status.draft'
  },
  [DatasetVersionStatus.Completed]: {
    color: 'green',
    text: 'datasets.status.completed'
  }
}

type DatasetStatusChipProps = {
  status: DatasetVersionStatus
}

export const DatasetStatusChip = ({ status }: DatasetStatusChipProps) => {
  const config = DATASET_STATUS_CONFIG[status]

  if (!config) {
    return null
  }

  return (
    <Badge
      size="xs"
      color={config.color}
      styles={{
        label: {
          userSelect: 'none',
          fontSize: rem(8)
        }
      }}
    >
      <FormattedMessage id={config.text} />
    </Badge>
  )
}
