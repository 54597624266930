import { CameraStream, CameraStreamWithDeviceId, Device } from '@/types/device'

export const getCameraStreamsFromDevice = (
  device: Device
): CameraStreamWithDeviceId[] => {
  const streamAttrs = device.attributes.filter((attr) => attr.key === 'cameras')

  const streams = (streamAttrs[0]?.value || []) as CameraStream[]

  return streams.map((stream) => ({
    ...stream,
    device_id: device.id,
    device_name: device.label,
    is_device_active: device.active
  }))
}
