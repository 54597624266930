import { useCallback } from 'react'
import { createSearchParams, useNavigate } from 'react-router-dom'
import { ApplicationNestedPath, buildAppLink } from '@/router/paths'
import { DeploymentSteps } from '../types'

export const MODEL_ID_QUERY_PARAM = 'modelId'
export const STEP_QUERY_PARAM = 'step'

export const useDeploymentNavigation = () => {
  const navigate = useNavigate()

  const navigateToModelDeployment = useCallback(
    (appId: string, modelId: string) => {
      const queryParams = createSearchParams({
        [MODEL_ID_QUERY_PARAM]: modelId
      }).toString()

      navigate({
        pathname: buildAppLink(appId, ApplicationNestedPath.createDeployment),
        search: queryParams
      })
    },
    [navigate]
  )

  const navigateToDeploymentStep = useCallback(
    (appId: string, step: DeploymentSteps) => {
      const queryParams = createSearchParams({
        [STEP_QUERY_PARAM]: step.toString()
      }).toString()

      navigate({
        pathname: buildAppLink(appId, ApplicationNestedPath.createDeployment),
        search: queryParams
      })
    },
    [navigate]
  )

  return { navigateToModelDeployment, navigateToDeploymentStep }
}
