import { Progress, Text } from '@mantine/core'
import { FormattedMessage } from 'react-intl'

type UploadProgressIndicatorProps = {
  filesUploaded: number
  filesTotal: number
}
export const UploadProgressIndicator = ({
  filesUploaded,
  filesTotal
}: UploadProgressIndicatorProps) => {
  const progressValue = (filesUploaded / filesTotal) * 100

  return (
    <>
      <Text size="sm">
        <FormattedMessage
          id="datasets.upload.filesUploadedCount"
          values={{ count: `${filesUploaded}/${filesTotal}` }}
        />
      </Text>

      <Progress value={progressValue} />
    </>
  )
}
