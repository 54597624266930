import { Box, LoadingOverlay } from '@mantine/core'
import { FileRejection } from '@mantine/dropzone'
import { useDisclosure, useViewportSize } from '@mantine/hooks'
import { useEffect, useRef, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { getApiError } from '@/api/helpers/apiError'
import { getAnnotationPoints } from '@/components/annotations/helpers/getAnnotationPoints'
import { ConfirmUploadModal } from '@/components/datasets/UploadableMediaList/ConfirmUploadModal/ConfirmUploadModal'
import { UploadProgressModal } from '@/components/datasets/UploadableMediaList/UploadProgressModal/UploadProgressModal'
import { LabelSelectionModal } from '@/components/labels/LabelSelectionModal/LabelSelectionModal'
import { TrainingDrawer } from '@/components/trainings/TrainingDrawer/TrainingDrawer'
import { TrainingHandler } from '@/components/trainings/TrainingHandler'
import { ErrorWithReload } from '@/components/ui-shared/ErrorWithReload/ErrorWithReload'
import { MIN_ANNOTATION_POINTS } from '@/config/annotation'
import {
  useCreateDataset,
  useGetDatasets,
  useGetUploadStatus,
  useUpdateDataset
} from '@/queries/datasetLegacyQueries'
import { showToast } from '@/theme/notifications'
import { UserMLModel } from '@/types/model'
import { UploadDropzone } from '../../datasets/UploadDropzone/UploadDropzone'
import { MediaList } from '../MediaList/MediaList'
import { UploadToolbar } from './UploadToolbar/UploadToolbar'
import { useInvalidateFileList, useMediaListStatus } from './hooks'

const DEFAULT_DATASET_NAME = 'default dataset'

type UploadStatus = 'idle' | 'pending'

type UploadHandlerProps = {
  applicationId: string
  model: UserMLModel
}

export const UploadHandler = ({ applicationId, model }: UploadHandlerProps) => {
  const [acceptedFiles, setAcceptedFiles] = useState<File[]>([])
  const [fileRejections, setFileRejections] = useState<FileRejection[]>([])
  const [uploadStatus, setUploadStatus] = useState<UploadStatus>('idle')
  const [createdDatasetId, setCreatedDatasetId] = useState<string>('')
  const [batchId, setBatchId] = useState<string>('')

  const [
    isConfirmModalOpened,
    { open: openConfirmModal, close: closeConfirmModal }
  ] = useDisclosure(false)

  const [
    isProcessingModalOpened,
    { open: openProcessingModal, close: closeProcessingModal }
  ] = useDisclosure(false)

  const [
    isLabelSelectionModalOpened,
    { open: openLabelSelectionModal, close: closeLabelSelectionModal }
  ] = useDisclosure(false)

  const [
    isTrainingDrawerOpened,
    { open: openTrainingDrawer, close: closeTrainingDrawer }
  ] = useDisclosure(false)

  const intl = useIntl()
  const openRef = useRef<() => void>(null)

  const { height } = useViewportSize()

  const { data, isFetching, isError, refetch } = useGetDatasets(applicationId)
  const { mutateAsync: updateDataset, isPending: isUpdatePending } =
    useUpdateDataset()
  const { mutateAsync: createDataset, isPending: isCreatePending } =
    useCreateDataset()

  const datasets = data?.pages?.flatMap((page) => page.results) || []

  const datasetId = datasets[0]?.id || createdDatasetId

  const isPending = isUpdatePending || isCreatePending

  const { data: uploadStatusData } = useGetUploadStatus({
    batchId,
    enabled: Boolean(batchId) && uploadStatus === 'pending'
  })

  const { invalidateFileList } = useInvalidateFileList(datasetId)

  const { isMediaListEmpty, annotatedImagesCount, annotatedVideosCount } =
    useMediaListStatus(datasetId)

  const isTrainingButtonDisabled =
    getAnnotationPoints(annotatedImagesCount, annotatedVideosCount) <
    MIN_ANNOTATION_POINTS

  const isUploadFinished =
    uploadStatusData?.status === 'Complete' ||
    uploadStatusData?.status === 'Failed'

  useEffect(() => {
    if (
      uploadStatusData?.uploaded_files !== 0 &&
      uploadStatusData?.status === 'Pending'
    ) {
      // Refetch file list as more files were uploaded
      invalidateFileList()
    }

    if (isUploadFinished) {
      invalidateFileList()
      setUploadStatus('idle')

      if (uploadStatusData?.failed_files > 0) {
        openProcessingModal()

        showToast(
          intl.formatMessage(
            { id: 'datasets.upload.filesPartiallyUploaded' },
            { count: uploadStatusData?.failed_files }
          ),
          'error'
        )
      } else {
        closeProcessingModal()

        showToast(
          intl.formatMessage({ id: 'datasets.upload.completed' }),
          'success'
        )
      }
    }
  }, [
    intl,
    uploadStatusData,
    isUploadFinished,
    openProcessingModal,
    closeProcessingModal,
    invalidateFileList
  ])

  const handleDrop = (files: File[]) => {
    setAcceptedFiles(files)
    openConfirmModal()
  }

  const handleReject = (fileRejections: FileRejection[]) => {
    setFileRejections(fileRejections)
    openConfirmModal()
  }

  const resetSelectedFiles = () => {
    setAcceptedFiles([])
    setFileRejections([])
  }

  const handleCloseConfirmModal = () => {
    closeConfirmModal()
    resetSelectedFiles()
  }

  const handleUploadButtonClick = () => {
    if (uploadStatus === 'pending') {
      openProcessingModal()
    } else {
      openRef.current?.()
    }
  }

  const handleMediaUpload = async () => {
    try {
      if (!datasetId) {
        const { data } = await createDataset({
          application_id: applicationId,
          name: `${applicationId} ${DEFAULT_DATASET_NAME}`,
          media_files: acceptedFiles
        })

        setCreatedDatasetId(data.id)
        setBatchId(data.batch_id)
      } else {
        const { data } = await updateDataset({
          datasetId,
          data: {
            media_files: acceptedFiles
          }
        })

        setBatchId(data.batch_id)
      }

      setUploadStatus('pending')
      handleCloseConfirmModal()
      openProcessingModal()
    } catch (error) {
      const { errorMessage } = getApiError(error)

      const message =
        errorMessage || intl.formatMessage({ id: 'datasets.upload.failed' })

      showToast(message, 'error')
    }
  }

  if (isFetching) {
    return (
      <Box pos="relative" mih={200}>
        <LoadingOverlay visible />
      </Box>
    )
  }

  if (isError) {
    return (
      <ErrorWithReload onReload={() => void refetch()}>
        <FormattedMessage id="datasets.data.error" />
      </ErrorWithReload>
    )
  }

  return (
    <>
      <Box mb="sm">
        <UploadToolbar
          isUploading={uploadStatus === 'pending'}
          isLabelButtonDisabled={uploadStatus === 'pending'}
          isTrainingButtonDisabled={isTrainingButtonDisabled}
          isAnnotationProgressBarShown={isTrainingButtonDisabled}
          annotatedImagesCount={annotatedImagesCount}
          annotatedVideosCount={annotatedVideosCount}
          onUploadClick={handleUploadButtonClick}
          onLabelClick={openLabelSelectionModal}
          onTrainClick={openTrainingDrawer}
        />
      </Box>

      <UploadDropzone
        openRef={openRef}
        activateOnClick={isMediaListEmpty}
        showInstructions={isMediaListEmpty}
        minHeight={height - 300}
        onDrop={handleDrop}
        onReject={handleReject}
      >
        {datasetId && <MediaList datasetId={datasetId} />}
      </UploadDropzone>

      <ConfirmUploadModal
        opened={isConfirmModalOpened}
        acceptedFiles={acceptedFiles}
        fileRejections={fileRejections}
        isLoading={isPending}
        isConfirmDisabled={isPending || acceptedFiles.length === 0}
        onClose={handleCloseConfirmModal}
        onConfirmUpload={() => void handleMediaUpload()}
      />

      <UploadProgressModal
        totalFiles={uploadStatusData?.total_files || 0}
        uploadedFies={uploadStatusData?.uploaded_files || 0}
        isCompleted={isUploadFinished}
        failedFileNames={uploadStatusData?.failed_file_names || []}
        opened={isProcessingModalOpened}
        onClose={closeProcessingModal}
      />

      <LabelSelectionModal
        applicationId={applicationId}
        opened={isLabelSelectionModalOpened}
        onClose={closeLabelSelectionModal}
      />

      <TrainingDrawer
        opened={isTrainingDrawerOpened}
        onClose={closeTrainingDrawer}
      >
        <TrainingHandler applicationId={applicationId} model={model} />
      </TrainingDrawer>
    </>
  )
}
