import { SimpleGrid } from '@mantine/core'
import { InfiniteScroll } from '@/components/ui-shared/InfiniteScroll/InfiniteScroll'
import { useGetLibraryMlModels } from '@/queries/modelQueries'
import { LibraryMLModel, ModelType } from '@/types/model'
import { BaseModelCard } from '../BaseModelCard/BaseModelCard'
import { ModelsErrorLoader } from '../ModelsErrorLoader/ModelsErrorLoader'

type MasterModelListProps = {
  selectedModelId: string
  selectedModelType: ModelType | undefined
  onSelectionChange: (modelId: string, type: ModelType) => void
}

export const MasterModelList = ({
  selectedModelId,
  selectedModelType,
  onSelectionChange
}: MasterModelListProps) => {
  const { data, isFetching, isError, refetch, fetchNextPage, hasNextPage } =
    useGetLibraryMlModels({
      is_master_model: true
    })

  const masterLibraryMlModels = (
    data?.pages?.flatMap((page) => page.results) || []
  ).sort((a, b) => Number(b.is_active) - Number(a.is_active)) // active models first

  if (isError) {
    return <ModelsErrorLoader handleOnReload={() => void refetch()} />
  }

  return (
    <InfiniteScroll
      hasMore={hasNextPage}
      isFetching={isFetching}
      onLoadMore={() => void fetchNextPage()}
    >
      <SimpleGrid
        cols={{ base: 1, sm: 2, lg: 4, xl: 6 }}
        spacing="lg"
        verticalSpacing="lg"
      >
        {masterLibraryMlModels.map((model: LibraryMLModel, idx) => {
          const isSelected =
            selectedModelId === model.id &&
            selectedModelType === ModelType.LibraryMLModel

          return (
            <BaseModelCard
              key={idx}
              model={model}
              brandLogo={model.brand_logo || undefined}
              brandName={model.brand_name || undefined}
              networkType={model.network_type}
              trainable={model.can_be_trained}
              testable={model.can_be_tested}
              deployable={model.can_be_deployed}
              disabled={!model.is_active}
              isComingSoon={!model.is_active}
              isMoreInfoShown={model.is_active}
              isSelected={isSelected}
              showBadges={
                model.can_be_deployed ||
                model.can_be_trained ||
                model.can_be_tested
              }
              showBrandLogo
              onClick={() =>
                onSelectionChange(model.id, ModelType.LibraryMLModel)
              }
            />
          )
        })}
      </SimpleGrid>
    </InfiniteScroll>
  )
}
