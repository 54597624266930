import { ActionIcon, Menu } from '@mantine/core'
import { IconDotsVertical, IconTrash } from '@tabler/icons-react'
import { MouseEventHandler } from 'react'
import { FormattedMessage } from 'react-intl'

type DatasetMenuProps = {
  onDelete: MouseEventHandler<HTMLButtonElement>
}

export const DatasetMenu = ({ onDelete }: DatasetMenuProps) => {
  return (
    <Menu shadow="md" width={200} position="bottom-start">
      <Menu.Target>
        <ActionIcon
          variant="subtle"
          radius="xl"
          size="sm"
          onClick={(e) => {
            e.stopPropagation()
          }}
        >
          <IconDotsVertical style={{ width: '80%', height: '80%' }} />
        </ActionIcon>
      </Menu.Target>

      <Menu.Dropdown>
        <Menu.Item
          color="red"
          leftSection={<IconTrash size={14} />}
          onClick={onDelete}
        >
          <FormattedMessage id="datasets.delete" />
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  )
}
