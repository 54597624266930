import { Anchor, Button, Text, TextInput, Title } from '@mantine/core'
import { isEmail, useForm } from '@mantine/form'

import { FormattedMessage, useIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import { RouterPath } from '@/router/paths'

export type FormValues = {
  email: string
}

type PasswordResetFormProps = {
  loginLink: RouterPath
  onSubmit: (formValues: FormValues) => void
}

const messages = {
  email: {
    id: 'email'
  },
  enterYourEmail: {
    id: 'validation.enterYourEmail'
  }
}

export const PasswordResetForm = ({
  loginLink,
  onSubmit
}: PasswordResetFormProps) => {
  const intl = useIntl()

  const form = useForm({
    initialValues: {
      email: ''
    },

    validate: {
      email: isEmail(intl.formatMessage(messages.enterYourEmail))
    }
  })

  return (
    <form noValidate onSubmit={form.onSubmit((values) => onSubmit(values))}>
      <Title order={2} mb="lg">
        <FormattedMessage id="passwordReset.resetYourPassword" />
      </Title>

      <Text size="sm" c="dimmed" mb="lg">
        <FormattedMessage id="passwordReset.enterYourEmail" />
      </Text>

      <TextInput
        type="email"
        label={intl.formatMessage(messages.email)}
        mb="lg"
        autoFocus
        {...form.getInputProps('email')}
      />

      <Button type="submit" mb="md" fullWidth>
        <FormattedMessage id="continue" />
      </Button>

      <Text size="xs" ta="center">
        <Anchor component={Link} to={loginLink}>
          <FormattedMessage id="returnToLogin" />
        </Anchor>
      </Text>
    </form>
  )
}
