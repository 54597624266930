import { Box, Button, Grid, Group, Text, ThemeIcon } from '@mantine/core'
import {
  IconCheck,
  IconChevronRight,
  IconPhotoCheck
} from '@tabler/icons-react'
import { FormattedMessage } from 'react-intl'

type DatasetListToolbarProps = {
  isDatasetSelected: boolean
  name?: string
  onContinue: () => void
}

export const DatasetListToolbar = ({
  isDatasetSelected,
  name,
  onContinue
}: DatasetListToolbarProps) => {
  return (
    <Box py="sm">
      <Grid align="center">
        <Grid.Col span="auto">
          <Group wrap="nowrap" gap="md" align="center">
            <ThemeIcon variant="light" size="md">
              <IconPhotoCheck style={{ width: '90%', height: '90%' }} />
            </ThemeIcon>

            <Text size="sm">
              <FormattedMessage id="datasets.selectDataset" />
            </Text>
          </Group>
        </Grid.Col>

        <Grid.Col span="content">
          <Group align="center" gap={40}>
            {isDatasetSelected ? (
              <Group wrap="nowrap" gap="xs" align="center">
                <ThemeIcon size={14} color="green" radius="xl">
                  <IconCheck
                    style={{
                      width: '80%',
                      height: '80%'
                    }}
                  />
                </ThemeIcon>

                <Text size="xs">
                  <FormattedMessage
                    id="datasets.selectedName"
                    values={{
                      name: <b>&quot;{name}&quot;</b>
                    }}
                  />
                </Text>
              </Group>
            ) : (
              <Text size="xs">
                <FormattedMessage id="datasets.notSelected" />
              </Text>
            )}

            <Button
              miw={180}
              color="green"
              disabled={!isDatasetSelected}
              rightSection={<IconChevronRight size={20} />}
              onClick={onContinue}
            >
              <FormattedMessage id="continue" />
            </Button>
          </Group>
        </Grid.Col>
      </Grid>
    </Box>
  )
}
