import { MantineSize, Text } from '@mantine/core'
import { FormattedMessage } from 'react-intl'
import { BillingPeriod } from '@/types/subscription'
import { formatPrice } from '@/utils/price'

type FormattedPriceProps = {
  size?: MantineSize
  price: number
  currency: string
  billingPeriod: BillingPeriod
}

export const FormattedPrice = ({
  size,
  price,
  currency,
  billingPeriod
}: FormattedPriceProps) => {
  return (
    <Text size={size}>
      <FormattedMessage
        id={billingPeriod === 'monthly' ? 'pricePerMonth' : 'pricePerYear'}
        values={{
          price: (
            <Text size={size} fw="bold" component="span">
              {formatPrice(price, currency)}
            </Text>
          )
        }}
      />
    </Text>
  )
}
