import { Box, Button, Grid, TextInput, TextInputProps } from '@mantine/core'
import { FormattedMessage } from 'react-intl'
import { UrlStatusIndicator } from './UrlStatusIndicator'

export type UrlStatus = 'ok' | 'error' | 'none'

type ApiEndpointInputProps = {
  inputProps: TextInputProps
  status: UrlStatus
  isTestButtonDisabled?: boolean
  isLoading?: boolean
  onTestUrlClick: () => void
}

export const ApiEndpointInput = ({
  inputProps,
  status,
  isTestButtonDisabled,
  isLoading,
  onTestUrlClick
}: ApiEndpointInputProps) => {
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && !isTestButtonDisabled && !isLoading) {
      onTestUrlClick()
    }
  }

  return (
    <Box>
      <TextInput
        {...inputProps}
        type="url"
        inputContainer={(children) => (
          <Grid gutter="xs">
            <Grid.Col span="auto">{children}</Grid.Col>

            <Grid.Col span="content">
              <Button
                size="sm"
                disabled={isTestButtonDisabled}
                loading={isLoading}
                onClick={onTestUrlClick}
              >
                <FormattedMessage id="integrations.testUrl" />
              </Button>
            </Grid.Col>
          </Grid>
        )}
        onKeyDown={handleKeyDown}
      />

      {status !== 'none' && (
        <Box mt="xs">
          <UrlStatusIndicator urlStatus={status} />
        </Box>
      )}
    </Box>
  )
}
