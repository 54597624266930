import { Anchor, Box, Text, Title } from '@mantine/core'
import { IconAlertTriangle } from '@tabler/icons-react'
import { FormattedMessage } from 'react-intl'

type LinkExpiredProps = {
  onResendEmail: () => void
  onTryAnotherEmail: () => void
}

export const LinkExpired = ({
  onResendEmail,
  onTryAnotherEmail
}: LinkExpiredProps) => {
  return (
    <Box ta="center">
      <Title order={3}>
        <FormattedMessage id="verifyEmail.linkExpired" />
      </Title>

      <Box my="xl">
        <IconAlertTriangle size={48} color="var(--mantine-color-yellow-8)" />
      </Box>

      <Text size="sm">
        <FormattedMessage
          id="verifyEmail.linkExpiredInstructions"
          values={{
            resend: (chunks) => (
              <Anchor onClick={onResendEmail}>{chunks}</Anchor>
            ),
            signup: (chunks) => (
              <Anchor onClick={onTryAnotherEmail}>{chunks}</Anchor>
            )
          }}
        />
      </Text>
    </Box>
  )
}
