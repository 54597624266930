import { Button, SegmentedControl, Stack, Text, TextInput } from '@mantine/core'
import { useForm } from '@mantine/form'
import { FormattedMessage, useIntl } from 'react-intl'

enum WorkspaceType {
  Organization = 'Organization',
  Personal = 'Personal'
}

type FormValues = {
  workspaceType: WorkspaceType
  workspaceName: string
}

type CreateWorkspaceFormProps = {
  onSubmit: (formValues: FormValues) => void
}

const alphanumericRegex = /^[a-zA-Z0-9\s]*$/

export const CreateWorkspaceForm = ({ onSubmit }: CreateWorkspaceFormProps) => {
  const intl = useIntl()

  const form = useForm({
    initialValues: {
      workspaceType: WorkspaceType.Personal,
      workspaceName: ''
    },

    validate: {
      workspaceName: (value) => {
        if (value.length === 0) {
          return intl.formatMessage({ id: 'workspace.createForm.nameRequired' })
        }

        if (!alphanumericRegex.test(value)) {
          return intl.formatMessage({ id: 'workspace.createForm.invalidName' })
        }
      }
    }
  })

  const handleSubmit = (formValues: FormValues) => {
    onSubmit(formValues)
  }

  return (
    <form noValidate onSubmit={form.onSubmit((values) => handleSubmit(values))}>
      <Stack gap="sm">
        <Text size="sm">
          <FormattedMessage id="workspace.createForm.type" />
        </Text>

        <SegmentedControl
          {...form.getInputProps('workspaceType')}
          data={[
            {
              label: intl.formatMessage({ id: 'workspace.type.organization' }),
              value: WorkspaceType.Organization
            },
            {
              label: intl.formatMessage({ id: 'workspace.type.personal' }),
              value: WorkspaceType.Personal
            }
          ]}
          fullWidth
        />

        <TextInput
          label={intl.formatMessage({ id: 'workspace.createForm.name' })}
          {...form.getInputProps('workspaceName')}
        />

        <Button type="submit" mt="sm" fullWidth>
          <FormattedMessage id="workspace.createForm" />
        </Button>
      </Stack>
    </form>
  )
}
