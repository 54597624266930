import { Scope, ErrorBoundary as SentryErrorBoundary } from '@sentry/react'
import { ReactNode, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { ErrorPage } from './ErrorPage/ErrorPage'
import { ErrorTester } from './ErrorTester'

type ErrorBoundaryProps = {
  children: ReactNode
}

export const ErrorBoundary = ({ children }: ErrorBoundaryProps) => {
  const [errorId, setErrorId] = useState<string>('')

  const handleBeforeCapture = (scope: Scope) => {
    const id = uuidv4()
    setErrorId(id)
    scope.setTag('errorId', id)
  }

  return (
    <SentryErrorBoundary
      fallback={<ErrorPage errorId={errorId} />}
      beforeCapture={handleBeforeCapture}
    >
      <ErrorTester>{children}</ErrorTester>
    </SentryErrorBoundary>
  )
}
