import { Group, List, Text, ThemeIcon } from '@mantine/core'
import { IconCheck } from '@tabler/icons-react'
import { BillingPeriod, UserSubscription } from '@/types/subscription'
import { getBillingPeriod, getProductPrice } from '@/utils/subscription'
import { FormattedPrice } from '../FormattedPrice/FormattedPrice'

type AddonListProps = {
  addons: UserSubscription[]
}

export const AddonList = ({ addons }: AddonListProps) => {
  return (
    <List
      spacing="md"
      icon={
        <ThemeIcon color="green" size="xs" radius="xl">
          <IconCheck style={{ width: '70%', height: '70%' }} />
        </ThemeIcon>
      }
    >
      {addons.map((addon) => (
        <List.Item key={addon.product_id}>
          <Group wrap="nowrap" gap="xs">
            <Text size="sm">
              {addon.quantity} x{' '}
              {addon.product_metadata.display_name?.toLocaleLowerCase()}
            </Text>
            -
            <FormattedPrice
              size="sm"
              price={getProductPrice(addon.price * addon.quantity)}
              currency={addon.price_currency}
              billingPeriod={getBillingPeriod(addon.freq) as BillingPeriod}
            />
          </Group>
        </List.Item>
      ))}
    </List>
  )
}
