import { Training, TrainingConfig } from '@/types/training'
import { vpApi } from './vpApi'

const getTrainingDetails = async (trainingId: string) => {
  const res = await vpApi.get<Training>(`/v1/trainings/${trainingId}/`)
  return res.data
}

const createTraining = async (data: TrainingConfig) => {
  const res = await vpApi.post<Training>('/v1/trainings/', data)
  return res.data
}

export const trainingApi = {
  getTrainingDetails,
  createTraining
}
