import { useEffect, useRef, useState } from 'react'
import {
  useGetCeleryTaskStatus,
  useGetTaskStatus,
  useUpdateCvatTask
} from '@/queries/annotationQueries'
import { LibraryMLModelType } from '@/types/model'

type UseFinishCvatSessionProps = {
  taskId: number | null
  model_type: LibraryMLModelType
}

export const useFinishCvatSession = ({
  taskId,
  model_type
}: UseFinishCvatSessionProps) => {
  const processId = useRef(Math.random().toString(36).substring(7))
  const [taskIsUpdated, setTaskIsUpdated] = useState(false)
  const [updateCeleryTaskId, setUpdateCeleryTaskId] = useState<string | null>(
    null
  )

  const { mutateAsync: updateCvatTask } = useUpdateCvatTask()

  const { data: taskStatusData } = useGetTaskStatus({
    taskId: taskId as number,
    statusType: 'default',
    processId: processId.current,
    enabled: taskId !== null && taskIsUpdated
  })

  const { data: celeryTaskStatusData } = useGetCeleryTaskStatus({
    celeryTaskId: updateCeleryTaskId as string,
    enabled: updateCeleryTaskId !== null
  })

  useEffect(() => {
    if (taskStatusData?.completed) {
      const updateTask = async () => {
        const { update_task_id } = await updateCvatTask({
          task_id: taskId as number,
          model_type
        })
        setUpdateCeleryTaskId(update_task_id)
      }

      void updateTask()
    }
  }, [taskStatusData, taskId, model_type, updateCvatTask])

  const finishCvatSession = () => {
    setTaskIsUpdated(true)
  }

  return {
    finishCvatSession,
    completed: celeryTaskStatusData?.task_status === 'SUCCESS'
  }
}
