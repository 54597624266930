import { Box, LoadingOverlay } from '@mantine/core'
import { FormattedMessage } from 'react-intl'
import { ApiError, getApiError } from '@/api/helpers/apiError'
import { RouterPath } from '@/router/paths'
import { useLogin } from '@/session/useLogin'
import { AuthLayout } from '../layout/AuthLayout/AuthLayout'
import { ErrorBanner } from '../ui-shared/ErrorBanner/ErrorBanner'
import { FormValues, LoginForm } from './LoginForm'

type LoginErrorProps = {
  error: ApiError
}

const LoginError = ({ error }: LoginErrorProps) => {
  if (error.status === 400) {
    return (
      <Box mb="md">
        <ErrorBanner>
          <FormattedMessage id="login.incorrectEmailOrPassword" />
        </ErrorBanner>
      </Box>
    )
  }

  return (
    <Box mb="md">
      <ErrorBanner>
        <FormattedMessage id="login.genericApiError" />
      </ErrorBanner>
    </Box>
  )
}

export const LoginPage = () => {
  const { login, isPending, isError, error } = useLogin()

  const onSubmit = (formValues: FormValues) => {
    if (!isPending) {
      void login(formValues)
    }
  }

  return (
    <AuthLayout>
      <Box pos="relative">
        <LoadingOverlay visible={isPending} />

        {isError && <LoginError error={getApiError(error)} />}

        <LoginForm
          signUpLink={RouterPath.signup}
          forgotPasswordLink={RouterPath.forgotPassword}
          onSubmit={onSubmit}
        />
      </Box>
    </AuthLayout>
  )
}
