import { Box, Button, Group, LoadingOverlay, Stack } from '@mantine/core'
import { IconChevronRight } from '@tabler/icons-react'
import { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { getApiError } from '@/api/helpers/apiError'
import { useCreateDataset } from '@/queries/datasetQueries'
import { showToast } from '@/theme/notifications'
import { DatasetType } from '@/types/dataset'
import { DatasetForm, FormValues } from '../DatasetForm/DatasetForm'
import { SelectDatasetType } from '../SelectDatasetType/SelectDatasetType'

type Step = 1 | 2

type CreateDatasetHandlerProps = {
  onCancel: () => void
  onDatasetCreated: () => void
}

export const CreateDatasetHandler = ({
  onCancel,
  onDatasetCreated
}: CreateDatasetHandlerProps) => {
  const intl = useIntl()
  // TODO: Enable dataset type selection (primary/secondary) once the second stage pipeline is supported.
  // Currently, all datasets are defaulted to primary.
  const [step, setStep] = useState<Step>(2)

  const [selectedType, setSelectedType] = useState<DatasetType>(
    DatasetType.Primary
  )

  const { mutateAsync: createDataset, isPending } = useCreateDataset()

  const handleCreateDataset = async (values: FormValues) => {
    const { name, description, classes, labelingTypes } = values

    try {
      await createDataset({
        name,
        description,
        type: selectedType,
        classes: classes.map((c) => c.name),
        labelingTypes
      })

      showToast(
        intl.formatMessage({ id: 'datasets.create.success' }),
        'success'
      )

      onDatasetCreated()
    } catch (err) {
      const { errorMessage } = getApiError(err)

      const message =
        errorMessage || intl.formatMessage({ id: 'datasets.create.error' })

      showToast(message, 'error')
    }
  }

  return (
    <Stack gap="lg">
      {/* TODO: Enable step indicator once the second stage pipeline is supported. */}

      {/* <Text size="sm">
        <FormattedMessage id={`datasets.create.step${step}`} />
      </Text> */}

      {step === 1 && (
        <Box>
          <SelectDatasetType
            selectedType={selectedType}
            onChange={setSelectedType}
          />

          <Group justify="end" mt="xl">
            <Button miw={120} variant="default" onClick={onCancel}>
              <FormattedMessage id="cancel" />
            </Button>

            <Button
              miw={120}
              rightSection={<IconChevronRight size={16} />}
              onClick={() => setStep(2)}
            >
              <FormattedMessage id="next" />
            </Button>
          </Group>
        </Box>
      )}

      {step === 2 && (
        <Box pos="relative">
          <LoadingOverlay visible={isPending} />

          <DatasetForm
            classes={[]}
            submitLabel={<FormattedMessage id="datasets.create" />}
            onCancel={onCancel}
            onSubmit={(values) => void handleCreateDataset(values)}
          />
        </Box>
      )}
    </Stack>
  )
}
