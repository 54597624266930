import { useState } from 'react'

export const useFileSelection = () => {
  const [selectedFileIds, setSelectedFileIds] = useState<string[]>([])

  const isSelectionModeEnabled = selectedFileIds.length > 0

  const toggleFileSelection = (fileId: string) => {
    if (selectedFileIds.includes(fileId)) {
      setSelectedFileIds((ids) => ids.filter((id) => id !== fileId))
    } else {
      setSelectedFileIds((ids) => [...ids, fileId])
    }
  }

  const clearFileSelection = () => {
    setSelectedFileIds([])
  }

  return {
    selectedFileIds,
    isSelectionModeEnabled,
    toggleFileSelection,
    selectFiles: setSelectedFileIds,
    clearFileSelection
  }
}
