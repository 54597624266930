import { useIntl } from 'react-intl'
import { Circle, Group, Line } from 'react-konva'
import { AnnotationLabel } from '../AnnotationLabel'

const CIRCLE_SIZE = 3

type LineInProgressProps = {
  color: string
  points: number[][]
  flattenedPoints: number[]
}

export const LineInProgress = ({
  color,
  points,
  flattenedPoints
}: LineInProgressProps) => {
  const intl = useIntl()

  const showStartLabel = flattenedPoints.length === 2
  const showEndLabel = flattenedPoints.length === 4

  return (
    <Group>
      <Line points={flattenedPoints} stroke={color} strokeWidth={3} />

      {points.map((point, index) => {
        const x = point[0]
        const y = point[1]

        return (
          <Circle key={index} x={x} y={y} radius={CIRCLE_SIZE} fill={color} />
        )
      })}

      {showStartLabel && (
        <AnnotationLabel
          x={flattenedPoints[0]}
          y={flattenedPoints[1] - 30}
          color="rgba(0,0,0,0.8)"
          textColor="white"
          text={intl.formatMessage({ id: 'logic.annotate.line.start' })}
        />
      )}

      {showEndLabel && (
        <AnnotationLabel
          x={flattenedPoints[2]}
          y={flattenedPoints[3] - 30}
          color="rgba(0,0,0,0.8)"
          textColor="white"
          text={intl.formatMessage({ id: 'logic.annotate.line.end' })}
        />
      )}
    </Group>
  )
}
