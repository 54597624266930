import { Box, Button, Card, Modal, Transition } from '@mantine/core'
import { IconX } from '@tabler/icons-react'
import { ReactNode } from 'react'
import { FormattedMessage } from 'react-intl'

type MediaGalleryOverlayProps = {
  opened: boolean
  toolbar: ReactNode
  children: ReactNode
  onClose: () => void
}

export const MediaGalleryOverlay = ({
  opened,
  toolbar,
  children,
  onClose
}: MediaGalleryOverlayProps) => {
  return (
    <Modal.Root opened={opened} onClose={onClose}>
      <Modal.Overlay backgroundOpacity={0.9} />

      <Transition mounted={opened} transition="fade">
        {(transitionStyles) => (
          <div
            style={{
              position: 'fixed',
              top: 0,
              bottom: 0,
              width: '100%',
              zIndex: 200,
              ...transitionStyles
            }}
          >
            <Card bg="white" radius={0}>
              {toolbar}
            </Card>

            <Box pos="relative">
              {children}

              <Box pos="absolute" top={4} right={0}>
                <Button
                  variant="subtle"
                  size="md"
                  color="gray.1"
                  rightSection={<IconX size={24} />}
                  onClick={onClose}
                >
                  <span
                    style={{
                      textTransform: 'lowercase'
                    }}
                  >
                    <FormattedMessage id="close" />
                  </span>
                </Button>
              </Box>
            </Box>
          </div>
        )}
      </Transition>
    </Modal.Root>
  )
}
