import { useIntl } from 'react-intl'
import { Arrow, Circle, Group } from 'react-konva'
import { AnnotationLabel } from '../AnnotationLabel'

const CIRCLE_SIZE = 4

type LineCrossingDirectionProps = {
  color: string
  points: number[][]
  flattenedPoints: number[]
}

export const LineCrossingDirection = ({
  color,
  points,
  flattenedPoints
}: LineCrossingDirectionProps) => {
  const intl = useIntl()

  const labelPos = {
    x: flattenedPoints[flattenedPoints.length - 2],
    y: flattenedPoints[flattenedPoints.length - 1] - 20
  }

  return (
    <Group>
      <Arrow
        points={flattenedPoints}
        pointerWidth={16}
        pointerLength={16}
        fill={color}
        stroke={color}
        strokeWidth={4}
      />

      {points.map((point, index) => {
        const x = point[0] - 1
        const y = point[1] - 1

        return (
          <Circle
            key={index}
            x={x}
            y={y}
            radius={CIRCLE_SIZE}
            fill="white"
            stroke={color}
            strokeWidth={2}
          />
        )
      })}

      {flattenedPoints.length === 2 && (
        <AnnotationLabel
          x={labelPos.x}
          y={labelPos.y}
          color="rgba(0,0,0,0.6)"
          textColor="white"
          text={intl.formatMessage({ id: 'logic.annotate.setDirection' })}
        />
      )}
    </Group>
  )
}
