import { Box, Group, LoadingOverlay, Modal } from '@mantine/core'
import { IconTag } from '@tabler/icons-react'
import { FormattedMessage, useIntl } from 'react-intl'
import { getApiError } from '@/api/helpers/apiError'
import { useCreateLabels, useGetLabels } from '@/queries/labelQueries'
import { showToast } from '@/theme/notifications'
import { FormValues, LabelSelectForm } from '../LabelSelectForm/LabelSelectForm'

const ModalTitle = () => {
  return (
    <Group gap="xs">
      <IconTag size={20} />
      <FormattedMessage id="labels" />
    </Group>
  )
}

type LabelSelectionModalProps = {
  opened: boolean
  applicationId: string
  onClose: () => void
}

export const LabelSelectionModal = ({
  opened,
  applicationId,
  onClose
}: LabelSelectionModalProps) => {
  const intl = useIntl()
  const { data, isFetching, refetch } = useGetLabels(applicationId)
  const { isPending: isCreatePending, mutateAsync: createLabels } =
    useCreateLabels()

  const labels = data?.pages.flatMap((page) => page.results) ?? []
  const appLabels = labels.filter((label) => label.source === 'applications')
  const modelLabels = labels.filter((label) => label.source === 'models')

  const labelOptions = modelLabels.map((label) => ({
    name: label.name,
    color: label.color
  }))

  const initialValues = appLabels.map((label) => ({
    name: label.name,
    color: label.color,
    nonRemovable: true
  }))

  const handleSubmit = async (values: FormValues) => {
    const { labels: updatedLabels } = values

    const labelsToCreate = updatedLabels
      .filter((label) => !appLabels.some((l) => l.name === label.name))
      .map((label) => ({
        name: label.name,
        color: label.color
      }))

    try {
      await createLabels({
        appId: applicationId,
        data: labelsToCreate
      })

      await refetch()
      showToast(intl.formatMessage({ id: 'labels.updated' }), 'success')
      onClose()
    } catch (err) {
      const { errorMessage } = getApiError(err)
      const message =
        errorMessage || intl.formatMessage({ id: 'labels.updateError' })
      showToast(message, 'error')
    }
  }

  return (
    <Modal size="xl" title={<ModalTitle />} opened={opened} onClose={onClose}>
      <Box pos="relative">
        <LoadingOverlay visible={isFetching || isCreatePending} />

        <LabelSelectForm
          initialValues={initialValues}
          options={labelOptions}
          onCancel={onClose}
          onSubmit={(values) => void handleSubmit(values)}
        />
      </Box>
    </Modal>
  )
}
