import { ActionIcon, ThemeIcon } from '@mantine/core'
import { IconCheck, IconCircle } from '@tabler/icons-react'
import { MouseEvent } from 'react'

type SelectionModeIconProps = {
  isSelected: boolean
}

export const SelectionModeIcon = ({ isSelected }: SelectionModeIconProps) => {
  if (isSelected) {
    return (
      <ThemeIcon variant="filled" radius="xl" size="sm">
        <IconCheck style={{ width: '70%', height: '70%' }} />
      </ThemeIcon>
    )
  }

  return (
    <ThemeIcon variant="light" color="white" radius="xl" size="sm">
      <IconCircle style={{ width: '90%', height: '90%' }} />
    </ThemeIcon>
  )
}

type HoverActionProps = {
  onClick: (e: MouseEvent<HTMLButtonElement>) => void
}

export const HoverAction = ({ onClick }: HoverActionProps) => {
  return (
    <ActionIcon
      variant="filled"
      radius="xl"
      size="sm"
      color="brand-primary"
      styles={{
        root: {
          boxShadow: '0 0 5px 1px var(--mantine-color-white)'
        }
      }}
      onClick={onClick}
    >
      <IconCheck style={{ width: '70%', height: '70%' }} />
    </ActionIcon>
  )
}
