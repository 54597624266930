import { Anchor, List, Text, ThemeIcon } from '@mantine/core'
import { IconHeadphones, IconMail, IconPhone } from '@tabler/icons-react'
import { FormattedMessage } from 'react-intl'
import { VP_CONTACT_EMAIL, VP_CONTACT_PHONE } from '@/config'

const RESPONSE_DAYS = '1-2'

export const ContactInfo = () => {
  return (
    <List spacing="lg">
      <List.Item
        icon={
          <ThemeIcon variant="light" radius="xl">
            <IconMail style={{ width: '70%', height: '70%' }} />
          </ThemeIcon>
        }
      >
        <Anchor size="sm" href={`mailto:${VP_CONTACT_EMAIL}`}>
          {VP_CONTACT_EMAIL}
        </Anchor>
      </List.Item>

      <List.Item
        icon={
          <ThemeIcon variant="light" radius="xl">
            <IconPhone style={{ width: '70%', height: '70%' }} />
          </ThemeIcon>
        }
      >
        <Anchor size="sm" href={`tel:${VP_CONTACT_PHONE}`}>
          {VP_CONTACT_PHONE}
        </Anchor>
      </List.Item>

      <List.Item
        icon={
          <ThemeIcon variant="light" radius="xl">
            <IconHeadphones style={{ width: '70%', height: '70%' }} />
          </ThemeIcon>
        }
      >
        <Text size="sm">
          <FormattedMessage
            id="contactUs.responseTime"
            values={{
              days: RESPONSE_DAYS
            }}
          />
        </Text>
      </List.Item>
    </List>
  )
}
