import { Button, Group, SimpleGrid, Stack } from '@mantine/core'
import { FormattedMessage } from 'react-intl'
import { UploadDropzone } from '@/components/datasets/UploadDropzone/UploadDropzone'
import { TestModelMediaCard } from './TestModelMediaCard'

type TestModelUploadMediaProps = {
  acceptedFiles: File[]
  isInferenceError: boolean
  isInferencePending: boolean
  setAcceptedFiles: (files: File[]) => void
  onUpload: (acceptedFiles: File[]) => Promise<void>
}

type DropzoneFileListProps = {
  files: File[]
  onFileDelete: (fileName: string) => void
}

const DropzoneFileList = ({ files, onFileDelete }: DropzoneFileListProps) => {
  return (
    <SimpleGrid cols={{ xs: 2, lg: 7 }} spacing="md">
      {files.map((file, index) => (
        <TestModelMediaCard
          key={index}
          file={file}
          onFileDelete={() => onFileDelete(file.name)}
        />
      ))}
    </SimpleGrid>
  )
}

const MAXIMUM_FILES = 5

export const TestModelUploadMedia = ({
  acceptedFiles,
  isInferenceError,
  isInferencePending,
  setAcceptedFiles,
  onUpload
}: TestModelUploadMediaProps) => {
  const handleDrop = (files: File[]) => {
    setAcceptedFiles([...files, ...acceptedFiles])
  }

  const removeFile = (fileName: string) => {
    const newFiles = acceptedFiles.filter((file) => file.name !== fileName)
    setAcceptedFiles(newFiles)
  }

  return (
    <>
      <Stack>
        {acceptedFiles.length > 0 && (
          <DropzoneFileList files={acceptedFiles} onFileDelete={removeFile} />
        )}
        <UploadDropzone
          disabled={
            (acceptedFiles.length === MAXIMUM_FILES ? true : false) ||
            isInferencePending
          }
          fileLimit={MAXIMUM_FILES}
          showInstructions
          onDrop={handleDrop}
          onReject={() => {}}
        />
      </Stack>

      <Group pt="xl" justify="end">
        <Button
          miw={160}
          disabled={
            (acceptedFiles.length > MAXIMUM_FILES ||
              acceptedFiles.length === 0 ||
              isInferencePending) &&
            !isInferenceError
          }
          loading={isInferencePending}
          onClick={() => void onUpload(acceptedFiles)}
        >
          <FormattedMessage id="continue" />
        </Button>
      </Group>
    </>
  )
}
