import { Inference } from '@/types/inference'
import { vpApi } from './vpApi'

const getInferenceDetails = async (inferenceId: string) => {
  const res = await vpApi.get<Inference>(`/v1/inference/${inferenceId}/`)
  return res.data
}

export const inferenceApi = {
  getInferenceDetails
}
