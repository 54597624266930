import { Card, Collapse, SimpleGrid } from '@mantine/core'
import { useDisclosure, useHotkeys } from '@mantine/hooks'
import { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { InfiniteScroll } from '@/components/ui-shared/InfiniteScroll/InfiniteScroll'
import { useGetFiles } from '@/queries/datasetLegacyQueries'
import { BORDERED_CARD_STYLES } from '@/theme/customStyles'
import { FileStatus } from '@/types/dataset'
import { FileSelectionBar } from '../../datasets/FileSelectionBar/FileSelectionBar'
import { useFileSelection } from '../../datasets/FileSelectionBar/useFileSelection'
import { ErrorWithReload } from '../../ui-shared/ErrorWithReload/ErrorWithReload'
import { MediaView } from '../MediaView/MediaView'
import { DeleteFilesModal } from './DeleteFilesModal/DeleteFilesModal'
import { ImageCard } from './ImageCard/ImageCard'

type MediaListProps = {
  datasetId: string
}

export const MediaList = ({ datasetId }: MediaListProps) => {
  const [isMediaOpened, { open: openMedia, close: closeMedia }] =
    useDisclosure(false)

  const [
    isDeleteModalOpened,
    { open: openDeleteModal, close: closeDeleteModal }
  ] = useDisclosure(false)

  const [activeMediaIndex, setActiveMediaIndex] = useState(0)

  const { data, isFetching, isError, refetch, fetchNextPage, hasNextPage } =
    useGetFiles({ datasetId })

  const {
    selectedFileIds,
    isSelectionModeEnabled,
    toggleFileSelection,
    clearFileSelection,
    selectFiles
  } = useFileSelection()

  useHotkeys([['Escape', clearFileSelection]])

  const files = data?.pages?.flatMap((page) => page.results) || []

  const handleLoadMore = () => {
    void fetchNextPage()
  }

  const handleOnReload = () => {
    void refetch()
  }

  const handleViewMedia = (index: number) => {
    setActiveMediaIndex(index)
    openMedia()
  }

  const handleSelectionChange = (selected: boolean) => {
    if (selected) {
      selectFiles(files.map((file) => file.id))
    } else {
      clearFileSelection()
    }
  }

  const handleFileDelete = () => {
    closeDeleteModal()
    clearFileSelection()
  }

  if (isError) {
    return (
      <ErrorWithReload onReload={handleOnReload}>
        <FormattedMessage id="datasets.data.error" />
      </ErrorWithReload>
    )
  }

  return (
    <>
      <Collapse in={isSelectionModeEnabled}>
        <Card
          bg="gray.1"
          mb="md"
          px="lg"
          py="sm"
          styles={BORDERED_CARD_STYLES}
          withBorder
        >
          <FileSelectionBar
            allFilesSelected={files.length === selectedFileIds.length}
            fileCount={selectedFileIds.length}
            onSelectionChange={handleSelectionChange}
            onDeleteFiles={openDeleteModal}
          />
        </Card>
      </Collapse>

      <InfiniteScroll
        hasMore={hasNextPage}
        isFetching={isFetching}
        onLoadMore={handleLoadMore}
      >
        <SimpleGrid
          cols={{ base: 2, sm: 4, lg: 6, xl: 8 }}
          spacing="lg"
          verticalSpacing="lg"
        >
          {files.map((file, i) => (
            <ImageCard
              key={file.id}
              imageUrl={file.thumbnail}
              fileName={file.name}
              isSelected={selectedFileIds.includes(file.id)}
              isSelectionModeEnabled={isSelectionModeEnabled}
              isLabeled={file.status === FileStatus.Labeled}
              onSelectionChange={() => toggleFileSelection(file.id)}
              onClick={() => handleViewMedia(i)}
            />
          ))}
        </SimpleGrid>
      </InfiniteScroll>

      <MediaView
        opened={isMediaOpened}
        activeSlide={activeMediaIndex}
        mediaFiles={files}
        datasetId={datasetId}
        onClose={closeMedia}
        onSlideChange={setActiveMediaIndex}
      />

      <DeleteFilesModal
        opened={isDeleteModalOpened}
        datasetId={datasetId}
        selectedFileIds={selectedFileIds}
        onDelete={handleFileDelete}
        onClose={closeDeleteModal}
      />
    </>
  )
}
