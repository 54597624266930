import { Button, Group, Text, TextInput } from '@mantine/core'
import { isNotEmpty, useForm } from '@mantine/form'
import { FormattedMessage, useIntl } from 'react-intl'

export type FormValues = {
  name: string
}

type CreateModelFormProps = {
  onCancel: () => void
  onSubmit: (formValues: FormValues) => void
}

export const CreateModelForm = ({
  onCancel,
  onSubmit
}: CreateModelFormProps) => {
  const intl = useIntl()

  const form = useForm({
    initialValues: {
      name: ''
    },

    validate: {
      name: isNotEmpty(intl.formatMessage({ id: 'models.enterModelName' }))
    }
  })

  return (
    <form noValidate onSubmit={form.onSubmit((values) => onSubmit(values))}>
      <Text size="sm" mb="lg">
        <FormattedMessage id="models.enterModelName" />
      </Text>

      <TextInput
        label={intl.formatMessage({ id: 'models.modelName' })}
        mb="xl"
        autoFocus
        data-autofocus // required for auto focus inside the modal
        {...form.getInputProps('name')}
      />

      <Group justify="end">
        <Button miw={120} variant="default" onClick={onCancel}>
          <FormattedMessage id="cancel" />
        </Button>

        <Button type="submit">
          <FormattedMessage id="models.createModel" />
        </Button>
      </Group>
    </form>
  )
}
