import { Alert, Text } from '@mantine/core'
import { ReactNode } from 'react'

type ErrorBannerProps = {
  children: ReactNode
}

export const ErrorBanner = ({ children }: ErrorBannerProps) => {
  return (
    <Alert variant="light" color="red">
      <Text size="xs" c="red">
        {children}
      </Text>
    </Alert>
  )
}
