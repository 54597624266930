import { Box, Button, Flex, Group, Title } from '@mantine/core'
import { IconArrowRight, IconChevronLeft, IconVideo } from '@tabler/icons-react'
import { FormattedMessage, useIntl } from 'react-intl'
import { StepSection } from '@/components/deployments/deployment-editor/StepSection'
import { CameraStreamWithDeviceId } from '@/types/device'
import { UserMLModel, UserMLModelStatus } from '@/types/model'
import { DeviceCard } from '../DeviceSelection/DeviceCard/DeviceCard'
import { IntegrationPreviewCard } from '../IntegrationSelection/IntegrationPreviewCard/IntegrationPreviewCard'
import { SavedEventEndpoint } from '../IntegrationSelection/IntegrationSelection'
import { SavedStreamLogic } from '../LogicSelection/types'
import { ModelCard } from '../ModelSelection/ModelCard/ModelCard'
import { PreviewCard } from './PreviewCard'

type DevicePreview = {
  id: string
  name: string
  isActive: boolean
}

const Arrow = () => (
  <Flex h={120} direction="column" align="center" justify="center">
    <IconArrowRight size={24} />
  </Flex>
)

type DeployPreviewProps = {
  model: UserMLModel | null
  cameraStreams: CameraStreamWithDeviceId[]
  savedStreamLogics: SavedStreamLogic[]
  savedEventEndpoints: SavedEventEndpoint[]
  onDeployModel: () => void
  onGoBack: () => void
}

export const DeployPreview = ({
  model,
  cameraStreams,
  savedStreamLogics,
  savedEventEndpoints,
  onDeployModel,
  onGoBack
}: DeployPreviewProps) => {
  const intl = useIntl()
  // Get all unique devices from camera list
  const devices = cameraStreams.reduce((acc, cameraStream) => {
    if (!acc.find((device) => device.id === cameraStream.device_id)) {
      acc.push({
        id: cameraStream.device_id,
        name: cameraStream.device_name,
        isActive: cameraStream.is_device_active
      })
    }
    return acc
  }, [] as DevicePreview[])

  const getLogicNames = (logics: SavedStreamLogic[], deviceId: string) => {
    const logicNames = logics
      .filter((logic) => logic.device_id === deviceId)
      .filter((logic) => logic.lines.length > 0)
      .map((logic) => logic.logic_name)

    const uniqueLogicNames = [...new Set(logicNames)]

    return uniqueLogicNames.length > 0
      ? uniqueLogicNames.join(', ')
      : intl.formatMessage({ id: 'deployments.preview.noLogicSelected' })
  }

  return (
    <>
      <Title order={3} mb="md">
        <FormattedMessage id="deployments.step6" />
      </Title>

      <Box pos="relative">
        <StepSection
          actions={
            <Group>
              <Button
                size="md"
                variant="outline"
                miw={200}
                leftSection={<IconChevronLeft size={16} stroke={3} />}
                onClick={onGoBack}
              >
                <FormattedMessage id="deployments.previousStep" />
              </Button>

              <Button size="md" miw={200} onClick={onDeployModel}>
                <FormattedMessage id="deployments.deployModel" />
              </Button>
            </Group>
          }
        >
          {devices.map((device) => (
            <Group key={device.id} align="center" mb="xl">
              <Box w={160}>
                <ModelCard
                  name={model?.name || ''}
                  status={model?.status as UserMLModelStatus}
                />
              </Box>

              <Arrow />

              <Box w={160}>
                <DeviceCard name={device.name} isActive={device.isActive} />
              </Box>

              <Arrow />

              <Box w={160}>
                <PreviewCard icon={<IconVideo size={24} />}>
                  <FormattedMessage
                    id="deployments.preview.streamCount"
                    values={{
                      count: cameraStreams.filter(
                        (stream) => stream.device_id === device.id
                      ).length
                    }}
                  />
                </PreviewCard>
              </Box>

              <Arrow />

              <Box w={160}>
                <PreviewCard>
                  {getLogicNames(savedStreamLogics, device.id)}
                </PreviewCard>
              </Box>

              <Arrow />

              <Box w={160}>
                <IntegrationPreviewCard eventEndpoints={savedEventEndpoints} />
              </Box>
            </Group>
          ))}
        </StepSection>
      </Box>
    </>
  )
}
