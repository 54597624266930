import { MRT_ColumnFiltersState, MRT_SortingState } from 'mantine-react-table'

type BuildSortingAndFilterParamsProps = {
  sorting: MRT_SortingState
  columnFilters: MRT_ColumnFiltersState
}

export const buildSortingAndFilterParams = ({
  sorting,
  columnFilters
}: BuildSortingAndFilterParamsProps) => {
  const { id, desc } = sorting[0] || { id: '', desc: false }
  const sortProp = id.replace(' ', '_').toLowerCase()
  const sortOrder = desc ? 'desc' : 'asc'

  const hasSort = sortProp && sortOrder

  const timestampFilter = columnFilters.find(
    (filter) => filter.id === 'timestamp'
  ) || { value: [] }

  const [startDate, endDate] = timestampFilter.value as (Date | '')[]

  let dayAfterEndDate

  if (endDate) {
    dayAfterEndDate = new Date(endDate.getTime())
    dayAfterEndDate.setDate(endDate.getDate() + 1)
  }

  const deviceIdFilter = columnFilters.find(
    (filter) => filter.id === 'metadata.device_id'
  )

  const deviceIds = (deviceIdFilter?.value || '') as string

  const queryParams = {
    ...(hasSort && { sort_property: sortProp }),
    ...(hasSort && { sort_order: sortOrder }),
    ...(startDate && { start_date: startDate }),
    ...(dayAfterEndDate && { end_date: dayAfterEndDate }),
    ...(deviceIds && { devices: [deviceIds] })
  }

  return queryParams
}
