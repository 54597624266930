import { ActionIcon, Box, Card, Image, Tooltip } from '@mantine/core'
import { useHover } from '@mantine/hooks'
import { IconTrash } from '@tabler/icons-react'
import { MouseEventHandler } from 'react'
import { FormattedMessage } from 'react-intl'
import ImagePlaceholder from '@/assets/image-placeholder.png'
import { AnimatedCard } from '@/components/ui-shared/AnimatedCard/AnimatedCard'

type testModelMediaCardProps = {
  file: File
  onFileDelete: () => void
}

type DeleteButtonProps = {
  onClick: MouseEventHandler<HTMLButtonElement>
}

const DeleteButton = ({ onClick }: DeleteButtonProps) => {
  const { hovered, ref } = useHover<HTMLButtonElement>()

  const iconColor = hovered
    ? 'var(--mantine-color-gray-8)'
    : 'var(--mantine-color-white)'

  return (
    <Tooltip label={<FormattedMessage id="models.test.deleteMedia" />}>
      <ActionIcon
        ref={ref}
        radius="lg"
        variant="filled"
        color="red"
        onClick={onClick}
      >
        <IconTrash color={iconColor} size={16} />
      </ActionIcon>
    </Tooltip>
  )
}

export const TestModelMediaCard = ({
  file,
  onFileDelete
}: testModelMediaCardProps) => {
  const mediaUrl = URL.createObjectURL(file)
  const isVideo = file.type.startsWith('video')

  return (
    <AnimatedCard>
      <Card.Section>
        {isVideo ? (
          <video>
            <source src={mediaUrl} type="video/mp4" />
          </video>
        ) : (
          <Image
            src={mediaUrl}
            alt={file.name}
            fallbackSrc={ImagePlaceholder}
            onLoad={() => URL.revokeObjectURL(mediaUrl)}
          />
        )}
      </Card.Section>

      <Box pos="absolute" top={10} right={10}>
        <DeleteButton onClick={onFileDelete} />
      </Box>
    </AnimatedCard>
  )
}
