import { Group, Radio, Stack, Text } from '@mantine/core'
import { useHover } from '@mantine/hooks'
import { ReactNode } from 'react'

type RadioCardProps = {
  isSelected: boolean
  value: string
  title: string | ReactNode
  badge?: string | ReactNode
  description: string | ReactNode
}

export const RadioCard = ({
  isSelected,
  value,
  title,
  badge,
  description
}: RadioCardProps) => {
  const { hovered, ref } = useHover()

  return (
    <div ref={ref}>
      <Radio.Card
        value={value}
        styles={{
          card: {
            padding: 'var(--mantine-spacing-md)',
            borderColor: isSelected
              ? 'var(--mantine-primary-color-filled)'
              : 'var(--mantine-color-gray-4)',
            backgroundColor: hovered ? 'var(--mantine-color-gray-0)' : undefined
          }
        }}
      >
        <Group wrap="nowrap" align="start">
          <Radio.Indicator />

          <Stack gap="xs">
            <Group wrap="nowrap">
              <Text size="md" lh={1.3} fw="bold">
                {title}
              </Text>

              {badge}
            </Group>

            <Text size="sm" c="gray.7">
              {description}
            </Text>
          </Stack>
        </Group>
      </Radio.Card>
    </div>
  )
}
