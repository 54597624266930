import { Button, Card, CardSection, Group, Popover } from '@mantine/core'
import { IconHelp, IconPlus, IconRuler2, IconVector } from '@tabler/icons-react'
import { ReactNode, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { CalibrationHelp } from '../CalibrationHelp'

type CalibrationToolbarProps = {
  children: ReactNode
  roiDisabled: boolean
  side1Disabled: boolean
  side2Disabled: boolean
  hasCalibrations: boolean
  showHelpIcon: boolean
  onRoiClick: () => void
  onSide1Click: () => void
  onSide2Click: () => void
}

export const CalibrationToolbar = ({
  children,
  roiDisabled,
  side1Disabled,
  side2Disabled,
  hasCalibrations,
  showHelpIcon,
  onRoiClick,
  onSide1Click,
  onSide2Click
}: CalibrationToolbarProps) => {
  const [calibrationModeEnabled, setCalibrationModeEnabled] = useState(false)

  return (
    <Card radius={0} withBorder>
      <Card.Section py="sm" withBorder inheritPadding>
        <Group justify="space-between">
          <Group>
            <Button
              size="xs"
              radius="xl"
              leftSection={<IconVector size={16} />}
              disabled={roiDisabled}
              onClick={onRoiClick}
            >
              <FormattedMessage id="logic.roi.add" />
            </Button>

            {!calibrationModeEnabled && !hasCalibrations && (
              <Button
                size="xs"
                radius="xl"
                leftSection={<IconRuler2 size={16} />}
                onClick={() => setCalibrationModeEnabled(true)}
              >
                <FormattedMessage id="logic.calibration.add" />
              </Button>
            )}

            {(calibrationModeEnabled || hasCalibrations) && (
              <Group gap="xs">
                <Button
                  size="xs"
                  radius="xl"
                  leftSection={<IconPlus size={16} />}
                  disabled={side1Disabled}
                  onClick={onSide1Click}
                >
                  <FormattedMessage id="logic.calibration.side1" />
                </Button>

                <Button
                  size="xs"
                  radius="xl"
                  leftSection={<IconPlus size={16} />}
                  disabled={side2Disabled}
                  onClick={onSide2Click}
                >
                  <FormattedMessage id="logic.calibration.side2" />
                </Button>
              </Group>
            )}
          </Group>

          {showHelpIcon && (
            <Popover width={480} shadow="md" withArrow>
              <Popover.Target>
                <Button
                  variant="subtle"
                  size="xs"
                  leftSection={<IconHelp size={16} />}
                >
                  <FormattedMessage id="help" />
                </Button>
              </Popover.Target>

              <Popover.Dropdown p="lg">
                <CalibrationHelp />
              </Popover.Dropdown>
            </Popover>
          )}
        </Group>
      </Card.Section>

      <CardSection>{children}</CardSection>
    </Card>
  )
}
