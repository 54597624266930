import { Label, Tag, Text } from 'react-konva'

type AnnotationLabelProps = {
  x: number
  y: number
  text: string
  color: string
  textColor: string
}

export const AnnotationLabel = ({
  x,
  y,
  text,
  color,
  textColor
}: AnnotationLabelProps) => {
  return (
    <Label x={x} y={y}>
      <Tag fill={color} cornerRadius={4} />

      <Text
        text={text}
        fill={textColor}
        fontSize={10}
        fontStyle="bold"
        padding={3}
      />
    </Label>
  )
}
