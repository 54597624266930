import { useInfiniteQuery, useMutation } from '@tanstack/react-query'
import { labelApi } from '@/api/labelApi'
import { objectToQueryString } from '@/utils/url'

export const labelQueryKeys = {
  all: ['labels'] as const,
  list: (appId: string) => [...labelQueryKeys.all, 'list', appId] as const
}

export const useGetLabels = (appId: string) => {
  return useInfiniteQuery({
    queryKey: labelQueryKeys.list(appId),
    queryFn: ({ pageParam }) => labelApi.getLabels({ pageUrl: pageParam }),
    getNextPageParam: (lastPage) => {
      return lastPage.next ?? undefined
    },
    initialPageParam: `/v1/applications/${appId}/labels/?${objectToQueryString({
      limit: 100,
      ordering: 'created_at'
    })}` // you can see only first 100 labels
  })
}

export const useCreateLabels = () => {
  return useMutation({
    mutationFn: labelApi.createLabels
  })
}

export const useDeleteLabel = () => {
  return useMutation({
    mutationFn: labelApi.deleteLabel
  })
}
