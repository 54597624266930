import { Button, Group, Textarea } from '@mantine/core'
import { useForm } from '@mantine/form'
import { FormattedMessage, useIntl } from 'react-intl'

const MIN_CHARS = 2
const MAX_CHARS = 1000

type FormValues = {
  noteText: string
}

type EventNoteFormProps = {
  noteText: string
  onSubmit: (noteText: string) => void
  onCancel: () => void
}

export const EventNoteForm = ({
  noteText,
  onSubmit,
  onCancel
}: EventNoteFormProps) => {
  const intl = useIntl()

  const form = useForm<FormValues>({
    initialValues: {
      noteText
    },

    validate: {
      noteText: (value) => {
        if (value.length < MIN_CHARS || value.length > MAX_CHARS) {
          return intl.formatMessage(
            {
              id: 'events.notes.minMaxChars'
            },
            {
              min: MIN_CHARS,
              max: MAX_CHARS
            }
          )
        }
      }
    }
  })

  return (
    <form onSubmit={form.onSubmit((values) => onSubmit(values.noteText))}>
      <Textarea
        mb="lg"
        minRows={4}
        maxRows={10}
        autosize
        autoFocus
        {...form.getInputProps('noteText')}
      />

      <Group wrap="nowrap" justify="end">
        <Button variant="outline" size="xs" miw={100} onClick={onCancel}>
          <FormattedMessage id="cancel" />
        </Button>

        <Button size="xs" miw={100} type="submit">
          <FormattedMessage id="save" />
        </Button>
      </Group>
    </form>
  )
}
