import { Alert, Box, LoadingOverlay } from '@mantine/core'
import { IconAlertTriangle } from '@tabler/icons-react'
import { useQuery } from '@tanstack/react-query'
import { ReactNode } from 'react'
import { FormattedMessage } from 'react-intl'
import { accountApi } from '@/api/accountApi'
import { setCsrfToken } from '@/api/vpApi'

const QUERY_KEY = 'csrf'

type CsrfHandlerProps = {
  children: ReactNode
}

const LoadingIndicator = () => (
  <Box pos="relative" mih={400}>
    <LoadingOverlay visible />
  </Box>
)

const TokenError = () => {
  const icon = <IconAlertTriangle />

  const errorTitle = <FormattedMessage id="appConfigLoadError.title" />

  return (
    <Box m="20px auto" maw={640}>
      <Alert variant="light" color="red" title={errorTitle} icon={icon}>
        <FormattedMessage id="appConfigLoadError.description" />
      </Alert>
    </Box>
  )
}

export const CsrfHandler = ({ children }: CsrfHandlerProps) => {
  const { data, isLoading, isError } = useQuery({
    queryKey: [QUERY_KEY],
    queryFn: accountApi.getCsrfToken
  })

  if (isLoading) {
    return <LoadingIndicator />
  }

  if (isError) {
    return <TokenError />
  }

  if (data) {
    setCsrfToken(data)

    // Render children components only after csrf header was added to axios default config
    return children
  }
}
