import { Dialog, Group, Loader, Stack, Text } from '@mantine/core'
import { FormattedMessage } from 'react-intl'

const WAITING_TIME = 10 // mins

type DeploymentProcessBarProps = {
  isShown: boolean
  processType: 'deployment' | 'removal'
}

export const DeploymentProcessBar = ({
  isShown,
  processType
}: DeploymentProcessBarProps) => {
  return (
    <Dialog
      opened={isShown}
      styles={{
        root: {
          borderColor: 'var(--mantine-color-blue-4)'
        }
      }}
      size="xl"
      bg="blue.0"
      withBorder
    >
      <Group align="start" wrap="nowrap">
        <Loader size={20} />

        {processType === 'deployment' && (
          <Stack>
            <Text size="sm" fw="bold">
              <FormattedMessage id="deployments.inProgress" />
            </Text>

            <Text size="xs">
              <FormattedMessage
                id="deployments.waitingTime"
                values={{
                  numOfMinutes: WAITING_TIME
                }}
              />
            </Text>
          </Stack>
        )}

        {processType === 'removal' && (
          <Text size="sm" fw="bold">
            <FormattedMessage id="deployments.remove.inProgress" />
          </Text>
        )}
      </Group>
    </Dialog>
  )
}
