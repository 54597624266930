import { Annotation } from '@/types/annotation'
import { LibraryMLModelType } from '@/types/model'
import { vpApi } from './vpApi'

const getAnnotation = async (mediaId: string) => {
  const res = await vpApi.get<Annotation>(`/v1/media/${mediaId}/annotation/`)
  return res.data
}

const cvatLogin = () => {
  return vpApi.post('/v1/cvat/login/')
}

type CreateCvatTaskData = {
  file_metadata_id: string
  cvat_fps: number
  task_name: string
}

type CreateCvatTaskResponse = {
  message: string
  async_upload_video_task_id: string
  cvat_task_id: number
}

const createCvatTask = async (data: CreateCvatTaskData) => {
  const res = await vpApi.post<CreateCvatTaskResponse>('/v1/cvat/tasks/', data)
  return res.data
}

type UpdateCvatTaskData = {
  task_id: number
  model_type: LibraryMLModelType
}

type UpdateCvatTaskResponse = {
  message: string
  update_task_id: string
}

const updateCvatTask = async (data: UpdateCvatTaskData) => {
  const res = await vpApi.patch<UpdateCvatTaskResponse>(
    `/v1/cvat/task/${data.task_id}/`,
    data
  )
  return res.data
}

type CreateCvatTaskAnnotationData = {
  task_id: number
}

type CreateCvatTaskAnnotationResponse = {
  message: string
  process_annotation_task_id: string
}

const createCvatTaskAnnotation = async (data: CreateCvatTaskAnnotationData) => {
  const res = await vpApi.post<CreateCvatTaskAnnotationResponse>(
    '/v1/cvat/task/annotation/',
    data
  )
  return res
}

type GetCvatUrlResponse = {
  url: string
  token: string
}

const getCvatUrl = async (taskId: number) => {
  const res = await vpApi.get<GetCvatUrlResponse>(
    `/v1/cvat/task/${taskId}/url/`
  )
  return res.data
}

export type GetCvatTaskStatusParams = {
  taskId: number
  statusType: 'default' | 'video_upload'
}

type GetCvatTaskStatusResponse = {
  is_video_uploaded?: boolean
  completed?: boolean
}

const getTaskStatus = async ({
  taskId,
  statusType
}: GetCvatTaskStatusParams) => {
  const params =
    statusType === 'default' ? { status: true } : { video_upload_status: true }

  const res = await vpApi.get<GetCvatTaskStatusResponse>(
    `/v1/cvat/task/${taskId}/`,
    { params }
  )
  return res.data
}

type GetCeleryTaskStatusResponse = {
  task_id: string
  task_status: 'PENDING' | 'SUCCESS' | 'FAILURE'
  task_result: unknown
}

const getCeleryTaskStatus = async (celeryTaskId: string) => {
  const res = await vpApi.get<GetCeleryTaskStatusResponse>(
    `/v1/cvat/task/${celeryTaskId}/status/`
  )
  return res.data
}

const deleteAnnotationTask = (taskId: number) => {
  return vpApi.delete(`/v1/cvat/task/${taskId}/`)
}

export const annotationApi = {
  getAnnotation,
  cvatLogin,
  createCvatTask,
  updateCvatTask,
  createCvatTaskAnnotation,
  getCvatUrl,
  getTaskStatus,
  getCeleryTaskStatus,
  deleteAnnotationTask
}
