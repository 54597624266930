import { FileMetadata } from '@/types/dataset'
import { LabelModeToolbar } from './AnnotationToolbar/LabelModeToolbar'

type EditAnnotationToolbarHandlerProps = {
  file: FileMetadata
  onCancelLabeling: () => void
  onSaveLabeling: () => void
}

export const EditAnnotationToolbarHandler = ({
  file,
  onCancelLabeling,
  onSaveLabeling
}: EditAnnotationToolbarHandlerProps) => {
  return (
    <LabelModeToolbar
      fileName={file.name}
      fileType={file.media_type}
      onCancel={onCancelLabeling}
      onSave={onSaveLabeling}
    />
  )
}
