import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient
} from '@tanstack/react-query'
import { objectToQueryString } from '@/utils/url'
import { applicationApi } from '../api/applicationApi'

export const appQueryKeys = {
  all: ['apps'] as const,
  list: () => [...appQueryKeys.all, 'list'] as const,
  details: (id: string) => [...appQueryKeys.all, 'details', id] as const
}

export const useGetApplications = () => {
  return useInfiniteQuery({
    queryKey: appQueryKeys.list(),
    queryFn: ({ pageParam }) =>
      applicationApi.getApplications({ pageUrl: pageParam }),
    getNextPageParam: (lastPage) => {
      return lastPage.next ?? undefined
    },
    initialPageParam: `/v1/applications/?${objectToQueryString({
      limit: 20,
      ordering: 'created_at'
    })}`
  })
}

export const useGetApplicationDetails = (id: string) => {
  return useQuery({
    queryKey: appQueryKeys.details(id),
    queryFn: () => applicationApi.getApplicationDetails(id)
  })
}

export const useCreateApplication = () => {
  return useMutation({
    mutationFn: applicationApi.createApplication
  })
}

export const useUpdateApplication = (appId: string) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: applicationApi.updateApplication,
    onSuccess: () => {
      void queryClient.invalidateQueries({
        queryKey: appQueryKeys.details(appId)
      })
    }
  })
}

export const useDeleteApplication = () => {
  return useMutation({
    mutationFn: applicationApi.deleteApplication
  })
}
