export const getRandomColor = () => {
  const colors = [
    // Gray
    '#868E96',

    // Red
    '#FA5252',

    // Pink
    '#E64980',

    // Grape
    '#BE4BDB',

    // Violet
    '#7950F2',

    // Indigo
    '#4C6EF5',

    // Blue
    '#228BE6',

    // Cyan
    '#15AABF',

    // Teal
    '#12B886',

    // Green
    '#40C057',

    // Lime
    '#82C91E',

    // Yellow
    '#FAB005',

    // Orange
    '#FD7E14'
  ]

  const randomIndex = Math.floor(Math.random() * colors.length)

  return colors[randomIndex]
}
