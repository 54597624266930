import { SelectedEventFiltersType } from '@/components/events/EventsList/DownloadAs/useDownloadAs'
import { Event } from '@/types/event'
import { PaginatedResponse } from '../types/pagination'
import { vpApi } from './vpApi'

type EventsResponse = PaginatedResponse<Event[]>

type GetEventsParams = {
  pageUrl: string
}

const getEvents = async ({ pageUrl }: GetEventsParams) => {
  const res = await vpApi.get<EventsResponse>(pageUrl)
  return res.data
}

type EventParamsType = {
  appId: string
  eventId: string
}

type DeleteEventParams = {
  appId: string
  eventIds: string[]
}

const deleteEvent = ({ appId, eventIds }: DeleteEventParams) => {
  return vpApi.delete(`/v1/applications/${appId}/events/bulk-delete/`, {
    data: {
      event_ids: eventIds
    }
  })
}

type EventDetailsResponse = {
  results: Event
}

const getEventDetails = async ({ appId, eventId }: EventParamsType) => {
  const res = await vpApi.get<EventDetailsResponse>(
    `/v1/applications/${appId}/events/${eventId}/`
  )
  return res.data
}

type GenerateEventsAsParams = {
  appId: string
  data: SelectedEventFiltersType
}

type GenerateEventsAsResponse = {
  task_id: string
}

const generateEventsAs = async ({ appId, data }: GenerateEventsAsParams) => {
  const res = await vpApi.post<GenerateEventsAsResponse>(
    `/v1/applications/${appId}/events/download/`,
    data
  )
  return res
}

type FetchDownloadEventUrlParams = {
  appId: string
  taskId: string
}

type FetchDownloadEventUrlResponse = {
  url: string
}

const fetchDownloadEventUrl = async ({
  appId,
  taskId
}: FetchDownloadEventUrlParams) => {
  const res = await vpApi.get<FetchDownloadEventUrlResponse>(
    `/v1/applications/${appId}/events/download/${taskId}/`
  )
  return res
}

type AddEventNoteParams = {
  appId: string
  eventId: string
  note: string
}

const addEventNote = async ({ appId, eventId, note }: AddEventNoteParams) => {
  return vpApi.put(`/v1/applications/${appId}/events/${eventId}/add-note/`, {
    note
  })
}

const deleteEventNote = async ({ appId, eventId }: EventParamsType) => {
  return vpApi.delete(
    `/v1/applications/${appId}/events/${eventId}/delete-note/`
  )
}

export const eventApi = {
  getEvents,
  getEventDetails,
  deleteEvent,
  generateEventsAs,
  fetchDownloadEventUrl,
  addEventNote,
  deleteEventNote
}
