import { MantineProvider } from '@mantine/core'
import { Notifications } from '@mantine/notifications'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { App } from './App.tsx'
import { initSentry } from './config/sentry.ts'
import { isScenarioSimualatorEnabled } from './mocks/config.ts'
import { theme } from './theme/mantine.ts'

import '@mantine/core/styles.css'
import '@mantine/notifications/styles.css'
import 'react-image-gallery/styles/css/image-gallery.css'
import '@mantine/dropzone/styles.css'
import '@mantine/dates/styles.css'
import 'mantine-react-table/styles.css'

initSentry()

async function enableMocking() {
  if (!isScenarioSimualatorEnabled()) {
    return
  }

  const { worker } = await import('./mocks/browser')

  // `worker.start()` returns a Promise that resolves
  // once the Service Worker is up and ready to intercept requests.
  return worker.start()
}

void enableMocking().then(() => {
  ReactDOM.createRoot(document.getElementById('root')!).render(
    <React.StrictMode>
      <MantineProvider theme={theme}>
        <Notifications />
        <App />
      </MantineProvider>
    </React.StrictMode>
  )
})
