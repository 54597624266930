import { AspectRatio, Box, Button, Image } from '@mantine/core'
import { IconDownload } from '@tabler/icons-react'
import { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import ImagePlaceholder from '@/assets/image-placeholder.png'
import { showToast } from '@/theme/notifications'

type MediaType = 'video' | 'image'

type MediaItemProps = {
  eventId: string
  path: string
  type: MediaType
}

export const MediaItem = ({ eventId, path, type }: MediaItemProps) => {
  const intl = useIntl()
  const [isDownloading, setIsDownloading] = useState(false)

  const handleVideoDownload = async (name: string, path: string) => {
    setIsDownloading(true)

    try {
      const response = await fetch(path)
      const blob = await response.blob()
      const url = URL.createObjectURL(blob)

      const anchor = document.createElement('a')
      anchor.href = url
      anchor.setAttribute('download', `${name}.mp4`)
      document.body.appendChild(anchor)
      anchor.click()
      document.body.removeChild(anchor)
    } catch (_) {
      const message = intl.formatMessage({ id: 'events.download.video.error' })
      showToast(message, 'error')
    }

    setIsDownloading(false)
  }

  if (!path) {
    return null
  }

  return (
    <Box pos="relative">
      <AspectRatio ratio={16 / 10}>
        {type === 'video' ? (
          <video width="100%" style={{ borderRadius: 8 }} controls>
            <source src={path} type="video/mp4" />
          </video>
        ) : (
          <Image src={path} fallbackSrc={ImagePlaceholder} radius={8} />
        )}
      </AspectRatio>

      {type === 'video' && (
        <Box pos="absolute" top={8} right={8}>
          <Button
            size="xs"
            radius="xl"
            leftSection={<IconDownload size={14} />}
            loading={isDownloading}
            styles={{
              label: {
                textTransform: 'lowercase'
              }
            }}
            onClick={() => void handleVideoDownload(eventId, path)}
          >
            <FormattedMessage id="download" />
          </Button>
        </Box>
      )}
    </Box>
  )
}
