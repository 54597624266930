import { Card, Group } from '@mantine/core'
import { ReactNode } from 'react'
import { BORDERED_CARD_STYLES } from '@/theme/customStyles'

type StepActionToolbarProps = {
  leftSection?: ReactNode
  rightSection?: ReactNode
}

export const StepActionToolbar = ({
  leftSection,
  rightSection
}: StepActionToolbarProps) => {
  return (
    <Card styles={BORDERED_CARD_STYLES} p="sm" withBorder>
      <Group justify="space-between">
        <div>{leftSection}</div>

        <div>{rightSection}</div>
      </Group>
    </Card>
  )
}
