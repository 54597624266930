import { Alert, Box, LoadingOverlay, Modal, Text } from '@mantine/core'
import { FormattedMessage, useIntl } from 'react-intl'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { getApiError } from '@/api/helpers/apiError'
import { useClaimDevice } from '@/queries/deviceQueries'
import { RouterPath } from '@/router/paths'
import { showToast } from '@/theme/notifications'
import {
  DeviceActivationForm,
  FormValues
} from '../DeviceActivationForm/DeviceActivationForm'

const DEVICE_NAME_PARAM = 'device_name'

export const DeviceActivationPage = () => {
  const [searchParams] = useSearchParams()
  const intl = useIntl()
  const navigate = useNavigate()
  const { isPending, mutateAsync: claimDevice } = useClaimDevice()

  const deviceName = searchParams.get(DEVICE_NAME_PARAM)

  const handleSubmit = async ({
    activationCode,
    deviceName: deviceLabel
  }: FormValues) => {
    try {
      await claimDevice({
        claim_secret_key: activationCode,
        device_name: deviceName || '',
        device_label: deviceLabel // deviceLabel is the device name from the form
      })

      navigate(RouterPath.root, { replace: true })

      showToast(
        intl.formatMessage({ id: 'devices.activation.success' }),
        'success'
      )
    } catch (err) {
      const { errorMessage } = getApiError(err)
      const message =
        errorMessage || intl.formatMessage({ id: 'devices.activation.error' })

      showToast(message, 'error')
    }
  }

  return (
    <Modal
      title={<FormattedMessage id="devices.activation" />}
      withCloseButton={false}
      closeOnClickOutside={false}
      closeOnEscape={false}
      opened
      onClose={() => {}}
    >
      <Box pos="relative">
        <LoadingOverlay visible={isPending} />

        <Alert mb="xl">
          <Text size="xs">
            <FormattedMessage id="devices.activationDescription" />
          </Text>
        </Alert>

        <DeviceActivationForm
          onSubmit={(values) => void handleSubmit(values)}
        />
      </Box>
    </Modal>
  )
}
