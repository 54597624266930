import { Button, Group, Modal } from '@mantine/core'
import { FileRejection } from '@mantine/dropzone'
import { IconUpload } from '@tabler/icons-react'
import { FormattedMessage } from 'react-intl'
import { ConfirmFileList } from './ConfirmFileList'

type ConfirmUploadModalProps = {
  opened: boolean
  acceptedFiles: File[]
  fileRejections: FileRejection[]
  isLoading: boolean
  isConfirmDisabled: boolean
  onClose: () => void
  onConfirmUpload: () => void
}

export const ConfirmUploadModal = ({
  opened,
  acceptedFiles,
  fileRejections,
  isLoading,
  isConfirmDisabled,
  onClose,
  onConfirmUpload
}: ConfirmUploadModalProps) => {
  return (
    <Modal
      size="lg"
      title={<FormattedMessage id="datasets.upload.uploadFiles" />}
      opened={opened}
      onClose={onClose}
    >
      <>
        <ConfirmFileList
          acceptedFiles={acceptedFiles}
          fileRejections={fileRejections}
        />

        <Group justify="end" mt="lg">
          <Button miw={120} variant="default" onClick={onClose}>
            <FormattedMessage id="cancel" />
          </Button>

          <Button
            leftSection={<IconUpload size={14} />}
            loading={isLoading}
            disabled={isConfirmDisabled}
            onClick={onConfirmUpload}
          >
            <FormattedMessage id="datasets.upload.uploadFiles" />
          </Button>
        </Group>
      </>
    </Modal>
  )
}
