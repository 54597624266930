import { BoxProps, Center, Loader } from '@mantine/core'
import { useIntersection } from '@mantine/hooks'
import { ReactNode, useEffect } from 'react'

type InfiniteScrollProps = {
  hasMore: boolean
  isFetching: boolean
  children: ReactNode
  props?: BoxProps
  onLoadMore: () => void
}

export const InfiniteScroll = ({
  hasMore,
  isFetching,
  children,
  onLoadMore
}: InfiniteScrollProps) => {
  const { ref, entry } = useIntersection({
    threshold: 0.5
  })

  useEffect(() => {
    if (hasMore && entry?.isIntersecting && !isFetching) {
      onLoadMore()
    }
  }, [hasMore, isFetching, entry, onLoadMore])

  return (
    <div>
      {children}

      <Center ref={ref} mih={isFetching ? 120 : undefined}>
        {isFetching && <Loader size={32} />}
      </Center>
    </div>
  )
}
