import { useQueryClient } from '@tanstack/react-query'
import { useCallback } from 'react'
import { datasetQueryKeys, useGetFiles } from '@/queries/datasetLegacyQueries'
import { FileStatus } from '@/types/dataset'

export const useInvalidateFileList = (datasetId: string) => {
  const queryClient = useQueryClient()

  const invalidateFileList = useCallback(() => {
    void queryClient.invalidateQueries({
      queryKey: datasetQueryKeys.files(datasetId)
    })
  }, [queryClient, datasetId])

  return {
    invalidateFileList
  }
}

export const useMediaListStatus = (datasetId: string) => {
  const { data, isFetching } = useGetFiles({
    datasetId,
    enabled: datasetId !== ''
  })

  const files = data?.pages?.flatMap((page) => page.results) || []

  const annotatedFiles = files.filter(
    (file) => file.status === FileStatus.Labeled
  )

  const annotatedImagesCount = annotatedFiles.filter(
    (file) => file.media_type === 'image'
  ).length

  const annotatedVideosCount = annotatedFiles.filter(
    (file) => file.media_type === 'video'
  ).length

  return {
    isMediaListEmpty: files.length === 0 && !isFetching,
    annotatedImagesCount,
    annotatedVideosCount
  }
}
