import { Stack, Text } from '@mantine/core'
import { ReactNode } from 'react'
import { SelectableCard } from '@/components/ui-shared/SelectableCard/SelectableCard'

type PreviewCardProps = {
  icon?: ReactNode
  children: ReactNode
}

export const PreviewCard = ({ icon, children }: PreviewCardProps) => {
  return (
    <SelectableCard>
      <Stack h="100%" align="center" justify="center">
        {icon}

        <Text w="100%" ta="center" size="sm" fw={600}>
          {children}
        </Text>
      </Stack>
    </SelectableCard>
  )
}
