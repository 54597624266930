import { Badge, Group, Stack, Text, ThemeIcon } from '@mantine/core'
import { IconCheck } from '@tabler/icons-react'
import { ReactNode } from 'react'
import { FormattedMessage } from 'react-intl'

type LabelingTypeOptionProps = {
  icon: ReactNode
  name: string | ReactNode
  description: string | ReactNode
  isChecked: boolean
  isDisabled?: boolean
}

export const LabelingTypeOption = ({
  icon,
  name,
  description,
  isChecked,
  isDisabled
}: LabelingTypeOptionProps) => {
  return (
    <Group flex="1" align="start" wrap="nowrap" py={4}>
      {icon}

      <Stack gap={4}>
        <Group wrap="nowrap" gap="xs" align="center">
          <Text size="sm" fw={600}>
            {name}
          </Text>

          {isDisabled && (
            <Badge size="xs" color="brand-secondary.4">
              <FormattedMessage id="comingSoon" />
            </Badge>
          )}
        </Group>

        <Text size="xs">{description}</Text>
      </Stack>

      {isChecked && (
        <ThemeIcon
          color="green"
          size="xs"
          style={{ marginInlineStart: 'auto' }}
        >
          <IconCheck style={{ width: '80%', height: '80%' }} />
        </ThemeIcon>
      )}
    </Group>
  )
}
