import { Button, Loader, Popover, Stack, Text } from '@mantine/core'
import { IconDownload, IconFileDownload } from '@tabler/icons-react'
import { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { showToast } from '@/theme/notifications'
import { DownloadTypeSelect } from './DownloadTypeSelect'
import { DownloadAsParamsType, DownloadType } from './useDownloadAs'

type DownloadSelectedEventsButtonProps = {
  eventIds: string[]
  isDownloadPending: boolean
  onDownloadAs: (params: DownloadAsParamsType) => void
}

const MAX_SELECTED_EVENTS = 50

export const DownloadSelectedEventsButton = ({
  eventIds,
  isDownloadPending,
  onDownloadAs
}: DownloadSelectedEventsButtonProps) => {
  const intl = useIntl()
  const [downloadType, setDownloadType] = useState<DownloadType | null>(null)

  const handleDownloadButtonClick = () => {
    if (eventIds.length > MAX_SELECTED_EVENTS) {
      showToast(
        intl.formatMessage(
          { id: 'events.download.maxSelectedError' },
          { count: MAX_SELECTED_EVENTS }
        ),
        'error'
      )
      return
    }

    onDownloadAs({ eventIds, download_type: downloadType })
  }

  return (
    <Popover width={300} position="bottom" shadow="md">
      <Popover.Target>
        <Button
          disabled={
            isDownloadPending ||
            eventIds.length > MAX_SELECTED_EVENTS ||
            eventIds.length === 0
          }
          leftSection={
            isDownloadPending ? (
              <Loader size={16} />
            ) : (
              <IconFileDownload size={14} />
            )
          }
          color="brand-primary"
        >
          <Text size="sm">
            <FormattedMessage
              id={
                isDownloadPending
                  ? 'events.download.pending'
                  : 'events.list.downloadSelectedAs'
              }
            />
          </Text>
        </Button>
      </Popover.Target>
      <Popover.Dropdown>
        <Stack align="stretch" justify="center" gap="md">
          <DownloadTypeSelect
            value={downloadType}
            onDownloadTypeChange={setDownloadType}
          />
          <Button
            color="brand-primary"
            variant="outline"
            disabled={!downloadType}
            leftSection={<IconDownload size={14} />}
            onClick={handleDownloadButtonClick}
          >
            <Text size="sm">
              <FormattedMessage id={'download'} />
            </Text>
          </Button>
        </Stack>
      </Popover.Dropdown>
    </Popover>
  )
}
