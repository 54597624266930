import {
  Anchor,
  Box,
  Button,
  Grid,
  PasswordInput,
  Text,
  TextInput
} from '@mantine/core'
import { isEmail, isNotEmpty, useForm } from '@mantine/form'
import { FormattedMessage, useIntl } from 'react-intl'
import { PasswordRules } from '@/components/ui-shared/PasswordRules/PasswordRules'
import { VP_PRIVACY_POLICY_URL, VP_TERMS_AND_CONDITIONS_URL } from '@/config'
import { isStrongPassword } from '@/utils/validation/password'

type FormValues = {
  email: string
  firstName: string
  lastName: string
  password: string
  confirmPassword: string
}

export type SubmitValues = Omit<FormValues, 'confirmPassword'>

type SignUpFormProps = {
  initialEmail: string
  onSubmit: (formValues: SubmitValues) => void
}

const messages = {
  email: {
    id: 'email'
  },
  firstName: {
    id: 'firstName'
  },
  lastName: {
    id: 'lastName'
  },
  password: {
    id: 'password'
  },
  confirmPassword: {
    id: 'confirmPassword'
  },
  invalidEmail: {
    id: 'validation.invalidEmail'
  },
  enterYourFirstName: {
    id: 'validation.enterYourFirstName'
  },
  enterYourLastName: {
    id: 'validation.enterYourLastName'
  },
  invalidPassword: {
    id: 'validation.strongPassword.invalid'
  },
  passwordsDoNotMatch: {
    id: 'validation.passwordsDoNotMatch'
  }
}

export const SignUpForm = ({ initialEmail, onSubmit }: SignUpFormProps) => {
  const intl = useIntl()

  const form = useForm({
    initialValues: {
      email: initialEmail,
      firstName: '',
      lastName: '',
      password: '',
      confirmPassword: ''
    },

    validate: {
      email: isEmail(intl.formatMessage(messages.invalidEmail)),
      firstName: isNotEmpty(intl.formatMessage(messages.enterYourFirstName)),
      lastName: isNotEmpty(intl.formatMessage(messages.enterYourLastName)),
      password: isStrongPassword(intl.formatMessage(messages.invalidPassword)),
      confirmPassword: (value, values) =>
        value !== values.password
          ? intl.formatMessage(messages.passwordsDoNotMatch)
          : null
    }
  })

  const handleSubmit = (formValues: FormValues) => {
    const { confirmPassword, ...submitValues } = formValues
    onSubmit(submitValues)
  }

  return (
    <form noValidate onSubmit={form.onSubmit((values) => handleSubmit(values))}>
      <TextInput
        type="email"
        label={intl.formatMessage(messages.email)}
        mb="lg"
        disabled
        {...form.getInputProps('email')}
      />

      <Grid mb="lg">
        <Grid.Col span={6}>
          <TextInput
            label={intl.formatMessage(messages.firstName)}
            autoFocus
            {...form.getInputProps('firstName')}
          />
        </Grid.Col>

        <Grid.Col span={6}>
          <TextInput
            label={intl.formatMessage(messages.lastName)}
            {...form.getInputProps('lastName')}
          />
        </Grid.Col>
      </Grid>

      <Box mb="lg">
        <PasswordInput
          label={intl.formatMessage(messages.password)}
          mb="sm"
          {...form.getInputProps('password')}
        />

        <PasswordRules
          password={form.getInputProps('password').value as string}
        />
      </Box>

      <PasswordInput
        label={intl.formatMessage(messages.confirmPassword)}
        mb="xl"
        {...form.getInputProps('confirmPassword')}
      />

      <Text size="xs" mb="lg">
        <FormattedMessage
          id="acceptTermsAndConditions"
          values={{
            terms: (chunks) => (
              <Anchor
                href={VP_TERMS_AND_CONDITIONS_URL}
                target="_blank"
                inherit
              >
                {chunks}
              </Anchor>
            ),
            privacy: (chunks) => (
              <Anchor href={VP_PRIVACY_POLICY_URL} target="_blank" inherit>
                {chunks}
              </Anchor>
            )
          }}
        />
      </Text>

      <Button type="submit" fullWidth>
        <FormattedMessage id="createAccount" />
      </Button>
    </form>
  )
}
