import { ViewAnnotationToolbarHandler } from '@/components/annotations/ViewAnnotationToolbarHandler'
import { useGetAnnotation } from '@/queries/annotationQueries'
import { FileMetadata, FileStatus } from '@/types/dataset'
import { MediaGallery } from '../MediaGallery/MediaGallery'
import { MediaGalleryOverlay } from '../MediaGallery/MediaGalleryOverlay'

type MediaViewHandlerProps = {
  opened: boolean
  activeFileIndex: number
  files: FileMetadata[]
  onClose: () => void
  onActiveFileChange: (index: number) => void
}

export const MediaViewHandler = ({
  opened,
  activeFileIndex,
  files,
  onClose,
  onActiveFileChange
}: MediaViewHandlerProps) => {
  const activeFile = files[activeFileIndex]

  const { data: annotation, isFetching } = useGetAnnotation({
    mediaId: activeFile?.id,
    enabled:
      opened &&
      activeFile !== undefined &&
      (activeFile.status === FileStatus.Labeled ||
        activeFile.annotation_id !== null)
    // We check for annotation_id because an unlabeled file may have an unfinished annotation with a CVAT task_id and URL.
    // This lets us resume the CVAT session without re-uploading the file.
  })

  const galleryItems = files.map((file) => ({
    original: file.media_file,
    thumbnail: file.thumbnail,
    file
  }))

  return (
    <MediaGalleryOverlay
      opened={opened}
      toolbar={
        activeFile && (
          <ViewAnnotationToolbarHandler
            file={activeFile}
            annotation={annotation || null}
            isLoading={isFetching}
            onStartLabeling={() => {}}
            onViewLabeling={() => {}}
            onRemoveLabeling={() => {}}
          />
        )
      }
      onClose={onClose}
    >
      <MediaGallery
        items={galleryItems}
        startIndex={activeFileIndex}
        showThumbnails
        onSlideChange={onActiveFileChange}
      />
    </MediaGalleryOverlay>
  )
}
