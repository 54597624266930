import { Button, Checkbox, Group, Tabs } from '@mantine/core'
import { useForm } from '@mantine/form'
import { IconVideo } from '@tabler/icons-react'
import { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { LibraryLogic } from '@/types/businessLogic'
import { CameraStreamWithDeviceId } from '@/types/device'
import { deepClone } from '@/utils/clone'
import { getStreamErrors } from '../helpers/getStreamErrors'
import {
  LogicLineValues,
  SavedCalibrationValues,
  SavedStreamLogic
} from '../types'
import { StreamConfig } from './StreamConfig'

type ObjectMeasureHandlerProps = {
  libraryLogic: LibraryLogic
  cameraStreams: CameraStreamWithDeviceId[]
  savedStreamLogics: SavedStreamLogic[]
  liveInferenceCameraStreamIds: string[]
  onCancel: () => void
  onSave: (savedLogics: SavedStreamLogic[]) => void
}

export const ObjectMeasureHandler = ({
  libraryLogic,
  cameraStreams,
  savedStreamLogics,
  liveInferenceCameraStreamIds,
  onCancel,
  onSave
}: ObjectMeasureHandlerProps) => {
  const intl = useIntl()

  const form = useForm<{ cameraStreams: SavedStreamLogic[] }>({
    initialValues: {
      cameraStreams: cameraStreams.map((cameraStream) => {
        const savedLogics = savedStreamLogics
          .filter(
            (streamLogic) => streamLogic.logic_type === libraryLogic.logic_type
          )
          .find(
            (streamLogic) => streamLogic.camera_id === cameraStream.camera_id
          )

        return {
          logic_id: libraryLogic.id,
          logic_type: libraryLogic.logic_type,
          logic_name: libraryLogic.name,
          camera_id: cameraStream.camera_id,
          camera_name: cameraStream.camera_name,
          camera_image: cameraStream.image,
          device_id: cameraStream.device_id,
          live_inference: liveInferenceCameraStreamIds.includes(
            cameraStream.camera_id
          ),
          lines: savedLogics ? deepClone(savedLogics.lines) : [],
          settings: savedLogics ? deepClone(savedLogics.settings) : []
        }
      })
    },
    validate: {
      cameraStreams: {
        lines: {
          triggers: (value) => {
            return value.length === 0
              ? intl.formatMessage({
                  id: 'logic.validation.label.cannotBeEmpty'
                })
              : null
          }
        }
      }
    }
  })

  const [tabValue, setTabValue] = useState(cameraStreams[0].camera_id || '')

  const handleCalibrationSettingAdd = (
    streamIndex: number,
    setting: SavedCalibrationValues
  ) => {
    form.insertListItem(`cameraStreams.${streamIndex}.settings`, setting)
  }

  const handleCalibrationSettingChange = (
    streamIndex: number,
    settingIndex: number,
    setting: SavedCalibrationValues
  ) => {
    form.setFieldValue(
      `cameraStreams.${streamIndex}.settings.${settingIndex}`,
      setting
    )
  }

  const handleCalibrationSettingRemove = (
    streamIndex: number,
    settingIndex: number
  ) => {
    form.removeListItem(`cameraStreams.${streamIndex}.settings`, settingIndex)
  }

  const handleTriggerAdd = (streamIndex: number, trigger: LogicLineValues) => {
    form.insertListItem(`cameraStreams.${streamIndex}.lines`, trigger)
    form.clearErrors()
  }

  const handleTriggerChange = (
    streamIndex: number,
    triggerIndex: number,
    trigger: LogicLineValues
  ) => {
    form.setFieldValue(
      `cameraStreams.${streamIndex}.lines.${triggerIndex}`,
      trigger
    )
    form.clearErrors()
  }

  const handleTriggerRemove = (streamIndex: number, triggerIndex: number) => {
    form.removeListItem(`cameraStreams.${streamIndex}.lines`, triggerIndex)
    form.clearErrors()
  }

  const handleSubmit = (values: SavedStreamLogic[]) => {
    onSave(values)
  }

  const handleLiveInferenceChange = (streamIndex: number, value: boolean) => {
    form.setFieldValue(`cameraStreams.${streamIndex}.live_inference`, value)
  }

  return (
    <form
      noValidate
      onSubmit={form.onSubmit((values) => handleSubmit(values.cameraStreams))}
    >
      <Tabs
        variant="outline"
        value={tabValue}
        onChange={(value) => setTabValue(value || '')}
      >
        <Tabs.List mb="md">
          {form.values.cameraStreams.map((cameraStream) => (
            <Tabs.Tab
              key={cameraStream.camera_id}
              value={cameraStream.camera_id}
            >
              <Group gap="xs">
                <IconVideo size={16} />

                <span>{cameraStream.camera_name}</span>
              </Group>
            </Tabs.Tab>
          ))}
        </Tabs.List>

        {form.values.cameraStreams.map((cameraStream, streamIndex) => (
          <Tabs.Panel
            key={cameraStream.camera_id}
            value={cameraStream.camera_id}
          >
            <StreamConfig
              deviceId={cameraStream.device_id}
              cameraStreamId={cameraStream.camera_id}
              active={tabValue === cameraStream.camera_id}
              settings={form.values.cameraStreams[streamIndex].settings}
              triggers={form.values.cameraStreams[streamIndex].lines}
              streamErrors={getStreamErrors(form.errors, streamIndex)}
              onSettingAdd={(setting) =>
                handleCalibrationSettingAdd(streamIndex, setting)
              }
              onSettingChange={(settingIndex, setting) =>
                handleCalibrationSettingChange(
                  streamIndex,
                  settingIndex,
                  setting
                )
              }
              onSettingRemove={(settingIndex) =>
                handleCalibrationSettingRemove(streamIndex, settingIndex)
              }
              onTriggerAdd={(trigger) => handleTriggerAdd(streamIndex, trigger)}
              onTriggerChange={(triggerIndex, trigger) =>
                handleTriggerChange(streamIndex, triggerIndex, trigger)
              }
              onTriggerRemove={(triggerIndex) =>
                handleTriggerRemove(streamIndex, triggerIndex)
              }
            />

            <Checkbox
              mt="md"
              checked={form.values.cameraStreams[streamIndex].live_inference}
              label={<FormattedMessage id="logic.enableLiveInference" />}
              onChange={(event) =>
                handleLiveInferenceChange(
                  streamIndex,
                  event.currentTarget.checked
                )
              }
            />
          </Tabs.Panel>
        ))}
      </Tabs>

      <Group justify="end" mt="xl">
        <Button miw={160} variant="default" onClick={onCancel}>
          <FormattedMessage id="cancel" />
        </Button>

        <Button miw={160} type="submit">
          <FormattedMessage id="save" />
        </Button>
      </Group>
    </form>
  )
}
