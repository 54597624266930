import { Group, Text, ThemeIcon } from '@mantine/core'
import { IconCircleFilled } from '@tabler/icons-react'
import { FormattedMessage } from 'react-intl'

type OnlineStatusProps = {
  status: 'online' | 'offline'
  withText?: boolean
}

export const OnlineStatus = ({
  status,
  withText = true
}: OnlineStatusProps) => {
  const color = status === 'online' ? 'green' : 'red'

  const messageId =
    status === 'online' ? 'devices.status.online' : 'devices.status.offline'

  return (
    <Group wrap="nowrap" gap={4} align="center">
      <ThemeIcon variant="transparent" color={color} size={10}>
        <IconCircleFilled style={{ width: '100%', height: '100%' }} />
      </ThemeIcon>

      {withText && (
        <Text size="xs">
          <FormattedMessage id={messageId} />
        </Text>
      )}
    </Group>
  )
}
