import { Dataset, FileMetadata, UploadStatus } from '@/types/dataset'
import { PaginatedResponse } from '@/types/pagination'
import { vpApi } from './vpApi'

type DatasetsResponse = PaginatedResponse<Dataset[]>

type PageUrlParams = {
  pageUrl: string
}

const getDatasets = async ({ pageUrl }: PageUrlParams) => {
  const res = await vpApi.get<DatasetsResponse>(pageUrl)
  return res.data
}

const getDatasetDetails = async (datasetId: string) => {
  const res = await vpApi.get<Dataset>(`/v2/datasets/${datasetId}/`)
  return res.data
}

type FilesResponse = PaginatedResponse<FileMetadata>

const getDatasetFiles = async ({ pageUrl }: PageUrlParams) => {
  const res = await vpApi.get<FilesResponse>(pageUrl)
  return res.data
}

type CreateDatasetData = Pick<
  Dataset,
  'name' | 'description' | 'type' | 'classes' | 'labelingTypes'
>

const createDataset = (data: CreateDatasetData) => {
  return vpApi.post('/v2/datasets/', data)
}

type UpdateDatasetParams = {
  datasetId: string
  data: Partial<Dataset>
}

const updateDataset = ({ datasetId, data }: UpdateDatasetParams) => {
  return vpApi.patch(`/v2/datasets/${datasetId}/`, data)
}

type UploadFilesParams = {
  versionId: string
  media_files: File[]
}

type UploadFilesResponse = {
  batch_id: string
}

const uploadFilesToDataset = ({
  versionId,
  media_files
}: UploadFilesParams) => {
  const formData = new FormData()

  media_files.forEach((file) => formData.append('media_files', file))

  return vpApi.put<UploadFilesResponse>(
    `/v2/datasets/versions/${versionId}/add-media/`,
    formData
  )
}

const getFileUploadStatus = async (batchId: string) => {
  const res = await vpApi.get<UploadStatus>(
    `/v2/datasets/${batchId}/upload-status/`
  )
  return res.data
}

export const datasetApi = {
  getDatasets,
  getDatasetDetails,
  getDatasetFiles,
  createDataset,
  updateDataset,
  uploadFilesToDataset,
  getFileUploadStatus
}
