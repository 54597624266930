import { Box } from '@mantine/core'
import { useState } from 'react'
import { CameraSource, Device } from '@/types/device'
import { ImportProvider } from './ImportContext'
import { IntegrationsList } from './IntegrationsList/IntegrationsList'
import { MilestoneImportHandler } from './milestone/MilestoneImportHandler'

type ImportStreamHandlerProps = {
  devices: Device[]
  onImportCompleted: () => void
  onClose: () => void
}

export const ImportStreamHandler = ({
  devices,
  onImportCompleted,
  onClose
}: ImportStreamHandlerProps) => {
  const [importSource, setImportSource] = useState<CameraSource | null>(null)

  return (
    <Box mih={480}>
      {importSource === null && (
        <IntegrationsList onImportClick={setImportSource} />
      )}

      {importSource === CameraSource.Milestone && (
        <ImportProvider
          devices={devices}
          onImportCompleted={onImportCompleted}
          onClose={onClose}
        >
          <MilestoneImportHandler />
        </ImportProvider>
      )}
    </Box>
  )
}
