import { Progress, Stack, Text, Tooltip } from '@mantine/core'
import { FormattedMessage } from 'react-intl'
import {
  MIN_ANNOTATED_IMAGES_COUNT,
  MIN_ANNOTATED_VIDEOS_COUNT,
  MIN_ANNOTATION_POINTS
} from '@/config/annotation'
import { getAnnotationPoints } from '../helpers/getAnnotationPoints'

type AnnotationProgressBarProps = {
  annotatedImagesCount: number
  annotatedVideosCount: number
}

export const AnnotationProgressBar = ({
  annotatedImagesCount,
  annotatedVideosCount
}: AnnotationProgressBarProps) => {
  const annotationProgressValue =
    (getAnnotationPoints(annotatedImagesCount, annotatedVideosCount) /
      MIN_ANNOTATION_POINTS) *
    100

  return (
    <Tooltip
      label={
        <FormattedMessage
          id="annotation.minRequirements"
          values={{
            minImageCount: MIN_ANNOTATED_IMAGES_COUNT,
            minVideoCount: MIN_ANNOTATED_VIDEOS_COUNT
          }}
        />
      }
    >
      <Stack gap={4}>
        <Text size="xs">
          <FormattedMessage
            id="annotation.imagesLabeled"
            values={{
              count: annotatedImagesCount
            }}
          />

          {', '}

          <FormattedMessage
            id="annotation.videosLabeled"
            values={{
              count: annotatedVideosCount
            }}
          />
        </Text>

        <Progress w={200} size="sm" value={annotationProgressValue} />
      </Stack>
    </Tooltip>
  )
}
