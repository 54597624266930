import { Box, Button, Group, LoadingOverlay, Modal, Text } from '@mantine/core'
import { FormattedMessage, useIntl } from 'react-intl'
import { getApiError } from '@/api/helpers/apiError'
import { useDeleteAnnotationTask } from '@/queries/annotationQueries'
import { showToast } from '@/theme/notifications'

type DeleteAnnotationModalProps = {
  opened: boolean
  taskId: number
  onClose: () => void
  onDelete: () => void
}

export const DeleteAnnotationModal = ({
  opened,
  taskId,
  onClose,
  onDelete
}: DeleteAnnotationModalProps) => {
  const intl = useIntl()
  const { isPending, mutateAsync: deleteAnnotationTask } =
    useDeleteAnnotationTask()

  const handleTaskRemove = async () => {
    try {
      await deleteAnnotationTask(taskId)

      onDelete()

      showToast(
        intl.formatMessage({ id: 'annotation.labelingDeleted' }),
        'success'
      )
    } catch (err) {
      const { errorMessage } = getApiError(err)

      const message =
        errorMessage ||
        intl.formatMessage({ id: 'annotation.labelingDeleteError' })

      showToast(message, 'error')
    }
  }

  return (
    <Modal
      opened={opened}
      title={<FormattedMessage id="annotation.removeLabeling" />}
      onClose={onClose}
    >
      <Box pos="relative">
        <LoadingOverlay visible={isPending} />

        <Text size="sm" mb="xl">
          <FormattedMessage id="annotation.removeConfirmation" />
        </Text>

        <Group justify="end">
          <Button variant="default" onClick={onClose}>
            <FormattedMessage id="cancel" />
          </Button>

          <Button color="red" onClick={() => void handleTaskRemove()}>
            <FormattedMessage id="annotation.removeLabeling" />
          </Button>
        </Group>
      </Box>
    </Modal>
  )
}
