import { CloseButton, Group, NumberInput, Text, Tooltip } from '@mantine/core'
import { FormattedMessage } from 'react-intl'

type CalibrationLineProps = {
  name: string
  color: string
  size: number
  unit: string
  onSizeChange: (value: number) => void
  onRemove: () => void
}

export const CalibrationLine = ({
  name,
  color,
  size,
  unit,
  onSizeChange,
  onRemove
}: CalibrationLineProps) => {
  const handleSizeChange = (value: string | number) => {
    if (value === '') {
      onSizeChange(0)
      return
    }

    onSizeChange(value as number)
  }

  return (
    <Group>
      <Text size="xs" fw={600} c={color}>
        {name}
      </Text>

      <NumberInput
        value={size}
        min={0}
        decimalScale={2}
        suffix={` ${unit}`}
        w={200}
        onChange={handleSizeChange}
      />

      <Tooltip label={<FormattedMessage id="remove" />}>
        <CloseButton size="sm" onClick={onRemove} />
      </Tooltip>
    </Group>
  )
}
