import { Box, Button, Group, LoadingOverlay, Modal, Text } from '@mantine/core'
import { IconTrash } from '@tabler/icons-react'
import { FormattedMessage, useIntl } from 'react-intl'
import { getApiError } from '@/api/helpers/apiError'
import { useRemoveCameraStream } from '@/queries/deviceQueries'
import { showToast } from '@/theme/notifications'

const ModalTitle = () => (
  <Group>
    <IconTrash size={20} />
    <FormattedMessage id="streams.remove" />
  </Group>
)

type RemoveStreamConfirmationProps = {
  opened: boolean
  cameraStreamId: string
  cameraStreamName: string
  deviceId: string
  deviceName: string
  onClose: () => void
  onRemovalSuccess: () => void
}

export const RemoveStreamConfirmation = ({
  opened,
  cameraStreamId,
  cameraStreamName,
  deviceId,
  deviceName,
  onClose,
  onRemovalSuccess
}: RemoveStreamConfirmationProps) => {
  const intl = useIntl()
  const { mutateAsync: removeStream, isPending } = useRemoveCameraStream()

  const handleRemoveStream = async () => {
    try {
      await removeStream({
        deviceId,
        cameraStreamId
      })

      showToast(intl.formatMessage({ id: 'streams.remove.success' }), 'success')
      onRemovalSuccess()
    } catch (err) {
      const { errorMessage } = getApiError(err)
      const message =
        errorMessage || intl.formatMessage({ id: 'streams.remove.error' })
      showToast(message, 'error')
    }
  }

  return (
    <Modal size="lg" title={<ModalTitle />} opened={opened} onClose={onClose}>
      <Box pos="relative">
        <LoadingOverlay visible={isPending} />

        <Text size="sm" mb="xl">
          <FormattedMessage
            id="streams.remove.confirmation"
            values={{
              b: (chunks) => <b>{chunks}</b>,
              stream: cameraStreamName,
              device: deviceName
            }}
          />
        </Text>

        <Group justify="end">
          <Button miw={160} variant="default" onClick={onClose}>
            <FormattedMessage id="cancel" />
          </Button>

          <Button color="red" onClick={() => void handleRemoveStream()}>
            <FormattedMessage id="streams.remove" />
          </Button>
        </Group>
      </Box>
    </Modal>
  )
}
