import {
  Badge,
  Button,
  Card,
  Grid,
  Group,
  Image,
  Stack,
  Text
} from '@mantine/core'
import { FormattedMessage } from 'react-intl'
import VpLogoStripes from '@/assets/vp-stripes.png'
import { BillingPeriod } from '@/types/subscription'
import { getFormattedDate } from '@/utils/date'
import { getProductPrice } from '@/utils/subscription'
import { FormattedPrice } from '../FormattedPrice/FormattedPrice'

const StatusChip = ({ status }: { status: string }) => {
  if (status === 'active') {
    return (
      <Badge size="xs" radius="xl" color="green">
        <FormattedMessage id="subscriptions.status.active" />
      </Badge>
    )
  }

  return null
}

type UserSubscriptionInfoProps = {
  name: string
  status: string
  billingPeriod: BillingPeriod
  price: number
  currency: string
  cancelledAt?: string
  onManageClick: () => void
}

export const UserSubscriptionInfo = ({
  name,
  status,
  billingPeriod,
  price,
  currency,
  cancelledAt,
  onManageClick
}: UserSubscriptionInfoProps) => {
  return (
    <Card p="lg" withBorder>
      <Grid>
        <Grid.Col span="auto">
          <Group align="start" wrap="nowrap">
            <Image src={VpLogoStripes} w={36} h={36} />

            <Stack gap="xs">
              <Group>
                <Text fw="bold">{name}</Text>
                <StatusChip status={status} />
              </Group>

              <FormattedPrice
                price={getProductPrice(price)}
                currency={currency}
                billingPeriod={billingPeriod}
              />

              {cancelledAt && (
                <Text size="xs" c="red">
                  <FormattedMessage
                    id="subscriptions.willBeCancelled"
                    values={{
                      date: getFormattedDate(cancelledAt)
                    }}
                  />
                </Text>
              )}
            </Stack>
          </Group>
        </Grid.Col>

        <Grid.Col span="content">
          <Button onClick={onManageClick}>
            <FormattedMessage id="subscriptions.manage" />
          </Button>
        </Grid.Col>
      </Grid>
    </Card>
  )
}
