import { Box, Center, Overlay, SimpleGrid, Text, Title } from '@mantine/core'
import { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { getApiError } from '@/api/helpers/apiError'
import { ErrorWithReload } from '@/components/ui-shared/ErrorWithReload/ErrorWithReload'
import { useDeviceDetect } from '@/hooks/useDeviceDetect'
import {
  useCreateCheckoutSession,
  useGetProducts
} from '@/queries/accountQueries'
import { showToast } from '@/theme/notifications'
import { BillingPeriod, Product } from '@/types/subscription'
import { getProductMonthlyPrice } from '@/utils/subscription'
import { BillingPeriodSelector } from './BillingPeriodSelector/BillingPeriodSelector'
import { CompareFeaturesButton } from './CompareFeaturesButton'
import { ContactUsDrawer } from './ContactUsDrawer'
import { CustomPlanCard } from './CustomPlanCard/CustomPlanCard'
import { DeviceSelectionDrawer } from './DeviceSelection/DeviceSelectionDrawer'
import { LoadingSkeletons } from './LoadingSkeletons'
import { SubscriptionPlanCard } from './SubscriptionPlanCard/SubscriptionPlanCard'

const filterSubscriptionPlans = (
  products: Product[],
  billingPeriod: BillingPeriod
) => {
  return products
    .filter(
      // Filter subscription plans, ignore other products like jetsons
      (product) => product.product_metadata.product_type === 'subscription_plan'
    )
    .filter((product) => {
      // Free plan should always be displayed
      if (product.price === 0) {
        return true
      }

      if (billingPeriod === 'monthly') {
        return product.freq === 'month_1'
      }

      if (billingPeriod === 'yearly') {
        return product.freq === 'year_1'
      }

      return false
    })
    .sort((a, b) => {
      return a.price - b.price
    })
}

const getJetsonProducts = (
  subscriptionPlan: Product | null,
  products: Product[]
) => {
  if (!subscriptionPlan) {
    return []
  }

  return products
    .filter((product) => {
      return product.product_metadata.product_type === 'jetson_device'
    })
    .sort((a, b) => {
      return a.price - b.price
    })
}

export const ChooseSubscriptionPage = () => {
  const intl = useIntl()
  const { isMobile } = useDeviceDetect()

  const [billingPeriod, setBillingPeriod] = useState<BillingPeriod>('monthly')
  const [deviceDrawerIsOpened, setDeviceDrawerIsOpened] = useState(false)
  const [contactUsDrawerIsOpened, setContactUsDrawerIsOpened] = useState(false)
  const [selectedSubscriptionPlan, setSelectedSubscriptionPlan] =
    useState<Product | null>(null)

  const { data, isLoading, isError, refetch } = useGetProducts()
  const { mutateAsync, isPending: isPendingCheckoutSession } =
    useCreateCheckoutSession()

  const subscriptionPlans = data
    ? filterSubscriptionPlans(data, billingPeriod)
    : []

  const openDeviceDrawer = () => {
    setDeviceDrawerIsOpened(true)
  }

  const closeDeviceDrawer = () => {
    setDeviceDrawerIsOpened(false)
  }

  const openContactUsDrawer = () => {
    setContactUsDrawerIsOpened(true)
  }

  const closeContactUsDrawer = () => {
    setContactUsDrawerIsOpened(false)
  }

  const handleSelectPlan = (product: Product) => {
    setSelectedSubscriptionPlan(product)
    openDeviceDrawer()
  }

  const handleCheckoutConfirm = async (productIds: string[]) => {
    closeDeviceDrawer()

    try {
      const session = await mutateAsync(productIds)

      // Navigate to Stripe checkout page
      window.location.href = session.url
    } catch (err) {
      const { errorMessage } = getApiError(err)

      const message =
        errorMessage ||
        intl.formatMessage({ id: 'subscriptions.createCheckoutError' })

      showToast(message, 'error')
    }
  }

  if (isLoading) {
    return <LoadingSkeletons isMobile={isMobile} />
  }

  if (isError) {
    return (
      <ErrorWithReload onReload={() => void refetch()}>
        <FormattedMessage id="subscriptions.failedToFetchPlans" />
      </ErrorWithReload>
    )
  }

  return (
    <>
      <Box ta="center" mt={40} mb={80}>
        <Title order={2} fz={32} mb="xl">
          <FormattedMessage id="subscriptions.chooseYourPlan" />
        </Title>

        <Text mb="lg">
          <FormattedMessage id="subscriptions.chooseBillingPeriod" />
        </Text>

        <BillingPeriodSelector
          value={billingPeriod}
          onChange={setBillingPeriod}
        />
      </Box>

      <SimpleGrid cols={isMobile ? 1 : 5} spacing="lg" mb={60}>
        {subscriptionPlans.map((product) => (
          <SubscriptionPlanCard
            key={product.product_id}
            title={product.product_metadata.display_name}
            description={product.description || ''}
            price={getProductMonthlyPrice(product.price, billingPeriod)}
            currency={product.currency}
            billingPeriod="monthly"
            features={[]}
            onSelect={() => void handleSelectPlan(product)}
          />
        ))}
        <CustomPlanCard
          title="Enterprise"
          onContactClick={openContactUsDrawer}
        />
      </SimpleGrid>

      <Box ta="center">
        <CompareFeaturesButton />
      </Box>

      <DeviceSelectionDrawer
        isOpened={deviceDrawerIsOpened}
        subscriptionPlan={selectedSubscriptionPlan}
        jetsonProducts={getJetsonProducts(selectedSubscriptionPlan, data || [])}
        canAddJetson={selectedSubscriptionPlan?.price !== 0}
        onConfirm={(productIds) => void handleCheckoutConfirm(productIds)}
        onClose={closeDeviceDrawer}
      />

      <ContactUsDrawer
        opened={contactUsDrawerIsOpened}
        onClose={closeContactUsDrawer}
      />

      {isPendingCheckoutSession && (
        <Overlay backgroundOpacity={0.7} fixed>
          <Center h="100%">
            <Text size="lg" c="gray.1">
              <FormattedMessage id="pleaseWait" />
            </Text>
          </Center>
        </Overlay>
      )}
    </>
  )
}
