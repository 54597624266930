import { KonvaEventObject } from 'konva/lib/Node'
import { Stage as StageType } from 'konva/lib/Stage'
import { useMemo, useState } from 'react'
import { getRandomColor } from '@/theme/randomColor'
import { LogicLineValues } from '../../types'
import { LineAddParams } from '../AnnotationArea'

type UseRoiParams = {
  lines: LogicLineValues[]
  onLineAdd: (params: LineAddParams) => void
  onLineChange: (lineIndex: number, line: LogicLineValues) => void
}

export const useRoi = ({ lines, onLineAdd, onLineChange }: UseRoiParams) => {
  const [points, setPoints] = useState<number[][]>([])
  const [position, setPosition] = useState([0, 0])
  const [color, setColor] = useState(getRandomColor())
  const [isMouseOverPoint, setIsMouseOverPoint] = useState(false)
  const [isCompleted, setIsCompleted] = useState(true)

  const flattenedPoints = useMemo(() => {
    return points.concat(position).reduce((a, b) => a.concat(b), [])
  }, [points, position])

  const reset = () => {
    setPoints([])
    setIsMouseOverPoint(false)
    setColor(getRandomColor())
  }

  const exit = () => {
    if (isCompleted) {
      return
    }

    reset()
    setIsCompleted(true)
  }

  const start = () => {
    reset()
    setIsCompleted(false)
  }

  const getMousePos = (stage: StageType | null) => {
    const pointerPosition = stage?.getPointerPosition()

    if (pointerPosition) {
      return [pointerPosition.x, pointerPosition.y]
    }

    return [0, 0]
  }

  const handleMouseDown = (e: KonvaEventObject<MouseEvent>) => {
    if (isCompleted) {
      return
    }

    const stage = e.target.getStage()
    const mousePos = getMousePos(stage)

    if (isMouseOverPoint && points.length === 4) {
      const roisCount = lines.filter((line) => line.type === 'roi').length

      onLineAdd({
        lineType: 'roi',
        name: `area${roisCount + 1}`,
        color,
        points: points
      })

      exit()
    }

    if (!isMouseOverPoint && points.length < 4) {
      setPoints([...points, mousePos])
    }
  }

  const handleMouseMove = (e: KonvaEventObject<MouseEvent>) => {
    const stage = e.target.getStage()
    const mousePos = getMousePos(stage)
    setPosition(mousePos)
  }

  const handleMouseOverStartPoint = (e: KonvaEventObject<MouseEvent>) => {
    if (points.length < 4) {
      return
    }

    e.target.scale({ x: 1.5, y: 1.5 })
    setIsMouseOverPoint(true)
  }

  const handleMouseOutStartPoint = (e: KonvaEventObject<MouseEvent>) => {
    e.target.scale({ x: 1, y: 1 })
    setIsMouseOverPoint(false)
  }

  const handlePointDragMove = (
    e: KonvaEventObject<DragEvent>,
    lineIndex: number
  ) => {
    const stage = e.target.getStage()
    const index = e.target.index - 1
    const lastPos = e.target._lastPos

    if (!stage || !lastPos) {
      return
    }

    const pos = [lastPos.x, lastPos.y]

    if (pos[0] < 0) {
      pos[0] = 0
    }

    if (pos[1] < 0) {
      pos[1] = 0
    }

    if (pos[0] > stage.width()) {
      pos[0] = stage.width()
    }

    if (pos[1] > stage.height()) {
      pos[1] = stage.height()
    }

    const activeLine = lines[lineIndex]

    onLineChange(lineIndex, {
      ...activeLine,
      points: [
        ...activeLine.points.slice(0, index),
        pos,
        ...activeLine.points.slice(index + 1)
      ]
    })
  }

  const handleGroupDragEnd = (
    e: KonvaEventObject<DragEvent>,
    lineIndex: number
  ) => {
    if (e.target.name() === 'draggable_roi_polygon') {
      const result = [] as number[][]
      const activeLine = lines[lineIndex]
      const copyPoints = [...activeLine.points]

      copyPoints.map((point) =>
        result.push([point[0] + e.target.x(), point[1] + e.target.y()])
      )

      e.target.position({ x: 0, y: 0 })

      onLineChange(lineIndex, {
        ...activeLine,
        points: [...result]
      })
    }
  }

  return {
    color,
    points,
    flattenedPoints,
    isCompleted,
    exit,
    start,
    handleMouseMove,
    handleMouseDown,
    handleMouseOverStartPoint,
    handleMouseOutStartPoint,
    handleGroupDragEnd,
    handlePointDragMove
  }
}
