import { SimpleGrid, Stack, Title } from '@mantine/core'
import { FormattedMessage } from 'react-intl'
import { ErrorWithReload } from '@/components/ui-shared/ErrorWithReload/ErrorWithReload'
import { useGetDatasets } from '@/queries/datasetQueries'
import { AddButtonCard } from '../../ui-shared/AddButtonCard/AddButtonCard'
import { InfiniteScroll } from '../../ui-shared/InfiniteScroll/InfiniteScroll'
import { DatasetCard } from '../DatasetCard/DatasetCard'

type OwnDatasetListProps = {
  selectedDatasetId: string
  onCreateDataset: () => void
  onPreviewDataset: (datasetId: string) => void
  onSelectDataset: (datasetId: string) => void
}

export const OwnDatasetList = ({
  selectedDatasetId,
  onCreateDataset,
  onPreviewDataset,
  onSelectDataset
}: OwnDatasetListProps) => {
  const { data, isFetching, isError, refetch, fetchNextPage, hasNextPage } =
    useGetDatasets()

  const datasets = data?.pages?.flatMap((page) => page.results) || []

  if (isError) {
    return (
      <ErrorWithReload onReload={() => void refetch()}>
        <FormattedMessage id="datasets.list.error" />
      </ErrorWithReload>
    )
  }

  return (
    <Stack mt={10}>
      <Title order={4}>
        <FormattedMessage id="datasets.own" />
      </Title>

      <InfiniteScroll
        hasMore={hasNextPage}
        isFetching={isFetching}
        onLoadMore={() => void fetchNextPage()}
      >
        <SimpleGrid cols={{ base: 1, sm: 2, lg: 4, xl: 7 }} spacing="xl">
          <AddButtonCard
            height={150}
            label={<FormattedMessage id="datasets.add" />}
            onClick={onCreateDataset}
          />

          {datasets.map((dataset) => (
            <DatasetCard
              key={dataset.id}
              name={dataset.name}
              thumbnailUrl={`https://picsum.photos/id/1/200/300`}
              fileCount={1}
              datasetType={dataset.type}
              isSelected={selectedDatasetId === dataset.id}
              onDelete={() => {}}
              onPreview={() => onPreviewDataset(dataset.id)}
              onSelect={() => onSelectDataset(dataset.id)}
            />
          ))}
        </SimpleGrid>
      </InfiniteScroll>
    </Stack>
  )
}
