import { Box, Card, Overlay, Transition } from '@mantine/core'
import { ReactNode } from 'react'

export const TOOLBAR_HEIGHT = 62

type AnnotationOverlayProps = {
  opened: boolean
  toolbar: ReactNode
  children: ReactNode
}

export const AnnotationOverlay = ({
  opened,
  toolbar,
  children
}: AnnotationOverlayProps) => {
  return (
    <Transition mounted={opened}>
      {(transitionStyles) => (
        <Overlay style={transitionStyles} backgroundOpacity={0.85} fixed>
          <Box w="100%">
            <Card radius={0}>{toolbar}</Card>

            <Box pos="relative">{children}</Box>
          </Box>
        </Overlay>
      )}
    </Transition>
  )
}
