export const getFormattedDate = (
  dateTimeString: string,
  options?: Intl.DateTimeFormatOptions
) => {
  return new Date(dateTimeString).toLocaleDateString(navigator.language, {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
    ...options
  })
}

export const getFormattedDateWithTime = (dateTimeString: string | number) => {
  return new Date(dateTimeString).toLocaleDateString(navigator.language, {
    day: 'numeric',
    month: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric'
  })
}
