import { Box, Grid, LoadingOverlay, Stack } from '@mantine/core'
import { FormattedMessage } from 'react-intl'
import { useGetEventDetails } from '@/queries/eventQueries'
import { ErrorWithReload } from '../../ui-shared/ErrorWithReload/ErrorWithReload'
import { EventDetailLeftPane } from './EventDetailLeftPane'
import { EventDetailRightPane } from './EventDetailRightPane'
import { EventToolbar } from './EventToolbar/EventToolbar'

type EventDetailProps = {
  appId: string
  eventId: string
  onBackClick: () => void
}

export const EventDetail = ({
  appId,
  eventId,
  onBackClick
}: EventDetailProps) => {
  const {
    data,
    isLoading,
    isError,
    refetch: refetchEvent
  } = useGetEventDetails(appId, eventId)

  const eventDetails = data?.results

  if (isError) {
    return (
      <ErrorWithReload onReload={() => void refetchEvent()}>
        <FormattedMessage id="events.details.fetchError" />
      </ErrorWithReload>
    )
  }

  if (isLoading) {
    return (
      <Box pos="relative" mih={120}>
        <LoadingOverlay visible />
      </Box>
    )
  }

  if (eventDetails) {
    return (
      <Stack>
        <EventToolbar
          timestamp={eventDetails.timestamp.$date}
          onBackClick={onBackClick}
        />

        <Grid>
          <EventDetailLeftPane event={eventDetails} />
          <EventDetailRightPane event={eventDetails} />
        </Grid>
      </Stack>
    )
  }
}
