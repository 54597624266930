import { Button, Group, SimpleGrid, Stack, Title } from '@mantine/core'
import { IconChevronRight } from '@tabler/icons-react'
import { useEffect, useMemo } from 'react'
import { FormattedMessage } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { StepSection } from '@/components/deployments/deployment-editor/StepSection'
import { ErrorWithReload } from '@/components/ui-shared/ErrorWithReload/ErrorWithReload'
import { InfiniteScroll } from '@/components/ui-shared/InfiniteScroll/InfiniteScroll'
import { useGetUserMlModels } from '@/queries/modelQueries'
import { ApplicationNestedPath, buildAppLink } from '@/router/paths'
import { UserMLModel, UserMLModelStatus } from '@/types/model'
import { ModelCard } from './ModelCard/ModelCard'

type ModelSelectionProps = {
  applicationId: string
  defaultModelId?: string
  singleModelSelection?: boolean
  selectedModel: UserMLModel | null
  onSelectionChange: (model: UserMLModel | null) => void
  onContinue: () => void
}

export const ModelSelection = ({
  applicationId,
  defaultModelId,
  singleModelSelection,
  selectedModel,
  onSelectionChange,
  onContinue
}: ModelSelectionProps) => {
  const navigate = useNavigate()

  const { data, isFetching, isError, refetch, fetchNextPage, hasNextPage } =
    useGetUserMlModels(applicationId, {
      status: [UserMLModelStatus.PreTrained, UserMLModelStatus.Trained],
      id: singleModelSelection && defaultModelId ? defaultModelId : undefined
    })

  const models = useMemo(
    () => data?.pages.flatMap((page) => page.results) ?? [],
    [data]
  )

  useEffect(() => {
    if (defaultModelId && models.length > 0) {
      const preSelectedModel = models.find(
        (model) => model.id === defaultModelId
      )

      if (preSelectedModel && preSelectedModel.id !== selectedModel?.id) {
        onSelectionChange(preSelectedModel)
      }
    }
  }, [defaultModelId, selectedModel, models, onSelectionChange])

  const handleLoadMore = () => {
    void fetchNextPage()
  }

  const handleOnReload = () => {
    void refetch()
  }

  const isModelSelected = (modelId: string) => {
    return selectedModel?.id === modelId
  }

  const toggleModelSelection = (model: UserMLModel) => {
    if (isModelSelected(model.id)) {
      onSelectionChange(null)
    } else {
      onSelectionChange(model)
    }
  }

  const handleGoToModels = () => {
    navigate(buildAppLink(applicationId, ApplicationNestedPath.models))
  }

  if (isError) {
    return (
      <ErrorWithReload onReload={handleOnReload}>
        <FormattedMessage id="models.modelList.apiError" />
      </ErrorWithReload>
    )
  }

  return (
    <>
      <Group align="center" gap="lg" mb="md">
        <Title order={3}>
          <FormattedMessage id="deployments.step1" />
        </Title>
      </Group>

      <StepSection
        actions={
          <Button
            miw={200}
            size="md"
            rightSection={<IconChevronRight size={16} stroke={3} />}
            disabled={selectedModel === null}
            onClick={onContinue}
          >
            <FormattedMessage id="deployments.nextStep" />
          </Button>
        }
      >
        {models.length === 0 && !isFetching && (
          <Stack align="center" justify="center" gap="xl">
            <FormattedMessage id="deployments.noTrainedModels" />

            <Button onClick={handleGoToModels}>
              <FormattedMessage id="deployments.goToMyModels" />
            </Button>
          </Stack>
        )}

        <InfiniteScroll
          hasMore={hasNextPage}
          isFetching={isFetching}
          onLoadMore={handleLoadMore}
        >
          <SimpleGrid cols={{ base: 1, sm: 2, lg: 4, xl: 6 }} spacing="md">
            {models.map((model) => (
              <ModelCard
                key={model.id}
                name={model.name}
                status={model.status}
                isSelected={isModelSelected(model.id)}
                onClick={() => toggleModelSelection(model)}
              />
            ))}
          </SimpleGrid>
        </InfiniteScroll>
      </StepSection>
    </>
  )
}
