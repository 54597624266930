import { getRandomColor } from '@/theme/randomColor'
import {
  BusinessLogicSetting,
  BusinessLogicSettingType,
  LibraryLogicType,
  Parameter,
  ParameterType
} from '@/types/businessLogic'
import { Deployment } from '@/types/deployment'
import { CameraStreamWithDeviceId } from '@/types/device'
import {
  LogicLineValues,
  LogicLineValuesType,
  SavedLogicSetting,
  SavedStreamLogic
} from '../LogicSelection/types'

export const stringCoordinatesToArray = (coordinates: string): number[][] => {
  const coords = coordinates.split(';')
  const result = []

  for (let i = 0; i < coords.length; i += 2) {
    result.push([Number(coords[i]), Number(coords[i + 1])])
  }

  return result
}

const getParameterType = (
  parameterType: ParameterType
): LogicLineValuesType => {
  switch (parameterType) {
    case ParameterType.ROI:
      return 'roi'
    case ParameterType.LineCrossing:
      return 'line_crossing'
  }
}

const buildLogicLine = (param: Parameter): LogicLineValues => {
  return {
    type: getParameterType(param.parameter_type),
    name: param.name,
    color: getRandomColor(),
    points: stringCoordinatesToArray(param.coordinates),
    triggers: param.event.triggers,
    notification: param.event.alert,
    video_upload: param.event.upload
  }
}

const buildSavedSettings = (
  setting: BusinessLogicSetting
): SavedLogicSetting[] => {
  return setting.parameters.map((param) => {
    return {
      type: setting.setting_type,
      side: param.side,
      points: stringCoordinatesToArray(param.coordinates),
      size: param.size,
      unit: param.unit,
      color: '#F03E3E'
    }
  })
}

export const buildSavedStreamLogic = (
  deployments: Deployment[],
  cameraStreams: CameraStreamWithDeviceId[]
): SavedStreamLogic[] => {
  return deployments
    .map((deployment) => {
      const { settings_payload } = deployment
      const streamData = cameraStreams.find(
        (stream) => stream.camera_id === settings_payload.stream_id
      )

      return settings_payload.business_logics.map((logic) => {
        return {
          logic_id: logic.id,
          logic_type: logic.logic_type as LibraryLogicType,
          logic_name: logic.name || '',
          camera_id: settings_payload.stream_id,
          camera_name: streamData?.camera_name || '',
          camera_image: streamData?.image || '',
          device_id: settings_payload.device_id,
          live_inference: settings_payload.eglsink,
          lines: logic.parameters.map(buildLogicLine),
          settings: logic.settings
            .filter(
              (setting) =>
                setting.setting_type === BusinessLogicSettingType.Calibration // Filter out all settings that we may not support
            )
            .map(buildSavedSettings)
            .flat()
        }
      })
    })
    .flat()
}
