import { SimpleGrid } from '@mantine/core'
import { InfiniteScroll } from '@/components/ui-shared/InfiniteScroll/InfiniteScroll'
import { useGetLibraryMlModels } from '@/queries/modelQueries'
import { ModelType } from '@/types/model'
import { BaseModelCard } from '../BaseModelCard/BaseModelCard'
import { ModelsErrorLoader } from '../ModelsErrorLoader/ModelsErrorLoader'

type MasterModelListProps = {
  selectedModelId: string
  selectedModelType: ModelType | undefined
  onSelectionChange: (modelId: string, type: ModelType) => void
}
export const PreTrainedModelList = ({
  selectedModelId,
  selectedModelType,
  onSelectionChange
}: MasterModelListProps) => {
  const {
    data: libraryModels,
    isFetching: isLibraryFetching,
    isError: isErrorLibraryModels,
    refetch: refetchLibraryModels,
    fetchNextPage: fetchNextPageLibraryModels,
    hasNextPage: hasNextPageLibraryModels
  } = useGetLibraryMlModels({
    is_master_model: false
  })

  const refetchPreTrainedModels = () => {
    if (isErrorLibraryModels) void refetchLibraryModels()
  }

  const handleLoadMorePretrainedModels = () => {
    void fetchNextPageLibraryModels()
  }

  const libraryMlModels =
    libraryModels?.pages?.flatMap((page) => page.results) || []

  if (isErrorLibraryModels) {
    return (
      <ModelsErrorLoader
        handleOnReload={() => void refetchPreTrainedModels()}
      />
    )
  }
  return (
    <InfiniteScroll
      hasMore={hasNextPageLibraryModels}
      isFetching={isLibraryFetching}
      onLoadMore={handleLoadMorePretrainedModels}
    >
      <SimpleGrid
        cols={{ base: 1, sm: 2, lg: 4, xl: 6 }}
        spacing="lg"
        verticalSpacing="lg"
      >
        {libraryMlModels?.map((model, idx) => {
          const isSelected =
            selectedModelId === model.id &&
            selectedModelType === ModelType.LibraryMLModel

          return (
            <BaseModelCard
              key={idx}
              model={model}
              brandLogo={model.brand_logo || undefined}
              brandName={model.brand_name || undefined}
              networkType={model.network_type}
              trainable={model.can_be_trained}
              testable={model.can_be_tested}
              deployable={model.can_be_deployed}
              isSelected={isSelected}
              showBadges={
                model.can_be_deployed ||
                model.can_be_trained ||
                model.can_be_tested
              }
              showBrandLogo
              isMoreInfoShown
              onClick={() =>
                onSelectionChange(model.id, ModelType.LibraryMLModel)
              }
            />
          )
        })}
      </SimpleGrid>
    </InfiniteScroll>
  )
}
