import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { getApiError } from '@/api/helpers/apiError'
import {
  useGetCameraStreamDetails,
  useRefreshCameraStreamImage
} from '@/queries/deviceQueries'
import { usePollTaskStatus } from '@/queries/generalQueries'
import { showToast } from '@/theme/notifications'

type UseCameraImageParams = {
  deviceId: string
  cameraStreamId: string
  enabled: boolean
}

export const useCameraImage = ({
  deviceId,
  cameraStreamId,
  enabled
}: UseCameraImageParams) => {
  const intl = useIntl()
  const [refreshImageTaskId, setRefreshImageTaskId] = useState<string>('')

  const {
    data: cameraStreamData,
    refetch,
    isFetching: isFetchingCameraDetails
  } = useGetCameraStreamDetails({
    deviceId,
    cameraStreamId,
    enabled
  })

  const { mutateAsync: refreshImage, isPending: isPendingImageRefresh } =
    useRefreshCameraStreamImage()

  const { data: refreshImageTaskData, isLoading: isLoadingTaskStatus } =
    usePollTaskStatus(refreshImageTaskId, refreshImageTaskId !== '')

  useEffect(() => {
    if (refreshImageTaskData?.task_status === 'SUCCESS') {
      void refetch()
      setRefreshImageTaskId('')
    }

    if (refreshImageTaskData?.task_status === 'FAILURE') {
      showToast(intl.formatMessage({ id: 'streams.image.error' }), 'error')
    }
  }, [refreshImageTaskData, refetch, intl])

  const isImageLoading =
    isFetchingCameraDetails ||
    isPendingImageRefresh ||
    isLoadingTaskStatus ||
    refreshImageTaskData?.task_status === 'PENDING'

  const handleRefresh = async () => {
    try {
      const { task_id } = await refreshImage({ deviceId, cameraStreamId })
      setRefreshImageTaskId(task_id)
    } catch (err) {
      const { errorMessage } = getApiError(err)
      const message =
        errorMessage || intl.formatMessage({ id: 'streams.image.error' })
      showToast(message, 'error')
    }
  }

  return {
    isImageLoading,
    cameraImage: cameraStreamData?.image,
    refreshCameraImage: handleRefresh
  }
}
