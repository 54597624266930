import { Group, Loader, Text, ThemeIcon } from '@mantine/core'
import { IconCheck, IconX } from '@tabler/icons-react'
import { ReactNode } from 'react'
import { FormattedMessage } from 'react-intl'
import { CameraCreationStatus } from '@/types/device'

type StatusIconWrapperProps = {
  color: string
  children: ReactNode
}

const StatusIconWrapper = ({ color, children }: StatusIconWrapperProps) => {
  return (
    <ThemeIcon color={color} radius="xl" size="xs">
      {children}
    </ThemeIcon>
  )
}

type ImportStatusIndicatorProps = {
  status: CameraCreationStatus
}

export const ImportStatusIndicator = ({
  status
}: ImportStatusIndicatorProps) => {
  if (status === 'Pending') {
    return (
      <Group wrap="nowrap" gap="xs" align="center">
        <Loader size="xs" />

        <Text size="sm">
          <FormattedMessage id="streams.import.inProgress" />
        </Text>
      </Group>
    )
  }

  if (status === 'Complete') {
    return (
      <Group wrap="nowrap" gap="xs" align="center">
        <StatusIconWrapper color="green">
          <IconCheck style={{ width: '80%', height: '80%' }} stroke={3} />
        </StatusIconWrapper>

        <Text size="sm">
          <FormattedMessage id="streams.import.success" />
        </Text>
      </Group>
    )
  }

  if (status === 'Failed') {
    return (
      <Group wrap="nowrap" gap="xs" align="center">
        <StatusIconWrapper color="red">
          <IconX style={{ width: '80%', height: '80%' }} stroke={3} />
        </StatusIconWrapper>

        <Text size="sm">
          <FormattedMessage id="streams.import.error" />
        </Text>
      </Group>
    )
  }

  return null
}
