import { Box, LoadingOverlay, Modal } from '@mantine/core'
import { FormattedMessage, useIntl } from 'react-intl'
import { getApiError } from '@/api/helpers/apiError'
import { useUpdateDataset } from '@/queries/datasetQueries'
import { showToast } from '@/theme/notifications'
import { Dataset } from '@/types/dataset'
import { Label } from '@/types/label'
import { DatasetForm, FormValues } from '../DatasetForm/DatasetForm'

type UpdateDatasetModalProps = {
  dataset: Dataset
  opened: boolean
  onClose: () => void
}

export const UpdateDatasetModal = ({
  dataset,
  opened,
  onClose
}: UpdateDatasetModalProps) => {
  const intl = useIntl()

  const { mutateAsync: updateDataset, isPending } = useUpdateDataset()

  const handleDatasetUpdate = async (values: FormValues) => {
    const { name, description, classes, labelingTypes } = values

    try {
      await updateDataset({
        datasetId: '123',
        data: {
          name,
          description,
          classes: classes.map((c) => c.name),
          labelingTypes
        }
      })

      showToast(
        intl.formatMessage({ id: 'datasets.update.success' }),
        'success'
      )

      onClose()
    } catch (err) {
      const { errorMessage } = getApiError(err)

      const message =
        errorMessage || intl.formatMessage({ id: 'datasets.update.error' })

      showToast(message, 'error')
    }
  }

  return (
    <Modal
      title={<FormattedMessage id="datasets.settings" />}
      size="lg"
      opened={opened}
      onClose={onClose}
    >
      <Box pos="relative">
        <LoadingOverlay visible={isPending} />

        <DatasetForm
          initialValues={{
            name: dataset.name,
            description: dataset.description,
            classes: dataset.classes.map((c) => ({
              name: c,
              color: '#FFA500'
            })),
            labelingTypes: dataset.labelingTypes
          }}
          classes={
            [
              {
                id: '1',
                name: 'Label 1',
                color: '#FFA500' // orange color
              },
              {
                id: '2',
                name: 'Label 2',
                color: '#008000' // green color
              }
            ] as Label[]
          }
          submitLabel={<FormattedMessage id="saveChanges" />}
          onCancel={onClose}
          onSubmit={(values) => void handleDatasetUpdate(values)}
        />
      </Box>
    </Modal>
  )
}
