import { BusinessLogic } from '@/types/businessLogic'
import { DeploymentConfig } from '@/types/deployment'
import { CameraStreamWithDeviceId } from '@/types/device'
import { SavedEventEndpoint } from '../IntegrationSelection/IntegrationSelection'
import { SavedStreamLogic } from '../LogicSelection/types'
import { buildBuisnessLogicParameter } from './buildBuisnessLogicParameter'
import { buildBusinessLogicSettings } from './buildBusinessLogicSettings'
import { buildEventEndpoints } from './buildEventEndpoints'

const getFrameSize = (resolution: CameraStreamWithDeviceId['resolution']) => {
  if (typeof resolution === 'string') {
    const [height, width] = resolution.split('x') // frame_width and frame_height are swapped h x w
    return { frameWidth: Number(width), frameHeight: Number(height) }
  } else {
    return { frameWidth: resolution.width, frameHeight: resolution.height }
  }
}

type BuildDeploymentDataParams = {
  appId: string
  modelId: string
  width: number
  height: number
  cameraStreams: CameraStreamWithDeviceId[]
  savedStreamLogics: SavedStreamLogic[]
  savedEventEndpoints: SavedEventEndpoint[]
  liveInferenceCameraStreamIds: string[]
}

export const buildDeploymentData = ({
  appId,
  modelId,
  width,
  height,
  cameraStreams,
  savedStreamLogics,
  savedEventEndpoints,
  liveInferenceCameraStreamIds
}: BuildDeploymentDataParams): DeploymentConfig => {
  return {
    application_id: appId,
    model_id: modelId,
    config_width: width,
    config_height: height,
    streams: cameraStreams.map((cameraStream) => {
      const streamLogics = savedStreamLogics.filter(
        (savedStreamLogic) =>
          savedStreamLogic.camera_id === cameraStream.camera_id
      )

      const businessLogics: BusinessLogic[] = streamLogics.flatMap(
        (streamLogic) => {
          const logicParameters = streamLogic.lines.map((line) => {
            return buildBuisnessLogicParameter({
              type: line.type,
              coordinates: line.points,
              name: line.name,
              alert: line.notification,
              upload: line.video_upload,
              triggers: line.triggers
            })
          })

          const logicSettings = buildBusinessLogicSettings(streamLogic.settings)

          return {
            id: streamLogic.logic_id,
            parameters: logicParameters,
            settings: logicSettings
          }
        }
      )

      const { frameWidth, frameHeight } = getFrameSize(cameraStream.resolution)

      return {
        stream_id: cameraStream.camera_id,
        device_id: cameraStream.device_id,
        frame_width: frameWidth,
        frame_height: frameHeight,
        eglsink: liveInferenceCameraStreamIds.includes(cameraStream.camera_id),
        business_logics: businessLogics,
        event_endpoints: buildEventEndpoints(savedEventEndpoints, cameraStream)
      }
    })
  }
}
