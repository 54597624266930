import { FileRejection } from '@mantine/dropzone'
import { useDisclosure } from '@mantine/hooks'
import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { getApiError } from '@/api/helpers/apiError'
import {
  useGetUploadStatus,
  useUploadFilesToDataset
} from '@/queries/datasetQueries'
import { showToast } from '@/theme/notifications'
import { ConfirmUploadModal } from './ConfirmUploadModal/ConfirmUploadModal'
import { UploadProgressModal } from './UploadProgressModal/UploadProgressModal'
import { UploadableMediaList } from './UploadableMediaList'

type UploadableMediaListHandlerProps = {
  datasetId: string
  versionId: string
}

export const UploadableMediaListHandler = ({
  datasetId,
  versionId
}: UploadableMediaListHandlerProps) => {
  const intl = useIntl()
  const [acceptedFiles, setAcceptedFiles] = useState<File[]>([])
  const [fileRejections, setFileRejections] = useState<FileRejection[]>([])
  const [uploadBatchId, setUploadBatchId] = useState<string>('')

  const [
    isConfirmModalOpened,
    { open: openConfirmModal, close: closeConfirmModal }
  ] = useDisclosure(false)

  const [
    isProgressModalOpened,
    { open: openProgressModal, close: closeProgressModal }
  ] = useDisclosure(false)

  const { mutateAsync: uploadFiles, isPending } = useUploadFilesToDataset()

  const { data: uploadStatusData } = useGetUploadStatus(
    uploadBatchId,
    uploadBatchId !== ''
  )

  const isUploadFinished =
    uploadStatusData?.status === 'Complete' ||
    uploadStatusData?.status === 'Failed'

  useEffect(() => {
    // if (
    //   uploadStatusData?.uploaded_files !== 0 &&
    //   uploadStatusData?.status === 'Pending'
    // ) {
    //   // Refetch file list as more files were uploaded
    //   invalidateFileList()
    // }

    if (isUploadFinished) {
      // invalidateFileList()

      if (uploadStatusData?.failed_files > 0) {
        openProgressModal()

        showToast(
          intl.formatMessage(
            { id: 'datasets.upload.filesPartiallyUploaded' },
            { count: uploadStatusData?.failed_files }
          ),
          'error'
        )
      } else {
        closeProgressModal()

        showToast(
          intl.formatMessage({ id: 'datasets.upload.completed' }),
          'success'
        )
      }
    }
  }, [
    intl,
    uploadStatusData,
    isUploadFinished,
    openProgressModal,
    closeProgressModal
  ])

  const handleDrop = (files: File[]) => {
    setAcceptedFiles(files)
    openConfirmModal()
  }

  const handleReject = (fileRejections: FileRejection[]) => {
    setFileRejections(fileRejections)
    openConfirmModal()
  }

  const handleCloseConfirmModal = () => {
    closeConfirmModal()
    setAcceptedFiles([])
    setFileRejections([])
  }

  const handleConfirmUpload = async () => {
    try {
      const { data } = await uploadFiles({
        versionId,
        media_files: acceptedFiles
      })

      setUploadBatchId(data.batch_id)
      closeConfirmModal()
      openProgressModal()
    } catch (err) {
      const { errorMessage } = getApiError(err)

      const message =
        errorMessage || intl.formatMessage({ id: 'datasets.upload.failed' })

      showToast(message, 'error')
    }
  }

  return (
    <>
      <UploadableMediaList
        datasetId={datasetId}
        versionId={versionId}
        isUploading={false}
        onDrop={handleDrop}
        onReject={handleReject}
      />

      <ConfirmUploadModal
        opened={isConfirmModalOpened}
        acceptedFiles={acceptedFiles}
        fileRejections={fileRejections}
        isLoading={isPending}
        isConfirmDisabled={isPending || acceptedFiles.length === 0}
        onClose={handleCloseConfirmModal}
        onConfirmUpload={() => void handleConfirmUpload()}
      />

      <UploadProgressModal
        totalFiles={uploadStatusData?.total_files || 0}
        uploadedFies={uploadStatusData?.uploaded_files || 0}
        isCompleted={isUploadFinished}
        failedFileNames={uploadStatusData?.failed_file_names || []}
        opened={isProgressModalOpened}
        onClose={closeProgressModal}
      />
    </>
  )
}
