import { Box, Button, Group, LoadingOverlay, Modal, Text } from '@mantine/core'
import { FormattedMessage, useIntl } from 'react-intl'
import { getApiError } from '@/api/helpers/apiError'
import { useDeleteFiles } from '@/queries/datasetLegacyQueries'
import { showToast } from '@/theme/notifications'

type DeleteFilesModalProps = {
  opened: boolean
  datasetId: string
  selectedFileIds: string[]
  onClose: () => void
  onDelete: () => void
}

export const DeleteFilesModal = ({
  opened,
  datasetId,
  selectedFileIds,
  onClose,
  onDelete
}: DeleteFilesModalProps) => {
  const intl = useIntl()
  const { isPending, mutateAsync: deleteFiles } = useDeleteFiles(datasetId)

  const handleFilesDelete = async () => {
    try {
      await deleteFiles({
        datasetId,
        fileIds: selectedFileIds
      })

      onDelete()

      showToast(
        intl.formatMessage(
          { id: 'datasets.filesDeleted' },
          { count: selectedFileIds.length }
        ),
        'success'
      )
    } catch (err) {
      const { errorMessage } = getApiError(err)

      const message =
        errorMessage || intl.formatMessage({ id: 'datasets.fileDeletionError' })

      showToast(message, 'error')
    }
  }

  return (
    <Modal
      opened={opened}
      title={<FormattedMessage id="datasets.deleteFiles" />}
      onClose={onClose}
    >
      <Box pos="relative">
        <LoadingOverlay visible={isPending} />

        <Text size="sm" mb="xl">
          <FormattedMessage
            id="datasets.deleteFilesConfirmation"
            values={{ count: selectedFileIds.length }}
          />
        </Text>

        <Group justify="end">
          <Button variant="default" onClick={onClose}>
            <FormattedMessage id="cancel" />
          </Button>

          <Button color="red" onClick={() => void handleFilesDelete()}>
            <FormattedMessage id="datasets.deleteFiles" />
          </Button>
        </Group>
      </Box>
    </Modal>
  )
}
