import { Box, Button, Group, LoadingOverlay, Modal, Text } from '@mantine/core'
import { IconTrash } from '@tabler/icons-react'
import { FormattedMessage, useIntl } from 'react-intl'
import { getApiError } from '@/api/helpers/apiError'
import { useDeleteEvent } from '@/queries/eventQueries'
import { showToast } from '@/theme/notifications'

const DeleteEventModalTitle = ({ eventsCount }: { eventsCount: number }) => (
  <Group>
    <IconTrash size={20} />
    <FormattedMessage id="events.delete" values={{ count: eventsCount }} />
  </Group>
)

type DeleteEventModalProps = {
  appId: string
  eventIds: string[]
  opened: boolean
  onClose: () => void
  onEventDeleted: () => void
}

export const DeleteEventModal = ({
  appId,
  eventIds,
  opened,
  onClose,
  onEventDeleted
}: DeleteEventModalProps) => {
  const intl = useIntl()
  const eventCount = eventIds.length

  const { isPending, mutateAsync: deleteEvent } = useDeleteEvent()

  const handleConfirmDelete = async () => {
    try {
      await deleteEvent({
        appId: appId || '',
        eventIds
      })

      showToast(
        intl.formatMessage(
          { id: 'events.delete.success' },
          { count: eventCount }
        ),
        'success'
      )

      onEventDeleted()
    } catch (err) {
      const { errorMessage } = getApiError(err)
      const message =
        errorMessage ||
        intl.formatMessage(
          {
            id: 'events.delete.error'
          },
          { count: eventCount }
        )
      showToast(message, 'error')
    }

    onClose()
  }

  return (
    <Modal
      size="lg"
      opened={opened}
      title={<DeleteEventModalTitle eventsCount={eventCount} />}
      onClose={onClose}
    >
      <Box pos="relative">
        <LoadingOverlay visible={isPending} />

        <Text size="sm" mb="lg">
          <FormattedMessage
            id="events.delete.areYouSure"
            values={{ count: eventCount }}
          />
        </Text>

        <Group justify="end">
          <Button miw={120} variant="default" onClick={onClose}>
            <FormattedMessage id="cancel" />
          </Button>

          <Button
            type="submit"
            color="red"
            onClick={() => {
              void handleConfirmDelete()
            }}
          >
            <FormattedMessage
              id="events.delete"
              values={{ count: eventCount }}
            />
          </Button>
        </Group>
      </Box>
    </Modal>
  )
}
