import { Box, Button, Group, Text } from '@mantine/core'
import { IconArrowLeft } from '@tabler/icons-react'
import { FormattedMessage } from 'react-intl'
import { getFormattedDateWithTime } from '@/utils/date'

// type StatusBadgeProps = {
//   status: number
//   showLabel: boolean
// }

// const StatusBadge = ({ status, showLabel }: StatusBadgeProps) => {
//   const intl = useIntl()

//   const FLAGGED = intl.formatMessage({ id: 'events.status.flagged' })
//   const ALERT = intl.formatMessage({ id: 'events.status.alert' })

// export enum EventStatus {
//   Flagged = 1,
//   Alert = 2
// }
//   const STATUS_PROPS = {
//     [EventStatus.Flagged]: {
//       color: 'yellow',
//       icon: <IconFlag3Filled size={15} />,
//       text: FLAGGED
//     },
//     [EventStatus.Alert]: {
//       color: 'red',
//       icon: <IconAlertTriangleFilled size={15} />,
//       text: ALERT
//     }
//   }

//   const { color, icon, text } = STATUS_PROPS[status as EventStatus] || {}

//   return (
//     <Badge variant="light" leftSection={icon} size="lg" color={color}>
//       {!showLabel && text}
//     </Badge>
//   )
// }

type EventToolbarProps = {
  timestamp: number
  onBackClick: () => void
}

export const EventToolbar = ({ timestamp, onBackClick }: EventToolbarProps) => {
  return (
    <Group justify="space-between">
      <Button
        size="xs"
        miw={160}
        variant="default"
        leftSection={<IconArrowLeft size={20} />}
        radius="xl"
        onClick={onBackClick}
      >
        <Text>
          <FormattedMessage id="events.details.backToList" />
        </Text>
      </Button>

      <Text size="lg">{getFormattedDateWithTime(timestamp)}</Text>
      {/* <StatusBadge status={event.status} showLabel={isLessThanEqualToMD} /> */}

      <Box w={160}>&nbsp;</Box>
    </Group>
  )
}
