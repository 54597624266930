import { KonvaEventObject } from 'konva/lib/Node'
import { Stage } from 'konva/lib/Stage'
import { Vector2d } from 'konva/lib/types'
import { useState } from 'react'
import { minMax } from './utils'

type UseDraggableGroupParams = {
  points: number[][]
}

export const useDraggableGroup = ({ points }: UseDraggableGroupParams) => {
  const [stage, setStage] = useState<Stage>()
  const [minMaxX, setMinMaxX] = useState<[number, number]>([0, 0])
  const [minMaxY, setMinMaxY] = useState<[number, number]>([0, 0])

  const handleGroupMouseOver = (e: KonvaEventObject<MouseEvent>) => {
    const eventStage = e.target.getStage() as Stage
    setStage(eventStage)
  }

  const handleGroupDragStart = () => {
    const arrX = points.map((p) => p[0])
    const arrY = points.map((p) => p[1])
    setMinMaxX(minMax(arrX))
    setMinMaxY(minMax(arrY))
  }

  const groupDragBound = (pos: Vector2d) => {
    if (!stage) {
      return pos
    }

    let { x, y } = pos

    const sw = stage.width()
    const sh = stage.height()

    if (minMaxY[0] + y < 0) {
      y = -1 * minMaxY[0]
    }

    if (minMaxX[0] + x < 0) {
      x = -1 * minMaxX[0]
    }

    if (minMaxY[1] + y > sh) {
      y = sh - minMaxY[1]
    }

    if (minMaxX[1] + x > sw) {
      x = sw - minMaxX[1]
    }

    return { x, y }
  }

  return {
    handleGroupMouseOver,
    handleGroupDragStart,
    groupDragBound
  }
}
