import { Group, List, ScrollArea, Text, ThemeIcon } from '@mantine/core'
import { IconX } from '@tabler/icons-react'
import { FormattedMessage } from 'react-intl'

type FailedFilesProps = {
  fileNames: string[]
}

export const FailedFiles = ({ fileNames }: FailedFilesProps) => {
  return (
    <>
      <Group wrap="nowrap" gap="xs" mb="sm">
        <ThemeIcon color="red" size={16}>
          <IconX />
        </ThemeIcon>

        <Text size="sm">
          <FormattedMessage id="datasets.upload.failedFiles" />
        </Text>
      </Group>

      <ScrollArea h={120}>
        <List size="xs">
          {fileNames.map((fileName) => (
            <List.Item key={fileName} icon={null}>
              {fileName}
            </List.Item>
          ))}
        </List>
      </ScrollArea>
    </>
  )
}
