import Konva from 'konva'
import { Context } from 'konva/lib/Context'
import { useState } from 'react'
import { useIntl } from 'react-intl'
import { Group, Label, Shape, Text } from 'react-konva'
import { getCenteredRectangleCoordinates } from '../Rectangle/helpers'

type StageMask = {
  width: number
  height: number
  activeAreaPercent: number
}

export const StageMask = ({ width, height, activeAreaPercent }: StageMask) => {
  const intl = useIntl()
  const [labelCoords, setLabelCoords] = useState<number[]>([])

  const handleSceneFunc = (
    ctx: Context,
    shape: Konva.Shape,
    cb: (points: number[][]) => void
  ) => {
    // draw background
    ctx.beginPath()
    ctx.moveTo(0, 0)
    ctx.lineTo(shape.width(), 0)
    ctx.lineTo(shape.width(), shape.height())
    ctx.lineTo(0, shape.height())
    ctx.lineTo(0, 0)

    const coords = getCenteredRectangleCoordinates(
      shape.width(),
      shape.height(),
      activeAreaPercent
    )

    ctx.moveTo(coords[3][0], coords[3][1])
    ctx.lineTo(coords[2][0], coords[2][1])
    ctx.lineTo(coords[1][0], coords[1][1])
    ctx.lineTo(coords[0][0], coords[0][1])
    ctx.lineTo(coords[3][0], coords[3][1])

    ctx.fillStrokeShape(shape)
    cb(coords)
  }

  const handleMaskAdded = (points: number[][]) => {
    setLabelCoords([points[0][0], points[0][1] - 22])
  }

  return (
    <Group>
      <Shape
        width={width}
        height={height}
        fill="rgba(0,0,0,0.8)"
        stroke="black"
        strokeWidth={1}
        sceneFunc={(ctx, shape) => handleSceneFunc(ctx, shape, handleMaskAdded)}
        listening={false}
      />

      <Label x={labelCoords[0]} y={labelCoords[1]}>
        <Text
          text={intl.formatMessage({ id: 'logic.annotate.activeArea' })}
          fill="white"
          fontSize={12}
          fontStyle="bold"
          padding={2}
        />
      </Label>
    </Group>
  )
}
