import { KonvaEventObject } from 'konva/lib/Node'
import { Circle, Group, Line } from 'react-konva'
import { AnnotationLabel } from '../AnnotationLabel'
import { useDraggableGroup } from '../useDraggableGroup'
import { useDraggablePoint } from '../useDraggablePoint'

const CIRCLE_SIZE = 4

type DraggableRoiPolygonProps = {
  name: string
  color: string
  points: number[][]
  onPointDragMove: (e: KonvaEventObject<DragEvent>) => void
  onGroupDragEnd: (e: KonvaEventObject<DragEvent>) => void
}

export const DraggableRoiPolygon = ({
  name,
  color,
  points,
  onPointDragMove,
  onGroupDragEnd
}: DraggableRoiPolygonProps) => {
  const { handlePointMouseOver, handlePointMouseOut, pointDragBoundFunc } =
    useDraggablePoint()

  const { handleGroupMouseOver, handleGroupDragStart, groupDragBound } =
    useDraggableGroup({ points })

  return (
    <Group
      name="draggable_roi_polygon"
      dragBoundFunc={groupDragBound}
      draggable
      onDragStart={handleGroupDragStart}
      onDragEnd={onGroupDragEnd}
      onMouseOver={handleGroupMouseOver}
    >
      <Line
        points={points.flat()}
        stroke={color}
        strokeWidth={2}
        fill="rgb(140,30,255,0.2)"
        closed
      />

      {points.map((point, index) => {
        const x = point[0] - 1
        const y = point[1] - 1

        return (
          <Circle
            key={index}
            x={x}
            y={y}
            radius={CIRCLE_SIZE}
            fill="white"
            stroke={color}
            strokeWidth={2}
            dragBoundFunc={(pos) => pointDragBoundFunc(pos, CIRCLE_SIZE)}
            draggable
            onDragMove={onPointDragMove}
            onMouseOver={handlePointMouseOver}
            onMouseOut={handlePointMouseOut}
          />
        )
      })}

      <AnnotationLabel
        x={points[0][0]}
        y={points[0][1]}
        color="rgba(0,0,0,0.6)"
        textColor="white"
        text={name}
      />
    </Group>
  )
}
