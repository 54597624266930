import { Label } from './label'

export enum UserMLModelStatus {
  Draft = 1,
  PreTrained = 2,
  Trained = 3,
  Evaluated = 4,
  Deployed = 5
}

export enum ModelType {
  UserMLModel = 1,
  LibraryMLModel = 2
}

export type UserMLModel = {
  id: string
  name: string
  mode_file_path: string
  status: UserMLModelStatus
  application: string
  content_type: number | null
  deployments: string[]
  labels: Label[]
  training: string | null
  base_model: UserMLModel | null
  root_model: LibraryMLModel | null
  created_by: string
  created_at: string
  updated_at: string
}

export type LibraryMLModel = {
  id: string
  name: string
  labels: Label[]
  model_file_path: string | null
  is_master_model: boolean
  is_active: boolean
  network_type: string
  brand_name: string | null
  brand_name_display: string | null
  brand_logo: string | null
  model_code: string
  can_be_trained: boolean
  can_be_deployed: boolean
  can_be_tested: boolean
  created_at: string
  model_type: LibraryMLModelType
  model_type_display: string
}

export enum LibraryMLModelType {
  ObjectDetection = 1,
  Segmentation = 2
}
