import { Alert, Modal } from '@mantine/core'
import { IconInfoCircle } from '@tabler/icons-react'
import { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { ModelType } from '@/types/model'
import { TestModelPredictionsStep } from './TestModelPredictionStep'
import { TestModelUploadMedia } from './TestModelUploadMedia'

type TestModelModalProps = {
  applicationId: string
  selectedModelId: string
  selectedModelType: ModelType | undefined
  inferredMedia: string[]
  modalPage: number
  isOpen: boolean
  isInferenceError: boolean
  isInferringPending: boolean
  setInferenceId: (taskId: string) => void
  setInferredMedia: (images: string[]) => void
  setModalPage: (page: number) => void
  restartInference: () => void
  onClose: () => void
  onInference: (acceptedFiles: File[]) => Promise<void>
}

const InfoIcon = <IconInfoCircle />

const ModelInferenceErrorAlert = () => {
  const intl = useIntl()
  return (
    <Alert mb="md" variant="light" color="red" icon={InfoIcon}>
      {intl.formatMessage({ id: 'models.test.error' })}
    </Alert>
  )
}

export const TestModelModal = ({
  inferredMedia,
  modalPage,
  isOpen,
  isInferenceError,
  isInferringPending,
  setInferenceId,
  setInferredMedia,
  setModalPage,
  restartInference,
  onClose,
  onInference
}: TestModelModalProps) => {
  const [acceptedFiles, setAcceptedFiles] = useState<File[]>([])

  const ModalTitle =
    modalPage === 1 ? (
      <FormattedMessage id="models.test.uploadMedia" />
    ) : (
      <FormattedMessage id="models.test.viewPredictions" />
    )

  const resetInferenceModalData = () => {
    setInferenceId('')
    setModalPage(1)
    setInferredMedia([])
  }

  const handleOnClose = () => {
    onClose()
    if (modalPage === 2) {
      setAcceptedFiles([])
      resetInferenceModalData()
      restartInference()
    }
  }

  return (
    <Modal
      size=""
      opened={isOpen}
      title={ModalTitle}
      centered
      onClose={handleOnClose}
    >
      {isInferenceError && <ModelInferenceErrorAlert />}

      {modalPage === 1 ? (
        <TestModelUploadMedia
          acceptedFiles={acceptedFiles}
          isInferenceError={isInferenceError}
          isInferencePending={isInferringPending}
          setAcceptedFiles={setAcceptedFiles}
          onUpload={onInference}
        />
      ) : (
        <TestModelPredictionsStep
          media_paths={inferredMedia}
          onClose={handleOnClose}
          onBack={resetInferenceModalData}
        />
      )}
    </Modal>
  )
}
