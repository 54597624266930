import { useHotkeys } from '@mantine/hooks'
import { KonvaEventObject } from 'konva/lib/Node'
import { Fragment, useState } from 'react'
import { ParameterType } from '@/types/businessLogic'
import { LogicLineValues } from '../types'
import { AnnotationAreaContainer } from './AnnotationAreaContainer'
import { DraggableLineCrossing } from './LineCrossing/DraggableLineCrossing'
import { DraggableLineCrossingDirection } from './LineCrossing/DraggableLineCrossingDirection'
import { LineCrossing } from './LineCrossing/LineCrossing'
import { LineCrossingDirection } from './LineCrossing/LineCrossingDirection'
import { useLineCrossing } from './LineCrossing/useLineCrossing'
import { DraggableRoiPolygon } from './Roi/DraggableRoiPolygon'
import { RoiPolygon } from './Roi/RoiPolygon'
import { useRoi } from './Roi/useRoi'
import { StageWithZoom } from './StageWithZoom/StageWithZoom'

export type LineAddParams = {
  lineType: LogicLineValues['type']
  name: string
  color: string
  points: number[][]
}

type AnnotationAreaProps = {
  enabledLogicParameters: ParameterType[]
  imageUrl?: string
  isImageLoading?: boolean
  width: number
  height: number
  lines: LogicLineValues[]
  onLineAdd: (params: LineAddParams) => void
  onLineChange: (lineIndex: number, line: LogicLineValues) => void
  onImageRefresh: () => void
}

export const AnnotationArea = ({
  enabledLogicParameters,
  imageUrl,
  isImageLoading,
  width,
  height,
  lines,
  onLineAdd,
  onLineChange,
  onImageRefresh
}: AnnotationAreaProps) => {
  const [annotationMode, setAnnotationMode] = useState<'line_crossing' | 'roi'>(
    'line_crossing'
  )

  const {
    color: lineCrossingColor,
    step: lineCrossingStep,
    linePoints,
    directionPoints,
    lineFlattenedPoints,
    directionFlattenedPoints,
    isCompleted: isLineCrossingCompleted,
    start: startLineCrossing,
    exit: exitLineCrossing,
    handleMouseMove: handleLineCrossingMouseMove,
    handleMouseDown: handleLineCrossingMouseDown,
    handleGroupDragEnd: handleLineCrossingGroupDragEnd,
    handlePointDragMove: handleLineCrossingPointDragMove
  } = useLineCrossing({
    lines,
    onLineAdd,
    onLineChange
  })

  const {
    color: roiColor,
    points: roiPoints,
    flattenedPoints: roiFlattenedPoints,
    isCompleted: isRoiCompleted,
    exit: exitRoi,
    start: startRoi,
    handleMouseMove: handleRoiMouseMove,
    handleMouseDown: handleRoiMouseDown,
    handleMouseOverStartPoint,
    handleMouseOutStartPoint,
    handleGroupDragEnd: handleRoiGroupDragEnd,
    handlePointDragMove: handleRoiPointDragMove
  } = useRoi({
    lines,
    onLineAdd,
    onLineChange
  })

  const exit = () => {
    exitLineCrossing()
    exitRoi()
  }

  useHotkeys([['Escape', exit]])

  const isAnnotating = !isLineCrossingCompleted || !isRoiCompleted

  const handleAddLineCrossing = () => {
    exit()
    setAnnotationMode('line_crossing')
    startLineCrossing()
  }

  const handleAddRoi = () => {
    exit()
    setAnnotationMode('roi')
    startRoi()
  }

  const handleMouseDown = (e: KonvaEventObject<MouseEvent>) => {
    if (annotationMode === 'line_crossing') {
      handleLineCrossingMouseDown(e)
    } else {
      handleRoiMouseDown(e)
    }
  }

  const handleMouseMove = (e: KonvaEventObject<MouseEvent>) => {
    if (annotationMode === 'line_crossing') {
      handleLineCrossingMouseMove(e)
    } else {
      handleRoiMouseMove(e)
    }
  }

  return (
    <AnnotationAreaContainer
      showRoi={enabledLogicParameters.includes(ParameterType.ROI)}
      showLineCrossing={enabledLogicParameters.includes(
        ParameterType.LineCrossing
      )}
      onAddLineCrossing={handleAddLineCrossing}
      onAddRoi={handleAddRoi}
    >
      <StageWithZoom
        width={width}
        height={height}
        imageUrl={imageUrl}
        isLoading={isImageLoading}
        isAnnotating={isAnnotating}
        onMouseMove={handleMouseMove}
        onMouseDown={handleMouseDown}
        onRefreshClick={onImageRefresh}
      >
        {lines.map((line, lineIndex) => {
          return line.type === 'line_crossing' ? (
            <Fragment key={line.name}>
              <DraggableLineCrossing
                name={line.name}
                color={line.color}
                points={line.points.slice(2, 4)}
                onGroupDragEnd={(e) =>
                  handleLineCrossingGroupDragEnd(e, lineIndex, [2, 4])
                }
                onPointDragMove={(e, pointIndex) =>
                  handleLineCrossingPointDragMove(e, lineIndex, pointIndex + 2)
                }
              />

              <DraggableLineCrossingDirection
                name={line.name}
                color={line.color}
                points={line.points.slice(0, 2)}
                onGroupDragEnd={(e) =>
                  handleLineCrossingGroupDragEnd(e, lineIndex, [0, 2])
                }
                onPointDragMove={(e, pointIndex) =>
                  handleLineCrossingPointDragMove(e, lineIndex, pointIndex)
                }
              />
            </Fragment>
          ) : (
            <DraggableRoiPolygon
              key={line.name}
              name={line.name}
              color={line.color}
              points={line.points}
              onGroupDragEnd={(e) => handleRoiGroupDragEnd(e, lineIndex)}
              onPointDragMove={(e) => handleRoiPointDragMove(e, lineIndex)}
            />
          )
        })}

        {annotationMode === 'line_crossing' && !isLineCrossingCompleted && (
          <>
            <LineCrossing
              color={lineCrossingColor}
              points={linePoints}
              flattenedPoints={lineFlattenedPoints}
            />

            {lineCrossingStep === 'direction' && (
              <LineCrossingDirection
                color={lineCrossingColor}
                points={directionPoints}
                flattenedPoints={directionFlattenedPoints}
              />
            )}
          </>
        )}

        {annotationMode === 'roi' && !isRoiCompleted && (
          <RoiPolygon
            color={roiColor}
            points={roiPoints}
            flattenedPoints={roiFlattenedPoints}
            onMouseOverStartPoint={handleMouseOverStartPoint}
            onMouseOutStartPoint={handleMouseOutStartPoint}
          />
        )}
      </StageWithZoom>
    </AnnotationAreaContainer>
  )
}
