import {
  BusinessLogicSetting,
  BusinessLogicSettingParameter,
  BusinessLogicSettingParameterType,
  BusinessLogicSettingType
} from '@/types/businessLogic'
import { SavedLogicSetting } from '../LogicSelection/types'
import { convertCoordinatesToString } from './coordinates'

const buildSettingParameter = (
  setting: SavedLogicSetting
): BusinessLogicSettingParameter | null => {
  if (setting.type === BusinessLogicSettingType.Calibration) {
    return {
      parameter_type: BusinessLogicSettingParameterType.Scale,
      side: setting.side,
      coordinates: convertCoordinatesToString(setting.points),
      size: setting.size,
      unit: setting.unit
    }
  }

  return null
}

export const buildBusinessLogicSettings = (
  savedSettings: SavedLogicSetting[]
): BusinessLogicSetting[] => {
  const result: BusinessLogicSetting[] = []

  savedSettings.forEach((savedSetting) => {
    const existingItem = result.find(
      (item) => item.setting_type === savedSetting.type
    )

    const settingParam = buildSettingParameter(savedSetting)

    if (!settingParam) {
      return
    }

    if (existingItem) {
      existingItem.parameters.push(settingParam)
    } else {
      result.push({
        setting_type: savedSetting.type,
        parameters: [settingParam]
      })
    }
  })

  return result
}
