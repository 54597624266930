import { Box, Center, Group, List, Text, ThemeIcon } from '@mantine/core'
import { IconPhoto } from '@tabler/icons-react'
import { FormattedMessage } from 'react-intl'
import { bytesToGigabytes } from '@/utils/file'

type DropzoneInstructionsProps = {
  maxFileSize: number
  maxFilesPerUpload?: number
  fileFormats: string
}

export const DropzoneInstructions = ({
  maxFileSize,
  maxFilesPerUpload,
  fileFormats
}: DropzoneInstructionsProps) => {
  return (
    <Center mih={350} p="lg">
      <Group gap={60} align="start" wrap="nowrap">
        <ThemeIcon size={60} radius="md" variant="light">
          <IconPhoto style={{ width: '80%', height: '80%' }} />
        </ThemeIcon>

        <Box>
          <Text fw={500} mb="lg">
            <FormattedMessage id="datasets.upload.dropYourFiles" />
          </Text>

          <List size="xs">
            <List.Item>
              <FormattedMessage
                id="datasets.upload.maxFileSize"
                values={{
                  fileSize: bytesToGigabytes(maxFileSize) + ' GB'
                }}
              />
            </List.Item>

            <List.Item>
              <FormattedMessage
                id="datasets.upload.supportedFileFormats"
                values={{ fileFormats }}
              />
            </List.Item>

            {maxFilesPerUpload && (
              <List.Item>
                <FormattedMessage
                  id="datasets.upload.maxFilesPerUpload"
                  values={{
                    count: maxFilesPerUpload
                  }}
                />
              </List.Item>
            )}
          </List>
        </Box>
      </Group>
    </Center>
  )
}
