import { Box, Group, HoverCard, Text } from '@mantine/core'
import { IconInfoCircle } from '@tabler/icons-react'
import { FormattedMessage } from 'react-intl'

const HelpCard = () => {
  return (
    <Box>
      <HoverCard shadow="lg" width={350} position="right">
        <HoverCard.Target>
          <IconInfoCircle size={20} />
        </HoverCard.Target>
        <HoverCard.Dropdown
          bg="gray.0"
          styles={{
            dropdown: {
              border: '1px solid var(--mantine-color-gray-5)'
            }
          }}
        >
          <Text size="xs">
            <FormattedMessage id="streams.addCameraStream.hint" />
          </Text>
        </HoverCard.Dropdown>
      </HoverCard>
    </Box>
  )
}

export const ModalTitle = () => (
  <Group gap="xs" justify="flex-start" align="flex-start">
    <FormattedMessage id="streams.addCameraStream" />
    <HelpCard />
  </Group>
)
