import { KonvaEventObject } from 'konva/lib/Node'
import { Circle, Group, Line } from 'react-konva'
import { AnnotationLabel } from '../AnnotationLabel'
import { useDraggableGroup } from '../useDraggableGroup'
import { useDraggablePoint } from '../useDraggablePoint'

const CIRCLE_SIZE = 4

type DraggableLineCrossingProps = {
  name: string
  color: string
  points: number[][]
  onPointDragMove: (e: KonvaEventObject<DragEvent>, pointIndex: number) => void
  onGroupDragEnd: (e: KonvaEventObject<DragEvent>) => void
}

export const DraggableLineCrossing = ({
  name,
  color,
  points,
  onPointDragMove,
  onGroupDragEnd
}: DraggableLineCrossingProps) => {
  const { handlePointMouseOver, handlePointMouseOut, pointDragBoundFunc } =
    useDraggablePoint()

  const { handleGroupMouseOver, handleGroupDragStart, groupDragBound } =
    useDraggableGroup({ points })

  return (
    <Group
      name="draggable_line_crossing"
      dragBoundFunc={groupDragBound}
      draggable
      onDragStart={handleGroupDragStart}
      onDragEnd={onGroupDragEnd}
      onMouseOver={handleGroupMouseOver}
    >
      <Line
        points={[points[0], points[1]].flat()}
        stroke={color}
        strokeWidth={4}
        lineJoin="round"
        dash={[20, 5]}
      />

      {points.map((point, index) => {
        const x = point[0] - 1
        const y = point[1] - 1

        return (
          <Circle
            key={index}
            x={x}
            y={y}
            radius={CIRCLE_SIZE}
            fill="white"
            stroke={color}
            strokeWidth={3}
            dragBoundFunc={(pos) => pointDragBoundFunc(pos, CIRCLE_SIZE)}
            draggable
            onDragMove={(e) => onPointDragMove(e, index)}
            onMouseOver={handlePointMouseOver}
            onMouseOut={handlePointMouseOut}
          />
        )
      })}

      <AnnotationLabel
        x={points[0][0] - 10}
        y={points[0][1] + 10}
        color="rgba(0,0,0,0.6)"
        textColor="white"
        text={name}
      />
    </Group>
  )
}
