import { AppShell } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { Outlet } from 'react-router-dom'
import { HEADER_HEIGHT, Header } from '../Header/Header'
import { HeaderContainer } from '../Header/HeaderContainer'
import { PageContainer } from '../PageContainer/PageContainer'

const NAVBAR_WIDTH = 280

export const PageLayout = () => {
  const [opened, { toggle }] = useDisclosure()

  return (
    <AppShell
      header={{
        height: HEADER_HEIGHT
      }}
      navbar={{
        width: NAVBAR_WIDTH,
        breakpoint: 'sm',
        collapsed: { desktop: true, mobile: !opened }
      }}
      padding="md"
    >
      <AppShell.Header bg="brand-primary">
        <HeaderContainer>
          <Header isMenuOpen={opened} onBurgerMenuClick={toggle} />
        </HeaderContainer>
      </AppShell.Header>

      <AppShell.Navbar p="md" />

      <AppShell.Main>
        <PageContainer>
          <Outlet />
        </PageContainer>
      </AppShell.Main>
    </AppShell>
  )
}
