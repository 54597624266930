import {
  Box,
  Flex,
  Grid,
  List,
  Paper,
  ScrollArea,
  Text,
  Timeline,
  Title,
  Transition,
  UnstyledButton,
  rem
} from '@mantine/core'
import { useInViewport } from '@mantine/hooks'
import { IconPoint } from '@tabler/icons-react'
import { useState } from 'react'
import { ListThemeIcon } from './ListThemeIcon'

const SCROLL_VIEWPORT_HEIGHT = 440

type FeatureCardProps = {
  opened: boolean
}

const StartFromScratchPage = ({ opened }: FeatureCardProps) => {
  const { ref, inViewport } = useInViewport()
  return (
    <Flex ref={ref} p="lg" px="xl" h={rem(SCROLL_VIEWPORT_HEIGHT)}>
      <Transition
        mounted={opened && inViewport}
        transition="fade"
        duration={400}
        timingFunction="ease"
      >
        {(styles) => (
          <Box m="auto" style={styles}>
            <Timeline
              active={3}
              bulletSize={24}
              lineWidth={2}
              color="brand-primary.6"
            >
              <Timeline.Item
                bullet={<IconPoint size={25} />}
                title={<strong>Start From Scratch</strong>}
              >
                <Text size="sm" mt={4}>
                  These models are basic and do not contain any pre-existing
                  training data. They are ideal for users who prefer to train
                  their models from the ground up, tailored to their specific
                  needs.
                </Text>
              </Timeline.Item>

              <Timeline.Item
                bullet={<IconPoint size={25} />}
                title={<strong>Pre-trained</strong>}
              >
                <Text size="sm" mt={4}>
                  These are ready-made models which have been trained by
                  VisionPlatform, incorporating a wide range of data and
                  knowledge. They come with some pre-existing knowledge, which
                  can save you time and effort.
                </Text>
              </Timeline.Item>
            </Timeline>
          </Box>
        )}
      </Transition>
    </Flex>
  )
}

const SelectingBaseModelPage = ({ opened }: FeatureCardProps) => {
  const { ref, inViewport } = useInViewport()

  return (
    <Flex ref={ref} p="lg" px="xl" h={rem(SCROLL_VIEWPORT_HEIGHT)}>
      <Transition
        mounted={opened && inViewport}
        transition="fade"
        duration={400}
        timingFunction="ease"
      >
        {(styles) => (
          <Box m="auto" style={styles}>
            <Timeline
              active={3}
              bulletSize={24}
              lineWidth={2}
              color="brand-primary.6"
            >
              <Timeline.Item bullet={<IconPoint size={25} />}>
                <Text>
                  Take a look at the options available for each type of starting
                  point.
                </Text>
              </Timeline.Item>

              <Timeline.Item bullet={<IconPoint size={25} />}>
                <Text>
                  Consider what you need for your project and how much
                  customization you want to do.
                </Text>
              </Timeline.Item>

              <Timeline.Item bullet={<IconPoint size={25} />}>
                <Text>
                  Choose the base model that best aligns with your project goals
                  and preferences.
                </Text>
              </Timeline.Item>
            </Timeline>
          </Box>
        )}
      </Transition>
    </Flex>
  )
}

const NextStepsPage = ({ opened }: FeatureCardProps) => {
  const { ref, inViewport } = useInViewport()

  return (
    <Flex ref={ref} p="lg" px="xl" h={rem(SCROLL_VIEWPORT_HEIGHT)}>
      <Transition
        mounted={opened && inViewport}
        transition="fade"
        duration={400}
        timingFunction="ease"
      >
        {(styles) => (
          <Box m="auto" style={styles}>
            <Timeline
              active={3}
              bulletSize={24}
              lineWidth={2}
              color="brand-primary.6"
            >
              <Timeline.Item
                bullet={<IconPoint size={25} />}
                title={<strong>Deploy Model</strong>}
              >
                <Text size="sm" mt={4}>
                  If you are ready to get started right away, you can deploy
                  your chosen model for immediate use.
                </Text>
              </Timeline.Item>

              <Timeline.Item
                bullet={<IconPoint size={25} />}
                title={<strong>Test Model</strong>}
              >
                <Text size="sm" mt={4}>
                  Try out your chosen model by uploading some sample photos or
                  data. This lets you see how well it works before you dive in.
                </Text>
              </Timeline.Item>

              <Timeline.Item
                bullet={<IconPoint size={25} />}
                title={<strong>Continue</strong>}
              >
                <Text size="sm" mt={4}>
                  If you want to make changes or add more to your model, you can
                  continue working on it. This option lets you refine your model
                  to fit your needs perfectly.
                </Text>
              </Timeline.Item>
            </Timeline>
          </Box>
        )}
      </Transition>
    </Flex>
  )
}

const FinalizingPage = ({ opened }: FeatureCardProps) => {
  const { ref, inViewport } = useInViewport()

  return (
    <Flex ref={ref} p="lg" px="xl" h={rem(SCROLL_VIEWPORT_HEIGHT)}>
      <Transition
        mounted={opened && inViewport}
        transition="fade"
        duration={400}
        timingFunction="ease"
      >
        {(styles) => (
          <Box m="auto" style={styles}>
            <Timeline
              active={3}
              bulletSize={24}
              lineWidth={2}
              color="brand-primary.6"
            >
              <Timeline.Item bullet={<IconPoint size={25} />}>
                <Text mt={4}>
                  Take a moment to review your choice and make sure it is what
                  you want
                </Text>
              </Timeline.Item>

              <Timeline.Item bullet={<IconPoint size={25} />}>
                <Text mt={4}>
                  Remember, you can always come back and make changes later if
                  you need to.
                </Text>
              </Timeline.Item>
            </Timeline>
          </Box>
        )}
      </Transition>
    </Flex>
  )
}

type FeaturePageProps = {
  page: number
}
const FeaturePage = ({ page }: FeaturePageProps) => {
  return page === 0 ? (
    <StartFromScratchPage opened={page === 0} />
  ) : page === 1 ? (
    <SelectingBaseModelPage opened={page === 1} />
  ) : page === 2 ? (
    <NextStepsPage opened={page === 2} />
  ) : (
    <FinalizingPage opened={page === 3} />
  )
}

export const BaseModelContent = () => {
  const [currentPage, setCurrentPage] = useState(0)

  return (
    <Grid gutter="lg">
      <Grid.Col span={{ base: 12, lg: 5 }}>
        <Title order={2}> Choosing a Starting Point for Your AI Project</Title>
        <Text c="dimmed" mt="md">
          On this page, you will be guided through the process of selecting a
          base model for your project. The base model serves as the foundation
          for your AI model and determines its initial capabilities.
        </Text>
        <List mt="lg" spacing="md" size="lg" center>
          <List.Item
            icon={<ListThemeIcon currentPage={currentPage} page={0} />}
          >
            <UnstyledButton onClick={() => setCurrentPage(0)}>
              Understanding Base Model Types
            </UnstyledButton>
          </List.Item>
          <List.Item
            icon={<ListThemeIcon currentPage={currentPage} page={1} />}
          >
            <UnstyledButton onClick={() => setCurrentPage(1)}>
              Selecting a Base Model
            </UnstyledButton>
          </List.Item>
          <List.Item
            icon={<ListThemeIcon currentPage={currentPage} page={2} />}
          >
            <UnstyledButton onClick={() => setCurrentPage(2)}>
              Next Steps After Selecting a Base Mode
            </UnstyledButton>
          </List.Item>
          <List.Item
            icon={<ListThemeIcon currentPage={currentPage} page={3} />}
          >
            <UnstyledButton onClick={() => setCurrentPage(3)}>
              Finalizing Your Selection
            </UnstyledButton>
          </List.Item>
        </List>
      </Grid.Col>
      <Grid.Col span={{ base: 12, lg: 7 }}>
        <Paper radius="md" h={rem(SCROLL_VIEWPORT_HEIGHT + 10)} withBorder>
          <ScrollArea w="100%" h={rem(SCROLL_VIEWPORT_HEIGHT)} type="auto">
            <FeaturePage page={currentPage} />
          </ScrollArea>
        </Paper>
      </Grid.Col>
    </Grid>
  )
}
