import { ThemeIcon } from '@mantine/core'
import { IconCheck, IconCircle } from '@tabler/icons-react'

type SelectionIndicatorProps = {
  isSelected: boolean
}

export const SelectionIndicator = ({ isSelected }: SelectionIndicatorProps) => {
  if (isSelected) {
    return (
      <ThemeIcon variant="filled" radius="xl" size="sm">
        <IconCheck style={{ width: '70%', height: '70%' }} />
      </ThemeIcon>
    )
  }

  return (
    <ThemeIcon variant="light" color="white" radius="xl" size="sm">
      <IconCircle style={{ width: '90%', height: '90%' }} />
    </ThemeIcon>
  )
}
