import { Group, NavLink, Text } from '@mantine/core'
import { IconCircleFilled } from '@tabler/icons-react'
import { FormattedMessage } from 'react-intl'
import { DatasetVersion } from '@/types/dataset'
import { getFormattedDate } from '@/utils/date'
import { DatasetStatusChip } from '../../DatasetStatusChip/DatasetStatusChip'

type DatasetVersionLinksProps = {
  selectedVersionId: string
  versions: DatasetVersion[]
  onVersionSelect: (versionId: string) => void
}

export const DatasetVersionLinks = ({
  selectedVersionId,
  versions,
  onVersionSelect
}: DatasetVersionLinksProps) => {
  return versions.map((version) => (
    <NavLink
      key={version.id}
      active={selectedVersionId === version.id}
      label={
        <Group gap={4}>
          <Text size="sm">
            <FormattedMessage
              id="datasets.versionNumber"
              values={{ number: version.version }}
            />
          </Text>

          <Text size="xs">
            (
            {getFormattedDate(version.created_at, {
              day: 'numeric',
              month: 'numeric',
              year: 'numeric'
            })}
            )
          </Text>
        </Group>
      }
      styles={{
        label: {
          textTransform: 'lowercase'
        }
      }}
      leftSection={
        <IconCircleFilled
          size={6}
          style={{
            visibility: selectedVersionId === version.id ? 'visible' : 'hidden'
          }}
        />
      }
      rightSection={<DatasetStatusChip status={version.status} />}
      onClick={() => onVersionSelect(version.id)}
    />
  ))
}
