import { Select } from '@mantine/core'
import { useIntl } from 'react-intl'
import { DownloadType } from './useDownloadAs'

type DownloadTypeSelectProps = {
  value?: DownloadType | null
  onDownloadTypeChange: (type: DownloadType) => void
}

export const DownloadTypeSelect = ({
  value,
  onDownloadTypeChange
}: DownloadTypeSelectProps) => {
  const intl = useIntl()

  return (
    <Select
      placeholder={intl.formatMessage({ id: 'events.pick.downloadType' })}
      data={[
        {
          value: `${DownloadType.PDF}`,
          label: 'PDF'
        },
        {
          value: `${DownloadType.CSV}`,
          label: 'CSV'
        }
      ]}
      value={value ? String(value) : ''}
      comboboxProps={{ withinPortal: false }}
      onChange={(value) => onDownloadTypeChange(Number(value))}
    />
  )
}
