import {
  Box,
  Button,
  Group,
  MultiSelect,
  TextInput,
  Textarea,
  ThemeIcon
} from '@mantine/core'
import { isNotEmpty, useForm } from '@mantine/form'
import { IconPolygon, IconRectangle, IconTag } from '@tabler/icons-react'
import { ReactNode } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import {
  LabelOption,
  LabelsInput
} from '@/components/labels/LabelsInput/LabelsInput'
import { DatasetLabelingType } from '@/types/dataset'
import { Label } from '@/types/label'
import { LabelingTypeOption } from './LabelingTypeOption'

const DESCRIPTION_MAX_LENGTH = 500

const labelingTypeNames = {
  [DatasetLabelingType.ObjectDetection]: {
    name: 'annotation.type.objectDetection',
    description: 'annotation.type.objectDetection.description',
    disabled: false,
    icon: (
      <ThemeIcon variant="light">
        <IconRectangle style={{ width: '80%', height: '80%' }} />
      </ThemeIcon>
    )
  },
  [DatasetLabelingType.Segmentation]: {
    name: 'annotation.type.segmentation',
    description: 'annotation.type.segmentation.description',
    disabled: false,
    icon: (
      <ThemeIcon variant="light">
        <IconPolygon style={{ width: '80%', height: '80%' }} />
      </ThemeIcon>
    )
  },
  [DatasetLabelingType.Classification]: {
    name: 'annotation.type.classification',
    description: 'annotation.type.classification.description',
    disabled: true,
    icon: (
      <ThemeIcon variant="light">
        <IconTag style={{ width: '80%', height: '80%' }} />
      </ThemeIcon>
    )
  }
}

export type FormValues = {
  name: string
  description: string
  classes: LabelOption[]
  labelingTypes: DatasetLabelingType[]
}

type DatasetFormProps = {
  initialValues?: FormValues
  classes: Label[]
  submitLabel: string | ReactNode
  onCancel: () => void
  onSubmit: (formValues: FormValues) => void
}

export const DatasetForm = ({
  initialValues,
  classes,
  submitLabel,
  onCancel,
  onSubmit
}: DatasetFormProps) => {
  const intl = useIntl()

  const form = useForm<FormValues>({
    initialValues: {
      name: initialValues?.name || '',
      description: initialValues?.description || '',
      classes: initialValues?.classes || [],
      labelingTypes: initialValues?.labelingTypes || []
    },

    validate: {
      name: isNotEmpty(
        intl.formatMessage({
          id: 'datasets.name.required'
        })
      ),
      description: (value) => {
        if (value.length > DESCRIPTION_MAX_LENGTH) {
          return intl.formatMessage(
            {
              id: 'datasets.description.maxChars'
            },
            {
              maxChars: DESCRIPTION_MAX_LENGTH
            }
          )
        }
      },
      classes: (value) => {
        if (value.length === 0) {
          return intl.formatMessage({
            id: 'annotation.classes.required'
          })
        }
      },
      labelingTypes: (value) => {
        if (value.length === 0) {
          return intl.formatMessage({
            id: 'annotation.type.required'
          })
        }
      }
    }
  })

  const classOptions = classes.map((c) => ({
    name: c.name,
    color: c.color
  }))

  const labelingTypeOptions = Object.entries(labelingTypeNames).map(
    ([type, config]) => ({
      value: type,
      label: intl.formatMessage({
        id: config.name
      }),
      disabled: config.disabled
    })
  )

  return (
    <form noValidate onSubmit={form.onSubmit((values) => onSubmit(values))}>
      <TextInput
        label={<FormattedMessage id="datasets.name" />}
        mb="xl"
        autoFocus
        data-autofocus // required for auto focus inside the modal
        {...form.getInputProps('name')}
      />

      <Textarea
        label={<FormattedMessage id="datasets.description" />}
        mb="xl"
        minRows={4}
        maxRows={4}
        autosize
        {...form.getInputProps('description')}
      />

      <MultiSelect
        mb="xl"
        label={<FormattedMessage id="annotation.type" />}
        data={labelingTypeOptions}
        maxDropdownHeight={320}
        renderOption={({ option, checked }) => {
          const { value } = option as { value: DatasetLabelingType }

          return (
            <LabelingTypeOption
              icon={labelingTypeNames[value].icon}
              name={<FormattedMessage id={labelingTypeNames[value].name} />}
              description={
                <FormattedMessage id={labelingTypeNames[value].description} />
              }
              isChecked={checked || false}
              isDisabled={option.disabled}
            />
          )
        }}
        {...form.getInputProps('labelingTypes')}
      />

      <Box>
        <LabelsInput
          label={<FormattedMessage id="annotation.classes" />}
          values={form.values.classes}
          options={classOptions}
          error={form.errors.classes}
          onChange={(value) => form.setFieldValue('classes', value)}
        />
      </Box>

      <Group justify="end" mt={180}>
        <Button miw={120} variant="default" onClick={onCancel}>
          <FormattedMessage id="cancel" />
        </Button>

        <Button type="submit" miw={120}>
          {submitLabel}
        </Button>
      </Group>
    </form>
  )
}
