import { Box, Card, Image, Overlay, Stack, Text } from '@mantine/core'
import { useHover } from '@mantine/hooks'
import ImagePlaceholder from '@/assets/image-placeholder.png'
import { SelectableCard } from '@/components/ui-shared/SelectableCard/SelectableCard'
import { DatasetType } from '@/types/dataset'
import { DatasetTypeChip } from '../DatasetTypeChip/DatasetTypeChip'
import { DatasetMenu } from './DatasetMenu'
import { FileCountLabel } from './FileCountLabel'
import { PreviewButton } from './PreviewButton'

const IMAGE_HEIGHT = 74

type DatasetCardProps = {
  name: string
  thumbnailUrl: string
  fileCount: number
  datasetType: DatasetType
  isSelected: boolean
  onSelect: () => void
  onPreview: () => void
  onDelete: () => void
}

export const DatasetCard = ({
  name,
  thumbnailUrl,
  fileCount,
  datasetType,
  isSelected,
  onSelect,
  onPreview,
  onDelete
}: DatasetCardProps) => {
  const { hovered, ref } = useHover()

  const imageStyles = {
    root: {
      filter: isSelected ? 'none' : 'blur(1px)',
      opacity: isSelected ? 1 : 0.5
    }
  }

  return (
    <SelectableCard
      height={150}
      renderAs="div"
      isSelected={isSelected}
      onClick={onSelect}
    >
      <Card.Section ref={ref} withBorder>
        <Box pos="relative">
          <Image
            h={IMAGE_HEIGHT}
            fit="cover"
            src={thumbnailUrl}
            fallbackSrc={ImagePlaceholder}
            styles={imageStyles}
          />

          {hovered && (
            <Overlay color="black" backgroundOpacity={0.5} blur={50} center>
              <PreviewButton
                onClick={(e) => {
                  e.stopPropagation()
                  onPreview()
                }}
              />

              <Box pos="absolute" bottom={0} right={0}>
                <FileCountLabel fileCount={fileCount} />
              </Box>
            </Overlay>
          )}

          <Box pos="absolute" top={0} right={8}>
            <DatasetTypeChip datasetType={datasetType} />
          </Box>
        </Box>
      </Card.Section>

      <Box pos="relative" pt="md">
        <Stack align="center" gap={8}>
          <Text
            w="100%"
            ta="center"
            size="xs"
            fw="bold"
            style={{
              userSelect: 'none'
            }}
            truncate
          >
            {name}
          </Text>

          <Text size="xs" c="dimmed">
            some info
          </Text>
        </Stack>

        <Box pos="absolute" top={4} right={-12}>
          <DatasetMenu
            onDelete={(e) => {
              e.stopPropagation()
              onDelete()
            }}
          />
        </Box>
      </Box>
    </SelectableCard>
  )
}
