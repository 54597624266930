import {
  Combobox,
  Pill,
  PillsInput,
  rem,
  useCombobox,
  useMantineTheme
} from '@mantine/core'
import { IconLock } from '@tabler/icons-react'
import { ReactNode, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { getRandomColor } from '@/theme/randomColor'

export type LabelOption = {
  name: string
  color: string
  nonRemovable?: boolean
}

const getPillStyles = (color: string, nonRemovable?: boolean) => ({
  root: {
    color: 'white',
    backgroundColor: color,
    opacity: nonRemovable ? 0.6 : 1
  }
})

type LabelsInputProps = {
  values: LabelOption[]
  options: LabelOption[]
  label?: string | ReactNode
  placeholder?: string
  disabled?: boolean
  error?: ReactNode
  onChange: (values: LabelOption[]) => void
}

export const LabelsInput = ({
  values,
  options,
  label,
  placeholder,
  disabled = false,
  error,
  onChange
}: LabelsInputProps) => {
  const theme = useMantineTheme()
  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption()
  })

  const [search, setSearch] = useState('')

  const closeDropdown = () => {
    combobox.closeDropdown()
    combobox.targetRef.current?.blur()
  }

  const handleValueSelect = (val: string) => {
    setSearch('')

    if (val === '$create') {
      const hasMatch = values.find(
        (item) => item.name.toLowerCase() === search.trim().toLowerCase()
      )

      if (!hasMatch) {
        onChange([...values, { name: search.trim(), color: getRandomColor() }])
        closeDropdown()
      }
    } else {
      const option = options.find((item) => item.name === val)

      if (option) {
        onChange([...values, option])
        closeDropdown()
      }
    }
  }

  const handleValueRemove = (val: LabelOption) => {
    if (disabled || val.nonRemovable) {
      return
    }
    onChange(values.filter((v) => v.name !== val.name))
    combobox.focusTarget()
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Backspace' && search.length === 0) {
      event.preventDefault()
      handleValueRemove(values[values.length - 1])
    }

    if (event.key === 'Escape') {
      // fix issue with modal closing on esc
      event.stopPropagation()
    }
  }

  const selectedValues = values.map((item) => (
    <Pill
      key={item.name}
      styles={getPillStyles(item.color, item.nonRemovable)}
      withRemoveButton={!item.nonRemovable}
      onRemove={() => handleValueRemove(item)}
    >
      {item.name}

      {item.nonRemovable && (
        <IconLock
          size={14}
          style={{ position: 'relative', top: '2px', marginLeft: '4px' }}
        />
      )}
    </Pill>
  ))

  const filteredOptions = options
    .filter((item) => !values.find((v) => v.name === item.name))
    .filter((item) =>
      item.name.toLowerCase().includes(search.trim().toLowerCase())
    )
    .sort((a, b) => a.name.localeCompare(b.name))

  const hasExactMatch = filteredOptions.some(
    (item) => item.name.toLowerCase() === search.trim().toLowerCase()
  )

  return (
    <Combobox
      store={combobox}
      shadow="sm"
      disabled={disabled}
      styles={{
        empty: {
          fontSize: rem(13),
          color: theme.colors.dark[9]
        }
      }}
      offset={0}
      onOptionSubmit={handleValueSelect}
    >
      <Combobox.DropdownTarget>
        <PillsInput
          disabled={disabled}
          label={label}
          error={error}
          onClick={() => combobox.openDropdown()}
        >
          <Pill.Group disabled={disabled}>
            {selectedValues}

            <Combobox.EventsTarget>
              <PillsInput.Field
                value={search}
                placeholder={placeholder}
                onFocus={() => combobox.openDropdown()}
                onBlur={() => combobox.closeDropdown()}
                onChange={(event) => {
                  setSearch(event.currentTarget.value)
                }}
                onKeyDown={handleKeyDown}
              />
            </Combobox.EventsTarget>
          </Pill.Group>
        </PillsInput>
      </Combobox.DropdownTarget>

      <Combobox.Dropdown>
        <Combobox.Options mah={220} style={{ overflowY: 'auto' }}>
          {search.trim().length > 1 && !hasExactMatch && (
            <Combobox.Option value="$create">
              <FormattedMessage
                id="labels.input.add"
                values={{
                  name: `"${search}"`
                }}
              />
            </Combobox.Option>
          )}

          {filteredOptions.map((item) => (
            <Combobox.Option key={item.name} value={item.name}>
              <Pill styles={getPillStyles(item.color)}>{item.name}</Pill>
            </Combobox.Option>
          ))}

          {filteredOptions.length === 0 && search.trim().length === 0 && (
            <Combobox.Empty ta="left">
              <FormattedMessage id="labels.typeLabelName" />
            </Combobox.Empty>
          )}
        </Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  )
}
