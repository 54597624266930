import { SimpleGrid } from '@mantine/core'
import { FormattedMessage } from 'react-intl'
import { InfiniteScroll } from '@/components/ui-shared/InfiniteScroll/InfiniteScroll'
import { useGetUserMlModels } from '@/queries/modelQueries'
import { ModelType, UserMLModelStatus } from '@/types/model'
import { BaseModelCard } from '../BaseModelCard/BaseModelCard'
import { ModelsErrorLoader } from '../ModelsErrorLoader/ModelsErrorLoader'

type UserTrainedModelListProps = {
  applicationId: string
  selectedModelId: string
  selectedModelType: ModelType | undefined
  onSelectionChange: (modelId: string, type: ModelType) => void
}

export const UserTrainedModelList = ({
  applicationId,
  selectedModelId,
  selectedModelType,
  onSelectionChange
}: UserTrainedModelListProps) => {
  const {
    data: userModels,
    isFetching: isFetchingUserModels,
    isError: isErrorUserModels,
    refetch: refetchUserModels,
    fetchNextPage: fetchNextPageUserModels,
    hasNextPage: hasNextPageUserModels
  } = useGetUserMlModels(applicationId || '', {
    status: UserMLModelStatus.Trained
  })

  const handleLoadMorePretrainedModels = () => {
    void fetchNextPageUserModels()
  }

  const userMlModels = userModels?.pages?.flatMap((page) => page.results) || []

  if (isErrorUserModels) {
    return <ModelsErrorLoader handleOnReload={() => void refetchUserModels()} />
  }
  if (userMlModels.length === 0) {
    return <FormattedMessage id="models.noUserTrainedModels" />
  }
  return (
    <InfiniteScroll
      hasMore={hasNextPageUserModels}
      isFetching={isFetchingUserModels}
      onLoadMore={handleLoadMorePretrainedModels}
    >
      <SimpleGrid
        cols={{ base: 1, sm: 2, lg: 4, xl: 6 }}
        spacing="lg"
        verticalSpacing="lg"
      >
        {userMlModels?.map((model, idx) => {
          const isSelected =
            selectedModelId === model.id &&
            selectedModelType === ModelType.UserMLModel

          return (
            <BaseModelCard
              key={idx}
              model={model}
              baseModelName={model?.root_model?.name}
              brandLogo={model?.root_model?.brand_logo || undefined}
              brandName={model?.root_model?.brand_name || undefined}
              networkType={model?.root_model?.network_type}
              trainable={model?.root_model?.can_be_trained}
              testable={model?.root_model?.can_be_tested}
              deployable={model?.root_model?.can_be_deployed}
              isSelected={isSelected}
              isMoreInfoShown
              onClick={() => onSelectionChange(model.id, ModelType.UserMLModel)}
            />
          )
        })}
      </SimpleGrid>
    </InfiniteScroll>
  )
}
