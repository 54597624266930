import { Parameter, ParameterType, Trigger } from '@/types/businessLogic'
import { LogicLineValuesType } from '../LogicSelection/types'
import { convertCoordinatesToString } from './coordinates'

const getParameterType = (type: LogicLineValuesType): ParameterType => {
  switch (type) {
    case 'roi':
      return ParameterType.ROI
    case 'line_crossing':
      return ParameterType.LineCrossing
  }
}

type BuildBuisnessLogicParameterParams = {
  type: LogicLineValuesType
  coordinates: number[][]
  name: string
  alert: boolean
  upload: boolean
  triggers: Trigger[]
}

export const buildBuisnessLogicParameter = ({
  type,
  coordinates,
  name,
  alert,
  upload,
  triggers
}: BuildBuisnessLogicParameterParams): Parameter => {
  return {
    parameter_type: getParameterType(type),
    coordinates: convertCoordinatesToString(coordinates),
    name,
    event: {
      alert,
      upload,
      triggers: triggers
    }
  }
}
