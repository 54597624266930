import { Button, TextInput } from '@mantine/core'
import { isNotEmpty, useForm } from '@mantine/form'
import { IconKey } from '@tabler/icons-react'
import { ChangeEvent } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

const CODE_MAX_LENGTH = 6

export type FormValues = {
  activationCode: string
  deviceName: string
}

type DeviceActivationFormProps = {
  onSubmit: (formValues: FormValues) => void
}

export const DeviceActivationForm = ({
  onSubmit
}: DeviceActivationFormProps) => {
  const intl = useIntl()

  const form = useForm({
    initialValues: {
      activationCode: '',
      deviceName: ''
    },

    validate: {
      activationCode: isNotEmpty(
        intl.formatMessage({ id: 'devices.validation.enterActivationCode' })
      ),
      deviceName: isNotEmpty(
        intl.formatMessage({ id: 'devices.validation.enterDeviceName' })
      )
    }
  })

  const transformToUppercase = (e: ChangeEvent<HTMLInputElement>) => {
    form.setFieldValue('activationCode', e.target.value.toUpperCase())
  }

  return (
    <form noValidate onSubmit={form.onSubmit((values) => onSubmit(values))}>
      <TextInput
        label={intl.formatMessage({ id: 'devices.activationCode' })}
        placeholder={intl.formatMessage({ id: 'devices.activationCodeHint' })}
        mb="xl"
        maxLength={CODE_MAX_LENGTH}
        autoFocus
        data-autofocus // required for auto focus inside the modal
        {...form.getInputProps('activationCode')}
        onChange={transformToUppercase}
      />

      <TextInput
        label={intl.formatMessage({ id: 'devices.deviceName' })}
        placeholder={intl.formatMessage({ id: 'devices.deviceNameHint' })}
        mb="xl"
        {...form.getInputProps('deviceName')}
      />

      <Button type="submit" leftSection={<IconKey size={18} />} fullWidth>
        <FormattedMessage id="devices.activate" />
      </Button>
    </form>
  )
}
