import { SegmentedControl } from '@mantine/core'
import { useIntl } from 'react-intl'
import { BillingPeriod } from '@/types/subscription'

type BillingPeriodSelectorProps = {
  value: BillingPeriod
  onChange: (value: BillingPeriod) => void
}

export const BillingPeriodSelector = ({
  value,
  onChange
}: BillingPeriodSelectorProps) => {
  const intl = useIntl()

  const data = [
    {
      label: intl.formatMessage({ id: 'monthly' }),
      value: 'monthly'
    },
    {
      label: intl.formatMessage({ id: 'yearly' }),
      value: 'yearly'
    }
  ]

  return (
    <SegmentedControl
      value={value}
      data={data}
      size="md"
      radius="md"
      color="blue"
      bg="white"
      onChange={onChange as (value: string) => void}
    />
  )
}
