import { TextInputProps } from '@mantine/core'
import { useState } from 'react'
import { useValidateIntegrationUrl } from '@/queries/deviceQueries'
import { CameraSource } from '@/types/device'
import {
  ApiEndpointInput,
  UrlStatus
} from '../ApiEndpointInput/ApiEndpointInput'

type IntergrationInputHandlerProps = {
  deviceId: string
  inputProps: TextInputProps
  isTestButtonDisabled?: boolean
}

export const IntergrationInputHandler = ({
  deviceId,
  inputProps,
  isTestButtonDisabled
}: IntergrationInputHandlerProps) => {
  const { mutateAsync: validateUrl, isPending } = useValidateIntegrationUrl()

  const [urlStatus, setUrlStatus] = useState<UrlStatus>('none')

  const handleTestUrl = async () => {
    setUrlStatus('none')

    try {
      const { status } = await validateUrl({
        deviceId,
        integrationUrl: inputProps.value as string,
        source: CameraSource.Milestone
      })

      if (status === 'healthy') {
        setUrlStatus('ok')
      } else {
        setUrlStatus('error')
      }
    } catch (error) {
      setUrlStatus('error')
    }
  }

  return (
    <ApiEndpointInput
      inputProps={inputProps}
      status={urlStatus}
      isTestButtonDisabled={isTestButtonDisabled}
      isLoading={isPending}
      onTestUrlClick={() => void handleTestUrl()}
    />
  )
}
