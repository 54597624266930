import { Box, Progress, Text } from '@mantine/core'
import { FormattedMessage } from 'react-intl'

type ImportProgressBarProps = {
  total: number
  imported: number
}

export const ImportProgressBar = ({
  total,
  imported
}: ImportProgressBarProps) => {
  const progress = (imported / total) * 100

  return (
    <Box>
      <Progress value={progress} />

      <Text ta="right" size="sm" mt={4}>
        <FormattedMessage
          id="import.status.count"
          values={{
            count: imported,
            total
          }}
        />
      </Text>
    </Box>
  )
}
