export type DeviceListItem = Omit<Device, 'attributes'>

export type CameraStreamWithDeviceId = CameraStream & {
  device_id: string
  device_name: string
  is_device_active: boolean
}

export type CameraStream = {
  camera_id: string
  source: CameraSource
  camera_name: string
  image: string
  thumbnail: string
  host: string
  rtsp_url: string
  connected: boolean
  resolution:
    | {
        width: number
        height: number
      }
    | string // TODO we still support old string format h x w for backward compatibility
  attributes?: MilestoneCameraAttributes
}

type CameraAttribute = {
  lastUpdateTs: number
  key: 'cameras'
  value: CameraStream[]
}

type OtherAttribute = {
  lastUpdateTs: number
  key: string
  value: unknown
}

export type MilestoneCameraAttributes = {
  milestone_camera_id: string
  milestone_stream_ids: string[]
}

export type Device = {
  id: string
  name: string
  label: string
  device_profile_name: string
  additional_info: {
    gateway: boolean
    overwriteActivityTime: boolean
    description: string
  }
  firmware_id: string | null
  software_id: string | null
  active: boolean
  customer_id: string
  device_profile_id: string
  attributes: (CameraAttribute | OtherAttribute)[]
}

export enum CameraSource {
  Visionplatform = 'visionplatform',
  Milestone = 'milestone'
}

export type CameraCreationStatusData = {
  status: CameraCreationStatus
  total_tasks: number
  completed_tasks: number
  failed_tasks: number
  failed_rtsp_urls: string[]
}

export type CameraCreationStatus = 'Pending' | 'Complete' | 'Failed'

export type ImportCameraData = {
  id: string
  name: string
  rtsp: {
    id: string
    url: string
    image: string | null
    thumbnail: string | null
  }
}
