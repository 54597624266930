import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { ModelNestedPath, buildModelDetailsLink } from '@/router/paths'
import { UserMLModel, UserMLModelStatus } from '@/types/model'
import { ModelStep, ModelStepper } from './ModelStepper/ModelStepper'

const modelSteps: ModelStep[] = [
  {
    id: 1,
    messageId: 'models.stepper.step1',
    urlPath: ModelNestedPath.selectBaseModel
  },
  {
    id: 2,
    messageId: 'models.stepper.step2',
    urlPath: ModelNestedPath.annotateData
  },
  {
    id: 3,
    messageId: 'models.stepper.step3',
    urlPath: ModelNestedPath.training
  }
]

type ModelStepperHandlerProps = {
  appId: string
  model: UserMLModel
}

export const ModelStepperHandler = ({
  appId,
  model
}: ModelStepperHandlerProps) => {
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const [completedSteps, setCompletedSteps] = useState<number[]>([])
  const [disabledSteps, setDisabledSteps] = useState<number[]>([])

  const activeStepIndex = modelSteps.findIndex((step) =>
    pathname.includes(step.urlPath)
  )

  const handleStepClick = (step: ModelStep) => {
    navigate(buildModelDetailsLink(appId, model.id, step.urlPath))
  }

  useEffect(() => {
    if (model.status === UserMLModelStatus.Trained) {
      setCompletedSteps([0, 1, 2])
      setDisabledSteps([0, 1])
    } else if (model.training !== null) {
      setCompletedSteps([0, 1])
      setDisabledSteps([0, 1])
    } else if (model.status === UserMLModelStatus.PreTrained) {
      setCompletedSteps([0])
      setDisabledSteps([2])
    } else if (model.status === UserMLModelStatus.Draft) {
      setCompletedSteps([])
      setDisabledSteps([1, 2])
    } else {
      setCompletedSteps([])
      setDisabledSteps([])
    }
  }, [model.status, model.training])

  return (
    <ModelStepper
      steps={modelSteps}
      activeStep={activeStepIndex}
      completedSteps={completedSteps}
      disabledSteps={disabledSteps}
      onStepClick={handleStepClick}
    />
  )
}
