import { useCallback, useRef, useState } from 'react'
import {
  useCreateCvatTask,
  useCvatLogin,
  useGetCvatUrl,
  useGetTaskStatus
} from '@/queries/annotationQueries'

type UseStartCvatSessionParams = {
  fileMetadataId: string
  taskName: string
  taskId?: number
}

export const useStartCvatSession = ({
  fileMetadataId,
  taskName,
  taskId
}: UseStartCvatSessionParams) => {
  const processId = useRef(Math.random().toString(36).substring(7))
  const [isLogged, setIsLogged] = useState(false)
  const [cvatTaskId, setCvatTaskId] = useState<number | null>(null)

  const { mutateAsync: login } = useCvatLogin()
  const { mutateAsync: createCvatTask } = useCreateCvatTask()

  const { data: taskStatusData } = useGetTaskStatus({
    taskId: cvatTaskId as number,
    statusType: 'video_upload',
    processId: processId.current,
    enabled: cvatTaskId !== null
  })

  const { data: cvatUrlData } = useGetCvatUrl({
    taskId: taskId ?? (cvatTaskId as number),
    enabled:
      taskStatusData?.is_video_uploaded === true ||
      (taskId !== undefined && isLogged)
  })

  const startCvatSession = useCallback(
    async (fps: number) => {
      await login()
      setIsLogged(true)

      if (taskId === undefined) {
        const { cvat_task_id } = await createCvatTask({
          file_metadata_id: fileMetadataId,
          cvat_fps: fps,
          task_name: taskName
        })

        setCvatTaskId(cvat_task_id)
      }
    },
    [fileMetadataId, login, createCvatTask, taskName, taskId]
  )

  return {
    startCvatSession,
    cvatTaskId: taskId ?? cvatTaskId,
    iframeUrl: cvatUrlData?.url,
    token: cvatUrlData?.token
  }
}
