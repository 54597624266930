import { Checkbox, Group } from '@mantine/core'
import { FormattedMessage } from 'react-intl'

export type EventSettingType = 'notification' | 'video_upload' | 'image_upload'

type EventSettingsProps = {
  notification?: boolean
  videoUpload?: boolean
  imageUpload?: boolean
  showNotification?: boolean
  showVideoUpload?: boolean
  showImageUpload?: boolean
  onChange: (settingType: EventSettingType, value: boolean) => void
}

export const EventSettings = ({
  notification = false,
  videoUpload = false,
  imageUpload = false,
  showImageUpload = false,
  showNotification = false,
  showVideoUpload = false,
  onChange
}: EventSettingsProps) => {
  return (
    <Group gap="xl" mt="lg">
      {showNotification && (
        <Checkbox
          size="xs"
          label={<FormattedMessage id="logic.eventSettings.notifyMe" />}
          checked={notification}
          onChange={(event) =>
            onChange('notification', event.currentTarget.checked)
          }
        />
      )}

      {showImageUpload && (
        <Checkbox
          size="xs"
          label={<FormattedMessage id="logic.eventSettings.uploadImage" />}
          checked={imageUpload}
          onChange={(event) =>
            onChange('image_upload', event.currentTarget.checked)
          }
        />
      )}

      {showVideoUpload && (
        <Checkbox
          size="xs"
          label={<FormattedMessage id="logic.eventSettings.uploadVideo" />}
          checked={videoUpload}
          onChange={(event) =>
            onChange('video_upload', event.currentTarget.checked)
          }
        />
      )}
    </Group>
  )
}
