import { AppShell, ScrollArea } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { ReactNode } from 'react'
import { HEADER_HEIGHT, Header } from '../Header/Header'

const NAVBAR_WIDTH = 320

type AppDetailsLayoutProps = {
  navbar: ReactNode
  children: ReactNode
}

export const AppDetailsLayout = ({
  navbar,
  children
}: AppDetailsLayoutProps) => {
  const [opened, { toggle }] = useDisclosure()

  return (
    <AppShell
      header={{
        height: HEADER_HEIGHT
      }}
      navbar={{
        width: NAVBAR_WIDTH,
        breakpoint: 'sm',
        collapsed: { mobile: !opened }
      }}
    >
      <AppShell.Header px="md" bg="brand-primary">
        <Header isMenuOpen={opened} onBurgerMenuClick={toggle} />
      </AppShell.Header>

      <AppShell.Navbar component={ScrollArea} p={4}>
        {navbar}
      </AppShell.Navbar>

      <AppShell.Main>{children}</AppShell.Main>
    </AppShell>
  )
}
