import { ThemeIcon, rem } from '@mantine/core'
import { IconCircleCheck } from '@tabler/icons-react'

type ListThemeIconProps = {
  currentPage: number
  page: number
}
const currentItem = (currentPage: number, page: number) =>
  currentPage === page ? 'brand-primary.9' : 'brand-primary.0'
export const ListThemeIcon = ({ currentPage, page }: ListThemeIconProps) => {
  return (
    <ThemeIcon color={currentItem(currentPage, page)} size={24} radius="xl">
      <IconCircleCheck style={{ width: rem(16), height: rem(16) }} />
    </ThemeIcon>
  )
}
