import { Avatar, Menu, UnstyledButton } from '@mantine/core'
import { IconCreditCard, IconLogout } from '@tabler/icons-react'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import { RouterPath } from '@/router/paths'
import { useSession } from '@/session/SessionContext'
import { useLogout } from '@/session/useLogout'

const getUserInitials = (firstName: string, lastName: string) => {
  return (firstName[0] || '') + (lastName[0] || '')
}

export const UserMenu = () => {
  const { user } = useSession()
  const { logout } = useLogout()

  const initials = user ? getUserInitials(user.first_name, user.last_name) : ''

  const handleLogout = () => {
    void logout()
  }

  return (
    <Menu shadow="sm">
      <Menu.Target>
        <UnstyledButton>
          <Avatar color="blue.1">{initials}</Avatar>
        </UnstyledButton>
      </Menu.Target>

      <Menu.Dropdown>
        <Menu.Label>
          {user?.first_name} {user?.last_name}
        </Menu.Label>

        <Menu.Item
          component={Link}
          to={RouterPath.mySubscription}
          leftSection={<IconCreditCard size={14} />}
        >
          <FormattedMessage id="subscriptions.manage" />
        </Menu.Item>

        <Menu.Item
          leftSection={<IconLogout size={14} />}
          onClick={handleLogout}
        >
          <FormattedMessage id="logout" />
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  )
}
