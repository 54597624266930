import { Anchor, Box, Text, Title } from '@mantine/core'
import { IconMail } from '@tabler/icons-react'
import { FormattedMessage } from 'react-intl'

type VerifyEmailProps = {
  onResendEmail: () => void
}

export const VerifyEmail = ({ onResendEmail }: VerifyEmailProps) => {
  return (
    <Box ta="center">
      <Title order={3}>
        <FormattedMessage id="verifyEmail.pleaseCheckYourInbox" />
      </Title>

      <Box my="xl">
        <IconMail size={48} color="var(--mantine-color-yellow-8)" />
      </Box>

      <Text mb="md">
        <FormattedMessage id="verifyEmail.linkWasSent" />
      </Text>

      <Text size="xs">
        <FormattedMessage
          id="verifyEmail.didNotReceiveEmail"
          values={{
            link: (chunks) => <Anchor onClick={onResendEmail}>{chunks}</Anchor>
          }}
        />
      </Text>
    </Box>
  )
}
