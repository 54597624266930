import { Button, Group, Modal, Text } from '@mantine/core'
import { FormattedMessage } from 'react-intl'

type NewVersionConfirmModalProps = {
  opened: boolean
  onClose: () => void
}

export const NewVersionConfirmModal = ({
  opened,
  onClose
}: NewVersionConfirmModalProps) => {
  return (
    <Modal
      title={<FormattedMessage id="datasets.newVersion.create" />}
      size="lg"
      opened={opened}
      onClose={onClose}
    >
      <Text size="sm" mb="xl">
        <FormattedMessage id="datasets.newVersion.confirmMessage" />
      </Text>

      <Group justify="end">
        <Button miw={120} variant="default" onClick={onClose}>
          <FormattedMessage id="cancel" />
        </Button>

        <Button>
          <FormattedMessage id="datasets.newVersion.create" />
        </Button>
      </Group>
    </Modal>
  )
}
