import { Box, Text } from '@mantine/core'
import { FormattedMessage } from 'react-intl'

type FileCountLabelProps = {
  fileCount: number
}

export const FileCountLabel = ({ fileCount }: FileCountLabelProps) => {
  return (
    <Box
      py={1}
      px={6}
      style={{
        backgroundColor: 'rgba(0, 0, 0, 0.4)',
        borderRadius: '4px 0 0 0'
      }}
    >
      <Text
        size="xs"
        c="white"
        style={{
          userSelect: 'none'
        }}
      >
        <FormattedMessage
          id="datasets.fileCount"
          values={{ count: fileCount }}
        />
      </Text>
    </Box>
  )
}
