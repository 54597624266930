import { useEffect } from 'react'
import { useApplicationContext } from '@/providers/ApplicationContext'
import { useModelContext } from '@/providers/ModelContext'
import { UserMLModelStatus } from '@/types/model'
import { useModelStepNavigation } from './useModelStepNavigation'

export const ModelDetailsPage = () => {
  const { application } = useApplicationContext()
  const { model } = useModelContext()

  const {
    goToBaseModelScreen,
    goToAnnotateDataScreen,
    goToModelTrainingScreen
  } = useModelStepNavigation({
    appId: application?.id || '',
    modelId: model?.id || ''
  })

  useEffect(() => {
    if (!model) {
      return
    }

    if (model.status === UserMLModelStatus.Trained || model.training !== null) {
      goToModelTrainingScreen(true)
      return
    }

    goToBaseModelScreen(true)
  }, [
    model,
    goToBaseModelScreen,
    goToAnnotateDataScreen,
    goToModelTrainingScreen
  ])

  return null
}
