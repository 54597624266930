import {
  CameraCreationStatusData,
  CameraSource,
  CameraStream,
  Device,
  DeviceListItem,
  ImportCameraData,
  MilestoneCameraAttributes
} from '@/types/device'

import { PaginatedResponse } from '@/types/pagination'
import { vpApi } from './vpApi'

type ClaimData = {
  device_name: string
  device_label: string
  claim_secret_key: string
}

const claimDevice = (data: ClaimData) => {
  return vpApi.post('/v1/devices/claiming/', data)
}

type GetDevicesParams = {
  pageUrl: string
}

type DevicesResponse = PaginatedResponse<DeviceListItem[]>

const getDevices = async ({ pageUrl }: GetDevicesParams) => {
  const res = await vpApi.get<DevicesResponse>(pageUrl)
  return res.data
}

const getDeviceDetails = async (deviceId: string) => {
  const res = await vpApi.get<Device>(`/v1/devices/${deviceId}/`)
  return res.data
}

type CameraData = {
  camera_name: string
  rtsp_url: string
  source: CameraSource
  attributes?: MilestoneCameraAttributes
}

type CreateCameraStreamsParams = {
  deviceId: string
  data: CameraData[]
}

const createCameraStreams = ({ deviceId, data }: CreateCameraStreamsParams) => {
  return vpApi.post(`/v1/devices/${deviceId}/cameras/`, data)
}

const getCameraCreationStatus = async (deviceId: string) => {
  const res = await vpApi.get<CameraCreationStatusData>(
    `/v1/devices/${deviceId}/cameras/camera-addition-status/`
  )
  return res.data
}

type CameraStreamDetailsParams = {
  deviceId: string
  cameraStreamId: string
}

const getCameraStreamDetails = async ({
  deviceId,
  cameraStreamId
}: CameraStreamDetailsParams) => {
  const res = await vpApi.get<CameraStream>(
    `/v1/devices/${deviceId}/cameras/${cameraStreamId}/`
  )
  return res.data
}

type RefreshCameraStreamImageResponse = {
  task_id: string
  camera_id: string
}

const refreshCameraStreamImage = async ({
  deviceId,
  cameraStreamId
}: CameraStreamDetailsParams) => {
  const res = await vpApi.put<RefreshCameraStreamImageResponse>(
    `/v1/devices/${deviceId}/cameras/${cameraStreamId}/camera-screenshot/`
  )
  return res.data
}

const removeCameraStream = ({
  deviceId,
  cameraStreamId
}: CameraStreamDetailsParams) => {
  return vpApi.delete(`/v1/devices/${deviceId}/cameras/${cameraStreamId}/`)
}

type IntegrationUrlParams = {
  deviceId: string
  integrationUrl: string
  source: CameraSource
}

type GetImportCameraStreamsResponse = {
  cameras: ImportCameraData[]
}

const getImportCameraStreams = async ({
  deviceId,
  integrationUrl,
  source
}: IntegrationUrlParams) => {
  const res = await vpApi.post<GetImportCameraStreamsResponse>(
    `/v1/devices/${deviceId}/cameras/integration-streams/`,
    {
      integration_url: integrationUrl,
      source
    }
  )

  return res.data
}

type ValidateIntegrationUrlParams = {
  deviceId: string
  integrationUrl: string
  source: CameraSource
}

type ValidateIntegrationUrlResponse = {
  status: 'healthy'
}

const validateIntegrationUrl = async ({
  deviceId,
  integrationUrl,
  source
}: ValidateIntegrationUrlParams) => {
  const res = await vpApi.post<ValidateIntegrationUrlResponse>(
    `/v1/devices/${deviceId}/cameras/integration-endpoint-check/`,
    {
      integration_url: integrationUrl,
      source
    }
  )

  return res.data
}

export const deviceApi = {
  claimDevice,
  getDevices,
  getDeviceDetails,
  createCameraStreams,
  getCameraCreationStatus,
  getCameraStreamDetails,
  refreshCameraStreamImage,
  removeCameraStream,
  getImportCameraStreams,
  validateIntegrationUrl
}
