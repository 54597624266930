import { Badge, Table } from '@mantine/core'
import { FormattedMessage } from 'react-intl'
import { Label } from '@/types/label'
import { Training } from '@/types/training'
import { getFormattedDateWithTime } from '@/utils/date'
import { TrainingStatusIndicator } from './TrainingStatusIndicator/TrainingStatusIndicator'

type TrainingParamsTableProps = {
  training: Training
  modelName: string
  labels: Label[]
}

export const TrainingParamsTable = ({
  training,
  modelName,
  labels
}: TrainingParamsTableProps) => {
  return (
    <>
      <Table
        verticalSpacing="md"
        horizontalSpacing="md"
        mb="xl"
        withColumnBorders
        withTableBorder
      >
        <Table.Thead bg="gray.0">
          <Table.Tr>
            <Table.Th colSpan={2}>
              <FormattedMessage id="training.summary" />
            </Table.Th>
          </Table.Tr>
        </Table.Thead>

        <Table.Tbody>
          <Table.Tr>
            <Table.Td fw="bold" w="50%">
              <FormattedMessage id="training.modelName" />
            </Table.Td>

            <Table.Td>{modelName}</Table.Td>
          </Table.Tr>

          <Table.Tr>
            <Table.Td fw="bold" w="50%">
              <FormattedMessage id="training.startDate" />
            </Table.Td>

            <Table.Td>{getFormattedDateWithTime(training.created_at)}</Table.Td>
          </Table.Tr>

          <Table.Tr>
            <Table.Td fw="bold">
              <FormattedMessage id="training.completedDate" />
            </Table.Td>

            <Table.Td>
              {training.done_at
                ? getFormattedDateWithTime(training.done_at)
                : '-'}
            </Table.Td>
          </Table.Tr>

          <Table.Tr>
            <Table.Td fw="bold">
              <FormattedMessage id="training.status" />
            </Table.Td>

            <Table.Td>
              <TrainingStatusIndicator status={training.status} />
            </Table.Td>
          </Table.Tr>
        </Table.Tbody>
      </Table>

      <Table
        verticalSpacing="md"
        horizontalSpacing="md"
        withColumnBorders
        withTableBorder
      >
        <Table.Thead bg="gray.0">
          <Table.Tr>
            <Table.Th colSpan={2}>
              <FormattedMessage id="training.params" />
            </Table.Th>
          </Table.Tr>
        </Table.Thead>

        <Table.Tbody>
          <Table.Tr>
            <Table.Td fw="bold" w="50%">
              <FormattedMessage id="trainingParams.frozenLayers" />
            </Table.Td>

            <Table.Td>{training.frozen_layers}</Table.Td>
          </Table.Tr>

          <Table.Tr>
            <Table.Td fw="bold">
              <FormattedMessage id="trainingParams.patience" />
            </Table.Td>

            <Table.Td>{training.patience}</Table.Td>
          </Table.Tr>

          <Table.Tr>
            <Table.Td fw="bold">
              <FormattedMessage id="trainingParams.epoch" />
            </Table.Td>

            <Table.Td>{training.epoch}</Table.Td>
          </Table.Tr>

          <Table.Tr>
            <Table.Td fw="bold">
              <FormattedMessage id="trainingParams.batchSize" />
            </Table.Td>

            <Table.Td>{training.batch_size}</Table.Td>
          </Table.Tr>

          <Table.Tr>
            <Table.Td fw="bold">
              <FormattedMessage id="trainingParams.inferenceResolution" />
            </Table.Td>

            <Table.Td>{training.resolution}</Table.Td>
          </Table.Tr>

          <Table.Tr>
            <Table.Td fw="bold">
              <FormattedMessage id="trainingParams.classes" />
            </Table.Td>

            <Table.Td>
              {labels.map((label) => (
                <Badge key={label.id} color={label.color} mr="xs">
                  {label.name}
                </Badge>
              ))}
            </Table.Td>
          </Table.Tr>
        </Table.Tbody>
      </Table>
    </>
  )
}
