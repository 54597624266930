import { Box, Button, Center, Text } from '@mantine/core'
import { ReactNode } from 'react'
import { FormattedMessage } from 'react-intl'

type ErrorWithReloadProps = {
  children: ReactNode
  onReload: () => void
}

export const ErrorWithReload = ({
  children,
  onReload
}: ErrorWithReloadProps) => {
  return (
    <Center mih={200}>
      <Box ta="center">
        <Text fw="bold" mb="lg">
          {children}
        </Text>

        <Button onClick={onReload}>
          <FormattedMessage id="reload" />
        </Button>
      </Box>
    </Center>
  )
}
