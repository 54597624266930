import { Badge, rem } from '@mantine/core'
import { FormattedMessage } from 'react-intl'
import { DatasetType } from '@/types/dataset'

const DATASET_TYPE_CONFIG = {
  [DatasetType.Primary]: {
    color: 'blue',
    text: 'datasets.type.primary'
  },
  [DatasetType.Secondary]: {
    color: 'orange',
    text: 'datasets.type.secondary'
  }
}

type DatasetTypeChipProps = {
  datasetType: DatasetType
}

export const DatasetTypeChip = ({ datasetType }: DatasetTypeChipProps) => {
  const config = DATASET_TYPE_CONFIG[datasetType]

  if (!config) {
    return null
  }

  return (
    <Badge
      size="xs"
      color={config.color}
      styles={{
        label: {
          userSelect: 'none',
          fontSize: rem(8)
        }
      }}
    >
      <FormattedMessage id={config.text} />
    </Badge>
  )
}
