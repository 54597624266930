import {
  Dropzone,
  FileRejection,
  FileWithPath,
  MIME_TYPES
} from '@mantine/dropzone'
import { ReactNode, RefObject } from 'react'
import { MEDIA_UPLOAD_LIMITS } from '@/config/media'
import { DropzoneInstructions } from './DropzoneInstructions'
import classes from './disabledDropzone.module.css'
import { checkBatchSize, checkFileCount } from './helpers'

const acceptedFileTypes = [MIME_TYPES.jpeg, MIME_TYPES.mp4]

type UploadDropzoneProps = {
  children?: ReactNode
  hidden?: boolean
  disabled?: boolean
  showInstructions?: boolean
  activateOnClick?: boolean
  minHeight?: number
  fileLimit?: number
  openRef?: RefObject<() => void>
  onDrop: (acceptedFiles: File[]) => void
  onReject: (fileRejections: FileRejection[]) => void
}

export const UploadDropzone = ({
  children,
  hidden,
  disabled = false,
  showInstructions,
  activateOnClick,
  minHeight,
  fileLimit,
  openRef,
  onDrop,
  onReject
}: UploadDropzoneProps) => {
  const validateFileName = (file: File) => {
    // file name is not always available despite the typing
    if (
      file.name &&
      file.name.length > MEDIA_UPLOAD_LIMITS.MAX_FILE_NAME_LENGTH
    ) {
      return {
        code: 'name-too-long',
        message: 'File name is too long'
      }
    }

    return null
  }

  const handleDropAny = (
    files: FileWithPath[],
    fileRejections: FileRejection[]
  ) => {
    const { files: checkCountAccepted, fileRejections: checkCountRejections } =
      checkFileCount(files, fileLimit)

    const { files: acceptedFiles, fileRejections: checkBatchSizeRejections } =
      checkBatchSize(
        checkCountAccepted,
        MEDIA_UPLOAD_LIMITS.MAX_BATCH_SIZE_BYTES
      )

    onDrop(acceptedFiles)

    onReject([
      ...checkCountRejections,
      ...checkBatchSizeRejections,
      ...fileRejections
    ])
  }

  return (
    <Dropzone
      openRef={openRef}
      accept={acceptedFileTypes}
      maxSize={MEDIA_UPLOAD_LIMITS.MAX_FILE_SIZE_BYTES}
      disabled={disabled}
      activateOnClick={activateOnClick}
      activateOnKeyboard={false}
      enablePointerEvents={!activateOnClick}
      style={{ display: hidden ? 'none' : 'block' }}
      className={disabled ? classes.disabled : undefined}
      mih={minHeight}
      validator={validateFileName}
      onDropAny={handleDropAny}
      onDrop={() => {}}
    >
      {children}

      {showInstructions && (
        <DropzoneInstructions
          maxFileSize={MEDIA_UPLOAD_LIMITS.MAX_FILE_SIZE_BYTES}
          maxFilesPerUpload={fileLimit}
          fileFormats="jpeg, mp4"
        />
      )}
    </Dropzone>
  )
}
