import { useInfiniteQuery, useMutation, useQuery } from '@tanstack/react-query'
import { datasetApi } from '@/api/datasetApi'
import { objectToQueryString } from '@/utils/url'

export const datasetQueryKeys = {
  all: ['datasets'] as const,
  list: () => [...datasetQueryKeys.all, 'list'] as const,
  details: (datasetId: string) =>
    [...datasetQueryKeys.all, 'details', datasetId] as const,
  files: (datasetId: string, versionId: string) =>
    [...datasetQueryKeys.all, 'files', datasetId, versionId] as const,
  uploadStatus: (batchId: string) =>
    [...datasetQueryKeys.all, 'upolad-status', batchId] as const
}

export const useGetDatasets = () => {
  return useInfiniteQuery({
    queryKey: datasetQueryKeys.list(),
    queryFn: ({ pageParam }) => datasetApi.getDatasets({ pageUrl: pageParam }),
    getNextPageParam: (lastPage) => {
      return lastPage.next ?? undefined
    },
    initialPageParam: `/v2/datasets/?${objectToQueryString({
      limit: 40
      // ordering: 'created_at'
    })}`
  })
}

export const useGetDatasetDetails = (datasetId: string, enabled?: boolean) => {
  return useQuery({
    queryKey: datasetQueryKeys.details(datasetId),
    queryFn: () => datasetApi.getDatasetDetails(datasetId),
    enabled
  })
}

export const useGetDatasetFiles = (datasetId: string, versionId: string) => {
  return useInfiniteQuery({
    queryKey: datasetQueryKeys.files(datasetId, versionId),
    queryFn: ({ pageParam }) =>
      datasetApi.getDatasetFiles({ pageUrl: pageParam }),
    getNextPageParam: (lastPage) => {
      return lastPage.next ?? undefined
    },
    initialPageParam: `/v2/datasets/${datasetId}/versions/${versionId}/media/?${objectToQueryString(
      {
        limit: 40
        // ordering: '-created_at'
      }
    )}`
  })
}

export const useCreateDataset = () => {
  return useMutation({
    mutationFn: datasetApi.createDataset
  })
}

export const useUpdateDataset = () => {
  return useMutation({
    mutationFn: datasetApi.updateDataset
  })
}

export const useUploadFilesToDataset = () => {
  return useMutation({
    mutationFn: datasetApi.uploadFilesToDataset
  })
}

export const useGetUploadStatus = (batchId: string, enabled?: boolean) => {
  return useQuery({
    queryKey: datasetQueryKeys.uploadStatus(batchId),
    queryFn: () => datasetApi.getFileUploadStatus(batchId),
    refetchInterval: (query) => {
      const { data, error } = query.state

      const isFinished =
        data?.status === 'Complete' || data?.status === 'Failed' || error

      return isFinished ? false : 3000
    },
    enabled
  })
}
