import * as Sentry from '@sentry/react'
import { ENV_NAME, SENTRY_DSN, SENTRY_ENABLED } from '.'

export const initSentry = () => {
  Sentry.init({
    dsn: SENTRY_DSN,
    enabled: SENTRY_ENABLED === 'true',
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false
      })
    ],
    environment: ENV_NAME,
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [/^https?:\/\/app\.visionplatform\.ai\/api/],
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  })
}
