import {
  Box,
  Flex,
  Grid,
  List,
  Paper,
  ScrollArea,
  Stack,
  Text,
  Title,
  Transition,
  UnstyledButton,
  rem
} from '@mantine/core'
import { useInViewport } from '@mantine/hooks'
import { useState } from 'react'
import { ListThemeIcon } from './ListThemeIcon'

const SCROLL_VIEWPORT_HEIGHT = 440

type FeatureCardProps = {
  opened: boolean
}

const SelectModel = ({ opened }: FeatureCardProps) => {
  const { ref, inViewport } = useInViewport()
  return (
    <Flex ref={ref} p="lg" px="xl" h={rem(SCROLL_VIEWPORT_HEIGHT)}>
      <Transition
        mounted={opened && inViewport}
        transition="fade"
        duration={400}
        timingFunction="ease"
      >
        {(styles) => (
          <Box m="auto" style={styles}>
            <Stack>
              <Text>
                Begin by selecting the computer vision model you wish to deploy.
                Choose from a list of available models tailored to your specific
                use case and requirements.
              </Text>
              <Text>
                Our platform offers a diverse range of pre-trained models as
                well as the option to deploy custom models trained using your
                own data.
              </Text>
            </Stack>
          </Box>
        )}
      </Transition>
    </Flex>
  )
}

const SelectDevice = ({ opened }: FeatureCardProps) => {
  const { ref, inViewport } = useInViewport()

  return (
    <Flex ref={ref} p="lg" px="xl" h={rem(SCROLL_VIEWPORT_HEIGHT)}>
      <Transition
        mounted={opened && inViewport}
        transition="fade"
        duration={400}
        timingFunction="ease"
      >
        {(styles) => (
          <Box m="auto" style={styles}>
            <Text>
              Choose the device on which you intend to deploy the selected
              model. Our platform seamlessly integrates with NVIDIA Jetson,
              ensuring compatibility and flexibility in deployment options.
              Select one from the available list to proceed.
            </Text>
          </Box>
        )}
      </Transition>
    </Flex>
  )
}

const SelectStream = ({ opened }: FeatureCardProps) => {
  const { ref, inViewport } = useInViewport()

  return (
    <Flex ref={ref} p="lg" px="xl" h={rem(SCROLL_VIEWPORT_HEIGHT)}>
      <Transition
        mounted={opened && inViewport}
        transition="fade"
        duration={400}
        timingFunction="ease"
      >
        {(styles) => (
          <Box m="auto" style={styles}>
            <Text>
              Once you have chosen the target devices, it is time to specify the
              camera streams that will be utilized for inference. Select the
              camera sources from the list of available options or configure
              custom camera streams as needed. This step ensures that your
              computer vision model receives input data from the designated
              camera sources.
            </Text>
          </Box>
        )}
      </Transition>
    </Flex>
  )
}

const SelectBusinessLogic = ({ opened }: FeatureCardProps) => {
  const { ref, inViewport } = useInViewport()

  return (
    <Flex ref={ref} p="lg" px="xl" h={rem(SCROLL_VIEWPORT_HEIGHT)}>
      <Transition
        mounted={opened && inViewport}
        transition="fade"
        duration={400}
        timingFunction="ease"
      >
        {(styles) => (
          <Box m="auto" style={styles}>
            <Stack>
              <Text>
                In this step, you have the option to define any specific
                business logic or custom workflows to be applied during the
                deployment. Tailor the deployment settings to align with your
                operational requirements and desired outcomes.
              </Text>
              <Text>
                Whether it is real-time analytics, event detection, or custom
                triggers, our platform empowers you to configure the deployment
                according to your business needs.
              </Text>
            </Stack>
          </Box>
        )}
      </Transition>
    </Flex>
  )
}

const Deploy = ({ opened }: FeatureCardProps) => {
  const { ref, inViewport } = useInViewport()

  return (
    <Flex ref={ref} p="lg" px="xl" h={rem(SCROLL_VIEWPORT_HEIGHT)}>
      <Transition
        mounted={opened && inViewport}
        transition="fade"
        duration={400}
        timingFunction="ease"
      >
        {(styles) => (
          <Box m="auto" style={styles}>
            <Stack>
              <Text>
                Finally, review your deployment settings and configurations
                before initiating the deployment process. Once you are satisfied
                with the setup, click on the{' '}
                <Text c="brand-primary.6" fw="bold" span>
                  Deploy
                </Text>{' '}
                button to kickstart the deployment process.
              </Text>
              <Text>
                Our platform will handle the deployment tasks automatically,
                ensuring seamless integration and optimization across the
                selected devices and camera streams.
              </Text>
            </Stack>
          </Box>
        )}
      </Transition>
    </Flex>
  )
}

type FeaturePageProps = {
  page: number
}
const FeaturePage = ({ page }: FeaturePageProps) => {
  return page === 0 ? (
    <SelectModel opened={page === 0} />
  ) : page === 1 ? (
    <SelectDevice opened={page === 1} />
  ) : page === 2 ? (
    <SelectStream opened={page === 2} />
  ) : page === 3 ? (
    <SelectBusinessLogic opened={page === 3} />
  ) : (
    <Deploy opened={page === 4} />
  )
}

export const NewDeploymentContent = () => {
  const [currentPage, setCurrentPage] = useState(0)

  return (
    <Grid gutter="lg">
      <Grid.Col span={{ base: 12, lg: 5 }}>
        <Title order={2}>Making a New Deployment</Title>
        <Text c="dimmed" mt="md">
          Creating a new deployment is a straightforward process consisting of
          five simple steps. Follow the steps below to configure and deploy your
          computer vision solution
        </Text>
        <List mt="lg" spacing="md" size="lg" center>
          <List.Item
            icon={<ListThemeIcon currentPage={currentPage} page={0} />}
          >
            <UnstyledButton onClick={() => setCurrentPage(0)}>
              Select Model
            </UnstyledButton>
          </List.Item>
          <List.Item
            icon={<ListThemeIcon currentPage={currentPage} page={1} />}
          >
            <UnstyledButton onClick={() => setCurrentPage(1)}>
              Select Devices
            </UnstyledButton>
          </List.Item>
          <List.Item
            icon={<ListThemeIcon currentPage={currentPage} page={2} />}
          >
            <UnstyledButton onClick={() => setCurrentPage(2)}>
              Select Cameras
            </UnstyledButton>
          </List.Item>
          <List.Item
            icon={<ListThemeIcon currentPage={currentPage} page={3} />}
          >
            <UnstyledButton onClick={() => setCurrentPage(3)}>
              Select Business Logic
            </UnstyledButton>
          </List.Item>
          <List.Item
            icon={<ListThemeIcon currentPage={currentPage} page={4} />}
          >
            <UnstyledButton onClick={() => setCurrentPage(4)}>
              Deploy
            </UnstyledButton>
          </List.Item>
        </List>
      </Grid.Col>
      <Grid.Col span={{ base: 12, lg: 7 }}>
        <Paper radius="md" h={rem(SCROLL_VIEWPORT_HEIGHT + 10)} withBorder>
          <ScrollArea w="100%" h={rem(SCROLL_VIEWPORT_HEIGHT)} type="auto">
            <FeaturePage page={currentPage} />
          </ScrollArea>
        </Paper>
      </Grid.Col>
    </Grid>
  )
}
