import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient
} from '@tanstack/react-query'
import { objectToQueryString } from '@/utils/url'
import { datasetLegacyApi } from '../api/datasetLegacyApi'

export const datasetQueryKeys = {
  all: ['datasets'] as const,
  list: (appId: string) => [...datasetQueryKeys.all, 'list', appId] as const,
  files: (datasetId: string) =>
    [...datasetQueryKeys.all, 'files', datasetId] as const,
  uploadStatus: (batchId: string) =>
    [...datasetQueryKeys.all, 'upolad-status', batchId] as const
}

export const useGetDatasets = (appId: string) => {
  return useInfiniteQuery({
    queryKey: datasetQueryKeys.list(appId),
    queryFn: ({ pageParam }) =>
      datasetLegacyApi.getDatasets({ pageUrl: pageParam }),
    getNextPageParam: (lastPage) => {
      return lastPage.next ?? undefined
    },
    initialPageParam: `/v1/applications/${appId}/datasets/?${objectToQueryString(
      {
        limit: 1,
        ordering: '-created_at'
      }
    )}` // fetching most recent datasets
  })
}

type UseGetFilesParams = {
  datasetId: string
  enabled?: boolean
}

export const useGetFiles = ({
  datasetId,
  enabled = true
}: UseGetFilesParams) => {
  return useInfiniteQuery({
    queryKey: datasetQueryKeys.files(datasetId),
    queryFn: ({ pageParam }) =>
      datasetLegacyApi.getFiles({ pageUrl: pageParam }),
    getNextPageParam: (lastPage) => {
      return lastPage.next ?? undefined
    },
    initialPageParam: `/v1/datasets/${datasetId}/file-metadata/?${objectToQueryString(
      {
        limit: 40,
        ordering: '-created_at'
      }
    )}`,
    enabled
  })
}

export const useCreateDataset = () => {
  return useMutation({
    mutationFn: datasetLegacyApi.createDataset
  })
}

export const useUpdateDataset = () => {
  return useMutation({
    mutationFn: datasetLegacyApi.updateDataset
  })
}

type UseGetUploadStatusParams = {
  batchId: string
  enabled: boolean
}

export const useGetUploadStatus = ({
  batchId,
  enabled
}: UseGetUploadStatusParams) => {
  return useQuery({
    queryKey: datasetQueryKeys.uploadStatus(batchId),
    queryFn: () => datasetLegacyApi.getUploadStatus(batchId),
    refetchInterval: (query) => {
      const { data } = query.state
      const isFinished =
        data?.status === 'Complete' || data?.status === 'Failed'
      return isFinished ? false : 2000
    },
    enabled
  })
}

export const useDeleteFiles = (datasetId: string) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: datasetLegacyApi.deleteFiles,
    onSuccess: () => {
      // refetch files list
      void queryClient.invalidateQueries({
        queryKey: datasetQueryKeys.files(datasetId)
      })
    }
  })
}
