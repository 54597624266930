export type LibraryLogic = {
  id: string
  name: string
  logic_type: LibraryLogicType
  logic_type_display: string
  created_at: string
  updated_at: string
  parameters: LibraryLogicParameter[]
}

export enum LibraryLogicType {
  ObjectDetection = 1,
  ObjectCount = 2,
  ObjectMeasurement3d = 3
}

type LibraryLogicParameter = {
  id: string
  name: string
  parameter_type: ParameterType
  parameter_type_display: string
  created_at: string
  updated_at: string
}

export enum ParameterType {
  ROI = 1,
  LineCrossing = 2
}

export type BusinessLogic = {
  id: string
  name?: string
  logic_type?: LibraryLogicType
  parameters: Parameter[]
  settings: BusinessLogicSetting[]
}

export type Parameter = {
  parameter_type: ParameterType
  coordinates: string
  name: string
  event: Event
}

type Event = {
  alert: boolean
  upload: boolean
  triggers: Trigger[]
}

export type Trigger = {
  label_id: string
  threshold: number
}

export type BusinessLogicSetting = {
  setting_type: BusinessLogicSettingType
  parameters: BusinessLogicSettingParameter[]
}

export enum BusinessLogicSettingType {
  Calibration = 1
}

export type BusinessLogicSettingParameter = CalibrationParameter

type CalibrationParameter = {
  parameter_type: BusinessLogicSettingParameterType.Scale
  side: string
  coordinates: string
  size: number
  unit: 'cm'
}

export enum BusinessLogicSettingParameterType {
  Scale = 1
}
