import { LibraryMLModel, ModelType, UserMLModel } from '@/types/model'
import { PaginatedResponse } from '@/types/pagination'
import { vpApi } from './vpApi'

// model api types
export type UserMLModelResponse = PaginatedResponse<UserMLModel[]>
export type LibraryMLModelResponse = PaginatedResponse<LibraryMLModel[]>

export type GetModelsParams = {
  pageUrl: string
}

export type GetModelDetailsParams = {
  appId: string
  modelId: string
}

export type ModelPayload = {
  status?: number
  base_model_id?: string
  base_model_type?: number
  name?: string
}

export type CreateModelParams = {
  appId: string | undefined
  data: ModelPayload
}

export type UpdateModelParams = {
  appId: string | undefined
  modelId: string | undefined
  data: ModelPayload
}

const getUserMLModels = async ({ pageUrl }: GetModelsParams) => {
  const res = await vpApi.get<UserMLModelResponse>(pageUrl)
  return res.data
}

const getLibraryMLModels = async ({ pageUrl }: GetModelsParams) => {
  const res = await vpApi.get<LibraryMLModelResponse>(pageUrl)
  return res.data
}

const getModelDetails = async ({ appId, modelId }: GetModelDetailsParams) => {
  const res = await vpApi.get<UserMLModel>(
    `/v1/applications/${appId}/models/${modelId}/`
  )
  return res.data
}

const createModel = ({ appId, data }: CreateModelParams) => {
  return vpApi.post<UserMLModel>(`/v1/applications/${appId}/models/`, data)
}

const updateModel = ({ appId, modelId, data }: UpdateModelParams) => {
  return vpApi.patch(`/v1/applications/${appId}/models/${modelId}/`, data)
}

type InferModel = {
  applicationId: string
  modelId: string
  modelType: ModelType
  mediaFiles: File[]
  resolution?: string
}

type InferModelResponse = {
  results: {
    inference_id: string
  }
}

const inferModel = ({
  applicationId,
  modelId,
  modelType,
  mediaFiles,
  resolution
}: InferModel) => {
  const formData = new FormData()
  mediaFiles.forEach((file) => formData.append('media_files', file))
  formData.append('model_type', modelType?.toString() || '')
  formData.append('model_id', modelId)

  if (resolution) formData.append('resolution', resolution)

  return vpApi.post<InferModelResponse>(
    `/v1/applications/${applicationId}/models/${modelId}/inference/`,
    formData
  )
}

export type DeleteModelParams = {
  appId: string
  modelId: string
}

const deleteModel = ({ appId, modelId }: DeleteModelParams) => {
  return vpApi.delete(`/v1/applications/${appId}/models/${modelId}/`)
}

export const modelAPI = {
  getUserMLModels,
  getLibraryMLModels,
  getModelDetails,
  createModel,
  updateModel,
  inferModel,
  deleteModel
}
