import { Box, Button, Group, Title } from '@mantine/core'
import { useInterval } from '@mantine/hooks'
import { IconRefresh } from '@tabler/icons-react'
import { useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import { useDeploymentNavigation } from '@/components/deployments/deployment-editor/helpers/navigation'
import { InferenceHandler } from '@/components/models/ModelDetails/InferenceHandler/InferenceHandler'
import { ErrorWithReload } from '@/components/ui-shared/ErrorWithReload/ErrorWithReload'
import { useGetTrainingDetails } from '@/queries/trainingQueries'
import { ApplicationNestedPath, buildAppLink } from '@/router/paths'
import { UserMLModel } from '@/types/model'
import { TrainingStatus } from '@/types/training'
import { TrainingCompletedBanner } from './TrainingCompletedBanner/TrainingCompletedBanner'
import { TrainingInProgressBanner } from './TrainingInProgressBanner/TrainingInProgressBanner'
import { TrainingParamsTable } from './TrainingParamsTable'

const POLL_INTERVAL = 10000

type TrainingDetailsProps = {
  trainingId: string
  appId: string
  model: UserMLModel
}

export const TrainingDetails = ({
  trainingId,
  appId,
  model
}: TrainingDetailsProps) => {
  const {
    data: trainingData,
    isError,
    isFetching,
    refetch
  } = useGetTrainingDetails(trainingId)

  const { start, stop, active } = useInterval(
    () => void refetch(),
    POLL_INTERVAL
  )

  const { navigateToModelDeployment } = useDeploymentNavigation()

  useEffect(() => {
    if (trainingData) {
      if (trainingData.status === TrainingStatus.Running && !active) {
        start()
      }

      if (
        trainingData.status === TrainingStatus.Completed ||
        trainingData.status === TrainingStatus.Failed
      ) {
        stop()
      }
    }
  }, [trainingData, active, start, stop])

  // Cleanup function to stop the interval when the component is unmounted
  useEffect(() => {
    return stop
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  if (isError) {
    return (
      <ErrorWithReload onReload={() => void refetch()}>
        <FormattedMessage id="training.fetchError" />
      </ErrorWithReload>
    )
  }

  const handleDeployClick = () => {
    navigateToModelDeployment(appId, model.id)
  }

  return (
    <Box
      style={{
        opacity: isFetching ? 0.7 : 1
      }}
    >
      <Title order={3} mb="md">
        <FormattedMessage id="training.results" />
      </Title>

      {trainingData?.status === TrainingStatus.Running && (
        <Box mb="lg">
          <TrainingInProgressBanner
            rightSection={
              <Button
                variant="light"
                size="xs"
                leftSection={<IconRefresh size={16} />}
                onClick={() => void refetch()}
              >
                {isFetching ? (
                  <FormattedMessage id="refreshing" />
                ) : (
                  <FormattedMessage id="refresh" />
                )}
              </Button>
            }
          />
        </Box>
      )}

      {trainingData?.status === TrainingStatus.Completed && (
        <Box mb="lg">
          <TrainingCompletedBanner
            rightSection={
              <Group justify="center">
                <InferenceHandler
                  appId={appId}
                  modelId={model.id}
                  buttonVariant="outline"
                  resolution={trainingData.resolution}
                />
                <Button
                  size="xs"
                  variant="outline"
                  radius="xl"
                  miw={160}
                  component={Link}
                  to={buildAppLink(appId, ApplicationNestedPath.models)}
                >
                  <FormattedMessage id="training.startAgain" />
                </Button>

                <Button
                  size="xs"
                  miw={160}
                  radius="xl"
                  onClick={handleDeployClick}
                >
                  <FormattedMessage id="deployments.deployModel" />
                </Button>
              </Group>
            }
          />
        </Box>
      )}

      {trainingData && (
        <TrainingParamsTable
          training={trainingData}
          modelName={model.name}
          labels={model.labels}
        />
      )}
    </Box>
  )
}
