import { Group } from '@mantine/core'
import {
  MRT_ShowHideColumnsButton,
  MRT_TableInstance,
  MRT_ToggleDensePaddingButton,
  MRT_ToggleFiltersButton,
  MRT_ToggleFullScreenButton
} from 'mantine-react-table'
import { ReactNode } from 'react'
import { Event } from '@/types/event'

type InternalActionsToolbarProps = {
  children: ReactNode
  table: MRT_TableInstance<Event>
}

export const InternalActionsToolbar = ({
  children,
  table
}: InternalActionsToolbarProps) => {
  return (
    <Group>
      {children}

      <MRT_ToggleFiltersButton table={table} />
      <MRT_ShowHideColumnsButton table={table} />
      <MRT_ToggleDensePaddingButton table={table} />
      <MRT_ToggleFullScreenButton table={table} />
    </Group>
  )
}
