import { Group, Text, ThemeIcon } from '@mantine/core'
import { IconCheck, IconClock, IconX } from '@tabler/icons-react'
import { FormattedMessage } from 'react-intl'
import { TrainingStatus } from '@/types/training'

type StatusIconProps = {
  status: TrainingStatus
}

const StatusIcon = ({ status }: StatusIconProps) => {
  if (status === TrainingStatus.Completed) {
    return (
      <ThemeIcon color="green" radius="xl" size="sm">
        <IconCheck style={{ width: '80%', height: '80%' }} stroke={2} />
      </ThemeIcon>
    )
  }

  if (status === TrainingStatus.Failed) {
    return (
      <ThemeIcon color="red" radius="xl" size="sm">
        <IconX style={{ width: '80%', height: '80%' }} stroke={2} />
      </ThemeIcon>
    )
  }

  if (status === TrainingStatus.Running) {
    return (
      <ThemeIcon variant="light" radius="xl" size="sm">
        <IconClock style={{ width: '80%', height: '80%' }} stroke={2} />
      </ThemeIcon>
    )
  }

  return null
}

type StatusTextProps = {
  status: TrainingStatus
}

const StatusText = ({ status }: StatusTextProps) => {
  if (status === TrainingStatus.Completed) {
    return <FormattedMessage id="training.status.completed" />
  }

  if (status === TrainingStatus.Failed) {
    return <FormattedMessage id="training.status.failed" />
  }

  if (status === TrainingStatus.Running) {
    return <FormattedMessage id="training.status.inProgress" />
  }

  return null
}

type TrainingStatusIndicatorProps = {
  status: TrainingStatus
}

export const TrainingStatusIndicator = ({
  status
}: TrainingStatusIndicatorProps) => {
  return (
    <Group gap="sm">
      <StatusIcon status={status} />

      <Text size="xs" fw="bold">
        <StatusText status={status} />
      </Text>
    </Group>
  )
}
