export const isMinLength = (value: string, length: number) =>
  value.length >= length

export const hasNumber = (value: string) => /[0-9]/.test(value)

export const hasUpperCase = (value: string) => /[A-Z]/.test(value)

// https://owasp.org/www-community/password-special-characters
export const hasSpecialChar = (value: string) =>
  /[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/.test(value)
