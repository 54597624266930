import {
  ActionIcon,
  Box,
  Card,
  Image,
  Stack,
  Text,
  Tooltip
} from '@mantine/core'
import { useHover } from '@mantine/hooks'
import { IconTrash } from '@tabler/icons-react'
import { FormattedMessage } from 'react-intl'
import ImagePlaceholder from '@/assets/image-placeholder.png'
import { CameraSource } from '@/types/device'
import { SelectableCard } from '../../../../ui-shared/SelectableCard/SelectableCard'
import { OnlineStatus } from '../../../OnlineStatus/OnlineStatus'
import { CameraSourceChip } from '../CameraSourceChip/CameraSourceChip'

const IMAGE_HEIGHT = 76

type RemoveIconProps = {
  onClick: (event: React.MouseEvent) => void
}

const RemoveIcon = ({ onClick }: RemoveIconProps) => {
  const { hovered, ref } = useHover<HTMLButtonElement>()

  const iconColor = hovered
    ? 'var(--mantine-color-red-8)'
    : 'var(--mantine-color-gray-8)'

  return (
    <Tooltip position="bottom" label={<FormattedMessage id="streams.remove" />}>
      <ActionIcon ref={ref} variant="subtle" radius="xl" onClick={onClick}>
        <IconTrash color={iconColor} size={13} />
      </ActionIcon>
    </Tooltip>
  )
}

type CameraStreamCardProps = {
  name: string
  thumbnailUrl: string
  source: CameraSource
  isActive: boolean
  isSelected: boolean
  disabled: boolean
  onClick: () => void
  onRemoveClick: () => void
}

export const CameraStreamCard = ({
  name,
  thumbnailUrl,
  source,
  isActive,
  isSelected,
  disabled,
  onClick,
  onRemoveClick
}: CameraStreamCardProps) => {
  const handleRemoveClick = () => {
    onRemoveClick()
  }

  return (
    <Box pos="relative">
      <SelectableCard
        isSelected={isSelected}
        disabled={disabled}
        onClick={onClick}
      >
        <Card.Section mb="md">
          <Image
            h={IMAGE_HEIGHT}
            fit="cover"
            src={thumbnailUrl}
            fallbackSrc={ImagePlaceholder}
          />
        </Card.Section>

        <Box pos="relative">
          <Stack align="center" justify="space-between" gap="xs">
            <Text w="100%" ta="center" size="sm" fw={600} truncate>
              {name}
            </Text>

            <OnlineStatus status={isActive ? 'online' : 'offline'} />
          </Stack>
        </Box>
      </SelectableCard>

      <Box pos="absolute" top="50%" right={0}>
        <RemoveIcon onClick={handleRemoveClick} />
      </Box>

      <Box pos="absolute" top={0} right={8}>
        <CameraSourceChip source={source} />
      </Box>
    </Box>
  )
}
