import { Box, Group, Image, Text } from '@mantine/core'
import { FormattedMessage } from 'react-intl'
import VpLogoStripes from '@/assets/vp-stripes.png'
import { BillingPeriod } from '@/types/subscription'

type SubscriptionPlanItemProps = {
  name: string
  description: string
  price: string
  billingPeriod: BillingPeriod
}

export const SubscriptionPlanItem = ({
  name,
  description,
  price,
  billingPeriod
}: SubscriptionPlanItemProps) => {
  return (
    <Group wrap="nowrap" justify="space-between" align="start">
      <Group wrap="nowrap" align="start" gap="xs">
        <Image src={VpLogoStripes} w={40} />

        <Box>
          <Text size="md" fw={500}>
            {name}
          </Text>

          <Text size="xs">{description}</Text>
        </Box>
      </Group>

      <Text fw={600}>
        <FormattedMessage
          id={billingPeriod === 'monthly' ? 'pricePerMonth' : 'pricePerYear'}
          values={{ price }}
        />
      </Text>
    </Group>
  )
}
