import { Box, Button, Card, Overlay, Transition } from '@mantine/core'
import { useHotkeys } from '@mantine/hooks'
import { IconX } from '@tabler/icons-react'
import { ReactNode } from 'react'
import { FormattedMessage } from 'react-intl'

export const TOOLBAR_HEIGHT = 62

type MediaOverlayProps = {
  opened: boolean
  toolbar: ReactNode
  children: ReactNode
  isClosable: boolean
  onClose: () => void
}

export const MediaOverlay = ({
  opened,
  toolbar,
  children,
  isClosable,
  onClose
}: MediaOverlayProps) => {
  useHotkeys([
    [
      'Escape',
      () => {
        if (isClosable) {
          onClose()
        }
      }
    ]
  ])

  return (
    <Transition mounted={opened}>
      {(transitionStyles) => (
        <Overlay style={transitionStyles} backgroundOpacity={0.85} fixed>
          <Box w="100%">
            <Card radius={0}>{toolbar}</Card>

            <Box pos="relative">
              {children}

              {isClosable && (
                <Box pos="absolute" top={4} right={0}>
                  <Button
                    variant="transparent"
                    color="gray.3"
                    rightSection={<IconX size={20} />}
                    onClick={onClose}
                  >
                    <FormattedMessage id="close" />
                  </Button>
                </Box>
              )}
            </Box>
          </Box>
        </Overlay>
      )}
    </Transition>
  )
}
