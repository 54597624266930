import { KonvaEventObject } from 'konva/lib/Node'
import { Circle, Group, Line } from 'react-konva'
import { AnnotationLabel } from '../AnnotationLabel'
import { useDraggablePoint } from '../useDraggablePoint'

const CIRCLE_SIZE = 3

type LineCompletedProps = {
  name: string
  color: string
  points: number[][]
  onCoordinateChange: (points: number[][]) => void
}

export const LineCompleted = ({
  name,
  color,
  points,
  onCoordinateChange
}: LineCompletedProps) => {
  const { handlePointMouseOver, handlePointMouseOut } = useDraggablePoint()

  const handlePointDragMove = (
    e: KonvaEventObject<DragEvent>,
    pointIndex: number
  ) => {
    onCoordinateChange([
      ...points.slice(0, pointIndex),
      [e.target.x(), e.target.y()],
      ...points.slice(pointIndex + 1)
    ])
  }

  return (
    <Group>
      <Line
        points={[points[0], points[1]].flat()}
        stroke={color}
        strokeWidth={3}
      />

      {points.map((point, index) => {
        const x = point[0]
        const y = point[1]

        return (
          <Circle
            key={index}
            x={x}
            y={y}
            radius={CIRCLE_SIZE}
            fill={color}
            draggable
            onDragMove={(e) => handlePointDragMove(e, index)}
            onMouseOver={handlePointMouseOver}
            onMouseOut={handlePointMouseOut}
          />
        )
      })}

      <AnnotationLabel
        x={points[0][0] - 10}
        y={points[0][1] - 30}
        color="rgba(0 0,0,0.8)"
        textColor="white"
        text={name}
      />
    </Group>
  )
}
