import { Image } from '@mantine/core'

import VpLogoDark from '@/assets/vp-logo-480-dark.png'
import VpLogoLight from '@/assets/vp-logo-480-light.png'

const MAX_WIDTH = 240

type VpLogoProps = {
  variant: 'light' | 'dark'
  width?: number
}

export const VpLogo = ({ variant, width }: VpLogoProps) => {
  return (
    <Image
      src={variant === 'light' ? VpLogoLight : VpLogoDark}
      w={width}
      maw={MAX_WIDTH}
    />
  )
}
