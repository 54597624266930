export type DatasetLegacy = {
  id: string
  name: string
  created_at: string
  updated_at: string
}

type MediaType = 'image' | 'video'

export enum FileStatus {
  Unlabeled = 1,
  Labeled = 2
}

export type FileMetadata = {
  id: string
  name: string
  status: FileStatus
  status_display: string
  dataset: number
  media_file: string
  thumbnail: string
  media_type: MediaType
  extension: string
  created_at: string
  updated_at: string
  original_video_fps: number | null
  annotation_id: string | null
}

export type UploadStatus = {
  status: 'Pending' | 'Complete' | 'Failed'
  total_files: number
  uploaded_files: number
  failed_files: number
  failed_file_names: string[]
}

// Dataset V2 Types

export type Dataset = {
  id: string
  name: string
  description: string
  type: DatasetType
  classes: string[]
  labelingTypes: DatasetLabelingType[]
  versions: DatasetVersion[]
}

export type DatasetVersion = {
  id: string
  version: number
  status: DatasetVersionStatus
  created_at: string
}

export enum DatasetType {
  Primary = 'Primary',
  Secondary = 'Secondary'
}

export enum DatasetLabelingType {
  ObjectDetection = 'ObjectDetection',
  Segmentation = 'Segmentation',
  Classification = 'Classification'
}

export enum DatasetVersionStatus {
  Draft = 'Draft',
  Completed = 'Completed'
}
