import { Box } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { useState } from 'react'
import { useApplicationContext } from '@/providers/ApplicationContext'
import { useModelContext } from '@/providers/ModelContext'
import { PageWithFixedToolbar } from '../layout/PageWithFixedToolbar/PageWithFixedToolbar'
import { useModelStepNavigation } from '../models/ModelDetails/useModelStepNavigation'
import { CreateDatasetHandler } from './CreateDatasetHandler/CreateDatasetHandler'
import { CreateDatasetModal } from './CreateDatasetModal/CreateDatasetModal'
import { OwnDatasetList } from './DatasetList/OwnDatasetList'
import { DatasetListToolbar } from './DatasetListToolbar/DatasetListToolbar'
import { DatasetPreview } from './DatasetPreview/DatasetPreview'

export const SelectDatasetPage = () => {
  const [selectedDatasetId, setSlectedDatasetId] = useState('')
  const [previewDatasetId, setPreviewDatasetId] = useState('')

  const [
    isCreateModalOpened,
    { open: openCreateModal, close: closeCreateModal }
  ] = useDisclosure(false)

  const { application } = useApplicationContext()
  const { model } = useModelContext()

  const { goToLabelDataScreen } = useModelStepNavigation({
    appId: application?.id || '',
    modelId: model?.id || ''
  })

  const toggleDatasetSelect = (datasetId: string) => {
    setSlectedDatasetId((prev) => (prev === datasetId ? '' : datasetId))
  }

  const handleOnContinue = () => {
    goToLabelDataScreen(selectedDatasetId)
  }

  const handleDatasetCreated = () => {
    closeCreateModal()
    goToLabelDataScreen('123')
  }

  return (
    <PageWithFixedToolbar
      toolbar={
        <DatasetListToolbar
          isDatasetSelected={selectedDatasetId !== ''}
          name="My Dataset 1"
          onContinue={handleOnContinue}
        />
      }
    >
      <OwnDatasetList
        selectedDatasetId={selectedDatasetId}
        onCreateDataset={openCreateModal}
        onPreviewDataset={setPreviewDatasetId}
        onSelectDataset={toggleDatasetSelect}
      />

      <CreateDatasetModal
        opened={isCreateModalOpened}
        onClose={closeCreateModal}
      >
        <Box mt="md">
          <CreateDatasetHandler
            onCancel={closeCreateModal}
            onDatasetCreated={handleDatasetCreated}
          />
        </Box>
      </CreateDatasetModal>

      <DatasetPreview
        datasetId={previewDatasetId}
        opened={previewDatasetId !== ''}
        onClose={() => setPreviewDatasetId('')}
      />
    </PageWithFixedToolbar>
  )
}
